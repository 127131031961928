import React from 'react';
import {Input, RadioButton, Spinner, Card} from 'ui-kit';
import {BookingsList} from 'components/BookingsList';
import {ETranslations} from "../../types/translates";
import useBookingOrderList from './hooks/useBookingOrderList';

export const BookingOrdersList: React.FC<any> = ({onSelectOrder}) => {

  const {intl,
    setFetchMode,
    setSearchKeyword,
    isLoading,
    handleEditClick,
    sortedOrders,
    fetchMode,
    searchKeyword,
    apiResult} = useBookingOrderList({onSelectOrder})

  return (
    <>
      <Card>
        <Card.Header title={intl.formatMessage({id: ETranslations.PLURAL_REQUESTS_NOM})}>
          <RadioButton value={fetchMode} onChange={setFetchMode} >
            <RadioButton.Button value="active">{intl.formatMessage({id: ETranslations.BASE_ACTIVE})}</RadioButton.Button>
            <RadioButton.Button value="history">{intl.formatMessage({id: ETranslations.UNPROCESSED})}</RadioButton.Button>
          </RadioButton>
          <Input.Search
            value={searchKeyword}
            onSearch={setSearchKeyword}
          />
        </Card.Header>
        <Card.Content>
          {isLoading && <Spinner />}
          <div style={{overflowY: 'auto', display: 'block', flex: '1.0', padding: '15px 0'}}>
            {apiResult
              && (
                <BookingsList
                  canDrag={false}
                  bookings={sortedOrders}
                  onClick={onSelectOrder}
                  onEdit={handleEditClick}
                  withActions
                  compact
                  withDate
                />
              )}
          </div>
        </Card.Content>
      </Card>
    </>
  );
};
