import {useCallback, useState} from "react";
import {FormBooking} from "features/BookingFormProxy/types";
import {useDispatch} from "react-redux";
import {invalidateHallSlots} from "features/api/hallschema-api";
import {useCreateGuestMutation} from "features/api/guest-api";
import {useBooleanState} from "hooks/useBooleanState";
import {useRegisterBookingMutation} from "features/api/bookings-api";


export function useRegisterWithOverbooking() {
  const [createBooking] = useRegisterBookingMutation();
  const [hasManageralError, setHasManageralError] = useState<boolean>(false);
  const [createGuest] = useCreateGuestMutation();
  const dispatch = useDispatch();
  const [overbookingData, setOverbookingData] = useState<FormBooking | undefined>();
  const [isCreating, start, stop] = useBooleanState();


  const closeModal = () => setHasManageralError(false);

  const create = useCallback(
    async (formData) => {
      const data = formData;
      start();
      try {
        if (
          !data?.client?.client_id
          && data?.contact?.contact_type !== 'CONTACT'
          && data.phone
        ) {
          const { data: client } = await createGuest({
            ...data.client,
            phone: data.phone,
          }).unwrap();
          data.client_id = client.client_id;
          data.client = client;
        }
        return await createBooking({data}).unwrap();
      } catch (e) {
        // @ts-ignore
        if (e?.data?.errorCode === 10400) {
          setHasManageralError(true)
        }
        // @ts-ignore
        if (e?.data?.errorCode === 10100) {
          setOverbookingData(data);
        }
        throw e;
      } finally {
        stop();
      }
    },
    [createBooking, setOverbookingData]
  );

  const clearData = useCallback(() => {
    setOverbookingData(undefined);
  }, [setOverbookingData]);

  const createWithOverbooking = useCallback(async () => {
    if (!overbookingData) return undefined;
    start();
    try {
      const result = await createBooking({
        data: overbookingData,
        force: true,
      }).unwrap();
      setOverbookingData(undefined);
      dispatch(invalidateHallSlots());
      return result;
    } finally {
      stop();
    }
  }, [overbookingData, createBooking]);

  return {
    closeModal,
    hasManageralError,
    create,
    createWithOverbooking,
    isConfirmationRequired: !!overbookingData,
    clearData,
    isCreating,
  };
}
