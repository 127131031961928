import React from 'react';
import {Card, ICONS, Button} from 'ui-kit';
import styles from './style.module.scss'
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {ETranslations} from "../../../types/translates";
export interface ConfirmDialogModalProps {
  mode: any;
  data: any;
  handleClick: () => void;
  closeModal: () => void;
}

export default function ConfirmDialogModal(props: ConfirmDialogModalProps) {
  const {closeModal, handleClick} = props;
  const {intl, getIntlDeletingOf} = useIntlUtils();

  return (
    <div
      className={styles.container}
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Card.Header
        title={getIntlDeletingOf(ETranslations.OF_USER)}
        controls={<ICONS.Cross onClick={() => closeModal()} style={{cursor: 'pointer'}}/>}
      />
      <Card.Content className={styles.content}>
        {intl.formatMessage({id: ETranslations.CONFIRM_USER_DELETING})}
      </Card.Content>

      <div className={styles.buttonBlock}>
        <Button
          variant="dangerous"
          onClick={handleClick}
        >
          {intl.formatMessage({id: ETranslations.BASE_DELETE})}
        </Button>
        <Button
          variant="secondary"
          onClick={closeModal}
        >
          {intl.formatMessage({id: ETranslations.BASE_CANCEL})}
        </Button>
      </div>
    </div>
  );
}
