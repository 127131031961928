import {useEditManageralBookingMutation, useGetManageralTabelQuery} from "features/api/managerialTables";
import {useCallback, useEffect, useState} from "react";
import dayjs from "dayjs";
import {useDispatch} from "react-redux";
import {useHallSchemaActions} from "features/HallSchema";
import {invalidateHallSlots} from "features/api/hallschema-api";
import {useFromProxyActions} from "features/BookingFormProxy";
import {getTimeOption} from "components/registration/forms/utils";
import {ManagerialTable} from "types/booking";


const useEditManageralBooking = (booking: ManagerialTable) => {
    const {reset: closeEdit, setEditMode} = useFromProxyActions();
    const {data, isLoading} = useGetManageralTabelQuery(booking.notes.management_table_id);
    const [value, setValue] = useState({start_date: undefined});
    const [manageralError, setManageralError] = useState<string>('');
    const [editManagerlBooking] = useEditManageralBookingMutation();
    const dispatch = useDispatch();
    const {setActiveTables} = useHallSchemaActions();


    const onSubmit = useCallback(async (values) => {
        const {tables, time, end_date, bookingId, visit_time, duration,} = values;
        const payload = {
            date: end_date,
            time: `${dayjs(time.value).format("HH:mm:ss")}`,
            table_ids: tables,
            duration,
            visit_time
        };
        editManagerlBooking({id: bookingId, payload}).then((res) => {
            // @ts-ignore
            if (res?.error) {
                // @ts-ignore
                if (res.error.data.errorCode === 10100) {
                    //@ts-ignore
                    setManageralError(res.error.data.errorMessage)
                    setActiveTables({activeTables: data?.data?.places.map((i: { id: any }) => i.id),})
                }
            }

            else {
                dispatch(invalidateHallSlots())
                setEditMode(false)
            }

        })

    }, [data]);

    const closeErrorModal = () => {
        setManageralError('');
    }


    useEffect(() => {
        setValue(() => {
            return {
                start_date: data?.data?.bookingDate,
                end_date: data?.data?.bookingDate,
                time: getTimeOption(data?.data?.bookingTime),
                duration: data?.data?.visitTime,
                tables: data?.data?.places.map((i: { id: any }) => i.id),
                placeId: data?.data?.places[0].placeId,
                date: new Date(`${data?.data?.bookingDate} ${data?.data?.bookingTime}`),
                persons: 1,
                bookingId: data?.data?.notes?.management_table_id,
            };
        });

    }, [data, isLoading, booking]);

    return {
        isLoading,
        closeEdit,
        data,
        value,
        onSubmit,
        manageralError,
        closeErrorModal
    }
}

export default useEditManageralBooking