import cn from "classnames";
import dayjs from "dayjs";
import {useIntlUtils} from "hooks/useIntlUtils";
import {useCallback} from "react";
import {ETranslations} from "types/translates";
import {preventDefault, stopPropagation} from "utils";

interface DataTabProps {
  index: number;
  item: any;
  datePickerHandler: (value: string) => void;
  styles: any;
  isSameDay: boolean;
  disabled: boolean;
}

export function DataTab({index, item, datePickerHandler, styles, isSameDay, disabled}: DataTabProps) {
  const handleChangeDate = useCallback(
    (e) => {
      preventDefault(e);
      stopPropagation(e);
      datePickerHandler(item);
    },
    [item]
  );

  const {intl} = useIntlUtils();

  return (
    <button
      disabled={disabled}
      onClick={handleChangeDate}
      className={cn(styles.chip, {[styles.active]: isSameDay, [styles.disabled]: disabled})}
      key={index}
    >
      {index === 0 ? intl.formatMessage({id: ETranslations.TOMORROW}) : dayjs(item).format("DD.MM")}
    </button>
  );
}
