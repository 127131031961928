import React from "react";
import {FieldRenderProps} from "react-final-form";
import {Textarea} from "ui-kit";
import {ETranslations} from "../../../types/translates";
import useChoiceNotesInput from "./hooks/useChoiceNotesInput";

export const ChoiceNotesInput: React.FC<FieldRenderProps<string, any>> = ({input, meta, needResize = false, ...rest}) => {
  
  const {autoResize, textareaHeight, getIntlEnterEntity} = useChoiceNotesInput(needResize)

  return (
    <Textarea
      id="textarea-id"
      onInput={autoResize}
      style={{height: textareaHeight, minHeight:"50px"}}
      {...input}
      {...rest}
      placeholder={getIntlEnterEntity(ETranslations.TEXT)}
    />
  );
};
