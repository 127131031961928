import { useIntl, IntlShape } from 'react-intl';
import { ETranslations } from '../types/translates';
import { capitalizeString } from '../utils';
import { useCallback, useMemo } from 'react';
import type { ShortLocales } from 'types/commons';

export const useIntlUtils = () => {
  const intl = useIntl() as IntlShape & {locale: ShortLocales}

  const isRussianLocale = useMemo(() => intl.locale === 'ru', []);

  const getIntlBaseSelect = useCallback((entity: string): string => {
    return intl.formatMessage(
      { id: ETranslations.BASE_SELECT_ENTITY },
      { entity }
    );
  }, []);

  const getIntlBaseChoose = useCallback((entity: string): string => {
    return intl.formatMessage(
      { id: ETranslations.BASE_CHOOSE_ENTITY },
      { entity }
    );
  }, []);

  const getIntlSelectEntity = useCallback((entity: ETranslations): string => {
    const value = intl.formatMessage({ id: entity }).toLowerCase();
    return getIntlBaseSelect(value);
  }, []);

  const getIntlChooseEntity = useCallback((entity: ETranslations): string => {
    const value = intl.formatMessage({ id: entity }).toLowerCase();
    return getIntlBaseChoose(value);
  }, []);

  const buildIntlChosenDateOf = useCallback((entity: ETranslations) => {
    return intl.formatMessage(
      { id: ETranslations.BASE_CHOSEN_DATE },
      { value: intl.formatMessage({ id: entity }) }
    );
  }, []);

  const getIntlDateOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl.formatMessage(
        { id: ETranslations.DATE_OF },
        { value: intl.formatMessage({ id: entity }).toLowerCase() }
      )
    );
  }, []);

  const getIntlDateOfStrict = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl.formatMessage(
        { id: ETranslations.DATE_OF_STRICT },
        { value: intl.formatMessage({ id: entity }).toLowerCase() }
      )
    );
  }, []);

  const getIntlTimeOf = useCallback((entity: ETranslations) => {
    return intl.formatMessage(
      { id: ETranslations.TIME_OF },
      { value: intl.formatMessage({ id: entity }).toLowerCase() }
    );
  }, []);

  const buildIntlCountOf = useCallback((entity: ETranslations) => {
    return intl.formatMessage(
      { id: ETranslations.COUNT_OF },
      { value: intl.formatMessage({ id: entity }) }
    );
  }, []);

  const getIntlReasonOf = useCallback((entity: ETranslations) => {
    return intl.formatMessage(
      { id: ETranslations.REASON_OF },
      { value: intl.formatMessage({ id: entity }).toLowerCase() }
    );
  }, []);

  const getIntlHistoryOf = useCallback((entity: ETranslations) => {
    return intl.formatMessage(
      { id: ETranslations.HISTORY_OF },
      { value: intl.formatMessage({ id: entity }).toLowerCase() }
    );
  }, []);

  const getIntlHistoryOfEn = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl.formatMessage(
        { id: ETranslations.HISTORY_OF_EN },
        { value: intl.formatMessage({ id: entity }).toLowerCase() }
      )
    );
  }, []);

  const getIntlStatusOf = useCallback((entity: ETranslations) => {
    return intl.formatMessage(
      { id: ETranslations.STATUS_OF },
      { value: intl.formatMessage({ id: entity }).toLowerCase() }
    );
  }, []);

  const getIntlStatusAlt = useCallback((entity: ETranslations) => {
    return capitalizeString(
      isRussianLocale
        ? `${intl.formatMessage({ id: entity })} ${intl
            .formatMessage({ id: ETranslations.PLURAL_STATUSES_ALT })
            .toLowerCase()}`
        : `${intl.formatMessage({
            id: ETranslations.PLURAL_STATUSES_ALT,
          })} ${intl.formatMessage({ id: entity }).toLowerCase()}`
    );
  }, []);

  const getIntlFullNameOfEntity = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl.formatMessage(
        { id: ETranslations.FULL_NAME_OF },
        { entity: intl.formatMessage({ id: entity }).toLowerCase() }
      )
    );
  }, []);

  const getIntlParametersOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.PARAMETERS_OF },
          { value: intl.formatMessage({ id: entity }) }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlMovingOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.MOVING_OF },
          { value: intl.formatMessage({ id: entity }) }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlNumberOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl.formatMessage(
        { id: ETranslations.NUMBER_OF },
        { value: intl.formatMessage({ id: entity }).toLowerCase() }
      )
    );
  }, []);

  const getIntlNumbersOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.NUMBERS_OF },
          { value: intl.formatMessage({ id: entity }) }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlLevelOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.LEVEL_OF },
          { value: intl.formatMessage({ id: entity }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlRegistrationOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.REGISTRATION_OF },
          { value: intl.formatMessage({ id: entity }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlDeletingOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.ENTITY_DELETING },
          { entity: intl.formatMessage({ id: entity }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlEntityCreation = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.ENTITY_CREATING },
          { entity: intl.formatMessage({ id: entity }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlCardOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.CARD_OF },
          { value: intl.formatMessage({ id: entity }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlEntityEdition = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.ENTITY_EDITING },
          { entity: intl.formatMessage({ id: entity }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlEntityChanging = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.BASE_ENTITY_CHANGING },
          { entity: intl.formatMessage({ id: entity }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlEntityAddition = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl.formatMessage(
        { id: ETranslations.ENTITY_ADDING },
        { entity: intl.formatMessage({ id: entity }) }
      )
    );
  }, []);

  const getIntlCreatingOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl.formatMessage(
        { id: ETranslations.BASE_ENTITY_CREATION },
        { entity: intl.formatMessage({ id: entity }) }
      )
    );
  }, []);

  const getIntlAddOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl.formatMessage(
        { id: ETranslations.BASE_ADD_ENTITY },
        { entity: intl.formatMessage({ id: entity }) }
      )
    );
  }, []);

  const getIntlConfirmingOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.ENTITY_CONFIRMING },
          { value: intl.formatMessage({ id: entity }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlListOf = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.LIST_OF },
          { value: intl.formatMessage({ id: entity }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlInfoMissingOf = useCallback((value: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.INFO_MISSING_OF },
          { value: intl.formatMessage({ id: value }).toLowerCase() }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlOrEntities = useCallback(
    (entityOne: ETranslations, entityTwo: ETranslations) => {
      const intlOr = intl.formatMessage({ id: ETranslations.OR });
      const intlEntityOne = intl
        .formatMessage({ id: entityOne })
        .toLocaleLowerCase();
      const intlEntityTwo = intl
        .formatMessage({ id: entityTwo })
        .toLocaleLowerCase();
      return intl.formatMessage(
        { id: ETranslations.BASE_SELECT_ENTITY },
        { entity: `${intlEntityOne} ${intlOr} ${intlEntityTwo}` }
      );
    },
    []
  );

  const getIntlAllEntities = useCallback((entities: ETranslations): string => {
    return intl.formatMessage(
      { id: ETranslations.BASE_ALL_ENTITIES },
      { entities: intl.formatMessage({ id: entities }).toLowerCase() }
    );
  }, []);

  const getIntlShowEntity = useCallback((entity: ETranslations): string => {
    return intl.formatMessage(
      { id: ETranslations.BASE_SHOW_ENTITY },
      {
        entity: intl.formatMessage({ id: entity }).toLowerCase(),
      }
    );
  }, []);

  const getIntlJoinedParts = useCallback((parts: ETranslations[]): string => {
    return capitalizeString(
      parts
        .map((part) =>
          intl.formatMessage({ id: ETranslations[part] }).toLowerCase())
        .join(' ')
    );
  }, []);

  const getIntlEntityOnPage = useCallback((entity: ETranslations): string => {
    return intl.formatMessage(
      { id: ETranslations.ENTITY_ON_PAGE },
      {
        entity: intl.formatMessage({ id: entity }).toLowerCase(),
      }
    );
  }, []);

  const getIntlActionFailed = useCallback((action: string) => {
    return capitalizeString(
      intl.formatMessage({ id: ETranslations.FAILED }, { action })
    );
  }, []);

  const getIntlCurrentUnit = useCallback((unit: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.CURRENT_UNIT },
          { unit: intl.formatMessage({ id: unit }) }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlNewtUnit = useCallback((unit: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.NEW_UNIT },
          { unit: intl.formatMessage({ id: unit }) }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlBaseErrorNotSelected = useCallback((value: string) => {
    return capitalizeString(
      intl.formatMessage({ id: ETranslations.ERROR_NOT_SELECTED }, { value })
    );
  }, []);

  const getIntlErrorNotSelected = useCallback((entity: ETranslations) => {
    return getIntlBaseErrorNotSelected(
      intl.formatMessage({ id: entity }).toLowerCase()
    );
  }, []);

  const getIntlEnterEntity = useCallback((entity: ETranslations) => {
    return capitalizeString(
      intl
        .formatMessage(
          { id: ETranslations.BASE_ENTER_VALUE },
          { value: intl.formatMessage({ id: entity }) }
        )
        .toLowerCase()
    );
  }, []);

  const getIntlEntityViewingAction = useCallback(
    (entity: ETranslations, field: ETranslations) => {
      return intl
        .formatMessage(
          { id: ETranslations.INFO_VIEWING_ACTION },
          {
            entity: intl.formatMessage({ id: entity }),
            field: intl.formatMessage({ id: field }),
          }
        )
        .toUpperCase();
    },
    []
  );

  return {
    intl,
    isRussianLocale,
    getIntlBaseSelect,
    getIntlBaseChoose,
    getIntlSelectEntity,
    getIntlChooseEntity,
    getIntlDateOf,
    getIntlDateOfStrict,
    getIntlTimeOf,
    buildIntlChosenDateOf,
    buildIntlCountOf,
    getIntlHistoryOf,
    getIntlStatusOf,
    getIntlStatusAlt,
    getIntlOrEntities,
    getIntlAllEntities,
    getIntlShowEntity,
    getIntlReasonOf,
    getIntlJoinedParts,
    getIntlEntityOnPage,
    getIntlFullNameOfEntity,
    getIntlParametersOf,
    getIntlMovingOf,
    getIntlNumberOf,
    getIntlNumbersOf,
    getIntlLevelOf,
    getIntlActionFailed,
    getIntlRegistrationOf,
    getIntlDeletingOf,
    getIntlCurrentUnit,
    getIntlNewtUnit,
    getIntlCreatingOf,
    getIntlErrorNotSelected,
    getIntlBaseErrorNotSelected,
    getIntlEnterEntity,
    getIntlAddOf,
    getIntlEntityViewingAction,
    getIntlConfirmingOf,
    getIntlEntityCreation,
    getIntlEntityEdition,
    getIntlCardOf,
    getIntlListOf,
    getIntlEntityAddition,
    getIntlEntityChanging,
    getIntlInfoMissingOf,
    getIntlHistoryOfEn,
  };
};
