import {FunctionComponent} from 'react';
import moment from 'moment';
import {ICONS, Spinner} from 'ui-kit';
import {useFetchBookingHistoryDetailsParamQuery} from 'features/api/bookings-api';
import {getTimeHoursAndMinutes} from 'common/helpers';
import {Tags} from 'components/Tags';
import {useIntlUtils} from '../../hooks/useIntlUtils';
import {ETranslations} from '../../types/translates';
import {capitalizeString, declOfNum} from '../../utils';
import {HOURS_TITLES, MINUTES_TITLES} from '../../constants';
import {GreyRubleIcon} from '../../ui-kit/ICONS/icons';
import {HideWhen} from "components/HideWhen";
import {config} from "config";

const {Clock, Calendar, GuestsIcon, CommentFill, TagIcon, Seat} = ICONS;

const isDepositMade = (state: string) => {
  return state !== "NOT_MADE";
};

interface Props {
  bookingId: number;
}

const BookingHistoryModalBook: FunctionComponent<Props> = ({bookingId}) => {
  const {intl, getIntlJoinedParts,  getIntlNumberOf, getIntlNewtUnit} = useIntlUtils();
  const {data} = useFetchBookingHistoryDetailsParamQuery(bookingId);

  const yesTitle = intl.formatMessage({id: ETranslations.BASE_YES});
  const noTitle = intl.formatMessage({id: ETranslations.BASE_NO});

  if (!data) return <Spinner />;

  const {minutes: newMinutes, hours: newHours} = getTimeHoursAndMinutes(data.visitTime.new)
  const {minutes: oldMinutes, hours: oldHours} = getTimeHoursAndMinutes(data.visitTime.old)

  return (
    <div className="bookingHistoryBookBody">
      <div className="bookingHistorySectionHeader">
        <div className="bookingHistoryValue bookingHistoryTitle">
          {capitalizeString(intl.formatMessage({id: ETranslations.PLURAL_PARAMETERS_NOM}))}
        </div>
        <div
          className="bookingHistoryValue">{getIntlJoinedParts([ETranslations.CURRENT, ETranslations.PLURAL_VALUES_NOM])}</div>
        <div
          className="bookingHistoryValue">{getIntlJoinedParts([ETranslations.CREATED, ETranslations.PLURAL_VALUES_NOM])}</div>
      </div>
      <div className="bookingHistoryBookSection">
        <div className="bookingHistoryValue bookingHistoryTitle">
          <Calendar width={24} height={24} />
          {intl.formatMessage({id: ETranslations.BASE_DATE})}
        </div>
        <div className="bookingHistoryValue">{moment(data.date.new).format("DD.MM.YYYY HH:mm")}</div>
        <div className="bookingHistoryValue">{moment(data.date.old).format("DD.MM.YYYY HH:mm")}</div>
      </div>
      <div className="bookingHistoryBookSection">
        <div className="bookingHistoryValue bookingHistoryTitle">
          <Seat />
          {getIntlNumberOf(ETranslations.PLURAL_TABLES)}
        </div>
        <div className="bookingHistoryValue">{data.tables.new.join(" ")}</div>
        <div className="bookingHistoryValue">{data.tables.old.join(" ")}</div>
      </div>
      <div className="bookingHistoryBookSection">
        <div className="bookingHistoryValue bookingHistoryTitle">
          <Clock />
          {intl.formatMessage({id: ETranslations.DURATION})}
        </div>
        <div className="bookingHistoryValue">{
          `${newHours} ${intl.formatMessage({id: declOfNum(newHours, HOURS_TITLES)}).toLowerCase()}
          ${newMinutes} ${intl.formatMessage({id: declOfNum(newMinutes, MINUTES_TITLES)}).toLowerCase()}`
        }</div>
        <div className="bookingHistoryValue">{
          `${oldHours} ${intl.formatMessage({id: declOfNum(oldHours, HOURS_TITLES)}).toLowerCase()}
          ${oldMinutes} ${intl.formatMessage({id: declOfNum(oldMinutes, MINUTES_TITLES)}).toLowerCase()}`
        }</div>
      </div>
      <div className="bookingHistoryBookSection">
        <div className="bookingHistoryValue bookingHistoryTitle">
          <CommentFill />
          {intl.formatMessage({id: ETranslations.RESERVATION_NOTE})}
        </div>
        <div className="bookingHistoryValue">{data.comment.new}</div>
        <div className="bookingHistoryValue">{data.comment.old}</div>
      </div>
      <div className="bookingHistoryBookSection">
        <div className="bookingHistoryValue bookingHistoryTitle">
          <GuestsIcon width={24} height={24} />
          {capitalizeString(intl.formatMessage({id: ETranslations.PLURAL_GUESTS_NOM}))}
        </div>
        <div className="bookingHistoryValue">{data.persons.new}</div>
        <div className="bookingHistoryValue">{data.persons.old}</div>
      </div>
      <div className="bookingHistoryBookSection">
        <div className="bookingHistoryValue bookingHistoryTitle">
          <TagIcon fill="var(--gl_icon_constant_primary2)" />
          {intl.formatMessage({id: ETranslations.RESERVATION_TAGS})}
        </div>
        <div className="bookingHistoryValue">
          <Tags tags={data.tags.new} />
        </div>
        <div className="bookingHistoryValue">
          <Tags tags={data.tags.old} />
        </div>
      </div>
      <div className="bookingHistoryBookSection">
        <div className="bookingHistoryValue bookingHistoryTitle">
          <HideWhen condition={config.BRAND === 'DUBAI'}>
            <GreyRubleIcon />
          </HideWhen>
          {intl.formatMessage({id: ETranslations.DEPOSIT_MADE})}
        </div>
        <div className="bookingHistoryValue">{isDepositMade(data.depositStatus.new) ? yesTitle : noTitle}</div>
        <div className="bookingHistoryValue">{isDepositMade(data.depositStatus.old) ? yesTitle : noTitle}</div>
      </div>
      <div className="bookingHistoryBookSection">
        <div className="bookingHistoryValue bookingHistoryTitle">
          <HideWhen condition={config.BRAND === 'DUBAI'}>
            <GreyRubleIcon />
          </HideWhen>
          {intl.formatMessage({id: ETranslations.DEPOSIT_AMOUNT})}
        </div>
        <div className="bookingHistoryValue">
          {data.depositAmount.new}
          &nbsp;
          {config.currency}
        </div>
        <div className="bookingHistoryValue">
          {data.depositAmount.old}
          &nbsp;
          {config.currency}
        </div>
      </div>
    </div>
  );
};

export default BookingHistoryModalBook;
