import {ReactElement} from "react";
import {ClientDetailsPopup} from "components/BookingClientDetails";
import {Placement} from "@popperjs/core";
import {stopPropagation} from "utils";
import {Popup} from "components/Popup";

interface BookingInfoPopupProps {
  clientId: number;
  children: ReactElement;
  placement?: Placement;
}

export function ClientInfoPopup({children, clientId, placement = "auto"}: BookingInfoPopupProps) {
  return (
    <Popup placement={placement}>
      <Popup.Trigger>{children}</Popup.Trigger>
      <Popup.Content noPadding>
        <ClientDetailsPopup onClick={stopPropagation} clientId={clientId} />
      </Popup.Content>
    </Popup>
  );
}
