import { ReactNode } from 'react';
import cn from 'classnames';
import { useIsTablet } from '../hooks';
import { ICONS, Button } from '..';
import { useCloseCard } from './useCloseCard';
import styles from './Card.module.scss';
import { useTableBookingListActions } from 'features/TableBooking/slice';

interface BlockHeaderProps {
  children?: ReactNode;
  title?: ReactNode;
  controls?: ReactNode;
  className?: string;
  ManageralButton?: ReactNode;
  needToReset?: boolean;
}

export function Header({
  children,
  title,
  controls,
  className,
  ManageralButton = null,
  needToReset = false,
}: BlockHeaderProps): JSX.Element {
  const close = useCloseCard();
  const isTablet = useIsTablet();
  const { reset: resetTable } = useTableBookingListActions();

  const handleClose = () => {
    close && close();
    needToReset && resetTable();
  };

  return (
    <div className={cn(styles.header, className)}>
      <div className={cn(styles.headerContent)}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {!isTablet && children && (
          <div className={styles.subHeader}>{children}</div>
        )}
        {controls && <div className={styles.controls}>{controls}</div>}
        {(close || ManageralButton) && (
          <div className={styles.buttons}>
            {ManageralButton}
            <Button
              variant="phantom"
              square
              onClick={handleClose}
              className={styles.close}
            >
              <ICONS.Cross />
            </Button>
          </div>
        )}
      </div>
      {isTablet && children && (
        <div className={cn(styles.subHeader)}>{children}</div>
      )}
    </div>
  );
}
