import React from 'react';
import cn from 'classnames';
import './phone-indicator.scss';

export const PhoneIndicator = (props: any) => {
  const {isActive} = props;
  return (
    <>
      <div className={cn('phone-indicator', isActive ? 'active' : '')}>
        <div className={cn('phone-icon', isActive ? 'active' : '')} />
      </div>
    </>
  );
};
