import {forwardRef, Ref, ReactNode} from 'react';
import cn from 'classnames';

import styles from './Labeled.module.scss';

export interface LabeledProps {
  label: ReactNode | undefined;
  required?: boolean | undefined;
  children: ReactNode;
  className?: string;
  as?: 'label' | 'div'
}

export const Labeled = forwardRef(({
  label, className, required, children, as: Label = 'label'
}: LabeledProps, ref: Ref<any>) => {
  if (!label) return <Label ref={ref} className={className}>{children}</Label>;
  return (
    <Label ref={ref} className={className}>
      <span className={cn(styles.label, {[styles.required]: required})}>
        {label}
      </span>
      {children}
    </Label>
  );
});
