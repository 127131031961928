import React, {FC} from 'react';
import {HallMode, useHallSchemaActions} from 'features/HallSchema';
import {HallSchema} from './HallSchemaV2/hall-schema';
import {SelectGuestFromList} from 'features/GuestsList/components/select-guest-from-list';
import {useFromProxyActions} from 'features/BookingFormProxy';
import {Client} from 'models/client.model';
import {isBookingView, isRegView} from './HallSchemaV2/hall-helpersFn';

interface Props {
  hallMode: HallMode;
}

export const RightSideComponent: FC<Props> = ({hallMode}) => {
  const {setClient} = useFromProxyActions();
  const {switchMode} = useHallSchemaActions();
  const isGuestForm = [
    HallMode.REGISTRATION_GUESTS,
    HallMode.BOOKING_GUEST,
    HallMode.TABLE_BOOKINGS_EDIT_GUEST
  ].includes(hallMode);


  const selectGuestHandler = (guest: Client) => {
    setClient({client: guest});
    if (hallMode === HallMode.TABLE_BOOKINGS_EDIT_GUEST) switchMode(HallMode.REGISTRATION_TABLET);
    if (isRegView(hallMode)) switchMode(HallMode.REGISTRATION_TABLET);
    if (isBookingView(hallMode)) switchMode(HallMode.BOOKING_TABLET);
  };

  if (isGuestForm) {
    return (
      <SelectGuestFromList
        onSelectGuest={selectGuestHandler}
      />
    );
  }

  return (<HallSchema/>);
};
