import cn from 'classnames';
import {ComponentType, ReactNode, useCallback} from 'react';

import styles from './TabTitle.module.scss';

export interface TabTitleProps<T> {
  title: ReactNode;
  onClick(key: T): void;
  tabName: T;
  isActive?: boolean;
  disabled?: boolean;
  icon?: ComponentType;
}

export function TabTitle<T>({isActive, title, onClick, tabName, disabled, icon: Icon}: TabTitleProps<T>) {
  const handleClick = useCallback(() => {
    if (disabled) return;
    onClick(tabName);
  }, [title, tabName, disabled]);

  return (
    <li
      className={cn(styles.tabTitle, {[styles.active]: isActive, [styles.disabled]: disabled})}
      onClick={handleClick}
      data-active={isActive}
    >
      {Icon && <Icon />}
      {title}
    </li>
  );
}
