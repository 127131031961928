import {Tag} from 'components/Tag';
import {useTags} from 'features/api/tags';
import {Tag as ITags} from 'types/tag';


interface TagsProps{
  tagsIDs: number[];
}


export function Tags({tags}: { tags: ITags[]}) {
  return (
    <div className="tags">
      {tags.map((t) => <Tag tag={t} key={t.id} />)}
    </div>
  );
}

export function TagsById({tagsIDs}: TagsProps) {
  const tags = useTags(tagsIDs);
  return (
    <Tags tags={tags} />
  );
}
