import { Link } from 'react-router-dom';
import { Button, Input, SelectCheckbox } from 'ui-kit';
import { STATE_FILTERS_TITLES, STATUS_FILTERS_TITLES } from 'constants/';
import type { IntlShape } from 'react-intl';
import { ETranslations } from 'types/translates';
import type { Locales } from 'types/commons';
import type { BookingModeType, BookingsData } from './types';
import { useBookingFilters } from './useBookingFilters';

export const BookingFilters = ({
  styles,
  bookingMode,
  locale,
  formatMessage,
  isManagerableTableSelected,
  setBookingsData,
}: {
  styles: { [key: string]: string };
  bookingMode: BookingModeType;
  locale: Locales;
  formatMessage: IntlShape['formatMessage'];
  isManagerableTableSelected: boolean | undefined;
  setBookingsData: React.Dispatch<React.SetStateAction<BookingsData>>;
}) => {
  const {
    isLoading,
    handleStatusFilterSelect,
    handleExtraStatusFilterSelect,
    statusFilter,
    extraStatusFilter,
    statusOptions,
    extraStatusOptions,
    searchQuery,
    setSearchQuery,
  } = useBookingFilters(
    bookingMode,
    locale,
    setBookingsData,
    isManagerableTableSelected
  );

  return (
    <div className={styles.filters}>
      <div className={styles.search}>
        <Input.Search
          onSearch={setSearchQuery}
          isLoading={isLoading}
          className={styles.searchInput}
        />
        <Link
          to={{
            pathname: './search',
            state: {
              searchQuery,
            },
          }}
        >
          <Button variant="thin" type="button" className={styles.searchButton}>
            {formatMessage({ id: ETranslations.GLOBAL_SEARCH })}
          </Button>
        </Link>
      </div>
      <div className={styles.selectFilter}>
        <SelectCheckbox
          styleObject={{
            menuList: { width: 290 },
            menuPortal: { width: 290 },
            valueContainer: { display: 'inline', paddingRight: 0, width: 139 },
          }}
          isClearable={false}
          placeholder={formatMessage({
            id: ETranslations.STATES,
          })}
          onChange={handleExtraStatusFilterSelect}
          value={extraStatusFilter}
          options={extraStatusOptions}
          titles={STATE_FILTERS_TITLES}
          maxContentWidth
        />
        <SelectCheckbox
          styleObject={{
            valueContainer: { display: 'inline', paddingRight: 0 },
          }}
          isClearable={false}
          placeholder={formatMessage({
            id: ETranslations.STATUSES,
          })}
          onChange={handleStatusFilterSelect}
          value={statusFilter}
          options={statusOptions}
          titles={STATUS_FILTERS_TITLES}
          maxContentWidth
        />
      </div>
    </div>
  );
};
