import React, {FC} from "react";
import {useIntl} from "react-intl";
import {Modal} from "../../../modal";
import styles from "../Form.module.scss";
import {Field} from "react-final-form";
import {Input, Labeled, SelectBasic} from "ui-kit";
import cn from "classnames";
import {ETranslations} from "types/translates";
import {getDurationFormat, getPartyOptions, TDuration, addPlusToDuration, required} from "../../utils";
import {changeGuestTime} from "./utils";
import {declOfNum} from "utils";
import {GUESTS_TITLE} from "../../../../constants";
import Checkbox from "common/components/checkbox";
import {FormApi} from "final-form";

type TProps = {
    form:  FormApi<any, Partial<any>>,
}

const CapacityFields: FC<TProps> = ({form}) => {
    const intl = useIntl();
    return (
            <Modal.Content className={styles.modalContent}>
                <Field name="shift_name" required validate={required} style={{display: 'none'}}>
                    {({
                          input,
                          meta,
                          ...rest
                      }) => <Input {...input} {...meta} {...rest} />}
                </Field>
                <span className={cn(styles.description, styles.row)}>
                                    {intl.formatMessage({id: ETranslations.DURATION_DESCRIPTION})}
                                </span>
                <div className={styles.durationsBlock}>
                    <Field name={'durations'} type="checkbox">
                        {({input}) => {
                            const fieldValues: TDuration[] = form.getFieldState('durations')?.value;
                            return fieldValues?.map(place => (
                                <Labeled
                                    label={`${addPlusToDuration(place.count, fieldValues.length)
                                    + ` ${intl.formatMessage({id: declOfNum(place.count, GUESTS_TITLE)})}`}`}
                                    className={styles.row}>
                                    <SelectBasic
                                        {...input}
                                        defaultValue={getDurationFormat(place.duration)}
                                        options={getPartyOptions()}
                                        changedMaxSize
                                        isDisabled={input.disable}
                                        onChange={(value: any) => (
                                            changeGuestTime(value, fieldValues, place.count, input.onChange)
                                        )}
                                        key={place.count}
                                    />
                                </Labeled>
                            ));
                        }}
                    </Field>
                </div>
                <Field name="use_max_covered" type="checkbox">
                    {({input}) => (
                        <Checkbox
                            indent="confirmed"
                            text={intl.formatMessage({id: ETranslations.SET_MAX_COVERED})}
                            onChange={input.onChange}
                            value={input.checked}
                            useStopPropagation
                            className={cn(styles.capacityRow, styles.checkbox)}
                        />
                    )}
                </Field>
                <Field name="max_covered_size">
                    {({
                          input,
                          meta,
                          ...rest
                      }) =>
                        <Input {...input} {...meta} {...rest}
                               className={cn(styles.row, styles.capacityRow)}/>}
                </Field>

            </Modal.Content>
    );
};

export default CapacityFields
