import {useCallback, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {useIntl} from "react-intl";
import {BookingOrder} from 'types/booking';
import {OrderType} from '../../../types/commons';
import {useOrderList} from '../../../features/api/booking-order-api';
import {useFromProxyActions} from 'features/BookingFormProxy';

const useBookingOrderList = ({onSelectOrder}: { onSelectOrder: any }) => {
    const intl = useIntl()
    const [fetchMode, setFetchMode] = useState<OrderType>('active');
    const [searchKeyword, setSearchKeyword] = useState('');
    const {isLoading, data: apiResult} = useOrderList(fetchMode, searchKeyword);
    const {setEditMode} = useFromProxyActions();

    const handleEditClick = useCallback((order: BookingOrder) => {
        onSelectOrder(order);
        setEditMode(true);
    }, []);

    const sortedOrders = useMemo(() => {
        if (!apiResult) return [];

        return [...apiResult].sort((a, b) => {
            const firstDate = dayjs(`${a.bookingDate} ${a.bookingTime}`, 'YYYY-MM-DD HH:mm')
            const secondDate = dayjs(`${b.bookingDate} ${b.bookingTime}`, 'YYYY-MM-DD HH:mm')
            return firstDate.valueOf() - secondDate.valueOf()
        })
    }, [apiResult]);
    
    return {
        intl,
        setFetchMode,
        setSearchKeyword,
        isLoading,
        handleEditClick,
        sortedOrders,
        fetchMode,
        searchKeyword,
        apiResult
    };
}

export default useBookingOrderList