import cn from 'classnames';
import { useState, ReactNode } from 'react';
import { ICONS } from 'ui-kit';
import type { BookingModeType } from './types';

export const BookingListCollapse = ({
  title,
  count,
  bookingMode,
  bookingList,
  children,
}: {
  title: string;
  count: number;
  bookingMode: BookingModeType;
  bookingList: ReactNode;
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        style={{
          overflowY: 'auto',
          display: isOpen ? 'none' : 'block',
          flex: '1.0',
          padding: '15px 0',
        }}
      >
        {bookingList}
      </div>
      {bookingMode === 'active' && (
        <section className={cn({ active: isOpen })}>
          <div className="badge" onClick={() => setIsOpen((prev) => !prev)}>
            <h3>{title}</h3>
            <div className="info">
              <div key="people">
                <ICONS.People />
                <span>{count}</span>
              </div>
            </div>
            <figure className="collapse">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="var(--ico-color)"
                  d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z"
                />
              </svg>
            </figure>
          </div>
          {isOpen && children}
        </section>
      )}
    </>
  );
};
