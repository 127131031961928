import { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash';
import { useSelector } from 'react-redux';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import { useField, useForm } from 'react-final-form';
import { useThrottle } from 'react-use';
import { config } from 'config';
import { useIsTablet } from '../../../../hooks/useIsTablet';
import {
  HallMode,
  useHallSchemaActions,
} from '../../../../features/HallSchema';
import { hallModeSelector } from '../../../../features/HallSchema/selectors';
import { isRegView } from '../../../hall-scheme/redux/HallSchemaV2/hall-helpersFn';
import { useFetchGuestsPageQuery } from '../../../../features/api/guest-api';
import { guestListFilter } from '../../../../features/GuestsList/selectors';
import { useGuestListActions } from '../../../../features/GuestsList';
import { useFromProxyActions } from '../../../../features/BookingFormProxy';
import { isRealClient } from '../../validators/validators';
import { useLocation } from 'react-router';

type Client =
  | {
      user_id?: number | undefined; //not sure
      client_id: number;
      editClient: boolean;
      email?: string;
      grade_color?: string;
      grade_name?: string;
      middle_name?: string;
      name: string;
      phone?: string;
      surname: string;
      tags?: number[];
      vip?: boolean;
    }
  | undefined;

const useChoicePhoneRedux = ({
  value,
  onChange,
  disabled,
  isCreateBooking,
}: {
  value: string | undefined;
  onChange: any;
  disabled: boolean;
  isCreateBooking: boolean;
}) => {
  const hallMode = useSelector(hallModeSelector);
  const filter = useSelector(guestListFilter);
  const { applyFilter, reset } = useGuestListActions();
  const { setClient, resetClient, setEditClient } = useFromProxyActions();
  const {
    input: { value: client },
  } = useField<Client>('client');
  const [searchPhone, setSearchPhone] = useState(value);
  const { pathname } = useLocation();

  const isEmpty = useMemo(
    () =>
      Boolean(
        value?.trim() === config.defaultPhoneNumber || !value?.trim().length
      ),
    [value]
  );

  useThrottle(() => {
    setSearchPhone(value);
  }, 1500);

  const filterWithPhone = useMemo(
    () => ({
      ...filter,
      phone: searchPhone === config.defaultPhoneNumber ? undefined : searchPhone,
    }),
    [filter, searchPhone]
  );
  // this hook is for cases when guest component is unmount
  const { data = [] } = useFetchGuestsPageQuery(filterWithPhone);
  const isTablet = useIsTablet();
  const form = useForm();

  const throttledFilter = useMemo(
    () => throttle(applyFilter, 1e3),
    [applyFilter]
  );

  // why? todo: check if we can remove it
  useEffect(() => {
    if (disabled || !value) return;
    const [nextClient] = data;
    if (isValidPhoneNumber(value) || isTablet) {
      if (
        data?.length === 1
        && nextClient.phone.replace(/\D/g, '') === value.replace(/\D/g, '')
      ) {
        setClient({ client: data[0] });
        form.batch(() => {
          form.change('client', data[0]);
          form.change('phone', formatPhoneNumberIntl(data[0].phone));
          form.change('client_id', data[0].client_id);
        });
      }
    }
  }, [data, disabled, value]);

  useEffect(() => {
    if (!value || (!isValidPhoneNumber(value) && !client)) {
      resetClient();
    }
  }, [value, client]);

  useEffect(() => {
    //@ts-ignore
    if (isRealClient(client) && client?.phone && !disabled) {
      //@ts-ignore
      const clientPhone = formatPhoneNumberIntl(
        client.phone.replace(/^([^\D])/g, '+$1')
      );
      clientPhone && onChange(clientPhone);
    }
  }, [client]);

  const { switchMode } = useHallSchemaActions();

  const showClients = () => {
    if (isRegView(hallMode)) {
      switchMode(HallMode.REGISTRATION_GUESTS);
      return;
    }

    if (hallMode === HallMode.MANAGERAL_CREATE_BOOKING) {
      switchMode(HallMode.MANAGERAL_OPEN_GUESTS);
      return;
    }

    if (hallMode === HallMode.TABLE_BOOKINGS_EDIT) {
      switchMode(HallMode.TABLE_BOOKINGS_EDIT_GUEST);
      return;
    }
    switchMode(HallMode.BOOKING_GUEST);
  };

  const clearPhoneNumber = useCallback(() => {
    if (disabled) return;
    onChange('');
    reset();
    setEditClient(true);
    form.batch(() => {
      form.change('client', undefined);
      form.change('client_id', undefined);
      form.change('phone', undefined);
    });
    resetClient();
  }, [onChange, disabled]);

  useEffect(() => {
    if (value && value.length >= 3) {
      throttledFilter({ ...filter, phone: value });
    }
  }, [value]);

  const showPopUp
    = client?.client_id && (pathname.includes('search') || isCreateBooking);

  return {
    clearPhoneNumber,
    showClients,
    client,
    isEmpty,
    showPopUp,
  };
};

export default useChoicePhoneRedux;
