import {useLayoutEffect} from 'react';
import {useIntl} from "react-intl";
import {useHWLContext} from 'common/components/HorizontalWindowLayout/hwl-context';
import {HallMode, useHallSchemaActions} from '../../../../features/HallSchema';
import {isBookingView, isRegView} from '../HallSchemaV2/hall-helpersFn';
import {useFromProxyActions} from '../../../../features/BookingFormProxy';
import {Client} from '../../../../models/client.model';

const useHallContainer = (hallMode: HallMode) => {
    const intl = useIntl();
    const {switchMode} = useHallSchemaActions();
    const {setClient} = useFromProxyActions();
    const guestCloseHandler = () => {
        if (hallMode.startsWith('MANAGARAL')) switchMode(HallMode.MANAGARAL_TABLET);
        if (hallMode.startsWith('REGISTRATION')) switchMode(HallMode.REGISTRATION_TABLET);
        if (hallMode.startsWith('BOOKING')) switchMode(HallMode.BOOKING_TABLET);
        if (hallMode.startsWith('EDIT')) switchMode(HallMode.TABLE_BOOKINGS_EDIT);
        if (hallMode.startsWith('TABLE_BOOKINGS_EDIT')) switchMode(HallMode.TABLE_BOOKINGS_EDIT);
    };

    const selectGuestHandler = (guest: Client) => {
        setClient({client: guest});
        if (hallMode === HallMode.TABLE_BOOKINGS_EDIT_GUEST) switchMode(HallMode.REGISTRATION_TABLET);
        if (isRegView(hallMode)) switchMode(HallMode.REGISTRATION_TABLET);
        if (isBookingView(hallMode)) switchMode(HallMode.BOOKING_TABLET);
    };

    const {findByWindowName, popupWindow, closeWindow} = useHWLContext();

    useLayoutEffect(() => {
        const window = findByWindowName('form');
        if (![
            HallMode.REGISTRATION_GUESTS,
            HallMode.BOOKING_GUEST,
            HallMode.BOOKING_HALL,
            HallMode.REGISTRATION_HALL,
            HallMode.MANAGARAL_HALL,
            HallMode.EDIT_HALL,
            HallMode.TABLE_BOOKINGS_EDIT_GUEST,
        ].includes(hallMode)) {
            !window.active && popupWindow(window);
        } else {
            window.active && closeWindow(window);
        }
    });
    return {
        guestCloseHandler,
        selectGuestHandler,
        intl,

    }
}

export default useHallContainer;