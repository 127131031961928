import {useMemo} from 'react';
import {getTimeOption} from '../../../registration/forms/utils';
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';
import {tableBookingsTableSelector} from 'features/TableBooking/selectors';
import useClosestInterval from 'hooks/useClosestInterval';

export type TManagerialForm = {
  start_date: Date;
  end_date: Date;
  duration: number,
  week_days: any[],
  tables: any[],
  restaurant_id: number,
  place_id: number
  time: any
}

export function prepareData(): TManagerialForm {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const place = useSelector(appContextSelectors.place)
  const table = useSelector(tableBookingsTableSelector);
  const startDate = useSelector(appContextSelectors.date);

 const closestInterval = useClosestInterval();

  const data = {
    start_date: new Date(startDate.format("YYYY-MM-DD")),
    end_date: new Date(startDate.format("YYYY-MM-DD")),
    start_time: getTimeOption(closestInterval) || "",
    duration: 120,
    persons: 1,
    time: getTimeOption(closestInterval),
    week_days: [],
    tables: [table].filter(Boolean),
    restaurant_id: restaurant.restaurant_id,
    place_id: place
  }

  return useMemo(() => data, [closestInterval])
}
