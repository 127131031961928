import React, {FC} from 'react';
import {Getter, Getters, Plugin, TemplateConnector} from '@devexpress/dx-react-core';
import {Plugins} from '@technolab/hse';

import {HallSlotsQResponse, SlotEvent, TTable} from 'features/api/hallschema-api';
import {TablePathTemplate} from './TablePathTemplate';
import {TNullable} from 'types/commons';

import {GETTERS} from './utils';

const {Core} = Plugins

const PLUGIN_NAME = 'HOS_TABLE_PATH';



const getBookings = (getters: Getters, hallInfo: TNullable<HallSlotsQResponse[]>) => {
  if(!hallInfo) return []
  return hallInfo
    .reduce<SlotEvent[]>((acc, value) => {
      if (value.slots.length) acc.push(...value.slots);

      return acc;
    }, [])
    .reduce<SlotEvent[]>((acc, value) => {
      const findBooking = acc.find(slot =>
        (slot.booking.bookingId === value.booking.bookingId)
        || (value.booking.places.length < 2));
      if (!findBooking) acc.push(value);

      return acc;
    },[])
}

const getPlaces = (getters: Getters) => {
  const places = getters[Core.GETTERS.place];

  if(!places) return {};

  return  Object.keys(places).reduce((acc,value) => {
    const tableId = places[value]?.hostesInfo?.id
    if(!tableId) return acc
    acc[tableId] = {...places[value]}
    return acc
  },{} as Record<number, TTable>)
}

interface Props {
  hallInfo?: HallSlotsQResponse[];
}

export const TablePath: FC<Props> = ({hallInfo}) => {
  return (
    <Plugin name={PLUGIN_NAME}>
      <Getter name={GETTERS.getBookings} computed={(getters) => getBookings(getters, hallInfo)}/>
      <Getter name={GETTERS.getPlaces} computed={(getters) => getPlaces(getters)}/>
      <TemplateConnector>
        {(getters) => <TablePathTemplate slots={getters[GETTERS.getBookings]} places={getters[GETTERS.getPlaces]}/>}
      </TemplateConnector>
    </Plugin>
  );
};

