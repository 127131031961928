import {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';

import {options, SearchTypeOption} from './utils';

import styles from './SearchHeader.module.scss';
import {bookingApi} from 'features/api/bookings-api';
import {useSelector} from 'react-redux';
import {globalSearchSelectors, useGlobalSearchActions} from 'features/GlobalSearch';
import {useUnmount, useUpdateEffect} from 'react-use';
import {UserTags} from './UserTags';
import {SearchContext} from 'types/globalSearch';
import {useRestsOptions} from 'features/api/restaurants';
import {RestaurantSelect} from 'components/RestaurantSelect';
import {useApplicationContextActions} from 'features/AppContex';
import {Input, SelectBasic, Button} from 'ui-kit';
import {useIntl} from "react-intl";
import {ETranslations} from "../../../../types/translates";

export function SearchHeader() {
  const intl = useIntl();
  const [searchType, setSearchType] = useState<SearchTypeOption>(
    {value: options[0].value, label: intl.formatMessage({id: options[0].label})}
  );
  const params = useSelector(globalSearchSelectors.params);
  const selectGlobalSearch = bookingApi.endpoints.globalSearch.select(params);
  const {isLoading} = useSelector(selectGlobalSearch);
  const history = useHistory();
  const close = useCallback(() => history.goBack(), [history]);
  const {changeContext, changeKeyword, reset} = useGlobalSearchActions();
  const {setRestaurant} = useApplicationContextActions();
  const restOpts = useRestsOptions();
  const {state} = useLocation<{ searchQuery?: string }>();

  useEffect(() => {
    const {searchQuery} = state || {};
    searchQuery && changeKeyword(searchQuery);
  }, [state]);

  useUnmount(reset);

  useUpdateEffect(() => {
    changeContext(searchType.value);
  }, [searchType]);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{intl.formatMessage({id: ETranslations.BOOKING_GLOBAL_SEARCH})}</h1>
      <div className={styles.search}>
        {Boolean((restOpts?.length ?? 0) > 1)
          && <RestaurantSelect value={params.restaurantId} onRestaurantChange={setRestaurant} className={styles.rest} />}
        <div className={styles.searchType}>
          <SelectBasic
            //@ts-ignore
            options={options}
            onChange={setSearchType as any}
            //@ts-ignore
            value={searchType} />
        </div>
        {searchType.value === SearchContext.CLIENT_TAG ? <UserTags value={params.keyword} onChange={changeKeyword} /> : (
          <Input.Search
            value={params.keyword}
            onSearch={changeKeyword} className={styles.searchValue}
            isLoading={isLoading} placeholder={intl.formatMessage({id: ETranslations.GLOBAL_SEARCH_PLACEHOLDER})}
          />
        )}
      </div>
      <Button variant="primary" onClick={close}>{intl.formatMessage({id: ETranslations.BASE_CLOSE})}</Button>
    </div>
  );
}
