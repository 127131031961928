import React, {FC} from 'react';
import {TNullable} from 'types/commons';
import dayjs from "dayjs";
import {useEditShiftMutation} from "features/api/shifts";
import {rebuildDurations} from "../../utils";
import {Shift, ShiftModalType} from "types/shift";
import EditShiftForm from "../EditShiftForm/EditShiftForm";

type Props = {
    shift: TNullable<Shift>
    setView: (value: ShiftModalType) => void
}

export const INDEFINITE_DATE = "2054-01-01"

export const EditFormContainer: FC<Props> = ({shift, setView}) => {
    if(!shift) return null
    const [editShift, {isLoading: editShiftIsLoading}] = useEditShiftMutation();
    const onSubmitCallBack = async (formData: any) => {
        const payload = {
            id: shift.id,
            data: {
                end_active_time: `${dayjs.duration(formData?.close_time?.value, "minutes").format("HH:mm:ss")}`,
                end_date: formData?.continue_indefinite ? INDEFINITE_DATE : dayjs(formData?.end_date).format("YYYY-MM-DD"),
                start_date: dayjs(formData?.start_date).format("YYYY-MM-DD"),
                start_active_time: dayjs.duration(formData?.open_time?.value, "minutes").format("HH:mm:ss"),
                places_id: formData?.places,
                repeat_days: formData?.repeatDays,
                shift_name: formData?.shift_name,
                deposit_options: {deposit_required: false},
                booking_interval: formData.booking_interval.value,
                restaurant_id: shift?.restaurant_id,
                capacity: {
                    ...shift?.capacity,
                    durations: rebuildDurations(formData?.durations),
                    use_max_covered: formData?.use_max_covered,
                    max_covered_size: formData?.max_covered_size,
                },
            }
        }

        const response = await editShift(payload)
        if('data' in response){
            setView('INFO')
        }
    }

    return <EditShiftForm onCancel={setView} shift={shift} onSubmitCallBack={onSubmitCallBack} isLoadingSubmit={editShiftIsLoading}/>
};
