import {useRecoverBookingStatusMutation} from "features/api/bookings-api";
import {useCallback} from "react";
import {Notification} from "services/notification";
import {useIntl} from "react-intl";
import {ETranslations} from "../types/translates";
import type { ErrorResponse } from "types/commons";

export function useRecoveryBooking(booking: { bookingId?: number | null }) {
  const [recoverStatus, {isLoading}] = useRecoverBookingStatusMutation();
  const intl = useIntl();

  const recoveryWithId = useCallback(async (bookingId: number) => {
    try {
      await recoverStatus({bookingId}).unwrap();
      Notification.success(intl.formatMessage({id: ETranslations.PREV_BOOKING_STATUS_RESTORED}));
    } catch (e) {
      Notification.error({
        title: intl.formatMessage({
          id: ETranslations.UNABLE_TO_RESTORE_BOOKING_STATUS,
        }),
        message: (e as ErrorResponse['error'])?.data?.errorMessage,
      });
    }
  }, []);

  const recovery = useCallback(async () => {
    const {bookingId} = booking;
    if (!bookingId) return;
    await recoveryWithId(bookingId);
  }, [booking]);
  return {
    recovery,
    recoveryWithId,
    isRecovering: isLoading,
  };
}
