import React, { FC, CSSProperties } from 'react';
import { SvgForeignObjectBody } from 'components/Table/SvgForeignObject';
import { ICONS } from 'ui-kit';

import styles from './style.module.scss';
import cn from 'classnames';

interface Props {
  alerts: {
    isOverbooking: boolean;
    isVip: boolean;
    isEndingSoon: boolean;
    isManagerial: boolean;
    isDeposit: boolean;
  };
  style?: CSSProperties;
}

export const BottomBlock: FC<Props> = ({ alerts, style }) => (
  <section style={style}
    data-no-d3-zoom
    className={styles.notifications}
  >
    {alerts.isManagerial && (
      <div className={cn(styles.iconWrapper, styles.lock)}>
        <ICONS.LockIcon width={18} height={18} />
      </div>
    )}
    {alerts.isDeposit && (
      <div className={cn(styles.iconWrapper, styles.deposit)}>$</div>
    )}
    {alerts.isOverbooking && (
      <div className={cn(styles.iconWrapper, styles.overbooking)}>!</div>
    )}
    {alerts.isVip && (
      <div className={styles.iconWrapper}>
        <ICONS.VipSign width={20} height={20} />
      </div>
    )}
    {alerts.isEndingSoon && <ICONS.ClockCircleScheme width={24} height={24} />}
  </section>
);
