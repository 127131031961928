import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Spinner } from 'ui-kit';
import { FormProxyProps } from './types';
import useBookingFormReduxProxy from './hooks/useBookingFormReduxProxy';
const BookingForm = React.lazy(() => import('./BookingForm'));

function BookingFormReduxProxy<P>({
  component: Component,
  isRegisterVisit,
  isCreating = true,
  isUpdate = false,
  isFromManagement = false,
  booking,
  isAnonym,
  ...rest
}: FormProxyProps<P>) {
  const { onSubmit, initial, dispatchEventFn } = useBookingFormReduxProxy({
    isRegisterVisit,
    isCreating,
    booking,
    isAnonym,
    ...rest,
  });

  return (
    <Form
      key={booking?.bookingId || booking}
      onSubmit={onSubmit}
      initialValues={initial}
      render={(props) => (
        <>
          {Component ? (
            <Component {...props} {...rest} />
          ) : (
            <React.Suspense fallback={<Spinner />}>
              <BookingForm
                booking={booking}
                isAnonym={isAnonym}
                isFromManagement={isFromManagement}
                isRegisterVisit={isRegisterVisit}
                isUpdate={isUpdate}
              />
            </React.Suspense>
          )}
          <FormSpy
            subscription={{ values: true }}
            onChange={(form) => dispatchEventFn(form.values)}
          />
        </>
      )}
    />
  );
}

export default React.memo(BookingFormReduxProxy);
