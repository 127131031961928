import React, {useCallback, useLayoutEffect, useRef} from "react";
import cn from "classnames";
import {useToggle} from "react-use";

import {ICONS, Button} from "..";

import styles from "./Collapse.module.scss";

interface CollapseProps {
  header: React.ReactNode;
  onClick?: () => void;
  headerClassName?: string;
  iconClassName?: string;
  initialOpen?: boolean;
  autoHeight?: boolean;
  expendInHeader?: boolean;
}

export const Collapse: React.FC<CollapseProps> = ({
  header,
  children,
  onClick,
  headerClassName,
  iconClassName,
  initialOpen,
  autoHeight,
  expendInHeader = true,
}) => {
  const [isActive, toggleIsActive] = useToggle(Boolean(initialOpen));

  const content = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    const {current: container} = content;
    if (!container) return;
    if (isActive) {
      container.style.height = "";
      const {scrollHeight} = container;
      container.style.height = autoHeight ? "auto" : `${scrollHeight}px`;
    } else {
      container.style.height = "0";
    }
  }, [children, isActive]);

  const toggle = useCallback(
    (e) => {
      e.stopPropagation();
      toggleIsActive();
    },
    [toggleIsActive]
  );

  return (
    <div className={styles.collapse}>
      <div
        className={cn(styles.header, headerClassName, {[styles.active]: isActive})}
        onClick={expendInHeader ? onClick || toggle : ()=>{}}
      >
        {header}
        {children ? (
          <Button variant="phantom" onClick={toggle} className={cn(styles.chevron__block, {[styles.rotate]: isActive})}>
            <ICONS.ArrowDown className={cn(styles.icon, iconClassName)} />
          </Button>
        ) : null}
      </div>
      <div ref={content} style={{height: "0"}} className={cn(styles["collapse-body"])}>
        {children}
      </div>
    </div >
  );
};
