/* eslint-disable no-param-reassign */
import {HallTableStatus, HallTableView} from 'models/hall-table';
import {isPageCreateBook, minsToString} from 'common/helpers';
import {TableSchema} from 'models/common';
import M from 'moment';

export const SCHEME_FACTOR = 40;

export enum TableColor {
  red = 'red',
  green = 'green',
  yellow = 'yellow',
  no_color = 'no_color',
  light_grey = 'light_grey',
  purple="purple"
}

// TODO need add localization
export enum TableTimeWord {
  in = 'В',
  empty = '',
  remaining = 'Осталось',
  overdue = 'Пересиж.',
  late = 'Опаздывает',
  soon = 'до брони',
}


export function hallTableView(
  beforeStart: number, beforeEnd: number, status: HallTableStatus, afterDayStart: number, bookStartTime?: string,
): HallTableView {
  let tableColor: TableColor = TableColor.no_color;
  let timeWord: TableTimeWord = TableTimeWord.empty;
  let timeString = '';
  let stripPercent = -1;

  switch (status) {
    case HallTableStatus.booked:
      tableColor = TableColor.light_grey;
      timeWord = TableTimeWord.in;
      timeString = bookStartTime || '';
      // timeString = minsToString(beforeStart);
      // stripPercent = (beforeStart * 100) / afterDayStart;
      break;
    case HallTableStatus.delay_start:
      tableColor = TableColor.yellow;
      timeWord = TableTimeWord.late;
      timeString = minsToString(-beforeStart);
      stripPercent = 100;
      break;
    case HallTableStatus.in_hall:
      tableColor = TableColor.green;
      timeWord = TableTimeWord.remaining;
      timeString = minsToString(beforeEnd);
      stripPercent = beforeStart > 0 ? 100 : (beforeEnd * 100) / (-beforeStart + beforeEnd);
      break;
    case HallTableStatus.delay_end:
      tableColor = TableColor.red;
      timeWord = TableTimeWord.overdue;
      timeString = minsToString(-beforeEnd);
      stripPercent = 100;
      break;
    case HallTableStatus.booked_not_conf:
      tableColor = TableColor.no_color;
      timeWord = TableTimeWord.in;
      timeString = bookStartTime || '';
      // timeString = minsToString(beforeStart);
      // stripPercent = (beforeStart * 100) / afterDayStart;
      break;
    default:
      break;
  }


  stripPercent = Math.round(stripPercent);

  return new HallTableView(tableColor, timeWord, timeString, stripPercent);
}

export function getNowDur(): M.Duration {
  const nowHour = M().hours();
  const nowMin = M().minutes();
  return M.duration(nowHour, 'h').add(nowMin, 'm');
}

export function getActualTableType(mode: 'add' | 'delete',
  persons: number,
  tableType: any,
  arrActiveTableTypes: number[]): number | string {
  if (mode === 'add') {
    if (tableType !== persons && tableType === 0 && !arrActiveTableTypes.length) {
      return persons;
    }
    if (tableType === persons && arrActiveTableTypes.length >= 1) {
      return persons;
    }
    if (tableType !== persons && arrActiveTableTypes.length >= 1) {
      return 'any';
    }
    return persons;
  }

  const isAllTablesEqType = arrActiveTableTypes.every((table) => table === arrActiveTableTypes[0]) && !!arrActiveTableTypes.length;
  return isAllTablesEqType ? arrActiveTableTypes[0] : 'any';
}

export enum Dimensions {
  width = 'width',
  height = 'height',
}


export class SchemaDimensions {
  // eslint-disable-next-line no-useless-constructor
  constructor(public schemaWidth: number,
    public schemaHeight: number,
    public viewportWidth: number,
    public viewportHeight: number) { }
}

export class SchemaPosAndZoom {
  // eslint-disable-next-line no-useless-constructor
  constructor(public x: number,
    public y: number,
    public zoom: number) { }
}


export class SchemaCornersInfo {
  // eslint-disable-next-line no-useless-constructor
  constructor(public startX: number,
    public startY: number,
    public width: number,
    public height: number,
    public maxX: number,
    public maxY: number) { }
}

export function cornersBySchemas(schemas: TableSchema[]): SchemaCornersInfo {
  const maxX: number = Math.max(...schemas.map((s) => s.x + s.width));
  const maxY: number = Math.max(...schemas.map((s) => s.y + s.height));

  const startX: number = Math.min(...schemas.map((s) => s.x));
  const startY: number = Math.min(...schemas.map((s) => s.y));

  const width = maxX - startX;
  const height = maxY - startY;

  return new SchemaCornersInfo(
    startX * SCHEME_FACTOR,
    startY * SCHEME_FACTOR,
    width * SCHEME_FACTOR,
    height * SCHEME_FACTOR,
    maxX * SCHEME_FACTOR,
    maxY * SCHEME_FACTOR,
  );
}

export function getMaxCoordinates(schemas: TableSchema[]) {
  const maxX: number = Math.max(...schemas.map((s) => s.x + s.width));
  const maxY: number = Math.max(...schemas.map((s) => s.y + s.height));
  return {x: maxX, y: maxY};
}

export function getPositionZoom(dims: SchemaDimensions): 'right' | 'top' {
  const pad = 3;

  dims.schemaWidth += pad;
  dims.schemaHeight += pad;

  const viewportRatio = dims.viewportWidth / dims.viewportHeight;
  const schemaRatio = dims.schemaWidth / dims.schemaHeight;
  const isWider: boolean = schemaRatio > viewportRatio;
  return isWider ? 'top' : 'right';
}

function shiftMainAxis(
  viewport: number,
  schema: number,
  inverseZoomFactor:
    number, pad: number,
  isMoveToCentr?: boolean,
  zoom?: number | false,
): number {
  if (isMoveToCentr) {
    return (viewport - (schema + pad) * SCHEME_FACTOR) / 2;
  }
  if (zoom) {
    return (viewport - 100 - ((schema + pad * 2 * 2) * SCHEME_FACTOR * zoom)) / 2;
  }
  return viewport * inverseZoomFactor + pad * SCHEME_FACTOR;
  // return isMoveToCentr ? (viewport - (schema + pad) * SCHEME_FACTOR) / 2
  //   : viewport * inverseZoomFactor + pad * SCHEME_FACTOR;
}

function shiftSecondaryAxis(viewport: number, schema: number, pad: number, zoom?: number | false): number {
  return zoom ? (viewport - ((schema + pad * 2 * 2) * SCHEME_FACTOR * zoom)) * 2 : (viewport - (schema + pad) * SCHEME_FACTOR) / 2;
  // return (viewport - (schema + pad) * SCHEME_FACTOR) / 2;
}

export function pos_and_zoom_by_dim(dims: SchemaDimensions, isHalfView: boolean): SchemaPosAndZoom {
  const pad = 3;

  dims.schemaWidth += pad;
  dims.schemaHeight += pad;

  const viewportRatio = dims.viewportWidth / dims.viewportHeight;
  const schemaRatio = dims.schemaWidth / dims.schemaHeight;
  const isWider: boolean = schemaRatio > viewportRatio;

  let x = 0;
  let y = 0;
  let zoom = 0;

  if (isWider) {
    zoom = dims.viewportWidth / dims.schemaWidth;
  } else {
    zoom = dims.viewportHeight / dims.schemaHeight;
  }
  zoom /= SCHEME_FACTOR;
  zoom = Math.min(0.3, zoom);

  const inverseZoomFactor = (1 - 1 / zoom) / 2,
    currentWidth = document.body.clientWidth,
    isWidthAbout1366 = currentWidth >= 1266 && currentWidth < 1466,
    isWidthAbout1920 = currentWidth >= 1750;

  let isMoveToCentr = false;
  // HARDCODE while there is no formula
  if (!isPageCreateBook()
    && ((isWidthAbout1920 && dims.schemaHeight < 63)
      || (isWidthAbout1366 && dims.schemaHeight < 48))
    && !isHalfView
  ) {
    isMoveToCentr = true;
  }
  if (!isPageCreateBook() && isHalfView
    && ((isWidthAbout1920 && dims.schemaHeight < 50)
      || (isWidthAbout1366 && dims.schemaWidth < 55))
  ) {
    isMoveToCentr = true;
  }
  if (isPageCreateBook()) {
    const w = dims.schemaWidth - pad,
      h = dims.schemaHeight - pad,
      diff = w > h ? w - h : h - w;

    if ((diff > 20 && diff < 25 && isWidthAbout1366)
      || (diff > 1 && diff < 5 && isWidthAbout1920)
      || (diff >= 5 && diff < 20)
    ) {
      isMoveToCentr = true;
    }
  }

  if (isWider) {
    x = shiftMainAxis(dims.viewportWidth, dims.schemaWidth, inverseZoomFactor, pad / 2, isMoveToCentr);
    y = shiftSecondaryAxis(dims.viewportHeight, dims.schemaHeight, pad / 2, isHalfView && zoom);
  } else {
    x = shiftSecondaryAxis(dims.viewportWidth, dims.schemaWidth, pad / 2);
    y = shiftMainAxis(dims.viewportHeight, dims.schemaHeight, inverseZoomFactor, pad / 2, undefined, isHalfView && zoom);
  }

  return new SchemaPosAndZoom(0, 0, 1);

}

// function shiftMainAxis(
//   viewport: number,
//   schema: number,
//   inverseZoomFactor: number,
//   pad: number,
//   zoom: number,
// ): number {
//   return viewport * inverseZoomFactor + pad * SCHEME_FACTOR;
// }

// function shiftSecondaryAxis(viewport: number, schema: number,
//   inverseZoomFactor: number, pad: number, zoom?: number): number {

//   return zoom ? (viewport - ((schema + pad) * SCHEME_FACTOR * zoom)) * 2 : (viewport - (schema + pad) * SCHEME_FACTOR) / 2;
// }

// export function pos_and_zoom_by_dim(dims: SchemaDimensions, isHalfView: boolean): SchemaPosAndZoom {
//   const pad = 3;

//   dims.schemaWidth += pad;
//   dims.schemaHeight += pad;

//   const viewportRatio = dims.viewportWidth / dims.viewportHeight;
//   const schemaRatio = dims.schemaWidth / dims.schemaHeight;
//   const isWider: boolean = schemaRatio > viewportRatio;

//   let x = 0;
//   let y = 0;
//   let zoom = 0;

//   if (isWider) {
//     zoom = dims.viewportWidth / dims.schemaWidth;
//   } else {
//     zoom = dims.viewportHeight / dims.schemaHeight;
//   }
//   zoom /= SCHEME_FACTOR;
//   zoom = Math.min(0.3, zoom);

//   const inverseZoomFactor = (1 - 1 / zoom) / 2;

//   console.log('zoom', zoom)
//   if (isWider) {
//     x = shiftMainAxis(dims.viewportWidth, dims.schemaWidth, inverseZoomFactor, pad / 2 ,  zoom);
//     y = shiftSecondaryAxis(dims.viewportHeight, dims.schemaHeight,inverseZoomFactor,  pad, isHalfView && zoom);
//   } else {
//     x = shiftSecondaryAxis(dims.viewportWidth, dims.schemaWidth, inverseZoomFactor, pad / 2);
//     y = shiftMainAxis(dims.viewportHeight, dims.schemaHeight, inverseZoomFactor, pad , zoom);
//   }

//   return new SchemaPosAndZoom(x, y, zoom);
// }

export const getCoordsModal = (e: any,
  isViewHalf: boolean,
  {widthModal = 187,
    heightModal = 142}: {
      widthModal?: number;
      heightModal?: number;
    } = {}): { left: number; top: number; posModal: 'above' | 'below' | 'left' | 'right' | 'center' } => {
  const currentWidth = document.body.clientWidth,
    posTable = e.currentTarget.getBoundingClientRect(),
    heightWidthTriangle = 20,
    receivedLeft = posTable.left as number,
    receivedRight = posTable.right as number,
    receivedTop = posTable.top as number,
    receivedWidth = posTable.width as number,
    receivedHeight = posTable.height as number,
    receivedBottom = posTable.bottom as number,
    paddingVertPos = isViewHalf ? 23 : 16,
    paddingHorPos = isViewHalf ? 10 : 0;

  // OFFSET vars, because of header and nav-bar
  const offsetLeft = currentWidth <= 1366 ? 94 : 122,
    offsetTop = currentWidth <= 1366 ? 60 : 80,
    // @ts-ignore
    svgLeftPosition = isViewHalf ? document.querySelector('svg.general-svg').getBoundingClientRect().left - offsetLeft : 0,
    // @ts-ignore
    svgRightPosition = document.querySelector('svg.general-svg').getBoundingClientRect().right,
    isFitModalLeft = (receivedLeft - offsetLeft - svgLeftPosition) > (widthModal / 2 - receivedWidth / 2),
    isFitModalTop = (receivedTop - offsetTop) > (heightModal + heightWidthTriangle + 20),
    isFitModalRight = (svgRightPosition - receivedRight) > (widthModal / 2 - receivedWidth / 2);

  let posModal: 'above' | 'below' | 'left' | 'right' | 'center' = 'above',
    left = 0,
    top = 0;

  if (currentWidth <= 1366) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const svgWidth = document.querySelector('svg.general-svg')!.getBoundingClientRect().width;
    left = (svgWidth / 2) - (widthModal / 2);
    top = (window.innerHeight / 2 - heightModal / 2) - offsetTop;
    return {left, top, posModal: 'center'};
  }

  // svgLeftPosition - required var, because SVG changed position near Half-view
  // padding... - required vars, because padding changed near Half-view
  // OFFSET - required vars, because of header and nav-bar changed near 1366/1920 pesolution

  if (isFitModalLeft && isFitModalRight && isFitModalTop) {
    left = (receivedLeft + receivedWidth / 2) - offsetLeft - widthModal / 2 - svgLeftPosition;
    top = receivedTop - offsetTop - heightModal - paddingVertPos;
  } else if (!isFitModalLeft) {
    left = receivedRight - offsetLeft + heightWidthTriangle - svgLeftPosition;
    top = receivedBottom - offsetTop - heightModal / 2 - receivedHeight / 2 - paddingHorPos;
    posModal = 'right';
  } else if (!isFitModalRight) {
    left = receivedLeft - offsetLeft - heightWidthTriangle - widthModal - svgLeftPosition;
    top = receivedBottom - offsetTop - heightModal / 2 - receivedHeight / 2 - paddingHorPos;
    posModal = 'left';
  } else {
    left = (receivedLeft + receivedWidth / 2) - offsetLeft - widthModal / 2 - svgLeftPosition;
    top = receivedBottom - offsetTop + receivedHeight / 2 - heightWidthTriangle + paddingVertPos;
    posModal = 'below';
  }

  return {left, top, posModal};
};
