import React from 'react';
import {FieldRenderProps} from 'react-final-form';
import {UserExtended} from '../../../models/user.model';
import {SelectBasic} from 'ui-kit';
import {useIntl} from "react-intl";
import {ETranslations} from "../../../types/translates";

type Props = FieldRenderProps<UserExtended, any>;

export const ChoiceHostess = ({value, onChange, disabled, options, openTo = 'top', isClearable = false, invalid, ...rest}: any) => {
  const intl = useIntl();

  return <SelectBasic
    value={value}
    openTo={openTo}
    changedMaxSize
    isDisabled={disabled}
    menuPlacement="top"
    onChange={onChange}
    placeholder={intl.formatMessage({id: ETranslations.BASE_CHOOSE})}
    options={options}
    isClearable={isClearable}
    isValid={!invalid}
    {...rest}
  />;
};

export const ChoiceHostessInput: React.FC<Props> = ({input, meta, ...rest}: Props) => (
  <ChoiceHostess {...input} {...meta} {...rest} />
);
