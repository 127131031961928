import { ICONS } from 'ui-kit';
import styles from './GuestNotes.module.scss';

export const Actions = ({
  className,
  editNote,
  deleteNote,
}: {
  className: string;
  editNote: () => void;
  deleteNote: () => void;
}) => (
  <section className={className}>
    <button onClick={editNote}>
      <ICONS.Edit className={styles.action} />
    </button>
   {/*  <div className={styles.divider} />
    <button onClick={deleteNote}>
      <ICONS.TrashBin className={styles.action} />
    </button> */}
  </section>
);
