import React from "react";
import {FieldRenderProps} from "react-final-form";
import cn from "classnames";
import dayjs from "dayjs";
import {DataTab} from "components/DataTab";
import useChoiseDataChips from "./hooks/useChoiseDataChips";
import styles from "./style.module.scss";


type DateProps = FieldRenderProps<Date, any>;

const nextThreeDays = [
  dayjs().add(1, "day").format("YYYY-MM-DD"),
  dayjs().add(2, "day").format("YYYY-MM-DD"),
  dayjs().add(3, "day").format("YYYY-MM-DD"),
];

const ChoiceDateChips = ({value, onChange, disabled, isCreateBooking, isFromManagement}: any) => {
  const {datePickerHandler, dateField} = useChoiseDataChips({value, onChange, disabled});

  return (
    <div className={cn(isCreateBooking && !isFromManagement ? styles.chipsWrapper : styles.chipsWrapperEdit)}>
      {nextThreeDays.map((item, index) => {
        const isSameDay = dayjs(item).isSame(dayjs(dateField).format("YYYY-MM-DD"));
        return (
          <DataTab
            disabled={disabled}
            styles={styles}
            isSameDay={isSameDay}
            item={item}
            index={index}
            key={index}
            datePickerHandler={datePickerHandler}
          />
        );
      })}
    </div>
  );
};

export const DataChips: React.FC<DateProps> = ({input, meta, ...rest}) => <ChoiceDateChips {...input} {...meta} {...rest} />;
