import React, {useMemo} from "react";
import {Card, Spinner} from "ui-kit";
import {HallMode} from "features/HallSchema";
import {CloseBookingContext} from "contexts/CloseBookingContext";
import {ETranslations} from "types/translates";
import useDashBoards, {Tab} from "./useDashBoards";
import {EditManageralBooking} from "components/ManagerialTables/form/EditManageralBooking";
import {SelectGuestFromList} from "features/GuestsList/components/select-guest-from-list";
import {HideWhen} from "components/HideWhen";
import {DashboardBookings} from "components/booking/DashboardBookings";
import {Booking, ManagerialTable} from "types/booking";
import {EditBooking} from "components/hall-scheme/redux/TableBookingList/table-booking-list";
import {HallSchema} from "components/hall-scheme/redux/HallSchemaV2/hall-schema";
import {CreateBooking} from "components/hall-scheme/redux/Booking/CreateBooking";
import {BookingHistory} from "components/BookingHistory";


export function Dashboard() {

  const {hasBooking,
    isManagarelTableBooking,
    booking,
    emptyMessage,
    timestamp,
    isTablet,
    isCreatingBooking,
    mode,
    resetBooking,
    closeHall,
    intl,
    closeGuests,
    handleSetClient,
    isGuestMode,
    tab,
    getIntlHistoryOf,
    setTab,
    close} = useDashBoards();

  const EditSection = useMemo(() => {
    if (hasBooking && !isManagarelTableBooking) {
      return <EditBooking bookingId={(booking as Booking).bookingId} />;
    } else if (hasBooking && isManagarelTableBooking) {
      return <EditManageralBooking booking={booking as unknown as ManagerialTable} />;
    } else {
      return emptyMessage;
    }
  }, [hasBooking, isManagarelTableBooking, booking, timestamp]);

  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <HideWhen condition={(isTablet && hasBooking) || (isTablet && isCreatingBooking)} noUnmount>
          <DashboardBookings />
        </HideWhen>
      </React.Suspense>
      {isCreatingBooking && (
        <>
          <Card style={{display: ![HallMode.MANAGERAL_CREATE_BOOKING].includes(mode) ? "none" : "initial"}}>
            <HideWhen noUnmount condition={![HallMode.MANAGERAL_CREATE_BOOKING].includes(mode)}>
              <CreateBooking key={booking?.bookingId} reset={resetBooking} isFromManagement />
            </HideWhen>
          </Card>

          <HideWhen noUnmount condition={![HallMode.MANAGARAL_HALL, HallMode.MANAGARAL_HALL_BOOKING].includes(mode)}>
            <Card onClose={closeHall}>
              <Card.Header title={intl.formatMessage({id: ETranslations.HALL_SCHEME})} />
              <Card.Content>
                <HallSchema />
              </Card.Content>
            </Card>
          </HideWhen>
          <HideWhen
            noUnmount
            condition={
              ![HallMode.MANAGERAL_OPEN_GUESTS].includes(mode)
            }
          >
            <div>
              <SelectGuestFromList onClose={closeGuests} onSelectGuest={handleSetClient} />
            </div>
          </HideWhen>
        </>
      )}
      {(isTablet && !hasBooking) || isCreatingBooking ? null : (
        <>
          <CloseBookingContext.Provider value={hasBooking ? close : null}>
            <HideWhen condition={mode.includes("HALL") || isGuestMode} noUnmount>
              <Card onClose={hasBooking ? close : undefined}>
                <Card.HeaderTabs activeTab={tab} onChange={setTab}>
                  <Card.HeaderTabs.Item title={intl.formatMessage({id: ETranslations.BOOKING_MANAGEMENT})} tabName={Tab.Edit} />
                  <Card.HeaderTabs.Item
                    title={getIntlHistoryOf(ETranslations.PLURAL_CHANGES)}
                    tabName={Tab.History}
                    disabled={!hasBooking}
                  />
                </Card.HeaderTabs>
                <HideWhen condition={tab !== Tab.Edit} noUnmount>
                  <Card.Content noPadding>{EditSection}</Card.Content>
                </HideWhen>
                <HideWhen condition={tab !== Tab.History}>
                  {booking ? <BookingHistory booking={booking} isExpanded /> : emptyMessage}
                </HideWhen>
              </Card>
            </HideWhen>
          </CloseBookingContext.Provider>
          {tab === Tab.Edit && (
            <>
              <HideWhen condition={!mode.includes("HALL")}>
                <Card onClose={closeHall}>
                  <Card.Header title={intl.formatMessage({id: ETranslations.HALL_SCHEME})} />
                  <Card.Content>
                    <HallSchema />
                  </Card.Content>
                </Card>
              </HideWhen>
              <HideWhen condition={![HallMode.BOOKING_GUEST, HallMode.TABLE_BOOKINGS_EDIT_GUEST].includes(mode)}>
                <div>
                  <SelectGuestFromList onClose={closeGuests} onSelectGuest={handleSetClient} />
                </div>
              </HideWhen>
            </>
          )}
        </>
      )}
    </>
  );
}
