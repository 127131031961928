import React, {FC, useCallback} from 'react';
import {NavLink as RouterLink, NavLinkProps} from 'react-router-dom';
import styles from './Sidebar.module.scss';

interface IProps extends NavLinkProps {
  resetFn?: () => void
}

export const NavLink: FC<IProps> = ({resetFn, onClick, ...props}) => {
  const handleClick = useCallback((e) => {
    onClick?.(e);
    resetFn?.();
  }, [resetFn, onClick]);

  return (
    <RouterLink {...props} onClick={handleClick} activeClassName={styles.active} />
  );
};
