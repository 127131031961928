import {ChangeEventHandler, forwardRef, Ref} from 'react';
import OriginalPhoneInput from 'react-phone-number-input/input';

import {InputProps, Input} from './Input';


const InputOverride = forwardRef(({onChange, ...props}: InputProps, ref: Ref<HTMLInputElement>) => {
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if ((e.target.value.match(/\d/g)?.length ?? 0) > 15) return;
    onChange?.(e);
  };

  return <Input {...props} onChange={handleOnChange} ref={ref} />;
}) as typeof Input;

interface PhoneInputProps extends Omit<InputProps, 'onChange'> {
  onChange: (val: string) => void;
}


export function PhoneInput(props: PhoneInputProps) {
  return (
    <OriginalPhoneInput
      placeholder="+"
      {...props}
      // @ts-ignore
      inputComponent={InputOverride}
    />
  );
}
