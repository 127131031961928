import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { HallMode, useHallSchemaActions } from 'features/HallSchema';
import { useFromProxyActions } from 'features/BookingFormProxy';
import { getSliderTime } from 'features/Timeline/selectors';
import { roundMomentTime } from 'components/registration/forms/apiFn';
import { useIsTablet } from 'hooks/useIsTablet';
import { appContextSelectors } from 'features/AppContex';
import moment from 'moment';
import { BottomBar, Button } from 'ui-kit';
import { useBlackoutZone } from 'hooks/useBlackoutZone';
import { timelineSelectors } from 'features/Timeline';
import { useCurrentHallShiftsSchedule } from 'features/api/hallschema-api';
import { useIntlUtils } from '../../../../hooks/useIntlUtils';
import { ETranslations } from '../../../../types/translates';
import { useHistory } from 'react-router';
import { defer } from 'lodash';
import styles from './TableActions.module.scss';
import type { PlaceTable } from 'types/table';

interface TableActionsProps {
  table: PlaceTable;
  isRegisterDisabled?: boolean;
  className?: string;
}

export const TableActions = memo(
  ({ table, isRegisterDisabled, className }: TableActionsProps) => {
    const { intl, getIntlJoinedParts, isRussianLocale, getIntlAddOf }
      = useIntlUtils();
    const sliderTime = useSelector(getSliderTime);
    const place = useSelector(appContextSelectors.place);
    const date = useSelector(appContextSelectors.date);
    const { switchMode, setActiveTables } = useHallSchemaActions();
    const { setTime } = useFromProxyActions();
    const isTablet = useIsTablet();
    const { setBooking, resetBooking } = useFromProxyActions();
    const history = useHistory();
    const timestamp = useSelector(timelineSelectors.getTimestamp);
    const { data: optionsData } = useCurrentHallShiftsSchedule();

    const isBlackoutZone = useMemo(() => {
      return useBlackoutZone(timestamp, optionsData || []);
    }, [timestamp, optionsData]);

    const registrationHandle = () => {
      if (isRegisterDisabled) return;
      resetBooking();
      history.push({
        pathname: '/create-booking',
        state: {
          from: history.location.pathname,
          isRegistrationVisit: true,
        },
      });
      defer(() => {
        const time: String = roundMomentTime(sliderTime());
        setTime({ selectedTimeValue: time.substring(0, 5) });
        setActiveTables({ activeTables: [table.table_id] });
        table
          && setBooking({
            booking: {
              places: [
                {
                  id: table.table_id,
                  number: table.number.toString(),
                  place: { id: place },
                },
              ],
            },
            client: null,
          });
      });
    };
    const checkIsToday = useCallback(
      () => moment().isSame(date, 'day'),
      [date]
    );

    const [isToday, setIsToday] = useState(checkIsToday);

    useEffect(() => {
      const interval = setInterval(() => {
        setIsToday(checkIsToday);
      }, 1e3);
      return () => clearInterval(interval);
    }, [checkIsToday, setIsToday]);

    const createBooking = useCallback(() => {
      history.push({
        pathname: '/create-booking',
        state: {
          from: history.location.pathname,
        },
      });
      switchMode(isTablet ? HallMode.BOOKING_TABLET : HallMode.BOOKING_GUEST);
      table
        && setBooking({
          booking: {
            places: [
              {
                id: table.table_id,
                number: table.number.toString(),
                place: { id: place },
              },
            ],
          },
          client: null,
        });
    }, [table, history.location]);
    return (
      <BottomBar className={cn(styles.container, className)}>
        <BottomBar.Part placement="left" className={styles.buttons}>
          {Boolean(isToday) && !isBlackoutZone && (
            <Button
              variant="primary"
              disabled={isRegisterDisabled}
              onClick={registrationHandle}
            >
              {intl.formatMessage({ id: ETranslations.PLACING_BY_FACT })}
            </Button>
          )}

          <Button onClick={createBooking} variant="primary">
            {isRussianLocale
              ? getIntlJoinedParts([
                  ETranslations.BASE_CREATE,
                  ETranslations.PLURAL_BOOKING,
                ])
              : getIntlAddOf(ETranslations.PLURAL_BOOKING)}
          </Button>
        </BottomBar.Part>
      </BottomBar>
    );
  }
);
