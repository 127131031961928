import React, {useState} from "react";
import {Redirect} from 'react-router';
import {useDispatch} from 'react-redux';
import cn from 'classnames';

import {ICONS} from 'common/helpers';
import {AuthService} from 'services/auth.service';
import {config} from 'config';
import {dictionariesApi, useTranslates} from "features/api/dictionaries-api";

import {Card, Input} from 'ui-kit';
import styles from './Auth.module.scss';
import {ETranslations} from "../../types/translates";
import {LocaleControls} from './LocaleControls'
import {useLocalStorage} from "react-use";
import {ELocales} from "../../types/commons";
import {HideWhen} from '../HideWhen';

const Clear = () => {
  const dispatch = useDispatch();
  dispatch(dictionariesApi.util.invalidateTags(['Dicts']));
  return null;
};

interface AuthState {
  login: string;
  password: string;
  loginSuccess: boolean;
  isValid: boolean;
  viewPassword: 'password' | 'text';
}

export default () => {
  const [userLocale, setUserLocale] = useLocalStorage<ELocales>('locale', config.defaultLocale)
  const {translates} = useTranslates(userLocale)
  const [authState, setAuthState] = useState<AuthState>({
    login: '', password: '', loginSuccess: false, isValid: true, viewPassword: 'password',
  })

  const handleChangeLogin = (value: string) => {
    setAuthState(prev =>({...prev,login: value}));
  }

  const handleChangePassword =(value: string) => {
    if (!/[а-яА-Я]/.test(value.slice(-1)) || value === '') {
      setAuthState(prev => ({...prev,password: value}));
    }
  }

  const handleChangeViewPassword =() => {
    setAuthState((prev) => ({...prev, viewPassword: prev.viewPassword === 'password' ? 'text' : 'password'}));
  }

  const onLogin = async ()=> {
    const {login, password} = authState;
    if (!(/[0-9a-z_]/g.test(login))) {
      setAuthState(prev =>({...prev,isValid: false}));
    } else {
      await AuthService.login({login, password, locale: userLocale})
        .then(
          () => setAuthState(prev =>({...prev,isValid: true, loginSuccess: true})),
          () => setAuthState(prev =>({...prev,isValid: false})),
        );
    }
  }

    if (authState.loginSuccess) {
      // predata.init().then();
      window.history.pushState(null, 'Главная', '/');
      return <Redirect to="/" />;
    }

    const {isValid, login, password, viewPassword} = authState;

    const BRAND_LOGO = ICONS[`${config.BRAND}_AUTH_LOGO` as keyof typeof ICONS] as string || ICONS.WRF_AUTH_LOGO;
    const BRAND_BG = ICONS[`${config.BRAND}_BG` as keyof typeof ICONS] as string || ICONS.WRF_BG;

    return (
      <div id="auth" onKeyPress={(e) => e.charCode === 13 && onLogin()}>
        <div className="group">
          <div className="img-group">
            <div className="img" style={{backgroundImage: `url(${BRAND_BG})`}} />
            <div className="logo-group">
              <HideWhen condition={!config.hasLogo}>
                <img src={BRAND_LOGO} alt="" />
              </HideWhen>
              <h1>{translates[ETranslations.AUTH_SYSTEM_NAME]}</h1>
            </div>
          </div>
          <div className="form-group">
            <Card.Header
              title={translates[ETranslations.AUTH_SYSTEM_ENTRY]}
              controls={<HideWhen condition={!config.hasMultiLanguage}>
                <LocaleControls onLocaleChange={setUserLocale}/>
              </HideWhen>}
            />
            <div className="form-content">
              <div className="error" style={{visibility: isValid ? 'hidden' : 'visible'}}>
                {translates[ETranslations.AUTH_INCORRECT_CREDENTIALS]}
              </div>
              <form>
                <h3>{translates[ETranslations.AUTH_LOGIN]}</h3>
                <Input
                  autoFocus
                  className={isValid ? '' : 'invalid'}
                  value={login} onChange={(e) => handleChangeLogin(e.target.value)}
                  type="text"
                />

                <h3>{translates[ETranslations.AUTH_PASSWORD]}</h3>
                <div>
                  <Input
                    className={isValid ? '' : 'invalid'}
                    value={password}
                    onChange={(e) => handleChangePassword(e.target.value)}
                    type={viewPassword}
                    suffix={
                      <ICONS.Eye
                        className={cn(styles.icon, {[styles.active]: viewPassword === 'text'})}
                        onClick={(e) => { e.stopPropagation(); handleChangeViewPassword(); }}
                      />
                    }
                  />
                </div>

                <button type="button" className="primary" onClick={onLogin}>{translates[ETranslations.AUTH_SING_IN]}</button>
              </form>
              <div className="footer">
                {(config.supportEmail || config.supportPhone) && (
                <>
                  <span className="title">{translates[ETranslations.AUTH_SUPPORT_SERVICE]}:</span>
                  {config.supportEmail && (
                    <a href={`mailto:${config.supportEmail}`}>
                      <span className="mail">
                        <img src={ICONS.mail} alt="" />
                        {config.supportEmail}
                      </span>
                    </a>
                  )}
                  {config.supportPhone && (
                  <a href={`tel:${config.supportPhone}`}>
                    <span className="mail">
                      <img src={ICONS.call} alt="" />
                      {config.supportPhone}
                    </span>
                  </a>
                 )}
                </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Clear />
      </div>
    );
}
