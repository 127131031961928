import {globalSearchSliceName, initialState} from './index';
import {createSelector} from '@reduxjs/toolkit';
import {GlobalSearchParams} from 'types/globalSearch';
import {appContextSelectors} from 'features/AppContex';
import {Slice} from './slice';

const selectDomain = (state: any): Slice => state[globalSearchSliceName] || initialState;

export const params = createSelector([
  selectDomain, appContextSelectors.restaurant,
], (domain, restaurant): GlobalSearchParams => ({
  ...domain,
  restaurantId: restaurant.restaurant_id,
}));
