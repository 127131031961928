import React from "react";
import {Form} from "react-final-form";
import {BottomBar, Button, Card} from "ui-kit";
import styles from "./Form.module.scss";
import {CancelBooking} from "components/ManagerialTables/form/CancelBooking";
import {ETranslations} from "types/translates";
import cn from "classnames";
import useCurretRuleForm from "./useCurretRuleForm";
import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";
import RuleForm from "./RuleForm";


const CurrentRuleForm = ({
    onClose,
    currentRule,
}: {
    onClose: () => void;
    currentRule?: any;
}) => {
    const {initalValue,
        onSubmit,
        intl,
        openEditModal,
        toggleOpensSave,
        openDeleteModal,
        toggleOpenDeleteModal,
        error,
        onToggleCloseError,
        handleDeleteCurrentRule} = useCurretRuleForm(currentRule, onClose);

    return (
        <Form initialValues={initalValue} onSubmit={onSubmit}>
            {({handleSubmit, values}) => {
                return (
                    <Card onClose={close}>
                        <Card.Content noPadding>
                            <form onSubmit={handleSubmit} className={cn(styles.form)}>
                                <div className={styles.content}>
                                    <RuleForm />
                                </div>
                                <BottomBar className={cn(styles.controls)}>
                                    <BottomBar.Part placement="left">
                                        <Button variant="secondary"
                                            onClick={toggleOpenDeleteModal}
                                        >
                                            {intl.formatMessage({id: ETranslations.BASE_DELETE})}
                                        </Button>
                                    </BottomBar.Part>
                                    <BottomBar.Part placement="right">
                                        <CancelBooking openModal={onClose} type="secondary" />
                                        <Button onClick={toggleOpensSave} variant="primary">
                                            {intl.formatMessage({id: ETranslations.SAVE})}
                                        </Button>
                                    </BottomBar.Part>
                                </BottomBar>
                            </form>
                        </Card.Content>


                        {
                            !!error && <ModalPopup
                                needIcon={true}
                                isOpen={!!error}
                                onClose={onToggleCloseError}
                                buttons={
                                        <Button onClick={onToggleCloseError} variant="secondary">
                                            {intl.formatMessage({id: ETranslations.CANCEL})}
                                        </Button>
                                }
                                title={intl.formatMessage({id: ETranslations.BASE_ERROR})}
                                text={error}
                            />
                        }


                        {openDeleteModal && (
                            <ModalPopup
                                isOpen={openDeleteModal}
                                onClose={toggleOpenDeleteModal}
                                buttons={
                                    <>
                                        <Button variant="dangerous"
                                            value={values?.index}
                                            onClick={handleDeleteCurrentRule}
                                            type="submit"
                                        >
                                            {intl.formatMessage({id: ETranslations.BASE_DELETE})}
                                        </Button>
                                        <Button onClick={toggleOpenDeleteModal} variant="secondary">
                                            {intl.formatMessage({id: ETranslations.CANCEL})}
                                        </Button>
                                    </>
                                }
                                title={intl.formatMessage({id: ETranslations.DELETE_AR})}
                                text={intl.formatMessage({id: ETranslations.DELETE_WARNING_TEXT})}
                            />
                        )}

                        {
                            openEditModal && <ModalPopup
                                needIcon={false}
                                isOpen={openEditModal}
                                onClose={toggleOpensSave}
                                buttons={
                                    <>
                                        <Button variant="primary" type="submit" onClick={handleSubmit}>
                                            {intl.formatMessage({id: ETranslations.BASE_SAVE})}
                                        </Button>
                                        <Button onClick={toggleOpensSave} variant="secondary">
                                            {intl.formatMessage({id: ETranslations.CANCEL})}
                                        </Button>
                                    </>
                                }
                                title={intl.formatMessage({id: ETranslations.ASK_SAVE_CHANGES})}
                                text={intl.formatMessage({id: ETranslations.IMMEDIATELY_CHANGES})}
                            />
                        }
                    </Card>
                );
            }}
        </Form>
    );
};

export default CurrentRuleForm;
