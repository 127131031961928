import { useCallback, useRef, useState, Suspense, useEffect } from 'react';
import cn from 'classnames';
import { useFetchAllRestaurantsQuery } from 'features/api/restaurants';
import { ICONS, Spinner } from 'ui-kit';
import { useIntl } from 'react-intl';
import { ETranslations } from 'types/translates';
import { HallModal } from './HallModal';
import { UpdatePlaceDTO } from 'types/place';
import { HallEditor } from 'components/HallEditor';
import { Popup, PopupRef } from 'components/Popup';
import { HallMenu } from './HallMenu';
import { Place, Restaurant } from 'models/restaurant.model';
import styles from './RestaurantManagement.module.scss';

export function RestaurantManagement() {
  const { data: restaurants, isLoading } = useFetchAllRestaurantsQuery();
  const [selectedRest, setSelectedRest] = useState<number | undefined>();
  const [selectedHall, setSelectedHall]
    = useState<Partial<UpdatePlaceDTO> | undefined>();
  const [schemeFor, setSchemeFor]
    = useState<{ hall: Place; restaurant: Restaurant } | undefined>();
  const popup = useRef<PopupRef>(null);

  const resetHall = useCallback(() => setSelectedHall(undefined), []);
  const onClose = useCallback(() => setSchemeFor(undefined), []);

  const intl = useIntl();

  useEffect(() => {
    if (restaurants && restaurants.length === 1) {
      setSelectedRest(restaurants[0]?.restaurant_id);
    }
  }, [restaurants]);

  if (isLoading) return <Spinner />;

  return (
    <>
      <div
        className={`appointHostess sections include-table restaurantManagement ${
          restaurants?.length === 1 ? 'one-tab' : ''
        }`}
      >
        {restaurants?.map((rest) => {
          const currentRestaurantActive = rest.restaurant_id === selectedRest;
          return (
            <div
              key={rest.restaurant_id}
              className={cn('rest', {
                active: currentRestaurantActive,
              })}
            >
              <div
                className="rest-info blocks"
                onClick={() =>
                  setSelectedRest((prev) =>
                    prev === rest.restaurant_id ? undefined : rest.restaurant_id)
                }
              >
                <span>{rest.restaurant_name}</span>
                <div>
                  <div
                    className="clickable"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedHall({ restaurant_id: rest.restaurant_id });
                    }}
                  >
                    <p>
                      {intl.formatMessage({ id: ETranslations.CREATE_HALL })}
                    </p>
                  </div>
                  {restaurants?.length !== 1 && (
                    <figure
                      className={cn(styles.arrow, {
                        [styles.active]: currentRestaurantActive,
                      })}
                    >
                      <ICONS.ArrowDown />
                    </figure>
                  )}
                </div>
              </div>
              <div className="table rows custom-scroll">
                {rest.places.map((place) => (
                  <div key={place.id} className="table-row blocks">
                    <span>{place.name}</span>
                    <div>
                      <Popup ref={popup}>
                        <Popup.Trigger>
                          <div
                            className="edit"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </Popup.Trigger>
                        <Popup.Content>
                          <HallMenu
                            hall={place}
                            onClick={() => popup.current?.close()}
                            onEditName={(hall) => {
                              setSelectedHall({
                                ...hall,
                                restaurant_id: rest.restaurant_id,
                              });
                            }}
                            onEditScheme={(hall) => {
                              setSchemeFor({ hall, restaurant: rest });
                            }}
                          />
                        </Popup.Content>
                      </Popup>
                    </div>
                  </div>
                ))}
                {!rest.places.length && (
                  <div className="message">
                    {intl.formatMessage({
                      id: ETranslations.HOSTESS_INFO_MISSING,
                    })}
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {!restaurants?.length && (
          <div className="empty-list">
            {intl.formatMessage({ id: ETranslations.NOT_FOUND })}
          </div>
        )}
      </div>
      <HallModal
        hall={selectedHall}
        onClose={resetHall}
        onAfterCreate={(hall) => {
          const restaurant = restaurants?.find(
            (r) => r.restaurant_id === hall.restaurant_id
          );
          restaurant && setSchemeFor({ hall, restaurant });
        }}
      />
      <Suspense fallback={<Spinner className={styles.spinner} />}>
        {schemeFor && (
          <HallEditor
            hall={schemeFor.hall}
            restaurant={schemeFor.restaurant}
            onClose={onClose}
          />
        )}
      </Suspense>
    </>
  );
}
