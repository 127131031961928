import React from "react";
import {Form} from "react-final-form";
import {BottomBar, Button, Card, ICONS} from "ui-kit";
import useAccessRuleForm from "./useAccessRuleForm";
import Settings from "./Steps/Settings";
import Capicacity from "./Steps/Capicacity";
import Widget from "./Steps/Widget";
import styles from "./Form.module.scss";
import {CancelBooking} from "components/ManagerialTables/form/CancelBooking";
import {ETranslations} from "types/translates";
import AccessRuleInfo from "components/AccessRuleInfo/AccessRuleInfo";
import cn from "classnames";
import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";

interface Content {
  [key: string]: React.ComponentType<any>;
}

interface MyFormValues {
  settings: {
    rules: string[];
  };
}

const Content: Content = {
  settings: Settings,
  capicacity: Capicacity,
  widget: Widget,
};

const AccessRuleForm = ({
  onClose,
  access_rule,
  setIsEditMode,
  isCreating = false,
  isEditMode,
}: {
  onClose: () => void;
  access_rule?: any;
  setIsEditMode: any;
  isEditMode?: boolean;
  isCreating?: boolean;
}) => {
  const {
    initalValue,
    onSubmit,
    activeTab,
    intl,
    onToggleEdit,
    handleDeleteAR,
    openDeleteModal,
    toggleOpenDeleteModal,
    toggleOpensSave,
    openEditModal,
    responseError,
    handleRemoveResponseError
  } = useAccessRuleForm(access_rule, setIsEditMode, onClose);

  const Component = Content[activeTab];

  const validate = (values: MyFormValues) => {
    const errors: Partial<MyFormValues> = {};
    if (!values.settings || !values.settings.rules || values.settings.rules.length === 0) {
      // @ts-ignore
      errors.settings = {rules: "Rules are required"};
    }
    return errors;
  };

  return (
    <Form initialValues={initalValue} validate={validate} onSubmit={onSubmit}>
      {({handleSubmit, valid, form}) => {
        const handleSubmitForm = () => {
          handleSubmit();
          // form.reset();
        }

        const handleClickSave = () => {
          if (isCreating) {
            handleSubmitForm();
          } else {
            toggleOpensSave();
          }
        }

        return (
          <Card onClose={close}>
            <form onSubmit={handleSubmitForm} className={cn(styles.form)}>
              <div className={styles.content}>
                {isCreating || isEditMode ? <Component isCreating={isCreating} /> : <AccessRuleInfo access_rule={access_rule} />}
              </div>
              <BottomBar className={cn(styles.controls)}>
                {isCreating || isEditMode ? (
                  <BottomBar.Part placement="right">
                    <CancelBooking openModal={onClose} type="secondary" />
                    <Button onClick={handleClickSave} disabled={!valid} variant="primary">
                      {intl.formatMessage({id: ETranslations.SAVE})}
                    </Button>
                  </BottomBar.Part>
                ) : (
                  <>
                    <BottomBar.Part placement="left">
                      <Button variant="secondary" onClick={toggleOpenDeleteModal}>
                        {intl.formatMessage({id: ETranslations.BASE_DELETE})}
                      </Button>
                    </BottomBar.Part>
                    <BottomBar.Part placement="right">
                      <Button variant="primary" onClick={onToggleEdit}>
                        <ICONS.Edit />
                      </Button>
                    </BottomBar.Part>
                  </>
                )}
              </BottomBar>
              {openDeleteModal && (
                <ModalPopup
                  isOpen={openDeleteModal}
                  onClose={toggleOpenDeleteModal}
                  buttons={
                    <>
                      <Button variant="dangerous" type="submit" onClick={handleDeleteAR}>
                        {intl.formatMessage({id: ETranslations.BASE_DELETE})}
                      </Button>
                      <Button onClick={toggleOpenDeleteModal} variant="secondary">
                        {intl.formatMessage({id: ETranslations.CANCEL})}
                      </Button>
                    </>
                  }
                  title={intl.formatMessage({id: ETranslations.DELETE_AR_GROUP_QUESTION})}
                  text={intl.formatMessage({id: ETranslations.DELETE_WARNING_TEXTS})}
                />
              )}

              {
                openEditModal && <ModalPopup
                  needIcon={false}
                  isOpen={openEditModal}
                  onClose={toggleOpensSave}
                  buttons={
                    <>
                      <Button variant="primary" type="submit" onClick={handleSubmitForm}>
                        {intl.formatMessage({id: ETranslations.BASE_SAVE})}
                      </Button>
                      <Button onClick={toggleOpensSave} variant="secondary">
                        {intl.formatMessage({id: ETranslations.CANCEL})}
                      </Button>
                    </>
                  }
                  title={intl.formatMessage({id: ETranslations.ASK_SAVE_CHANGES})}
                  text={intl.formatMessage({id: ETranslations.IMMEDIATELY_CHANGES})}
                />
              }
            </form>


            {!!responseError && <ModalPopup
              editBooking
              isOpen={!!responseError}
              onClose={(handleRemoveResponseError)}
              buttons={
                <>
                  <Button variant="dangerous" type="submit" onClick={handleRemoveResponseError}>
                    {intl.formatMessage({id: ETranslations.BASE_CLOSE})}
                  </Button>
                </>
              }
              title={intl.formatMessage({id: ETranslations.BASE_ERROR})}
              text={responseError}
            />}
          </Card>
        );
      }}

    </Form>
  );
};

export default AccessRuleForm;
