import React from 'react';

import { isPageCreateBook } from 'common/helpers';
import ChangesParamsModal from 'components/modals/changes-params/changes-params';
import ChangeHostessModal from 'components/modals/change-hostess/change-hostess';
import { ChangesParams } from 'models/common';
import ConfirmDialogModal from 'components/modals/confirm-dialog/confirm-dialog';
import ChangeUserModal from 'components/modals/change-user/change-user';
import {
  SettingsHostess,
  SettingsUser,
  SettingsStatus,
} from 'models/settings.model';
import { TypeModal } from 'types/rootState';
import { useSelector } from 'react-redux';
import { appContextSelectors } from 'features/AppContex';
import { useDeleteUserMutation } from 'features/api/user-api';
import { useIntlUtils } from '../../hooks/useIntlUtils';
import { EditStatus } from 'components/modals/edit-status';

interface ModalProps {
  active?: boolean;
  closeModals: (isForce?: boolean) => void;
  activeTypeModal?: TypeModal;
  settingsParamsChanges?: ChangesParams[];
  settingsHostessModal?: SettingsHostess;
  settingsUserModal?: SettingsUser;
  settingsEditStatusModal?: SettingsStatus;
  confirmDialog?: { data: any; mode: string };
}

export default function LegacyModal(props: ModalProps) {
  const intlUtils = useIntlUtils();
  const {
    active,
    closeModals,
    activeTypeModal,
    settingsParamsChanges,
    settingsHostessModal,
    confirmDialog,
    settingsUserModal,
    settingsEditStatusModal,
  } = props;
  const [deleteUser] = useDeleteUserMutation();

  const handleClick = () => {
    if (!confirmDialog?.data) return;
    deleteUser(confirmDialog.data)
      .unwrap()
      .then(() => closeModals(true));
  };

  const restaurant = useSelector(appContextSelectors.restaurant);
  return (
    <div
      id="modal"
      onMouseDown={() => closeModals()}
      className={`${active ? 'active' : ''}
                ${isPageCreateBook() ? 'create' : ''}
                ${activeTypeModal || ''}`}
    >
      <div>
        {activeTypeModal === TypeModal.changesParams
          && settingsParamsChanges && (
            <ChangesParamsModal
              closeModal={() => closeModals()}
              changesParams={settingsParamsChanges}
            />
          )}

        {activeTypeModal === TypeModal.changeHostess && (
          <ChangeHostessModal
            intlUtils={intlUtils}
            closeModal={(isForceUpdate?: boolean) => closeModals(isForceUpdate)}
            settingsHostess={{
              ...settingsHostessModal,
              restId: settingsHostessModal?.restId || restaurant.restaurant_id,
            }}
          />
        )}

        {activeTypeModal === TypeModal.changeUser && settingsUserModal && (
          <ChangeUserModal
            intlUtils={intlUtils}
            closeModal={(isForceUpdate?: boolean) => closeModals(isForceUpdate)}
            settingsUser={settingsUserModal}
          />
        )}

        {activeTypeModal === TypeModal.confirm && confirmDialog && (
          <ConfirmDialogModal
            handleClick={() => handleClick()}
            closeModal={() => closeModals()}
            data={confirmDialog.data}
            mode={confirmDialog.mode}
          />
        )}

        {activeTypeModal === TypeModal.status && settingsEditStatusModal && (
          <EditStatus
            intlUtils={intlUtils}
            closeModal={(isForceUpdate?: boolean) => closeModals(isForceUpdate)}
            statuses={settingsEditStatusModal.allStatuses}
            statusId={settingsEditStatusModal.statusId}
            editStatus={settingsEditStatusModal.editStatus}
            isLoading={settingsEditStatusModal.isEditing}
          />
        )}
      </div>
    </div>
  );
}
