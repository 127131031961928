import {useCallback, useState} from 'react';

import {ICONS} from 'common/helpers';
import Checkbox from 'common/components/checkbox';
import {Modal} from 'components/modal';
import cn from 'classnames';

import styles from "./PlacementReport.module.scss";
import {useSelector} from "react-redux";
import {appContextSelectors} from "features/AppContex";
import {useWorkingTimeOptions} from "hooks/useWorkingTimeOptions";
import {Field, Form} from "react-final-form";
import {usePlacementReportMutation} from "features/api/reports";
import moment from "moment";
import {defaultTimeInterval, exportFormat, initial, timeIntervals, validate} from "./utils";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {DatePicker, SelectBasic} from "ui-kit";
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {ETranslations} from "../../../types/translates";
import {capitalizeString} from "../../../utils";

export interface ReportPlacementProps {
  closeModal: () => void;
  isOpen: boolean;
}


export function ReportPlacement({isOpen, closeModal}: ReportPlacementProps) {
  const {intl, getIntlDateOf, getIntlSelectEntity} = useIntlUtils();
  const [interval, setInterval] = useState(defaultTimeInterval);
  const timeOptions = useWorkingTimeOptions(isOpen ? interval.value : skipToken);
  const [download] = usePlacementReportMutation();
  const {restaurant_id} = useSelector(appContextSelectors.restaurant);

  const onSubmit = useCallback(async ({startDate, endDate, startTime, endTime, useVisitTime, isConfirmed, format}) => {
    await download({
      restaurant_id,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
      start_time: startTime?.label || '',
      end_time: endTime?.label || '',
      use_visit_time: useVisitTime,
      is_confirmed: isConfirmed,
      format: format.label,
      interval: interval.value,
    }).unwrap();
  },
   [restaurant_id, interval]);

  return (
    <Form onSubmit={onSubmit} initialValues={initial} validate={validate}>
      {({handleSubmit, submitting, invalid}) => (
      <Modal
        title={intl.formatMessage({id: ETranslations.SEATING_REPORT})}
        onClose={closeModal}
        isOpen={isOpen}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Content className={styles.placement}>
            <div className={cn(styles['date-time'], styles['titled-block'])}>
              <h3 className={cn(styles.title, styles.required)}>{getIntlSelectEntity(ETranslations.PLURAL_PERIOD)}</h3>
              <div>
                <Field name="startDate">
                  {({input}) => (
                    <DatePicker
                      selected={input.value}
                      placeholderText={getIntlDateOf(ETranslations.OF_START)}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
                <div className={styles.separ}>—</div>
                <Field name="endDate">
                  {({input}) => (
                    <DatePicker
                      selected={input.value}
                      placeholderText={getIntlDateOf(ETranslations.OF_END)}
                      onChange={input.onChange}
                    />
                  )}

                </Field>
              </div>
            </div>
            <div className={cn(styles['date-time'], styles['titled-block'])}>
              <h3 className={cn(styles.title, styles.required)}>{getIntlSelectEntity(ETranslations.TIME_SLOTS)}</h3>
              <div>
                <SelectBasic
                  value={interval}
                  changedMaxSize
                  options={timeIntervals}
                  // @ts-ignore
                  onChange={setInterval}
                  placeholder={intl.formatMessage({id: ETranslations.TIME_SLOT})}
                />
              </div>
            </div>
            <div className={styles['titled-block']}>
              <h3 className={cn(styles.title, styles.required)}>
                {capitalizeString(intl.formatMessage({id: ETranslations.FROM}))}{" "}
                -{" "}
                {capitalizeString(intl.formatMessage({id: ETranslations.TO}))}
              </h3>
              <div>
              <Field name="startTime">
                {({input}) => (
                  <SelectBasic
                    value={input.value}
                    changedMaxSize
                    options={timeOptions}
                    onChange={input.onChange}
                    placeholder={intl.formatMessage({id: ETranslations.FROM})}
                    maxMenuHeight={200}
                  />
                )}
              </Field>
              <div className={styles.separ}>—</div>
              <Field name="endTime">
                {({input}) => (
                  <SelectBasic
                    value={input.value}
                    changedMaxSize
                    options={timeOptions}
                    onChange={input.onChange}
                    placeholder={intl.formatMessage({id: ETranslations.TO})}
                    maxMenuHeight={200}
                  />
                )}
              </Field>
              </div>
            </div>
            <div className={styles['titled-block']}>
              <h3 className={styles.title}>{intl.formatMessage({id: ETranslations.OPTIONS})}</h3>
                <div  style={{gap: 10}}>
                  <Field name="isConfirmed" type="checkbox">
                    {({input}) => (
                      <Checkbox
                        indent="confirmed"
                        text={intl.formatMessage({id: ETranslations.ONLY_CONFIRMED})}
                        onChange={input.onChange}
                        value={input.checked}
                        className={styles.customCheckbox}
                        useStopPropagation
                      />
                      )}
                  </Field>

                    <Field name="useVisitTime" type="checkbox">
                    {({input}) => (
                      <Checkbox
                        indent="visit-time"
                        text={intl.formatMessage({id: ETranslations.STAY_TIME_EXCLUDE})}
                        onChange={input.onChange}
                        value={input.checked}
                        className={styles.customCheckbox}
                        useStopPropagation
                      />
                      )}
                    </Field>

                </div>
            </div>
            <div className={styles['titled-block']}>
              <h3 className={cn(styles.title, styles.required)}>{intl.formatMessage({id: ETranslations.FORMAT})}</h3>
              <div>
                <Field name="format">
                  {({input}) => (
                    <SelectBasic
                      value={input.value}
                      changedMaxSize
                      options={exportFormat}
                      onChange={input.onChange}
                      placeholder={intl.formatMessage({id: ETranslations.FORMAT})}
                    />
                  )}
                </Field>
              </div>
            </div>
          </Modal.Content>
          <Modal.Footer>
            <button
              type="submit"
              className="primary"
              disabled={invalid || submitting}
            >
              {intl.formatMessage({id: ETranslations.UPLOAD})}
              {submitting && <img src={ICONS.loadingSmall} alt="" />}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      )}
    </Form>
  );
}
