import React from "react";
import {appContextSelectors} from "features/AppContex";
import {useSelector} from "react-redux";
import Checkbox from "common/components/checkbox";
import {FieldRenderProps} from "react-final-form";
import {placesChangeHandler} from "components/shifts/Form/EditShiftForm/utils";

const SchemaField: React.FC<FieldRenderProps<string, any>> = ({input}) => {
    const restaurant = useSelector(appContextSelectors.restaurant);
    const value: number[] = input.value as unknown as []
    
    return (
        <>
            {restaurant.places.map((place) => {
                return (
                    <Checkbox
                        indent="confirmed"
                        text={place.name as string}
                        // eslint-disable-next-line @typescript-eslint/no-shadow
                        onChange={(value) => placesChangeHandler(value, input.value, place.id, input.onChange)}
                        value={value.includes(place.id)}
                        useStopPropagation
                        key={place.id}
                    />
                );
            })}
        </>
    );
};

export default SchemaField;
