import React, { useState } from 'react';
import { Button, RadioButton, Spinner } from 'ui-kit';
import '../settings.scss';
import { useIntl } from 'react-intl';
import { ETranslations } from 'types/translates';
import EachTagGroup from 'components/EachTagGroup/EachTagGroup';
import { CreateGroupTagsModal } from 'components/EachTagGroup/Modals/CreateGroupTagsModal';
import { useGetNewTagsQuery } from 'features/api/tags-api';
import { useSelector } from 'react-redux';
import { appContextSelectors } from 'features/AppContex';
import { userIsAdminRest, userIsHostess } from 'common/helpers';
import type { TagGroup } from 'types/tag';

export default function TagsPage() {
  const [selectedTag, setSelectedTag]
    = useState<TagGroup['destination']>('BOOKING');
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const restaurant = useSelector(appContextSelectors.restaurant);
  const { data, isLoading } = useGetNewTagsQuery({
    destination: selectedTag,
    resturant_id: `${restaurant.restaurant_id}`,
  });
  const intl = useIntl();
  const onTogleOpenCreateModal = () =>
    setIsOpenCreateModal((prevState) => !prevState);
  const isHostess = userIsHostess();
  const isAdminOfRest = userIsAdminRest();

  return isLoading ? (
    <Spinner />
  ) : (
    <div className="access_rules sections include-table">
      <div className="tags_button">
        <RadioButton value={selectedTag} onChange={setSelectedTag}>
          <RadioButton.Button value="BOOKING">
            {intl.formatMessage({ id: ETranslations.PLURAL_BOOKING })}
          </RadioButton.Button>
          <RadioButton.Button value="CLIENT">
            {intl.formatMessage({ id: ETranslations.PLURAL_GUEST })}
          </RadioButton.Button>
        </RadioButton>
        <div>
          {!isHostess && (
            <Button variant="primary" onClick={onTogleOpenCreateModal}>
              {intl.formatMessage({ id: ETranslations.CREATE_GROUP })}
            </Button>
          )}
        </div>
      </div>
      <div className="access_rules_box">
        {data?.map((eachGroup: TagGroup) => (
          <EachTagGroup
            isAdminOfRest={isAdminOfRest}
            isHostess={isHostess}
            selectedTag={selectedTag}
            key={eachGroup.id}
            eachGroup={eachGroup}
          />
        ))}
      </div>
      {isOpenCreateModal && (
        <CreateGroupTagsModal
          isAdminOfRest={isAdminOfRest}
          selectedTag={selectedTag}
          title={intl.formatMessage({ id: ETranslations.GROUP_CREATING })}
          isOpen={isOpenCreateModal}
          onClose={onTogleOpenCreateModal}
        />
      )}
    </div>
  );
}
