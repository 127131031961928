import {Card} from 'ui-kit';
import useHallContainer from './hooks/useHallContainer';
import {HallSchema} from './HallSchemaV2/hall-schema';
import {HallMode} from '../../../features/HallSchema';
import {SelectGuestFromList} from '../../../features/GuestsList/components/select-guest-from-list';
import {ETranslations} from "../../../types/translates";

interface PopUpByModeProps {
  hallMode: HallMode;
}

export const TabletHallPopup = ({hallMode}: PopUpByModeProps) => {

  const {guestCloseHandler,
    selectGuestHandler,
    intl,} = useHallContainer(hallMode)

  switch (hallMode) {
    case HallMode.REGISTRATION_GUESTS:
    case HallMode.BOOKING_GUEST:
    case HallMode.TABLE_BOOKINGS_EDIT_GUEST:
      return (
        <SelectGuestFromList
          onClose={guestCloseHandler}
          onSelectGuest={selectGuestHandler}
        />
      );
    case HallMode.BOOKING_HALL:
    case HallMode.REGISTRATION_HALL:
    case HallMode.EDIT_HALL:
    case HallMode.MANAGARAL_HALL:
      return (
        <Card onClose={guestCloseHandler}>
          <Card.Header
            title={intl.formatMessage({id: ETranslations.HALL_SCHEME})}
          />
          <Card.Content noPadding>
            <HallSchema />
          </Card.Content>
        </Card>
      );
    default: {
      return null
    }
  }
};
