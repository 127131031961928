import React from "react";
import cn from "classnames";
import {FieldRenderProps} from "react-final-form";
import {Input} from "ui-kit";
import Checkbox from "common/components/checkbox";
import {Deposit} from "../../../features/BookingFormProxy/types";
import {ETranslations} from "../../../types/translates";
import useChoiceDeposit from "./hooks/useChoiceDeposit";
import styles from "./Deposit.module.scss";


const ChoiceDeposit = ({value, onChange, disabled, isNewDesign}: any) => {
  const {
    deposit_made,
    deposit_amount,
    checkboxText,
    handleUseDeposit,
    handleAmountChange,
    handleDepositMade,
    widthControlRef,
    use_deposit,
    intl,
  } = useChoiceDeposit(value, onChange, isNewDesign);

  return (
    <div className={cn("control-group", {[styles.newDeposit]: isNewDesign})} ref={widthControlRef}>
      <div className="left">
        <button
          className={`${use_deposit ? "active" : ""}`}
          disabled={disabled}
          onClick={() => handleUseDeposit(!use_deposit)}
          type="button"
        >
          {use_deposit ? intl.formatMessage({id: ETranslations.WITH_DEPOSIT}) : intl.formatMessage({id: ETranslations.NO_DEPOSIT})}
        </button>
      </div>
      <div className={styles.deposit}>
        <Input
          size={1}
          pattern="[0-9*]"
          maxLength={6}
          value={deposit_amount}
          onChange={handleAmountChange}
          type="text"
          placeholder={intl.formatMessage({id: ETranslations.BASE_SUM})}
          disabled={disabled || !use_deposit}
          className={cn(isNewDesign ? styles.inputNew : styles.input)}
          suffix={<span className="suffix">₽</span>}
        />
        <Checkbox
          indent="deposit"
          text={checkboxText}
          onChange={handleDepositMade}
          value={deposit_made}
          disabled={disabled || !use_deposit}
        />
      </div>
    </div>
  );
};

export const ChoiceDepositInput: React.FC<FieldRenderProps<Deposit, any>> = ({input, meta, ...rest}) => (
  <ChoiceDeposit {...input} {...meta} {...rest} />
);
