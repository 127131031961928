import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useDispatchActions} from '../../hooks/useDispatchActions';
import {Place, Restaurant} from '../../models/restaurant.model';
import {Source, Tag} from '../../models/booking.model';
import moment, {Moment} from 'moment';
import {restaurantsApi} from 'features/api/restaurants';
import {Booking, BookingOrder} from '../../types/booking';

export interface State {
  restaurants: Restaurant[],
  tags: Tag[],
  sources: Source[],
  date: string,
  place: number | null;
  restaurant: Restaurant;
  // TODO тут по идее нужно подумать как хранить места только в одном атрибуте
  selectedPlaces: number[];
}

export const initialState: State = {
  restaurants: [],
  tags: [],
  sources: [],
  date: moment().startOf('day').toISOString(),
  place: null,
  restaurant: null!,
  selectedPlaces: [],
};

const slice = createSlice({
  name: 'applicationContext',
  initialState,
  reducers: {
    setUpRestaurants(state, action: PayloadAction<Pick<State, 'restaurants'>>) {
      state.restaurants = action.payload.restaurants;
    },
    setUpTags(state, action: PayloadAction<Pick<State, 'tags'>>) {
      state.tags = action.payload.tags;
    },
    setUpSources(state, action: PayloadAction<Pick<State, 'sources'>>) {
      state.sources = action.payload.sources;
    },
    setDate: {
      prepare(date: Date | Moment | null) {
        const momentDate = date ? moment(date).startOf('day') : moment().startOf('day');
        return {payload: momentDate};
      },
      reducer(state, {payload}: PayloadAction<Moment>) {
        const prevDate = moment(state.date);
        state.date = payload.clone().set({hour: prevDate.hour(), minute: prevDate.minute()}).toISOString();
      },
    },
    setPlace(state, {payload}: PayloadAction<number | null>) {
      state.place = payload;
    },
    setPlaceFromBooking(state, {payload}: PayloadAction<Booking>){
      const [firstPlace] = payload.places;
      state.place = firstPlace?.placeId
    },
    setPlaceFromOrder(state, {payload}: PayloadAction<BookingOrder>){
      const {placeId} = payload.places;
      state.place = placeId
    },
    setSelectedPlaces(state, {payload}: PayloadAction<number[]>) {
      state.selectedPlaces = payload;
    },
    setSelectedPlace(state, {payload}: PayloadAction<Place>) {
      state.selectedPlaces = [payload.id];
    },
    setRestaurant(state, {payload}: PayloadAction<Restaurant>) {
      const places = payload.places;
      state.restaurant = {...payload, places};
      state.selectedPlaces = payload.places.map((p) => p.id);
      [state.place] = state.selectedPlaces;
    },
  },
  extraReducers(build) {
    build.addMatcher(restaurantsApi.endpoints.fetchAllRestaurants.matchFulfilled, (state, {payload}) => {
      const restId = localStorage.getItem('restaurantId')
      let restaurant = payload.find((rest) => rest.restaurant_id === Number(restId))
      if(!restaurant) restaurant = payload[0]
      state.restaurants = payload;
      state.selectedPlaces = [...restaurant.places].map((p) => p.id);
      [state.place] = state.selectedPlaces;
      state.restaurant = restaurant;
    });
  },
});

export const {name: applicationContextSliceName, reducer: applicationContextReducer} = slice;
export const useApplicationContextActions = () => useDispatchActions(slice.actions);
