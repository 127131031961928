import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useIsTablet} from "hooks/useIsTablet";
import {fromProxySelectors, useFromProxyActions} from "features/BookingFormProxy";
import {useSelector} from "react-redux";
import styles from "./Dashboard.module.scss";
import {hallModeSelector} from "features/HallSchema/selectors";
import {HallMode, useHallSchemaActions} from "features/HallSchema";
import {Client} from "types/client";
import {useUnmount, useUpdateEffect} from "react-use";
import {appContextSelectors} from "features/AppContex";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {isManagerialTable} from "utils";
import {timelineSelectors, useTimelineActions} from "features/Timeline";
import {tableBookingsTableSelector} from "features/TableBooking/selectors";
import {useTableBookingListActions} from "features/TableBooking/slice";

export enum Tab {
    Edit = "Edit",
    History = "History",
}

const useDashBoards = () => {
    const {getIntlEntityViewingAction, intl, getIntlHistoryOf} = useIntlUtils();

    const emptyMessage = useMemo(
        () => (
            <div className={styles.emptyContainer}>
                <div className={styles.icon} />
                <p>{getIntlEntityViewingAction(ETranslations.OF_BOOKING, ETranslations.PLURAL_ALT_BOOKING)}</p>
            </div>
        ),
        []
    );

    const isTablet = useIsTablet();
    const {setOnlyBooking: setFormBooking, setClient, reset} = useFromProxyActions();
    const {switchMode, reset: resetBooking} = useHallSchemaActions();
    const {resetTimeShift} = useTimelineActions();
    const timestamp = useSelector(timelineSelectors.getTimestamp);
    const booking = useSelector(fromProxySelectors.booking);
    const rest = useSelector(appContextSelectors.restaurant);
    const hasBooking = useMemo(() => !!booking, [booking]);
    const mode = useSelector(hallModeSelector);
    const [tab, setTab] = useState<string>(Tab.Edit);
    const table = useSelector(tableBookingsTableSelector);
    const {reset: resetTable} = useTableBookingListActions();

    useEffect(() => {
        switchMode(HallMode.TABLES)
        if (table) {
            resetTable();
        }
    }, [])

    const close = useCallback(() => {
        resetTimeShift()
        setFormBooking(null)
    }, [setFormBooking]);

    // условия проверки откытости формы создания брони
    const isCreatingBooking = useMemo(
        () =>
            [
                HallMode.MANAGERAL_CREATE_BOOKING,
                HallMode.MANAGERAL_OPEN_GUESTS,
                HallMode.MANAGARAL_HALL,
                HallMode.MANAGARAL_HALL_BOOKING,
            ].includes(mode),
        [mode]
    );

    // изменения мода при закрытии схемы зала
    const closeHall = useCallback(() => {
        if (mode === HallMode.MANAGARAL_HALL_BOOKING) {
            switchMode(HallMode.MANAGERAL_CREATE_BOOKING)
        }
        else {
            switchMode(HallMode.TABLES)
        }
    }, [switchMode, mode]);

    // изменения мода при закрытии списка гостей
    const closeGuests = useCallback(() => {
        if ([HallMode.MANAGERAL_OPEN_GUESTS, HallMode.MANAGERAL_CREATE_BOOKING].includes(mode)) {
            switchMode(HallMode.MANAGERAL_CREATE_BOOKING);
            return;
        } else {
            switchMode(HallMode.TABLE_BOOKINGS_EDIT);
        }
    }, [switchMode, mode]);

    const handleSetClient = useCallback(
        (client: Client) => {
            setClient({client});
            closeGuests();
        },
        [setClient, closeGuests]
    );

    useUnmount(() => {
        switchMode(HallMode.TABLES)
        resetTimeShift()
    });

    const isManagarelTableBooking = useMemo(() => {
        return isManagerialTable(booking as any);
    }, [booking]);

    const isGuestMode = mode.includes("GUEST");

    useUpdateEffect(() => {
        reset();
    }, [rest]);

    return {
        hasBooking,
        isManagarelTableBooking,
        booking,
        emptyMessage,
        timestamp,
        isTablet,
        isCreatingBooking,
        mode,
        resetBooking,
        closeHall,
        intl,
        closeGuests,
        handleSetClient,
        isGuestMode,
        tab,
        getIntlHistoryOf,
        setTab,
        close
    }
}

export default useDashBoards