// import './common/test_helpers';
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {render} from "react-dom";
import {Redirect, Route, Switch} from "react-router";
import {HashRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "styles/normalize.scss";

import Root from "./components/root/root";
import "./legacy.scss";
import "./index.scss";
import Auth from "./components/auth/auth";
import {AuthService} from "./services/auth.service";
import {config} from "config";
import {Helmet} from "react-helmet";
import {Sentry} from "common/sentry";
import {Provider} from "react-redux";
import {store} from "./storage";
import {useServiceWorker} from "hooks/useServiceWorker";
import {Notification} from "services/notification";
import {useTranslates} from "./features/api/dictionaries-api";
import {useLocalStorage} from "react-use";
import {ELocales} from "./types/commons";
import {ETranslations} from "./types/translates";

const MainView: React.FC = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(AuthService.isAuthenticated());

  useEffect(() => {
    const authStatusChange = (newAuthenticated: boolean) => {
      setAuthenticated(newAuthenticated);
    };

    if (global?.channels?.auth) {
      (global.channels.auth as any).cb = authStatusChange;
    }
  }, []);

  if (authenticated) {
    return <Root />;
  } else {
    return <Redirect to="/login" />;
  }
};

function App() {
  const {reloadPage, showReload, waitingWorker} = useServiceWorker();
  const [userLocale] = useLocalStorage<ELocales>('locale', config.defaultLocale)
  const {translates} = useTranslates(userLocale)

  useEffect(() => {
    if (showReload && waitingWorker && !_.isEmpty(translates)) {
      Notification.warning({
        options: {
          autoClose: false,
          closeOnClick: false,
          draggable: true,
        },
        title: ETranslations.APP_VERSION_UPDATED,
        message: (<div>
          <button className="primary" onClick={reloadPage} type="button">{translates[ETranslations.BASE_REFRESH]}</button>
        </div>),
      });
    }
  }, [waitingWorker, showReload, reloadPage, translates]);

  return (
    <>
      <Helmet>
        <title>{config.brand_title}</title>
      </Helmet>
      <HashRouter>
        <Switch>
          <Route exact path="/login" component={Auth} />
          <Route path="/" component={MainView} />
        </Switch>
      </HashRouter>
      <ToastContainer
        autoClose={3e3}
        position="bottom-right"
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

render(
  <Provider store={store}>
    <Sentry.ErrorBoundary>
      <App />
    </Sentry.ErrorBoundary>
  </Provider>,
  document.getElementById("root"),
);
