import {memo, useCallback, useState} from 'react';
import {useThrottle, useThrottleFn, useUpdateEffect} from 'react-use';
import cn from 'classnames';

import {ICONS, Spinner} from '..';
import styles from './SearchInput.module.scss';
import {Input, InputProps} from './Input';
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";

interface SearchInputProps extends Omit<InputProps, 'suffix' | 'prefix'> {
  onSearch(value: string): void;
  className?: string;
  isLoading?: boolean;
  placeholder?: string;
  value?: string;
}

export const SearchInput = memo(({onSearch, className, value = '', isLoading, placeholder, ...props}: SearchInputProps) => {
  const intl = useIntl();
  const [inputValue, setValue] = useState(value);
  const handleChange = useCallback((e) => setValue(e.target.value), [setValue]);
  const delayedLoading = useThrottle(isLoading, 1e3);

  useThrottleFn(onSearch, 1e3, [inputValue]);

  useUpdateEffect(() => {
    setValue(value ?? '');
  }, [value]);

  return (
    <Input
      {...props}
      prefix={<img src={ICONS.search} alt="" className={styles.icon} />}
      className={cn(styles.search, className)}
      value={inputValue} onChange={handleChange}
      placeholder={placeholder || intl.formatMessage({id: ETranslations.BASE_SEARCH})}
      suffix={(isLoading || delayedLoading) && <Spinner className={styles.spinner} />}
     />
  );
});
