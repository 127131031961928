import {useCurrentHallShiftsSchedule} from 'features/api/hallschema-api';
import {useMemo} from 'react';
import dayjs from 'dayjs';

export type TOptionsConfig = {
  value: number,
  isShow: boolean,
  hasSeparator: boolean,
  shiftName: string,
  isShowShiftName: boolean
}

export function useOptions(){
  const {data} = useCurrentHallShiftsSchedule();

  const optionsConfig = useMemo(() => {
    if(!data) return  []

    const times  = data.reduce<TOptionsConfig[]>((acc, val, idx, array) => {
      const isFirstShift = idx == 0;
      const isLastShift = idx === (array.length - 1);
      const isBlackOut = val.zone === 'BLACKOUT';

      // У центрального элемента будет показываться название шифта
      const middleItem = Math.floor(val.values.length / 2);
      const prevZoneIsBook = idx > 0 && array[idx - 1]?.zone === "BOOK"

      // Достаем первое и последние значение и проверяем являются ли они не полным часом
      const firstTimeInShift = val.values[0];
      const lastTimeInShift = val.values[val.values.length - 1];
      const firstTimeIsNotWhole = firstTimeInShift && dayjs(firstTimeInShift).format('mm') !== '00'
      const lastTimeIsNotWhole = lastTimeInShift && dayjs(lastTimeInShift).format('mm') !== '00'

      val.values.forEach((timestamp, i, arr) => {
        const isStartTime = i === 0;
        const isEndTime = i === arr.length - 1
        const isFirstOrLastElem = isStartTime || isEndTime;
        const hasSeparator = (!isFirstShift && isStartTime) || (!isLastShift && isEndTime);

        // Проверка нужно ли скрывать значения с полным часом если шифт начинается или заканчивает не полным часом
        // К примеру если шифт начинается с 09:45 то значение лейбл с 10:00 надо скрыть
        // Если шифт заканчивается в 23:45 то значение 23:00 надо скрыть
        const hidePreviousThreeTimes = lastTimeIsNotWhole && ((i + 3) >= (arr.length - 1)) && !isEndTime
        const hideNextThreeTimes = firstTimeIsNotWhole && ((i - 3) <= 0) && !isStartTime

        if(isBlackOut) {
          (i < 3 || i > (arr.length - 3)) && acc.push({
            value: timestamp,
            isShow: false,
            hasSeparator : false,
            shiftName: val.shift_name,
            isShowShiftName: false
          });
        } else {
          const isWholeHour = dayjs(timestamp).format('mm') === '00';
          const isShowShiftName = i === middleItem;
          acc.push({
            value: timestamp,
            isShow:
              // Если это первый или последний элемент, то показываем значение.
              // Либо если это целый час (Ниже исключения когда целый час не показывается)
              (isWholeHour || isFirstOrLastElem)
              && !hidePreviousThreeTimes
              && !hideNextThreeTimes
              && !(prevZoneIsBook && isStartTime),
            hasSeparator,
            isShowShiftName,
            shiftName: val.shift_name
          });
        }
      })
      return acc
    },[])

    return times
  }, [data]);

  return optionsConfig
}
