import type { SpecialStatusColors } from 'constants/colors';
import moment from 'moment';
import type { Booking, BookingOrder, ManagerialTable } from 'types/booking';
import type { Status } from 'types/status';
import type { BookingTable } from 'types/table';
import { getBookingStartTime } from 'utils';

const getActualStatusColor = (
  booking: Booking,
  statusColors: Record<Status['system_name'], string> &
    typeof SpecialStatusColors
) => {
  const minutesPassedFromBookingStart = Math.max(
    moment().startOf('minute').diff(getBookingStartTime(booking), 'minute'),
    0
  );
  return minutesPassedFromBookingStart > 0
    && booking.status?.category === 'PRE_SERVICE'
    ? statusColors.LATE
    : minutesPassedFromBookingStart - booking.visitTime > 0
    ? statusColors.OVERSTAYED
    : statusColors[booking.status.system_name] || booking.status.color;
};

const getTimeLineStatusColor = (
  booking: Booking,
  statusColors: Record<Status['system_name'], string> &
    typeof SpecialStatusColors,
  tableColor: string
) => {
  switch (tableColor) {
    case 'red':
      return statusColors.OVERSTAYED;
    case 'yellow':
      return statusColors.LATE;
    case 'light_grey':
      return booking.status.system_name === 'CONFIRMED'
        ? statusColors.CONFIRMED
        : statusColors.NEW;
    case 'green':
      return statusColors.IN_HALL;
    default:
      return statusColors[booking.status.system_name] || booking.status.color;
  }
};

export const getStatusColor = (
  booking: Booking,
  statusColors: Record<Status['system_name'], string> &
    typeof SpecialStatusColors,
  isActualTime: boolean,
  tableColor: string
) =>
  isActualTime
    ? getActualStatusColor(booking, statusColors)
    : getTimeLineStatusColor(booking, statusColors, tableColor);

export const isCanDrag = (
  booking: Booking | undefined,
  tableId: BookingTable['tableId']
): boolean =>
  Boolean(
    booking?.bookingId && booking.places.some((place) => place.id === tableId)
  );
