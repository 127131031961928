import {Modal} from "components/modal";
import {useIntlUtils} from "hooks/useIntlUtils"
import {ETranslations} from "types/translates";
import {Button} from 'ui-kit';

import styles from './ConfirmBusyTable.module.scss';

interface ConfirmBusyTableProps {
  confirm: () => void;
  decline: () => void;
  isOpen?: boolean;
}

export function ConfirmBusyTable({confirm, decline, isOpen}: ConfirmBusyTableProps) {
  const {intl} = useIntlUtils();
  return (
    <Modal title={intl.formatMessage({id: ETranslations.TABLE_ALREADY_BUSY})} onClose={decline} isOpen={!!isOpen}>
      <Modal.Content className={styles.body}>
        {intl.formatMessage({id: ETranslations.TABLE_ALREADY_BUSY_HINT})}
      </Modal.Content>
      <Modal.Footer className={styles.footer}>
        <Button variant="primary" onClick={confirm} type="button">
            {intl.formatMessage({id: ETranslations.LOCATE})}
        </Button>
        <Button variant="secondary" onClick={decline} type="button">
            {intl.formatMessage({id: ETranslations.BASE_CANCEL})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
