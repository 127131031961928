import React from "react";
import {Form} from "react-final-form";
import {Card, Spinner} from "ui-kit";
import {ManagerialTable} from "types/booking";
import {ManagerialTablesForm} from "./ManagerialTablesForm";
import {BookingCard} from "components/BookingCard";
import ManageralModalError from "./ManageralModalError";
import useEditManageralBooking from "./hooks/useEditManageralBooking";
import stlyes from './styles.module.scss'

interface EditManageralBookingProps {
  booking: ManagerialTable;
}

export const EditManageralBooking = ({booking}: EditManageralBookingProps) => {

  const {isLoading,
    closeEdit,
    data,
    closeErrorModal,
    value,
    onSubmit,
    manageralError} = useEditManageralBooking(booking);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Card onClose={closeEdit}>
      <div className={stlyes.content}>
        <BookingCard booking={data?.data} compact />
      </div>

      {value?.start_date ? (
        <Form
          onSubmit={onSubmit}
          initialValues={value}
          render={() => {
            return <ManagerialTablesForm editBooking />;
          }}
        />
      ) : null}
      {!!manageralError && <ManageralModalError text={manageralError} isOpen={!!manageralError} onClose={closeErrorModal} />}

    </Card>
  );
};
