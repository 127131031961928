import * as yup from 'yup';
import {makeValidate} from 'utils';
import {useIntl} from "react-intl";
import {useMemo} from "react";
import {ETranslations} from "../../types/translates";

export interface FormData {
  old_password?: string;
  new_password?: string;
  password_confirm?: string;
}

export const useLocalizedScheme = () => {
  const intl = useIntl();

  const intlTitles = useMemo(() => ({
    required: intl.formatMessage({id: ETranslations.ERROR_REQUIRED_FIELD}),
    passwordsEqual: intl.formatMessage({id: ETranslations.ERROR_PASSWORDS_SHOULD_BE_EQUAL}),
  }), [])

  const schema: yup.SchemaOf<FormData> = yup.object({
    old_password: yup.string().required(intlTitles.required),
    new_password: yup.string().required(intlTitles.required),
    password_confirm: yup.string().required(intlTitles.required),
  }).test(
    'password_confirm',
    intlTitles.passwordsEqual, function validatePassword({new_password, password_confirm}) {
      if (new_password !== password_confirm) {
        return this.createError({path: 'password_confirm'});
      }
      return true;
    },
  );

  return {validate: makeValidate(schema)}
}
