import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import {AuthService} from '../services/auth.service';
import {User, UserExtended} from '../models/user.model';
import {UsersService} from '../services/users.service';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';

interface AppUsersContext {
  authUser: User | undefined;
  userList: UserExtended[];
  currentUser: UserExtended | undefined;
}

const AppUsers = createContext<AppUsersContext>({
  authUser: undefined,
  userList: [],
  currentUser: undefined,
});

export function AppUsersProvider(props: PropsWithChildren<{}>) {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const [authUser, setAuthUser] = useState<User | undefined>(undefined);
  const [userList, setUserList] = useState<UserExtended[]>([]);
  const [currentUser, setCurrentUser] = useState<UserExtended | undefined>(undefined);
  const authUserId = AuthService.getUser()?.id;
  const mapToExtendedUser = (user: User) => new UserExtended(
    user.id, user.name, user.id, user.name, user.photo, user.phone,
  );
  const mapToList = (users: User[]): UserExtended[] => users.map((it) => mapToExtendedUser(it));

  // load auth user
  useEffect(() => {
    if (authUserId) {
      const byId = UsersService.getById(authUserId);
      byId.then(
        (resp) => {
            const user = resp.data;
            setAuthUser(user);
            setCurrentUser(mapToExtendedUser(user));
        },
      );
    }
    return () => {
      setAuthUser(undefined);
      setCurrentUser(undefined);
    };
  }, [authUserId]);

  // load other users and auth user
  useEffect(() => {
    if (authUser && restaurant) {
      UsersService.getHostess(restaurant.restaurant_id).then(
        (resp) => {
          const newArray = [authUser];
          setUserList(mapToList(_.uniqBy(newArray.concat(resp.data), (e) => e.id)));
        },
      );
    }
    return () => {
      setUserList([]);
    };
  }, [authUser, restaurant]);

  return (
    <AppUsers.Provider {...props} value={{authUser, userList, currentUser}} />
  );
}

export const useAppUsers = () => useContext(AppUsers);
