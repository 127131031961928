import {VFC} from "react";
import {RadioButton} from "ui-kit";
import {config} from "../../config";
import {ELocales} from "../../types/commons";
import {useLocalStorage} from "react-use";

export type TLocaleControlsProps = {
  onLocaleChange?(locale: ELocales): void
}

export const LocaleControls: VFC<TLocaleControlsProps> = ({onLocaleChange}) => {

  const [userLocale, setUserLocale] = useLocalStorage<ELocales>('locale', config.defaultLocale)
  const handleOnLocaleChange = (locale: ELocales) => {
    setUserLocale(locale);
    onLocaleChange && onLocaleChange(locale)
  }

  if (!userLocale) return null;

  return <RadioButton value={userLocale} onChange={handleOnLocaleChange}>
    {(Object.keys(ELocales) as (ELocales)[]).map((locale) => {
      const displayingLocale = locale.split('_')[1];
      return <RadioButton.Button key={locale} value={locale}>{displayingLocale}</RadioButton.Button>
    })}
  </RadioButton>
}
