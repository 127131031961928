import {FunctionComponent} from "react";
import "./Modal.scss";
import ReactModal, {Props as ReactModalProps} from "react-modal";
import {ErrorWarning} from "ui-kit/ICONS/icons";
import {Button} from "ui-kit";
import {useIntlUtils} from "hooks/useIntlUtils";
import {ETranslations} from "types/translates";

export interface ModalProps extends Omit<ReactModalProps, "onAfterClose" | "onRequestClose"> {
  onClose(): void;

  manageralError: string[]
}

type Props = {} & Omit<ModalProps, "children">;

const parentSelector = () => document.body;

const CreateManageralModalError: FunctionComponent<Props> = ({onClose, isOpen, manageralError, ...props}) => {
  const {intl} = useIntlUtils();

  return (
    <ReactModal
      onRequestClose={onClose}
      closeTimeoutMS={0}
      portalClassName="modalPortal"
      overlayClassName="modalOverlay"
      className="errorModal"
      ariaHideApp
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      role="dialog"
      preventScroll
      isOpen={isOpen}
      parentSelector={parentSelector}
      {...props}
    >
      <ErrorWarning/>
      <div className="textContet">
        <div className="title">
          {intl.formatMessage({id: ETranslations.MANAGEMENT_ERROR_TITLE})}
        </div>
        {manageralError.map(eachText => {
          return <div className="text">
            {eachText}
          </div>
        })}


        <div className="buttonBox">
          <Button variant="primary" type="submit" onClick={onClose}>
            OK
          </Button>
        </div>
      </div>

    </ReactModal>
  );
};

export default CreateManageralModalError;
