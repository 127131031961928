import React, {FC} from 'react';
import styles from './ShortUserInfo.module.scss';
import dayjs from 'dayjs';
import { ETranslations } from 'types/translates';
import { useIntlUtils } from 'hooks/useIntlUtils';
import type { TNullable } from 'types/commons';
import cn from 'classnames';
import {config} from "config";


interface Props {
  birthday: TNullable<string>,
  email: TNullable<string>,
  comment: TNullable<string>,
  gradeName: TNullable<string>,
}

export const ShortInfo: FC<Props> = ({birthday, email, comment, gradeName}) => {
  const {intl, getIntlDateOfStrict, getIntlLevelOf} = useIntlUtils();

  return (
    <div className={styles.shortInfo}>
      <div className={styles.item}>
        <span className={styles.title}>{getIntlDateOfStrict(ETranslations.OF_BIRTH)}</span>
        <span className={styles.description}>{birthday && dayjs(birthday).format("DD.MM.YYYY")}</span>
      </div>
      <div className={styles.item}>
        <span className={styles.title}>{intl.formatMessage({id: ETranslations.EMAIL})}</span>
        <span className={styles.description}>{email || intl.formatMessage({id: ETranslations.BASE_NOT_INDICATED})}</span>
      </div>
      {config.clientsHasLoyalty && (
        <div className={styles.item}>
          <span className={styles.title}>{getIntlLevelOf(ETranslations.OF_LOYALTY)}</span>
          <span className={styles.description}>{gradeName}</span>
        </div>
      )}
      <div className={cn(styles.item, styles.fullRow)}>
        <span className={styles.title}>{intl.formatMessage({id: ETranslations.COMMENT})}</span>
        <span className={styles.description}>{comment || intl.formatMessage({id: ETranslations.BASE_NOT_INDICATED})}</span>
      </div>

    </div>
  );
};
