import {useState} from "react";

export const useTap = (ms:number) => {
    const [longTap, setLongTap] = useState(false);

    let timer: NodeJS.Timeout;

    const handleTouchStart = () => {
        timer = setTimeout(() => {
            setLongTap(true);
        }, ms); // 2000 миллисекунд = 2 секунды
    };

    const handleTouchEnd = () => {
        clearTimeout(timer);
        setLongTap(false);
    };

    return {
        handleTouchStart,
        handleTouchEnd,
        longTap
    }
}