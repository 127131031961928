import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Client} from '../../models/client.model';
import {ClientsFilter} from '../../services/clients.service';
import {useDispatchActions} from '../../hooks/useDispatchActions';
import _, {constant, isNull} from 'lodash';

export interface State {
  loadedGuests: Client[],
  filter: ClientsFilter,
  attachMode: 'none' | 'attach',
  selectedClient?: Client,
}

const emptyFilter = {
  offset: 0,
  count: 20,
};

export const initialState: State = {
  loadedGuests: [],
  filter: emptyFilter,
  attachMode: 'none',
};

const slice = createSlice({
  name: 'guests-list',
  initialState,
  reducers: {
    loadGuests(state, action: PayloadAction<Client[]>) {
      state.loadedGuests = action.payload.filter((it) => !isNull(it.phone));
      state.attachMode = 'none';
    },
    // concat list with onScroll action
    onScrollConcatLists(state, action: PayloadAction<Client[]>) {
      const {loadedGuests} = state;
      state.loadedGuests = _.uniqBy(
        loadedGuests.concat(
          action.payload.filter((it) => !isNull(it.phone)),
        ),
        (item) => item.client_id,
      );
      state.attachMode = 'none';
    },
    // apply filter
    applyFilter(state, action: PayloadAction<ClientsFilter>) {
      state.attachMode = 'none';
      state.filter = {...action.payload, offset: 0, count: 20};
    },
    applyFilterConcat(state, action: PayloadAction<ClientsFilter>) {
      state.attachMode = 'attach';
      state.filter = {...state.filter, ...action.payload};
    },
    updateFilter(state, action: PayloadAction<Partial<ClientsFilter>>) {
      state.filter = {...Object.assign(state.filter, action.payload), offset: 0};
    },
    // reset the state
    reset(state) {
      state.loadedGuests = [];
      state.filter = emptyFilter;
    },
    setSelectedClient(state, {payload}: PayloadAction<Client | undefined>) {
      state.selectedClient = payload;
    },
    clearSelectedClient: {
      prepare: constant({payload: undefined}),
      reducer(state) {
      state.selectedClient = undefined;
      },
    },
  },
});

export const {name: guestListSliceName, reducer: guestListReducer, actions: guestListActions} = slice;
export const useGuestListActions = () => useDispatchActions(slice.actions);
