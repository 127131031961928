import { ManagerialStatus } from 'types/booking';
import { Tag } from 'ui-kit';
import { useVisitContext } from './VisitContext';
import { useIntl } from 'react-intl';
import { ETranslations } from '../../types/translates';
import type { BaseStatus } from 'types/status';
import styles from './BookingStatus.module.scss';

export const BaseStatusTag = ({
  status,
  className,
}: {
  status: BaseStatus | ManagerialStatus | undefined | false;
  className: string;
}) => {
  const intl = useIntl();
  const { isExpired, isLate } = useVisitContext();

  if (!status) return null;

  const getStatusTagProps = () => {
    if (isExpired)
      return {
        label: intl.formatMessage({ id: ETranslations.OUTSTAY }),
        className: styles.bookingStatus,
      };
    if (isLate)
      return {
        label: intl.formatMessage({ id: ETranslations.LATE }),
        className: styles.bookingStatus,
      };
    return {
      label: status.name,
      style: { backgroundColor: status.color || 'gray' },
    };
  };

  return <Tag className={className} {...getStatusTagProps()} />;
};
