import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useBookingsList} from 'features/api/bookings-api';
import {Booking, TUseBookingList, ManagerialTable} from 'types/booking';
import cn from 'classnames';

import _ from 'lodash';
import {Spinner} from 'ui-kit';
import {BookingHistory} from 'components/BookingHistory';
import {useAllStatuses} from 'features/api/dictionaries-api';

import styles from './BookingsHistory.module.scss';

interface OwnProps {
  dateFrom?: Date | null,
  dateTo?: Date | null,
  searchWords: string,
  userid?: number | null
}

type Props = OwnProps;

export const BookingsHistory: FunctionComponent<Props> = ({dateFrom, dateTo, searchWords, userid}) => {
  const [search, setSearch] = useState(searchWords);
  const {data: allStatuses} = useAllStatuses();
  const allStatusesSystemName = allStatuses.map((status) => status.system_name);
  const bookingPayload : TUseBookingList = {
    search, includeStatuses: allStatusesSystemName, fromDate: dateFrom, toDate: dateTo, userid, isPooling: false
  }

  const {data, isLoading} = useBookingsList(bookingPayload);

  const debounceSearchWords = useCallback(_.debounce((val:string) => {
    setSearch(val);
  }, 500), []);

  useEffect(() => {
    debounceSearchWords(searchWords);
  }, [searchWords]);

  const [activeBook, setActiveBook] = useState<Booking | ManagerialTable | null>(null);
  const bookingClickHandler = useCallback((book: Booking | ManagerialTable) => {
    if (activeBook?.bookingId === book.bookingId) {
      setActiveBook(null);
      return;
    }
    setActiveBook(book);
  }, [setActiveBook, activeBook]);

  if (isLoading) return <Spinner />;

  return (
    <div className={cn("bookingHistory", styles.root)}>
      {data && data.bookings.map((book) => (
        <BookingHistory
          booking={book}
          key={book.bookingId}
          onClick={bookingClickHandler}
          isExpanded={activeBook?.bookingId === book.bookingId}
        />
      ))}
    </div>
  );
};
