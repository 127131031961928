import React from "react";
import dayjs from "dayjs";
import {TimeSelect} from "components/TimeSelect";
import useChoiseTimeInputNew from "./hooks/useChoiseTimeInputNew";

export const ChoiceTimeInputNew = ({
  disabled,
  allowValueFromOutsideRange = false,
  isEditMode,
  isFromManagement = false
}: {
  disabled: boolean;
  allowValueFromOutsideRange?: boolean;
  isEditMode: boolean;
  isFromManagement?: boolean;
}) => {

  const {
    allShifts,
    handleChange,
    startAndEnd,
    dateValue,
    value
  } = useChoiseTimeInputNew(allowValueFromOutsideRange, isFromManagement, disabled)

  return (
    <TimeSelect
      isFromManagement={isFromManagement}
      startAndEnd={startAndEnd}
      isEditMode={isEditMode}
      allShifts={allShifts}
      onChange={handleChange}
      dateValue={dateValue}
      inputValue={value}
      disabled={disabled}
      currentTime={dayjs()}
    />
  );
};
