import {useEffect} from 'react';
import {useBoolean} from 'react-use';

export function useIsTabVisible () {
  const [isVisible, setIsVisible ] = useBoolean(true)

  useEffect(() => {
    const handleChange = () => {
      setIsVisible(document.visibilityState === 'visible');
    }

    document.addEventListener('visibilitychange', handleChange);

    return () => {
      document.removeEventListener('visibilitychange', handleChange);
    };
  }, []);

  return isVisible;
}
