import {memo} from 'react';

import styles from './ProgressRing.module.scss';

interface ProgressRingProps{
  radius?: number;
  stroke?: number;
  percent: number;
}

export const ProgressRing = memo(({radius = 9, stroke = radius / 3, percent}: ProgressRingProps) => {
  const circumference = radius * 2 * Math.PI;
  const size = (radius + stroke) * 2;
  const center = radius + stroke;

  return (
    <div className={styles.container}>
      <svg height={size} width={size}>
        <circle
          stroke="var(--circle-color, var(--alto-2))"
          strokeWidth={stroke}
          r={radius}
          cx={center}
          cy={center}
          fill="transparent"
        />
        <circle
          className={styles.bar}
          stroke="var(--progress-color, currentColor)"
          strokeWidth={stroke}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (percent * circumference) / 100}
          fill="transparent"
          r={radius}
          cx={center}
          cy={center}
        />
      </svg>
    </div>
  );
});
