import {useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useField} from "react-final-form";
import {preventDefault, stopPropagation} from "utils";
import {HallMode, useHallSchemaActions} from "../../../../features/HallSchema";
import {hallModeSelector} from "../../../../features/HallSchema/selectors";
import {isBookingView, isRegView} from "../../../hall-scheme/redux/HallSchemaV2/hall-helpersFn";
import {useTablesOptions} from "hooks/useTablesOptions";
import {useIsTablet} from "../../../../hooks/useIsTablet";
import {useTimelineActions} from "features/Timeline";
import dayjs from "dayjs";


const useChoiceAutoTableInputRedux = (disabled: boolean, isCreateBooking: boolean, isShowTableScheme: boolean) => {
  const intl = useIntl();
  const {tables: options} = useTablesOptions();
  const {setActiveTables, switchMode} = useHallSchemaActions();
  const {setTimestamp} = useTimelineActions();
  const isTablet = useIsTablet();
  const {
    input: {value: personValue},
  } = useField("persons");
  const {
    input: {onChange: changeTables, value},
  } = useField("tables");

  const {
    input: {value: dateValue},
  } = useField("date");

  const {
    input: {value: timeValue},
  } = useField("time");


  const hasTableValue = useMemo(() => (Array.isArray(value) ? !!value.length : !!value), [value]);

  const isDisabled = useMemo(
    () => disabled || !options?.length || !personValue || hasTableValue,
    [personValue, options, hasTableValue, disabled]
  );

  const handleOnChange = () => {
    if (isDisabled) return;
    const [autoTable] = options.filter((it) => (it?.type || NaN) >= personValue);
    if (autoTable) {
      changeTables([autoTable.value]);
    }
  };

  const hallMode = useSelector(hallModeSelector);

  const showSchema = (event: any) => {

    const date = dayjs(dateValue);
    const time = dayjs(timeValue.label, "HH:mm");
    const hour = time.hour();
    const minute = time.minute();
    const timestamp = date.clone().set("hour", hour).set("minute", minute)
    .valueOf();
    setTimestamp(timestamp)

    preventDefault(event);
    stopPropagation(event);
    if (isRegView(hallMode)) {
      switchMode(HallMode.REGISTRATION_HALL);
      return;
    }
    if (isBookingView(hallMode)) {
      switchMode(HallMode.BOOKING_HALL);
      return;
    }
    switchMode(HallMode.EDIT_HALL);
  };

  const isShowSchema = useMemo(() => {
    if (!isTablet && isCreateBooking) {
      return false;
    }

    if (isTablet) {
      return false;
    }

    return true;
  }, [isCreateBooking, isTablet, isShowTableScheme]);
  return {
    showSchema,
    isShowSchema,
    handleOnChange,
    intl,
    isDisabled
  }
}

export default useChoiceAutoTableInputRedux