import React from "react";
import cn from "classnames";
import {useIntl} from "react-intl";
import {FieldRenderProps, useField} from "react-final-form";
import {Button, Input, SelectBasic, SelectCheckbox} from "ui-kit";
import {OpenScheme} from "./OpenScheme";
import {Option} from "../../../common/components/MultiSelect/multi-select";
import {TableOption} from "hooks/useTablesOptions";
import {ETranslations} from "../../../types/translates";
import {TABLES_TITLES} from "../../../constants";
import useChoiseTableRedux from "./hooks/useChoiseTableRedux";
import useChoiceAutoTableInputRedux from "./hooks/useChoiceAutoTableInputRedux";
import styles from "./style.module.scss";

const ChoicePerson = (props: any) => {
  const intl = useIntl();

  return (
    <Input
      size={1}
      type="number"
      step="1"
      min="1"
      autoComplete="off"
      placeholder={intl.formatMessage({id: ETranslations.QUANTITY})}
      inputClassName={styles.boldText}
      {...props}
    />
  );
};

const ChoicePersonSelect = (props: any) => {
  const options = Array.from({length: 10}, (_, index) => {
    return {label: index + 1, value: index + 1};
  });

  const {input} = useField<Option<string> | null>("persons", {
    allowNull: true,
    format: (value): any => {
      if (!value) {
        return {value: 1, label: 1};
      }
      return {value, label: value};
    },
  });

  const {value, onChange} = input;

  const handleChange = (selectedOption: any) => {
    onChange(selectedOption.value);
  };

  return (
    <SelectBasic
      getOptionValue={(option) => option.value}
      size={1}
      options={options}
      inputClassName={styles.boldText}
      isDisabled={props.disabled}
      {...props}
      value={value}
      onChange={handleChange}
    />
  );
};

const sortTables = (a: TableOption, b: TableOption) => a.number.toString().localeCompare(b.number.toString(), undefined, {numeric: true});

const ChoiceTablesRedux = ({value = [], onChange, disabled, isTablet, isFromManagement, bookingTables}: any) => {
  const { selected, intl, inputOptions, handleOnChange } = useChoiseTableRedux(
    onChange,
    isFromManagement,
    value,
    bookingTables
  );
  
  return (
    <SelectCheckbox
      menuPlacement="top"
      value={selected as any}
      isFromManagement={isFromManagement}
      isDisabled={disabled}
      //@ts-ignore
      onChange={handleOnChange}
      placeholder={intl.formatMessage({id: ETranslations.BASE_NUMBER})}
      maxMenuHeight={isTablet ? 180 : 220}
      titles={TABLES_TITLES}
      isClearable
      sortable
      sortingFn={sortTables}
      options={inputOptions as any}
      isValid={Boolean(selected?.length)}
    />
  );
};

export const ChoiceAutoTableInputRedux = ({
  disabled,
  isShowTableScheme = false,
  isCreateBooking = false,
}: {
  disabled: boolean;
  isShowTableScheme?: boolean;
  isCreateBooking?: boolean;
}) => {

  const {showSchema, isShowSchema, handleOnChange, isDisabled, intl} = useChoiceAutoTableInputRedux(
    disabled,
    isCreateBooking,
    isShowTableScheme
  );

  return (
    <div
      className={cn({
        [styles.oneElement]: !isShowSchema,
        [styles.twoElems]: isShowSchema,
      })}
    >
      {isShowSchema && (
        <button disabled={disabled} className={styles.openScheme}>
          <OpenScheme onClick={showSchema} />
        </button>
      )}

      <Button variant="phantom" className="autoChoice" onClick={handleOnChange} disabled={isDisabled}>
        {intl.formatMessage({id: ETranslations.AUTO_CHOICE})}
      </Button>
    </div>
  );
};

export const ChoicePersonsInput: React.FC<FieldRenderProps<any>> = ({input, meta, ...rest}) => (
  <ChoicePerson {...input} {...meta} {...rest} />
);

export const ChoicePersonsInputSelect: React.FC<FieldRenderProps<any>> = ({input, meta, ...rest}) => (
  <ChoicePersonSelect {...input} {...meta} {...rest} />
);

export const ChoiceTablesInputRedux: React.FC<FieldRenderProps<any>> = ({input, meta, ...rest}) => (
  <ChoiceTablesRedux {...input} {...meta} {...rest} />
);
