import { useContext } from 'react';
import { ModalContext } from 'contexts/ModalContext';
import { usePageId } from 'contexts/LayoutContext';
import { useSelector } from 'react-redux';
import { appContextSelectors } from 'features/AppContex';
import { useAppUsers } from 'contexts/AppUsersContext';
import { useIntlUtils } from '../../hooks/useIntlUtils';
import { useGetAllUsersQuery } from 'features/api/user-api';

const useSetting = () => {
  const intlUtils = useIntlUtils();
  const {
    settingsParamsChanges,
    settingsUserModal,
    activateModal,
    setConfirmDialogData,
    setSettingsHostessModal,
    setSettingsEditStatusModal,
  } = useContext(ModalContext);

  usePageId('settings');

  const date = useSelector(appContextSelectors.date);
  const restaurant = useSelector(appContextSelectors.restaurant);
  const selectedPlaces = useSelector(appContextSelectors.selectedPlaces);
  const { userList } = useAppUsers();
    const { data: allUsers } = useGetAllUsersQuery();

  return {
    settingsParamsChanges,
    settingsUserModal,
    activateModal,
    setConfirmDialogData,
    setSettingsHostessModal,
    intlUtils,
    date,
    restaurant,
    selectedPlaces,
    userList,
    allUsers,
    setSettingsEditStatusModal,
  };
};

export default useSetting;
