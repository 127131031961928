import {TextareaHTMLAttributes} from 'react';
import {Labeled} from '../Labeled';

import styles from './Textarea.module.scss';

type BaseTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export interface TextareaProps extends BaseTextareaProps {
  label?: string;
}

export function Textarea({label, className, ...props}: TextareaProps) {
  return (
    <Labeled label={label} required={props.required} className={className}>
      <textarea {...props} className={styles.textarea} />
    </Labeled>
  );
}
