import {OptionSelectType} from "../../../../ui-kit";
import {FormApi} from "final-form";
import {TNullable} from "../../../../types/commons";
import {Shift} from "../../../../types/shift";
import {TDuration} from "../../utils";

export const changeGuestTime = (
    value: OptionSelectType,
    fieldValues: TDuration[],
    placeCount: number,
    onChange: (e: any) => void
) => {
    const final = fieldValues?.map((v) => {
        if (v.count === placeCount) {
            return {
                count: placeCount,
                duration: value?.value
            };
        } else {
            return v;
        }
    });
    onChange(final);
};


export const indefiniteChangeHandler = (
    value: boolean, onChange: (value: boolean) => void, form: FormApi<any>, shift: TNullable<Partial<Shift>>
) => {
    form.change('end_date', value ? '' : shift?.end_date && new Date(shift.end_date));
    onChange(value);
};
export const placesChangeHandler = (value: boolean, values: any, place: number, onChange: (value: boolean) => void) => {
    onChange(!value ? values.filter((el: number) => el !== +place)
        : [...values, place]);
};
