import { HallSchema as Scheme } from 'components/hall-scheme/redux/HallSchemaV2/hall-schema';
import { useBooleanState } from 'hooks/useBooleanState';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useUnmount, useUpdateEffect } from 'react-use';

import { SortedBookingsList } from 'components/SortedBookingsList';
import { EditBooking } from 'components/hall-scheme/redux/TableBookingList/table-booking-list';
import { tableBookingsTableSelector } from 'features/TableBooking/selectors';
import {
  fromProxySelectors,
  useFromProxyActions,
} from 'features/BookingFormProxy';
import { moveBookingSelectors } from 'features/MoveBooking';
import { Card } from 'ui-kit';

import styles from './HallSheme.module.scss';
import { hallModeSelector } from 'features/HallSchema/selectors';
import { HallMode, useHallSchemaActions } from 'features/HallSchema';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTableBookingListActions } from 'features/TableBooking/slice';
import { SelectGuestFromList } from '../../features/GuestsList/components/select-guest-from-list';
import { Client } from '../../types/client';
import { useIntlUtils } from '../../hooks/useIntlUtils';
import { ETranslations } from '../../types/translates';
import { HideWhen } from 'components/HideWhen';
import { useIsTablet } from '../../ui-kit/hooks';
import { useTimelineActions } from 'features/Timeline';
import { isManagerialTable } from 'utils';
import { EditManageralBooking } from 'components/ManagerialTables/form/EditManageralBooking';
import { CreateBooking } from 'components/hall-scheme/redux/Booking/CreateBooking';
import { usePlacesTable } from 'features/api/tables-api';
import { useIsLandscape } from '../../hooks/useIsDeviceLandscape';

export function HallScheme() {
  const isTablet = useIsTablet();
  const [isBookingListShown, show, _hide] = useBooleanState(false);
  const { getIntlEntityEdition, isRussianLocale, intl } = useIntlUtils();
  const table = useSelector(tableBookingsTableSelector);
  const { reset: closeEdit, setClient } = useFromProxyActions();
  const { resetTimeShift } = useTimelineActions();
  const booking = useSelector(fromProxySelectors.booking);
  const hallMode = useSelector(hallModeSelector);
  const { switchMode, reset: resetBooking } = useHallSchemaActions();
  const { reset: resetTable } = useTableBookingListActions();
  const openedByTable = useRef(false);
  const { isLoading: isTableLoading } = usePlacesTable(table);
  const isLandscapeDevice = useIsLandscape();

  const intlTitles = useMemo(
    () => ({
      bookingEdit: getIntlEntityEdition(
        isRussianLocale
          ? ETranslations.PLURAL_BOOKINGS_NOM
          : ETranslations.PLURAL_BOOKING
      ),
    }),
    []
  );

  useEffect(() => {
    switchMode(HallMode.TABLES);
    if (table) {
      resetTable();
    }
  }, []);

  const isMoveBookingSelected = useSelector(
    moveBookingSelectors.isSourceSelected
  );

  const close = useCallback(() => {
    openedByTable.current = false;
    _hide();
  }, [table, resetTable, _hide]);

  useUnmount(close);

  useUpdateEffect(() => {
    if (!isMoveBookingSelected) return;
    close();
  }, [isMoveBookingSelected, close]);

  useUpdateEffect(() => {
    if (table && !isBookingListShown && !isMoveBookingSelected) {
      openedByTable.current = true;
      show();
    }
  }, [table, isBookingListShown]);

  const closeScheme = useCallback(() => {
    if (hallMode === HallMode.MANAGARAL_HALL_BOOKING) {
      switchMode(HallMode.MANAGERAL_CREATE_BOOKING);
      return;
    } else {
      switchMode(HallMode.TABLE_BOOKINGS_LIST);
      return;
    }
  }, [switchMode, hallMode]);

  useUpdateEffect(() => {
    if (!isBookingListShown) {
      closeEdit();
      closeScheme();
    }
  }, [isBookingListShown, closeEdit]);

  const closeGuests = useCallback(() => {
    if (
      [
        HallMode.MANAGERAL_OPEN_GUESTS,
        HallMode.MANAGERAL_CREATE_BOOKING,
      ].includes(hallMode)
    ) {
      switchMode(HallMode.MANAGERAL_CREATE_BOOKING);
      return;
    } else {
      switchMode(HallMode.TABLE_BOOKINGS_LIST);
    }
  }, [switchMode, hallMode]);

  const handleSetClient = useCallback(
    (client: Client) => {
      setClient({ client });
      closeGuests();
    },
    [setClient, closeGuests]
  );

  const isEditHallOpen = hallMode === HallMode.EDIT_HALL;
  const isShowGuestsList = [
    HallMode.BOOKING_GUEST,
    HallMode.TABLE_BOOKINGS_EDIT_GUEST,
  ].includes(hallMode);

  const handleCloseEdit = () => {
    closeEdit();
    resetTimeShift();
  };

  const isCreatingBooking = useMemo(
    () =>
      [
        HallMode.MANAGERAL_CREATE_BOOKING,
        HallMode.MANAGERAL_OPEN_GUESTS,
        HallMode.MANAGARAL_HALL,
        HallMode.MANAGARAL_HALL_BOOKING,
      ].includes(hallMode),
    [hallMode]
  );
  const isTabletAndCreating = isCreatingBooking && isTablet;

  const EditForm = useMemo(() => {
    if (booking && !isCreatingBooking) {
      if (booking && !isManagerialTable(booking)) {
        return <EditBooking bookingId={booking?.bookingId} />;
      }

      return <EditManageralBooking booking={booking} />;
    } else {
      return null;
    }
  }, [booking, hallMode]);

  return (
    <div
      className={cn(styles.hallScheme, {
        [styles.isBookingListShown]: isBookingListShown && !isTabletAndCreating,
        [styles.isEditOpen]: !!booking,
      })}
    >
      {isBookingListShown && !isTabletAndCreating && !isTableLoading && (
        <SortedBookingsList
          canDrag={true}
          onHide={close}
          className={styles.bookingsList}
          openedByTable={openedByTable.current}
          isHallScheme
        />
      )}
      {!booking && !isCreatingBooking && (
        <Scheme onBookingListClick={isBookingListShown ? close : show} />
      )}
      {isCreatingBooking && (
        <>
          <Card
            style={{
              display: ![HallMode.MANAGERAL_CREATE_BOOKING].includes(hallMode)
                ? 'none'
                : 'initial',
            }}
          >
            <HideWhen
              noUnmount
              condition={
                ![HallMode.MANAGERAL_CREATE_BOOKING].includes(hallMode)
              }
            >
              <CreateBooking reset={resetBooking} isFromManagement />
            </HideWhen>
          </Card>

          <HideWhen
            noUnmount
            condition={
              ![
                HallMode.MANAGARAL_HALL,
                HallMode.MANAGARAL_HALL_BOOKING,
              ].includes(hallMode)
            }
          >
            <Card onClose={closeScheme}>
              <Card.Header
                title={intl.formatMessage({ id: ETranslations.HALL_SCHEME })}
              />
              <Card.Content>
                <Scheme />
              </Card.Content>
            </Card>
          </HideWhen>
          <HideWhen
            noUnmount
            condition={![HallMode.MANAGERAL_OPEN_GUESTS].includes(hallMode)}
          >
            <Card>
              <SelectGuestFromList
                onClose={closeGuests}
                onSelectGuest={handleSetClient}
              />
            </Card>
          </HideWhen>
        </>
      )}
      {booking && !isCreatingBooking && (
        <Card
          onClose={isEditHallOpen ? closeScheme : handleCloseEdit}
          className={styles.editBooking}
        >
          {!isShowGuestsList && <Card.Header title={intlTitles.bookingEdit} />}
          <Card.Content noPadding>
            {isShowGuestsList && (
              <div style={{ height: '100%' }}>
                <SelectGuestFromList
                  onClose={closeGuests}
                  onSelectGuest={handleSetClient}
                />
              </div>
            )}
            {EditForm}
            <HideWhen condition={!isEditHallOpen}>
              <div style={{ height: '100%' }}>
                <Scheme />
              </div>
            </HideWhen>
          </Card.Content>
        </Card>
      )}
    </div>
  );
}
