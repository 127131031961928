import { Tags } from 'components/Tags';
import type { TagsShortView } from './utils';
import styles from './BookingTags.module.scss';
import { Tag } from 'components/Tag';

export const BookingTags = ({ tags }: { tags: TagsShortView }) => (
  <div className="tags">
    {tags.visibleTags.map((t) => (
      <Tag tag={t} key={t.id} />
    ))}
    {tags.invisibleTagsCount > 0 && (
      <Tag
        key={'tag-count'}
        tag={{
          name: `+${tags.invisibleTagsCount}`,
        }}
        className={styles.tagCount}
      />
    )}
  </div>
);
