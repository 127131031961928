import React, { FC } from 'react';
import cn from 'classnames';
import { SvgForeignObjectBody } from '../../SvgForeignObject';
import { TableSchema } from 'features/api/hallschema-api';
import styles from './style.module.scss';

interface Props {
  isShow: boolean;
  shape: TableSchema['shape'];
  statusColor?: string;
}

export const TableBorder: FC<Props> = ({ isShow, shape, statusColor }) => {
  if (!isShow) return null;

  return (
    <SvgForeignObjectBody
      as="div"
      style={{
        border: `7px solid ${statusColor || 'var(--floorplanNumber_default_text)'}`,
      }}
      className={cn(styles.border, styles[shape])}
    />
  );
};
