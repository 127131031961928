import cn from 'classnames';
import { MAX_STATUS_NAME_LENGTH, editStatusFormInputNames } from '../utils';
import type { ReactNode } from 'react';
import type { EditStatusRequest, Locale, Status } from 'types/status';
import type { IntlFormatters } from 'react-intl';
import { useEditStatusForm } from './useEditStatusForm';

export const EditStatusForm = ({
  formName,
  onValidSubmit,
  statuses,
  currentStatus,
  label,
  labelClassName,
  children,
  formatMessage,
  locale,
  className,
}: {
  formName: string;
  onValidSubmit: (payload: EditStatusRequest) => void;
  statuses: Status[];
  currentStatus: Status;
  label: string;
  className?: string;
  labelClassName?: string;
  formatMessage: IntlFormatters['formatMessage'];
  locale: Locale;
  children: ReactNode;
}) => {
  const { errors, handleStatusNameChange, handleEditStatusSubmit }
    = useEditStatusForm(
      statuses,
      currentStatus,
      onValidSubmit,
      formatMessage,
      locale
    );
  return (
    <form
      id={formName}
      className={cn('content custom-scroll', className)}
      onSubmit={handleEditStatusSubmit}
    >
      <label className={cn('status-labeled', labelClassName)}>
        {label}
        <input
          required
          autoComplete="off"
          name={editStatusFormInputNames.statusName}
          maxLength={MAX_STATUS_NAME_LENGTH}
          defaultValue={currentStatus.name}
          onChange={handleStatusNameChange}
          className={cn({ invalid: errors?.['status-name'] })}
        />
        {errors?.['status-name'] && (
          <span className="error">{errors['status-name']}</span>
        )}
      </label>
      {children}
      {errors?.color && <span className="error">{errors.color}</span>}
    </form>
  );
};
