import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import cn from 'classnames';

import { ModalContent } from './ModalContent';
import { ModalFooter } from './ModalFooter';

import styles from './Modal.module.scss';
import { ICONS } from 'common/helpers';

export interface ModalProps
  extends Omit<ReactModalProps, 'onAfterClose' | 'onRequestClose'> {
  onClose(): void;
  title: string;
  children: ReactNode;
}

const parentSelector = () => document.body;

ReactModal.setAppElement('#root');

function ModalComponent({
  onClose,
  title,
  className,
  children,
  ...props
}: ModalProps) {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const [content, footer] = useMemo(
    () =>
      React.Children.toArray(children).reduce<Array<ReactElement | null>>(
        (acc, el) => {
          if (!React.isValidElement(el)) return acc;
          if (el.type === ModalContent) {
            acc[0] = el;
          } else if (el.type === ModalFooter) {
            acc[1] = el;
          } else {
            acc[0] = el;
          }
          return acc;
        },
        [null, null]
      ),
    [children]
  );

  return (
    <ReactModal
      onRequestClose={handleClose}
      closeTimeoutMS={0}
      portalClassName={styles.modalPortal}
      overlayClassName={styles.modalOverlay}
      className={cn(styles.modal, className)}
      ariaHideApp
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      role="dialog"
      preventScroll
      parentSelector={parentSelector}
      {...props}
    >
      <div className={styles.title}>
        <h3>{title}</h3>
        <button className="close-button" onClick={handleClose}>
          <ICONS.Cross fill="var(--gl_icon_primary_1)" />
        </button>
      </div>
      {content}
      {footer}
    </ReactModal>
  );
}

export const Modal = Object.assign(ModalComponent, {
  Content: ModalContent,
  Footer: ModalFooter,
});
