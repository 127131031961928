import {FC} from 'react';
import {IPayloadClientHistory, TModeHistory} from './BookingClientHistory';
import {useBookingHistoryClientQuery} from 'features/api/bookings-api';
import {Spinner} from 'ui-kit';
import {Pagination} from 'components/Pagination';
import styles from './styles.module.scss';
import {BookingHistoryItem} from './BookingHistoryItem';

interface Props {
  clientId: number,
  payload: IPayloadClientHistory,
  paginationHandler: (page:number) => void,
  mode: TModeHistory,
}

export const BookingClientHistoryList: FC<Props> = ({clientId, payload, paginationHandler, mode}) => {
  const {data} = useBookingHistoryClientQuery({clientId, payload});

  if (!data) return <Spinner />;


  return (
    <>
      <section className={styles.list}>
        {data.content.map((el) => (<BookingHistoryItem mode={mode} booking={el} key={el.bookingId} />))}
      </section>
      {!data.empty && (
        <Pagination
          currentPage={data.pageable.pageNumber}
          pageSize={data.pageable.pageSize}
          total={data.totalElements}
          onChange={paginationHandler}
          className={styles.pagination}
        />
      )}
    </>
  );
};
