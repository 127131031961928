import React, { FC, useCallback } from 'react';
import cn from 'classnames';
import { components } from 'react-select';
import { OptionSelectType, SelectBasic, SelectBasicProps } from './SelectBasic';
import styles from './Select.module.scss';
import { getTitle, SelectStyles } from './helpers';
import { TPluralsTitles } from '../../types/commons';
import { ETranslations } from '../../types/translates';
import { useIntl } from 'react-intl';

interface Props extends SelectBasicProps<OptionSelectType> {
  titles: TPluralsTitles | string[];
  styleObject?: SelectStyles;
}

const optionStyles: SelectStyles['option'] = {
  option: {
    font: 'var(--font-12-r)',
    color: 'var(--gl_text_inverse)',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'transparent',
    },
  },
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <div className={styles.option}>
          <input type="checkbox" checked={props.isSelected} />
          <span
            className={cn(styles.mark, {
              [styles.markChecked]: props.isSelected,
            })}
          />
          <label className={styles.optionText}>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

export const SelectCheckbox: FC<Props> = ({
  titles,
  isFromManagement,
  styleObject,
  ...props
}) => {
  const intl = useIntl();

  const ValueContainer = useCallback(({ children, ...containerProps }: any) => {
    const { getValue, hasValue, options, selectProps } = containerProps;
    const newChildren = [...children];
    const values = getValue();
    newChildren[0] = getTitle(
      values,
      options.length,
      selectProps.placeholder,
      (titles as TPluralsTitles).map((titleItem) => {
        const isTranslationToken = titleItem in ETranslations;

        return isTranslationToken
          ? intl.formatMessage({ id: ETranslations[titleItem] })
          : titleItem;
      }),
      intl.formatMessage({ id: ETranslations.BASE_ALL }),
      isFromManagement
    );

    if (!hasValue) {
      return (
        <components.ValueContainer {...containerProps} className={styles.title}>
          {children}
        </components.ValueContainer>
      );
    }
    return (
      <components.ValueContainer {...containerProps} className={styles.title}>
        {newChildren}
      </components.ValueContainer>
    );
  }, []);

  return (
    <SelectBasic
      styleObject={{ ...optionStyles, ...styleObject }}
      components={{
        Option,
        ValueContainer,
      }}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isSearchable={false}
      isClearable
      isMulti
      {...props}
    />
  );
};
