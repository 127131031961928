import dayjs from "dayjs";
import {appContextSelectors, useApplicationContextActions} from "features/AppContex";
import {useTimelineActions} from "features/Timeline";
import {Option} from "models/common";
import moment from "moment";
import React, {FC, useEffect} from "react";
import {FieldRenderProps, useField} from "react-final-form";
import {useSelector} from "react-redux";
import {DatePicker} from "ui-kit";

export const EndDate: FC<FieldRenderProps<string>> = ({input, meta, ...rest}) => {
  const {onChange, value, name, ...restInput} = input;
  const contextData = useSelector(appContextSelectors.date);
  const {
    input: {value: timeValue},
  } = useField<Option<string> | null>("time", {allowNull: true});
  const date = new Date(value);
  const {setIsActualTime, setTimestamp} = useTimelineActions();
  const {setDate} = useApplicationContextActions();

  const handleChange = (e: Date) => {
    const inputValue = moment(e).format("YYYY-MM-DD");
    onChange(inputValue);

    if (name === "start_date") {
      const now = dayjs(value);
      const selectedDate = dayjs(e);
      const diffDays = selectedDate.diff(now, "days");
      const newTimestamp = dayjs(timeValue?.value).add(diffDays, "day");

      setDate(selectedDate.toDate());
      setTimestamp(newTimestamp.valueOf());
      setIsActualTime(false);
    }
  };

  useEffect(() => {
    onChange(contextData.format("YYYY-MM-DD"))
  }, [contextData])

  return <DatePicker selected={date} name={name} onChange={handleChange} {...restInput} {...meta} {...rest} />;
};
