import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { SelectBasic, OptionSelectType } from 'ui-kit';

const valueToOption = (source: {
  id: number;
  source_name: string;
  value?: number;
  label?: string;
}): OptionSelectType =>
  source.value && source.label
    ? (source as OptionSelectType)
    : {
        ...source,
        value: source.id,
        label: source.source_name,
      };

const ChoiceSource = ({
  value,
  onChange,
  disabled,
  options,
  placeholder,
}: any) => (
  <SelectBasic
    className="content"
    value={value && valueToOption(value)}
    openTo="top"
    changedMaxSize
    isClearable
    isTagsOrSource
    menuPlacement="top"
    isDisabled={disabled}
    onChange={onChange}
    options={options}
    maxMenuHeight={280}
    placeholder={placeholder}
  />
);

export const ChoiceSourceInput: React.FC<FieldRenderProps<any, any>> = ({
  input,
  meta,
  ...rest
}) => <ChoiceSource {...input} {...meta} {...rest} />;
