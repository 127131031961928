import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Layout } from 'components/Layout';
import { LayoutContextProvider } from 'contexts/LayoutContext';
import { AppUsersProvider } from '../../contexts/AppUsersContext';
import { Spinner } from 'ui-kit';
import { DndPreviewContainer } from '../DndPreviewLayouts';
import { routes } from './routes';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import { DndProvider } from 'react-dnd-multi-backend';

const ApplicationContextWrapper = lazy(() => import('contexts/ModalContext'));

const Root = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <ApplicationContextWrapper>
        <LayoutContextProvider>
          <AppUsersProvider>
            <DndProvider options={HTML5toTouch}>
              <Switch>
                <Redirect strict exact from="/" to="/dashboard" />
              </Switch>
              <Layout>
                {routes.map(({ exact, path, component, isShow, render }) =>
                  isShow ? (
                    <Route
                      key={`${component}-${path}`}
                      exact={exact}
                      path={path}
                      component={component}
                      render={render}
                    />
                  ) : null)}
              </Layout>
              <DndPreviewContainer />
            </DndProvider>
          </AppUsersProvider>
        </LayoutContextProvider>
      </ApplicationContextWrapper>
    </Suspense>
  );
};

export default Root;
