import {memo} from "react";
import moment from "moment";
import cn from "classnames";
import {Place} from "types/place";
import {TNullable} from "types/commons";
import {ICONS, minsToString} from "common/helpers";
import {ProgressRing} from "components/ProgressRing";
import {ETranslations} from "../../types/translates";
import styles from "./BookingVisitInfo.module.scss";
import useBookingVisitInfo from "./hooks/useBookingVisitInfo";

export interface BookingVisitInfoProps {
  bookingTime: string;
  date?: string;
  persons: number;
  places: Place[] | Place;
  isOverbooking: TNullable<boolean>;
  comment?: TNullable<string>;
  compact?: boolean;
  isManagerialTableBoolean?: boolean;
}

export const BookingVisitInfo = memo(
  ({
    date,
    bookingTime,
    places: rawPlaces,
    persons,
    isOverbooking,
    comment,
    compact,
    isManagerialTableBoolean = false,
  }: BookingVisitInfoProps) => {

    const {intl, hallName, visitPercents, visitTime, showProgress, places} = useBookingVisitInfo({rawPlaces});

    return (
      <div className={styles.container}>
        <div className={cn(styles.bookingVisitInfo, {[styles.withOverbooking]: isOverbooking})}>
          <div className={styles.info}>
            {date && (
              <div className={styles.blockWithIcon}>
                <ICONS.Calendar />
                <span>{moment(date).format("L")}</span>
              </div>
            )}
            <div className={styles.time}>
              {showProgress ? <ProgressRing percent={visitPercents} /> : <ICONS.Clock />}
              <span>
                {bookingTime}
                &nbsp;|&nbsp;
                {minsToString(visitTime)}
              </span>
            </div>
            {!isManagerialTableBoolean && (
              <div className={styles.blockWithIcon}>
                <ICONS.People />
                <span>{persons}</span>
              </div>
            )}

            <div className={styles.blockWithIcon}>
              <ICONS.Seat />
              <div className="cont">
                {places
                  .map((p) => p.number)
                  .sort((a, b) => +a - +b)
                  .join(", ")}
              </div>
            </div>
            {/* )} */}
            <div className={cn(styles.blockWithIcon, styles.transparent)}>
              <ICONS.Hall />
              <span>{hallName}</span>
            </div>
            {compact && Boolean(comment) && (
              <div className={cn(styles.blockWithIcon, styles.transparent, styles.commentShortContainer)}>
                <ICONS.Comment />
                <span className={styles.commentShort}>{comment}</span>
              </div>
            )}
          </div>
          {!compact && Boolean(comment) && <div className={cn(styles.comment, styles.transparent)}>{comment}</div>}
        </div>
        {isOverbooking && (
          <div className={styles.overbookingHint}>
            <ICONS.Info />
            {intl.formatMessage({id: ETranslations.BOOKING_OVERLAP})}
          </div>
        )}
      </div>
    );
  }
);
