import React, {FC} from 'react';
import {ModalProps} from '../../../modal';
import {BaseShiftsModal} from '../BaseShiftsModal';
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {appContextSelectors} from 'features/AppContex';
import {useCreateShiftMutation} from "../../../../features/api/shifts";
import {buildDurations, getWeekDayById, rebuildDurations} from "../../utils";
import EditShiftForm from "../../Form/EditShiftForm/EditShiftForm";
import {ShiftSelectedDates, TModalOpen} from '../../Calendar/ShiftsCalendar';
import {INDEFINITE_DATE} from '../../Form/EditFormContainer/EditFormContainer';

type Props = {
    startDate: ShiftSelectedDates
    setModalOpen: (value: TModalOpen) => void
    setActiveShiftId: (value: number) => void
    setActiveShiftName: (name: string) => void
} & Omit<ModalProps, 'children'>

export const CreateShiftsModal: FC<Props> = ({isOpen, onClose, title, startDate, setModalOpen, setActiveShiftId, setActiveShiftName}) => {
    const restaurant = useSelector(appContextSelectors.restaurant);
    const initShift = {
        capacity: {
            durations: buildDurations(),
            use_max_covered: false,
            max_covered_size: 0
        },
        shift_name: '',
        restaurant_id: restaurant.restaurant_id,
        start_date: dayjs(startDate.start).format("YYYY-MM-DD"),
        end_date: dayjs(startDate.end).format("YYYY-MM-DD"),
        start_active_time: dayjs(startDate.start).format("HH:mm:ss"),
        end_active_time: dayjs(startDate.end).format("HH:mm:ss"),
        booking_interval: 15,
        repeat_days: getWeekDayById(startDate.start),
        places_id: restaurant.places.map(place => place.id),
        deposit_options: {
            deposit_required: false,
        }
    }
    const [createShift, {isLoading: createIsLoading}] = useCreateShiftMutation();

    const onSubmitCallBack = async (formData: any) => {
        const payload = {
            capacity: {
                durations: rebuildDurations(formData?.durations),
                use_max_covered: formData?.use_max_covered,
                max_covered_size: formData?.max_covered_size,
            },
            shift_name: formData?.shift_name,
            restaurant_id: formData?.restaurant_id,
            start_date: dayjs(formData?.start_date).format("YYYY-MM-DD"),
            end_date: formData?.continue_indefinite ? INDEFINITE_DATE : dayjs(formData?.end_date).format("YYYY-MM-DD"),
            start_active_time: dayjs.duration(formData?.open_time?.value, "minutes").format("HH:mm:ss"),
            end_active_time: `${dayjs.duration(formData?.close_time?.value, "minutes").format("HH:mm:ss")}`,
            booking_interval: formData.booking_interval.value,
            repeat_days: formData?.repeatDays,
            places_id: formData?.places,
            deposit_options: {deposit_required: false},
        }

        const res = await createShift(payload)
        if('data' in res){
            onClose()
            setActiveShiftId(res.data.data.id)
            setActiveShiftName(res.data.data.shift_name)
            setModalOpen("INFO")
        }
    }

    return (
        <BaseShiftsModal
            onClose={onClose}
            title={title}
            isOpen={isOpen}
        >
            <EditShiftForm onCancel={onClose} shift={initShift} onSubmitCallBack={onSubmitCallBack} isLoadingSubmit={createIsLoading}/>
        </BaseShiftsModal>
    );
};
