import { useState } from 'react';
import { useHistory } from 'react-router';
import { useLocalStorage } from 'react-use';
import { useIntl } from 'react-intl';
import type { BookingModeType, BookingsData } from './types';

const UseDashboardBookings = () => {
  const intl = useIntl();
  const { location } = useHistory();
  const [bookingMode, setBookingMode] = useState<BookingModeType>(() =>
    location.search.includes('complete') ? 'complete' : 'active');
  const [isManagerableTableSelected, setIsManagerableTableSelected]
    = useLocalStorage('isManagerableTableSelected', true);

  const [bookingsData, setBookingsData] = useState<BookingsData>(() => ({
    bookings: [],
    waitList: [],
    statistics: undefined,
    includedStatuses: [],
    searchQuery: '',
    isLoading: true,
    extraStatusFilter: undefined,
  }));

  return {
    intl,
    bookings: bookingsData.bookings,
    statistics: bookingsData.statistics,
    waitList: bookingsData.waitList,
    includedStatuses: bookingsData.includedStatuses,
    searchQuery: bookingsData.searchQuery,
    isLoading: bookingsData.isLoading,
    extraStatusFilter: bookingsData.extraStatusFilter,
    isManagerableTableSelected,
    setIsManagerableTableSelected,
    bookingMode,
    setBookingMode,
    setBookingsData,
  };
};

export default UseDashboardBookings;
