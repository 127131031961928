import {ChangeEvent, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {ETranslations} from "../../../../types/translates";


const useChoiceDeposit = (value: any, onChange: any, isNewDesign: boolean) => {
    const intl = useIntl();
    const {use_deposit, deposit_made, deposit_amount} = value;
    const [checkboxText, setCheckboxText] = useState<string>(intl.formatMessage({id: ETranslations.DEPOSIT_MADE}));
    const widthControlRef = useRef(null);
    const handleUseDeposit = (useDeposit: boolean) => onChange({...value, use_deposit: useDeposit});
    const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => onChange({...value, deposit_amount: e.target.value});
    const handleDepositMade = (depositMade: boolean) => onChange({...value, deposit_made: depositMade});

    useEffect(() => {
        if (!use_deposit) {
            onChange({...value, deposit_amount: 0, deposit_made: false});
        }
    }, [use_deposit]);

    useLayoutEffect(() => {
        if (widthControlRef.current) {
            // @ts-ignore
            const {offsetWidth} = widthControlRef.current;
            if (offsetWidth < 300 && !isNewDesign) {
                setCheckboxText("");
            }
        }
    }, [widthControlRef]);

    return {
        deposit_made,
        deposit_amount,
        checkboxText,
        handleUseDeposit,
        handleAmountChange,
        handleDepositMade,
        widthControlRef,
        use_deposit,
        intl,
    }

}

export default useChoiceDeposit;