import React from 'react';
import { ICONS, predata, validatorPhone } from 'common/helpers';
import { AppointUser } from 'models/user.model';
import { UsersService } from 'services/users.service';
import { Role } from 'services/auth.service';
import { SettingsUser } from 'models/settings.model';
import {
  getOptionAppointRole,
  optionsAppointRoles,
} from 'components/settings/settings-helpers';
import { RestaurantsService } from 'services/restaurants.service';
import { SelectModel } from 'models/common';
import { AppointRestaurant } from 'models/restaurant.model';
import { NewPasswordForm } from 'components/NewPasswordForm';
import { Input, SelectBasic, Card, Button } from 'ui-kit';
import { useIntlUtils } from '../../../hooks/useIntlUtils';
import { ETranslations } from '../../../types/translates';
import { capitalizeString } from '../../../utils';

interface ChangeUserModalProps {
  settingsUser: SettingsUser;
  closeModal: (isForceUpdate?: boolean) => void;
  intlUtils: ReturnType<typeof useIntlUtils>;
}

interface ChangeUserModalState {
  modifableUser?: AppointUser;
  newUser: AppointUser;
  role?: SelectModel;
  changePassword: boolean;
  changeRole: boolean;
  error?: { message: string; data: string };
  newPassword?: string;
}

export default class ChangeUserModal extends React.Component<
  ChangeUserModalProps,
  ChangeUserModalState
> {
  idForUsers = 999;
  newUser = {
    name: '',
    phone: '',
    login: '',
    password: '',
    photo: null,
    restaurant_id: predata.rests[0].restaurant_id,
  };

  initialUser: AppointUser | undefined;
  isForcedUpdate = true;

  constructor(props: ChangeUserModalProps) {
    super(props);
    const { user } = props.settingsUser;
    this.initialUser = user ? { ...user } : undefined;

    this.state = {
      newUser: this.getNewUser(),
      role: getOptionAppointRole(user?.role) || optionsAppointRoles[0],
      changePassword: false,
      changeRole: !user,
      ...(user && { modifableUser: { ...user, password: '' } }),
    };
  }

  getNewUser(): AppointUser {
    const { settingsUser: { role = 'HOSTESS' } = {} } = this.props;
    this.idForUsers += 1;
    return { ...this.newUser, id: this.idForUsers, role };
  }

  handleChangeUser(field: string, value: string) {
    this.setState((prevState) => {
      const { modifableUser, newUser }: any = prevState;
      if (modifableUser) {
        modifableUser[field] = value;
      } else {
        newUser[field] = value;
      }
      return { modifableUser, newUser };
    });
  }

  changeRole(role: SelectModel) {
    this.setState((prevState) => {
      const { newUser }: any = prevState;
      if (!this.editMode) {
        newUser.phone = '';
        newUser.name = '';
      }
      return { role, newUser };
    });
  }

  handleChangeLogin(value: string) {
    if (/^[a-zA-Z][a-zA-Z0-9-_]?/.test(value) || value === '') {
      this.setState((prevState) => {
        const { modifableUser, newUser }: any = prevState;
        if (modifableUser) {
          modifableUser.login = value;
        } else {
          newUser.login = value;
        }
        return {
          modifableUser,
          newUser,
          ...(prevState.error?.data !== newUser.login && { error: undefined }),
        };
      });
    }
  }

  handleChangeUserPhone(value: string, isRegular: boolean) {
    this.setState((prevState) => {
      const { modifableUser, newUser }: any = prevState;

      let newPhone: string | undefined = value;
      if (isRegular) {
        newPhone = validatorPhone(
          modifableUser ? modifableUser.phone : newUser.phone,
          value
        );
      }

      if (newPhone === undefined) {
        return { modifableUser, newUser };
      }

      if (modifableUser) {
        modifableUser.phone = newPhone;
      } else {
        newUser.phone = newPhone;
      }
      return { modifableUser, newUser };
    });
  }

  private changeUser(user: AppointUser) {
    UsersService.change(this.modificateUser(user)).then(() =>
      this.props.closeModal(this.isForcedUpdate));
  }

  private createUser({ id, ...user }: AppointUser) {
    UsersService.create(this.modificateUser(user)).then(({ error, status }) => {
      const errorMessage = this.props.intlUtils.intl.formatMessage(
        { id: ETranslations.ERROR_USER_ALREADY_EXIST },
        { login: user.login }
      );
      if (status === 'ERROR' && error?.message === errorMessage) {
        this.setState({
          error: {
            message: errorMessage,
            data: user.login,
          },
        });
      } else {
        this.props.closeModal(this.isForcedUpdate);
      }
    });
  }

  private changeRestaurant(user: AppointUser) {
    RestaurantsService.change(this.transformToRestaurant(user)).then(() =>
      this.props.closeModal(this.isForcedUpdate));
  }

  private createRestaurant(user: AppointUser) {
    RestaurantsService.create(this.transformToRestaurant(user)).then(() =>
      this.props.closeModal(this.isForcedUpdate));
  }

  private modificateUser(user: AppointUser) {
    const { role, newPassword } = this.state;
    const modificatedUser = { ...user };
    delete modificatedUser.password;
    // role type === OptionAppointRole
    if (role) {
      modificatedUser.role = role.value as Role;
    }

    return {
      ...modificatedUser,
      password: newPassword,
    };
  }

  private transformToRestaurant(user: AppointUser): AppointRestaurant {
    const { phone, name, login, restaurant_id: id } = user;
    const { newPassword } = this.state;

    return {
      restaurant: {
        name,
        phone: phone ? phone.replace(/[^0-9]/g, '') : null,
        max_guests: 10,
        ...(this.editMode && { id }),
      },
      user: {
        login,
        password: newPassword,
      },
    };
  }

  get editMode() {
    return !!this.state.modifableUser;
  }

  get isValid() {
    const { role } = this.state;
    return this.getField('name') && this.getField('login') && role;
  }

  get getTitle() {
    const {
      getIntlEntityEdition,
      getIntlEntityAddition,
      isRussianLocale,
      intl,
    } = this.props.intlUtils;
    const intlTitles = {
      restaurantAdding: getIntlEntityAddition(
        isRussianLocale
          ? ETranslations.PLURAL_RESTAURANTS_ALT
          : ETranslations.PLURAL_RESTAURANT
      ),
      restaurantEditing: getIntlEntityEdition(
        isRussianLocale
          ? ETranslations.PLURAL_RESTAURANTS_ALT
          : ETranslations.PLURAL_RESTAURANT
      ),
      userAdding: getIntlEntityAddition(ETranslations.OF_USER),
      userEditing: intl.formatMessage({ id: ETranslations.USER_CARD_UPDATING }),
    };
    if (this.state.role?.value === 'RESTAURANT') {
      return this.editMode
        ? intlTitles.restaurantEditing
        : intlTitles.restaurantAdding;
    }
    return this.editMode ? intlTitles.userEditing : intlTitles.userAdding;
  }

  onSave(): void {
    const { modifableUser, newUser } = this.state;
    if (this.state.role?.value === 'RESTAURANT') {
      if (this.editMode && modifableUser) {
        this.changeRestaurant(modifableUser);
        return;
      }
      this.createRestaurant(newUser);
    } else {
      if (this.editMode && modifableUser) {
        this.changeUser(modifableUser);
        return;
      }
      this.createUser(newUser);
    }
  }

  getField(field: string) {
    const { modifableUser, newUser } = this.state;
    // @ts-ignore
    return modifableUser ? modifableUser[field] ?? '' : newUser[field];
  }

  render() {
    const { closeModal } = this.props;
    const {
      changePassword,
      changeRole,
      role: { value: role } = {},
      role: roleOption,
      error,
      newPassword,
    } = this.state;

    return (
      <div
        className="change-user"
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Card.Header
          title={this.getTitle}
          controls={
            <Button
              variant="phantom"
              className="close"
              onClick={() => closeModal()}
            >
              <ICONS.Cross />
            </Button>
          }
        />

        <div
          className={`content custom-scroll ${this.editMode ? 'edit' : 'add'}`}
        >
          {!(role === 'RESTAURANT' && this.editMode)
            && (changeRole ? (
              <div className="titled-block role">
                <h3 className="required">
                  {this.props.intlUtils.intl.formatMessage({
                    id: ETranslations.ROLE,
                  })}
                </h3>
                <SelectBasic
                  isSearchable={false}
                  isTagsOrSource
                  placeholder={this.props.intlUtils.getIntlChooseEntity(
                    ETranslations.ROLE
                  )}
                  changedMaxSize
                  // @ts-ignore
                  options={optionsAppointRoles.map((option) => ({
                    ...option,
                    label: this.props.intlUtils.intl.formatMessage({
                      id: option.label,
                    }),
                  }))}
                  // @ts-ignore
                  value={{
                    ...roleOption,
                    label: this.props.intlUtils.intl.formatMessage({
                      id: roleOption?.label || optionsAppointRoles[0].label,
                    }),
                  }}
                  // @ts-ignore
                  onChange={(e: SelectModel) => this.changeRole(e)}
                />
              </div>
            ) : (
              <div
                className="clickable"
                onClick={() =>
                  this.setState((prevState) => ({
                    changeRole: !prevState.changeRole,
                  }))
                }
              >
                <p>
                  {this.props.intlUtils.getIntlEntityChanging(
                    ETranslations.ROLE
                  )}
                </p>
              </div>
            ))}
          {!(role === 'RESTAURANT' && this.editMode) && (
            <div className="hr before-name" />
          )}
          <div
            className={`name-phone full-name ${
              role !== 'ADMINISTRATOR' ? 'phone' : ''
            }`}
          >
            <Input
              required
              label={this.props.intlUtils.intl.formatMessage({
                id:
                  role === 'RESTAURANT'
                    ? ETranslations.RESTAURANT_NAME
                    : ETranslations.FULL_NAME,
              })}
              className="name"
              value={this.getField('name')}
              onChange={(e) => this.handleChangeUser('name', e.target.value)}
            />
            <Input
              label={this.props.intlUtils.intl.formatMessage({
                id:
                  role === 'RESTAURANT'
                    ? ETranslations.PHONE
                    : ETranslations.ADDITIONAL_PHONE,
              })}
              className="phone"
              value={this.getField('phone')}
              onChange={(e) =>
                this.handleChangeUserPhone(
                  e.target.value,
                  role === 'RESTAURANT'
                )
              }
            />
          </div>
          {role !== 'ADMINISTRATOR' && role !== 'RESTAURANT' && (
            <div className="titled-block rest">
              <h3 className="required">
                {this.props.intlUtils.intl.formatMessage({
                  id: ETranslations.PLURAL_RESTAURANT,
                })}
              </h3>
              <SelectBasic
                isSearchable
                isTagsOrSource
                options={predata.rests}
                value={predata.restsMapper[this.getField('restaurant_id')]}
                onChange={(e: any) =>
                  this.handleChangeUser('restaurant_id', e.value)
                }
              />
            </div>
          )}
          <div className="hr" />
          <div className="login-block">
            <Input
              label={this.props.intlUtils.intl.formatMessage({
                id: ETranslations.AUTH_LOGIN,
              })}
              required
              className="login"
              disabled={this.editMode}
              value={this.getField('login')}
              onChange={(e) => this.handleChangeLogin(e.target.value)}
            />
          </div>
          <div className={`error-block ${error && 'active'}`}>
            {error?.message}
          </div>
          {!this.editMode && (
            <div className="login-block">
              <Input
                label={this.props.intlUtils.intl.formatMessage({
                  id: ETranslations.AUTH_PASSWORD,
                })}
                required
                className="password"
                autoComplete="new-password"
                type="password"
                value={newPassword ?? ''}
                onChange={(e) => this.setState({ newPassword: e.target.value })}
              />
            </div>
          )}
          {changePassword && this.state.modifableUser?.id ? (
            <NewPasswordForm
              userId={this.state.modifableUser.id}
              onClose={() =>
                this.setState((prevState) => ({
                  changePassword: !prevState.changePassword,
                }))
              }
            />
          ) : (
            this.editMode && (
              <div
                className="clickable"
                onClick={() =>
                  this.setState((prevState) => ({
                    changePassword: !prevState.changePassword,
                  }))
                }
              >
                <p>
                  {capitalizeString(
                    this.props.intlUtils.intl.formatMessage(
                      { id: ETranslations.BASE_ENTITY_CHANGING },
                      {
                        entity: this.props.intlUtils.intl
                          .formatMessage({ id: ETranslations.AUTH_PASSWORD })
                          .toLowerCase(),
                      }
                    )
                  )}
                </p>
              </div>
            )
          )}
        </div>
        <div className="form-controls">
          <Button
            variant="primary"
            onClick={() => this.onSave()}
            disabled={!this.isValid || changePassword}
          >
            {this.props.intlUtils.intl.formatMessage({
              id: ETranslations.BASE_SAVE,
            })}
          </Button>
        </div>
      </div>
    );
  }
}
