import {ValueType} from "react-select";
import {TagServer} from "./booking.model";

export type Param = string | boolean | number[] | string[] | {id: number, source_name: string} | TagServer[];

export interface ChangesParams {
  field: string;
  new: Param;
  old: Param;
}

export class SelectModel {
  constructor(
    public value: string | number,
    public label: string
  ) { }
}

export class TableSchema {
  constructor(
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public shape: 'square' | 'round'
  ) { }
}

/**
 * @deprecated используй простой объект
 */
export class Table {
  public id: number;
  constructor(
    public table_id: number,
    public number: number | string,
    public type: number,
    public schema?: TableSchema
  ) {this.id = table_id;}
}


/**
 * @deprecated Нужно перейти на простые объекты и Option ниже
 */
export class SingleOption {
  constructor(
    public value: number,
    public label: string,
    public type?: number,
  ) {}

}

export interface Option<T = unknown> {
  value: T,
  label: string;
}

export type SelectOption = ValueType<{value: number, label: string}, false>;
export type ViewReg = 'reg' | 'create' | 'confirm' | 'select';
export type ViewGuest = 'view' | 'edit' | 'create';
export type PositionModal = 'root' | 'guest-card';
export interface ResponseWithData<T> {
  data: T
}

export interface IResponse<T> extends ResponseWithData<T> {
  error: { message: string } | null,
  status: 'OK' | 'ERROR',
}

export class Response<T> implements IResponse<T> {
  constructor(
    public data: T,
    public error: {message: string} | null,
    public status: 'OK' | 'ERROR'
  ) {}
}
