import { FunctionComponent } from 'react';
import cn from 'classnames';

import styles from './Tag.module.scss';

interface Props {
  tag: { name: string; color?: string; status?: string | undefined };
  className?: string
}

export const Tag: FunctionComponent<Props> = ({ tag, className }) => (
  <div
    className={cn(styles.tag, tag.status && styles.status, className)}
    style={{
      backgroundColor: tag.status ? tag.status : tag.color,
    }}
  >
    {tag.name}
  </div>
);
