import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { identity } from 'lodash';
import { useUnmount } from 'react-use';
import { select, zoom, zoomIdentity } from 'd3';
import cn from 'classnames';

import {
  HallSlotsQResponse,
  usePrepareShiftsSchedule,
  useSlots,
} from 'features/api/hallschema-api';
import { ICONS, Spinner, Button } from 'ui-kit';

import {
  MAX_ZOOM_RATIO,
  MIN_ZOOM_RATIO,
  useHallSchemaActions,
} from 'features/HallSchema';
import { ICON_SIZE, Table } from '../../../Table';
import { HallLegend } from './hall-legend';
import { HallMoveStatusControls } from './HallMoveStatusControls';

import { HallControls } from './HallControls';

import styles from './Hall.module.scss';
import { TablePath } from '../../../Table/TablePath';
import { ShiftsTimeline } from '../ShiftsTimeline/ShiftsTimeline';
import { isManagerialTable } from 'utils';
import { config } from 'config';
import { NewScheme } from './NewScheme';

export const SCHEME_FACTOR = 40;

type HallSchemaViewProps = {
  hallInfo?: HallSlotsQResponse[];
  onBookingListClick?: () => void;
};

const HallSchemaView: React.FC<HallSchemaViewProps> = ({
  hallInfo,
  onBookingListClick,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const svgGRef = useRef<SVGGElement | null>(null);
  const cornersTables = useMemo(():
    | [[number, number], [number, number]]
    | undefined => {
    if (hallInfo) {
      const GAP = ICON_SIZE * 3;
      const tables = hallInfo.map((it) => it.table);
      const maxX: number = Math.max(
        ...tables.map((s) => s.schema.x + s.schema.width)
      );
      const maxY: number = Math.max(
        ...tables.map((s) => s.schema.y + s.schema.height)
      );

      const startX: number = Math.min(...tables.map((s) => s.schema.x));
      const startY: number = Math.min(...tables.map((s) => s.schema.y));

      const width = maxX - startX;
      const height = maxY - startY;
      return [
        [startX * SCHEME_FACTOR - GAP, startY * SCHEME_FACTOR - GAP],
        [width * SCHEME_FACTOR + GAP * 2, height * SCHEME_FACTOR + GAP * 2],
      ];
    }
    return undefined;
  }, [hallInfo]);

  const zoomBehavior = useMemo(() => {
    if (!cornersTables) return undefined;
    return zoom<SVGSVGElement, unknown>()
      .scaleExtent([MIN_ZOOM_RATIO, MAX_ZOOM_RATIO])
      .extent(cornersTables)
      .filter(function checkEvent(event: MouseEvent) {
        return event.target === this;
      })
      .on('zoom', ({ transform }) => {
        const { current: group } = svgGRef;
        if (!group) return;
        select(group).attr('transform', transform);
      });
  }, [cornersTables]);

  const getSvgSelection = useCallback(() => {
    const { current: svg } = svgRef;
    if (!svg) return null;
    return select(svg);
  }, []);

  const { closeModalAction } = useHallSchemaActions();

  const renderTables = () => {
    if (hallInfo) {
      return hallInfo.map((hallSlot) => (
        <Table key={hallSlot.table.table_id} {...hallSlot} />
      ));
    }
    return null;
  };

  const renderTablesPath = () => {
    //Нужно найти уникальные бронирования
    const bookings = hallInfo?.reduce(
      (result, { slots }) => (
        //Проходимся про всем слотам
        slots.forEach(
          (slot) =>
            slot.booking
            //Если в слоте есть бронирование, которое мы еще не видели,
            //то есть информация по которому отсутствует в Set
            && !result.idsSet.has(
              Number(slot.booking.seatType === 'MANAGEMENT'
                ? slot.booking.notes?.batch_id
                : slot.booking.bookingId)
            )
            //То на основе этого бронирования создаем компонент TablePath и кладем его в массив результатов
            && result.tablePaths.push(
              <TablePath
                key={slot.booking.bookingId || slot.booking?.notes?.batch_id}
                slots={[slot]}
              />
            )
            && result.idsSet.add(
              Number(slot.booking.seatType === 'MANAGEMENT'
                ? slot.booking.notes?.management_table_id
                : slot.booking.bookingId)
            )
        ),
        // На каждой итерации слотов возвращаем результаты для метода reduce
        result
      ),

      //Начальный объект для метода reduce
      {
        tablePaths: new Array<JSX.Element>(),
        idsSet: new Set<number>(),
      }
    );
    return bookings ? <>{bookings.tablePaths}</> : null;
  };

  useUnmount(closeModalAction);

  useLayoutEffect(() => {
    if (!zoomBehavior) return;
    getSvgSelection()?.call(zoomBehavior);
  }, []);

  const zoomIn = useCallback(() => {
    const selection = getSvgSelection();
    if (!selection) return;
    zoomBehavior?.scaleBy(selection, 1.3);
  }, []);

  const resetZoom = useCallback(() => {
    const selection = getSvgSelection();
    if (!selection) return;
    if (!zoomBehavior) return;
    selection.call(zoomBehavior.transform, zoomIdentity);
  }, []);

  const zoomOut = useCallback(() => {
    const selection = getSvgSelection();
    if (!selection) return;
    zoomBehavior?.scaleBy(selection, 1 / 1.3);
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.schemeContainer}>
        <svg
          ref={svgRef}
          className={cn(styles.hall, 'general-svg')}
          viewBox={cornersTables?.flatMap(identity).join(' ')}
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
        >
          <defs>
            <symbol
              id="myDot"
              width="40"
              height="40"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5 12C18 12 19 11 19 9.5C19 8 18 7 16.5 7C15 7 14 8 14 9.5C14 11 15 12 16.5 12ZM9 11C10.66
                      11 12 9.66 12 8C12 6.34 10.66 5 9 5C7.34 5 6 6.34 6 8C6 9.66 7.34 11 9 11ZM16.5 14C14.67 14 11
                      15 11 16.75V19H22V16.75C22 15 18.33 14 16.5 14ZM9 13C6.67 13 2 14.17 2 16.5V19H9V16.75C9 15.9
                      9.33 14.41 11.37 13.28C10.5 13.1 9.66 13 9 13Z"
                fill="var(--ico-color)"
              />
            </symbol>
          </defs>
          <g className="tables" ref={svgGRef}>
            {renderTablesPath()}
            {renderTables()}
          </g>
        </svg>
        <div className={styles.controls}>
          <HallLegend />
          <HallControls
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            resetZoom={resetZoom}
          />
        </div>
        {onBookingListClick && (
          <Button
            square
            variant="secondary"
            className={styles.bookingListButton}
            onClick={onBookingListClick}
          >
            <ICONS.Book />
          </Button>
        )}
      </div>
      <HallMoveStatusControls />
      <ShiftsTimeline />
    </div>
  );
};

// new schema component for redux
export const HallSchema = ({
  onBookingListClick,
}: {
  onBookingListClick?: () => void;
}) => {
  // request to build timeline

  usePrepareShiftsSchedule();
  const { data: tableSlotsData, isLoading: slotsLoading } = useSlots();
  const isAllLoaded = [!slotsLoading].every(identity);

  if (!isAllLoaded) return <Spinner />;

  return config.HSE_ENABLED ? (
    <NewScheme
      onBookingListClick={onBookingListClick}
      hallInfo={tableSlotsData || []}
    />
  ) : (
    <HallSchemaView
      hallInfo={tableSlotsData}
      onBookingListClick={onBookingListClick}
    />
  );
};
