import React from "react";
import cn from "classnames";
import {Card, Spinner} from "ui-kit";
import {Booking} from "../../types/booking";
import {BookingOrdersList} from "./BookingOrdersList";
import styles from "../Dashboard/Dashboard.module.scss";
import {HideWhen} from "../../components/HideWhen";
import BookingFormReduxProxy from "../../components/registration/forms/BookingFormReduxProxy";
import {CreateFromOrder} from "../../components/registration/forms/create-from-order";
import {HallSchema} from '../../components/hall-scheme/redux/HallSchemaV2/hall-schema';
import {SelectGuestFromList} from '../../features/GuestsList/components/select-guest-from-list';
import {BookingCard} from "components/BookingCard";
import {CloseBookingContext} from "contexts/CloseBookingContext";
import {ETranslations} from "../../types/translates";
import {HallMode} from '../../features/HallSchema';
import useBookingOrders from "./hooks/useBookingOrders";
import useEditOrder from "./hooks/useEditOrder";

const EditOrder = ({orderSelected}: { orderSelected: unknown }) => {
  const {update} = useEditOrder()
  

  return (

    <div className={styles.edit}>
      <div className={cn(styles.editCard)}>
        <BookingCard booking={orderSelected as Booking} compact className={styles.bookingCard} />
      </div>
      <BookingFormReduxProxy
        component={CreateFromOrder}
        submitFn={update}
      />
    </div>

  );
};

export const BookingOrders: React.FC<any> = () => {

  const {
    intl,
    isTablet,
    hasOrder,
    onSelectOrder,
    handleClose,
    mode,
    orderSelected,
    getIntlEntityViewingAction,
    closeGuests,
    handleSetClient,
    closeHall,
  } = useBookingOrders();

  return (
    <>
      <HideWhen condition={isTablet && hasOrder}>
        <React.Suspense fallback={<Spinner />}>
          <BookingOrdersList onSelectOrder={onSelectOrder} />
        </React.Suspense>
      </HideWhen>
      <CloseBookingContext.Provider value={handleClose}>
        <HideWhen
          condition={
            isTablet
            && !hasOrder || mode.includes('HALL')
            || [HallMode.BOOKING_GUEST, HallMode.TABLE_BOOKINGS_EDIT_GUEST].includes(mode)
          }
          noUnmount
        >
          <Card onClose={handleClose} >
            <Card.Header
              title="Подтверждение"
            />
            <Card.Content noPadding>
              {hasOrder ? <EditOrder orderSelected={orderSelected} /> : (
                <div className={styles.emptyContainer}>
                  <div className={styles.icon} />
                  <p>{getIntlEntityViewingAction(ETranslations.OF_REQUEST, ETranslations.PLURAL_REQUESTS_THEY)}</p>
                </div>
              )}
            </Card.Content>
          </Card>
        </HideWhen>
        <HideWhen condition={![HallMode.BOOKING_GUEST, HallMode.TABLE_BOOKINGS_EDIT_GUEST].includes(mode)}>
          <SelectGuestFromList
            onClose={closeGuests}
            onSelectGuest={handleSetClient}
          />
        </HideWhen>
      </CloseBookingContext.Provider>
      <HideWhen condition={!mode.includes('HALL')}>
        <Card onClose={closeHall}>
          <Card.Header title={intl.formatMessage({id: ETranslations.HALL_SCHEME})} />
          <Card.Content>
            <HallSchema />
          </Card.Content>
        </Card>
      </HideWhen>
    </>
  );
};
