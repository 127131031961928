import {skipToken, SkipToken} from '@reduxjs/toolkit/dist/query';
import {useGetScheduleTimeByRestQuery} from 'features/api/schedule-api';
import {appContextSelectors} from 'features/AppContex';
import moment from 'moment';
import {useSelector} from 'react-redux';

export function fillTimeIntervals(startTimeInterval: string, endTimeInterval: string, interval: number) {
  const result = [];
  const [startHour, startMinute] = startTimeInterval.split(':');
  const [endHour, endMinute] = endTimeInterval.split(':');
  const nextDay = endHour < startHour;
  const current = moment(startTimeInterval, 'HH:mm').minute(+startMinute);
  const end = moment(endTimeInterval, 'HH:mm').minute(+endMinute);
  if (nextDay) end.add(1, 'd');
  do {
    const itemLabel = current.format('HH:mm');
    result.push({value: current.unix(), label: itemLabel});
    current.add(interval, 'm');
  } while (end.isSameOrAfter(current));
  return result;
}

export function useWorkingTimeOptions(interval: number | SkipToken = 30) {
  const {restaurant_id} = useSelector(appContextSelectors.restaurant);
  const {data} = useGetScheduleTimeByRestQuery(skipToken === interval ? skipToken : restaurant_id);

  if (!data || interval === skipToken) return [];

  return fillTimeIntervals(data.minTime, data.maxTime, interval);
}
