import {useLocalStorage} from "react-use";
import {ELocales} from "../../types/commons";
import {config} from "../../config";
import {useEffect} from "react";
import dayjs from "dayjs";
import ru from 'dayjs/locale/ru'
import en from 'dayjs/locale/en'

const DAYJS_LOCALE_DISPATCH = {
  [ELocales.ru_RU]: ru,
  [ELocales.en_EN]: en,
}

export const useDayjsLocale = () => {
  const [userLocale] = useLocalStorage<ELocales>("locale", config.defaultLocale);

  useEffect(() => {
    const locale = (userLocale || 'en_EN')
    dayjs.locale(DAYJS_LOCALE_DISPATCH[locale])
  }, [userLocale])
}
