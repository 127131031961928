import React, {useEffect} from 'react'
import {FieldRenderProps, useField} from 'react-final-form'
import {DatePicker} from 'ui-kit'

const DataField: React.FC<FieldRenderProps<string, any>> = ({input, meta, currentFieldName, ...rest}) => {
    const {value, onChange} = input

    const {
        input: {value: infininte}
    } = useField<Array<string | Date>>(currentFieldName ? `${currentFieldName}infininte` : "infininte");

    useEffect(() => {
        if (infininte && input.name.includes("end_date")) {
            onChange('')
        }
    }, [infininte]);

    return (
        <DatePicker
            selected={value as unknown as Date}
            invalid={!value}
            {...rest}
            {...meta}
            {...input}
            onChange={onChange}
        />
    )
}

export default DataField;