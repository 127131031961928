import {ComponentType, PropsWithChildren} from 'react';
import cn from 'classnames';

import {Button, ButtonProps} from 'ui-kit';

import styles from './Menu.module.scss';

type ItemProps = {
  className?: string,
  icon?: ComponentType
  disabled?: boolean;
} & Pick<ButtonProps, 'onClick'>

function Item({children, className, onClick, icon: Icon, disabled}: PropsWithChildren<ItemProps>) {
  return (
    <li className={cn(styles.item, className)}>
      <Button variant="phantom" className={styles.button} onClick={onClick} disabled={disabled}>
        {Icon && (
          <span className={styles.icon}>
            <Icon />
          </span>
        )}
        {children}
      </Button>
    </li>
  );
}

export const Menu = Object.assign(({children, className}: PropsWithChildren<{className?: string }>) => (
    <ol className={cn(styles.menu, className)}>
      {children}
    </ol>
  ), {
  Item,
});
