import {useCallback, useState} from "react";
import {useIntl} from "react-intl";
import {useHistory} from "react-router";
import {HallMode, useHallSchemaActions} from "features/HallSchema";
import {ETranslations} from "types/translates";
import {useCreateManagerialTableMutation} from "features/api/managerialTables";
import {Notification} from "services/notification";
import {useIntlUtils} from "hooks/useIntlUtils";
import {prepareData} from "../helpers/prepareData";


const useCreateManageralBooking = (reset:any) => {
    const initialValues = prepareData();
    const {getIntlActionFailed, getIntlJoinedParts} = useIntlUtils();
    const [manageralError, setManageralErrors] = useState([]);
    const {switchMode} = useHallSchemaActions();
    const history = useHistory();
    const intl = useIntl();
    const [createManagerialBooking] = useCreateManagerialTableMutation();



    const close = useCallback(() => {
        const {from} = (history.location.state as { from?: string }) || {};
        if (from !== history.location.pathname) {
            history.push(from ?? '/dashboard');
        } else {
            reset();
            switchMode(HallMode.BOOKING_GUEST);
        }
    }, [history]);

    const onClose = () => {
        setManageralErrors([])
    }

    const onSubmit = useCallback(async (values) => {
        const {
            tables,
            place_id,
            time: {label},
            ...rest
        } = values;
        const payload = {
            ...rest,
            tables: [
                {
                    place_id,
                    table_ids: tables,
                },
            ],
            start_time: `${label}:00`,
        };

        await createManagerialBooking({
            ...payload,
        })
            .then((res) => {
                // @ts-ignore
                if (res.data.fail_messages.length && !!res.data.fail_count) {
                    // @ts-ignore
                    setManageralErrors(() => [...res.data.fail_messages]);
                } else {
                    close();
                }
            })
            .catch(() => {
                Notification.error({
                  title: getIntlActionFailed(
                    getIntlJoinedParts([
                      ETranslations.BASE_CREATE,
                      ETranslations.PLURAL_BOOKING,
                    ]).toLowerCase()
                  ),
                });
            });
    }, []);


    return {
        initialValues,
        intl,
        onSubmit,
        onClose,
        close,
        manageralError
    }
}

export default useCreateManageralBooking