import React, { CSSProperties } from 'react';
import { config } from 'config';
import cn from 'classnames';
import { Card, RadioButton } from 'ui-kit';
import { BookingsList } from 'components/BookingsList';
import { RestaurantBookingStats } from 'components/RestaurantBookingStats';
import Checkbox from 'common/components/checkbox';
import { ETranslations } from 'types/translates';
import { SortedBookingsList } from 'components/SortedBookingsList';
import UseDashboardBookings from './UseDashboardBookings';
import styles from './DashboardBookings.module.scss';
import { BookingFilters } from './BookingFilters';
import { BookingListCollapse } from './BookingListCollapse';
import { transformLocale } from 'utils';
import type { ShortLocales } from 'types/commons';

interface BookingProps {
  style?: CSSProperties;
}

export const DashboardBookings: React.FC<BookingProps> = ({ style }) => {
  const {
    intl,
    bookings,
    statistics,
    isManagerableTableSelected,
    setIsManagerableTableSelected,
    bookingMode,
    setBookingMode,
    isLoading,
    extraStatusFilter,
    searchQuery,
    includedStatuses,
    waitList,
    setBookingsData,
  } = UseDashboardBookings();

  return (
    <Card id="booking" style={style}>
      <Card.Header
        title={
          <RestaurantBookingStats
            title={intl.formatMessage({ id: ETranslations.BASE_BOOKINGS })}
            stats={statistics}
          />
        }
        controls={
          <div className={styles.checkboxContainer}>
            <Checkbox
              text={intl.formatMessage({
                id: ETranslations.MANAGEMENT_RESERVATION,
              })}
              value={isManagerableTableSelected}
              onChange={setIsManagerableTableSelected}
            />
            <RadioButton value={bookingMode} onChange={setBookingMode}>
              <RadioButton.Button value="active">
                {intl.formatMessage({ id: ETranslations.BASE_ACTIVE })}
              </RadioButton.Button>
              <RadioButton.Button value="complete">
                {intl.formatMessage({ id: ETranslations.BASE_HISTORY })}
              </RadioButton.Button>
            </RadioButton>
          </div>
        }
      />
      <Card.Content noPadding>
        <BookingFilters
          bookingMode={bookingMode}
          locale={transformLocale(intl.locale as ShortLocales)}
          styles={styles}
          formatMessage={intl.formatMessage}
          isManagerableTableSelected={isManagerableTableSelected}
          setBookingsData={setBookingsData}
        />
        <BookingListCollapse
          title={intl.formatMessage({ id: ETranslations.WAITING_LIST })}
          count={waitList.reduce((sum, booking) => sum + booking.persons, 0)}
          bookingMode={bookingMode}
          bookingList={
            config.sortStatusInDashboard ? (
              <SortedBookingsList
                searchQuery={searchQuery}
                canDrag={false}
                className={styles.bookingsList}
                showAllNodes={false}
                includedStatuses={includedStatuses}
                extraStatusFilter={extraStatusFilter}
              />
            ) : (
              <BookingsList
                className={cn('opened')}
                canDrag={false}
                bookings={bookings}
                loading={isLoading}
                withActions
                compact
              />
            )
          }
        >
          <BookingsList bookings={waitList} loading={isLoading} compact />
        </BookingListCollapse>
      </Card.Content>
    </Card>
  );
};
