import React, { FC, useMemo } from 'react';
import { HallSlotsQResponse } from '../../features/api/hallschema-api';
import { useSliderDatetime } from '../../hooks/useSliderDatetime';
import {
  getCenterCoords,
  getSlotExtraOptions,
  selectSlotByShift,
  sumOfSquares,
} from './utils';
import cn from 'classnames';
import { SCHEME_FACTOR } from '../hall-scheme/redux/HallSchemaV2/hall-schema';

import styles from './tablePath.module.scss';
import { useSelector } from 'react-redux';
import { timelineSelectors } from 'features/Timeline';
import { useStatusColor } from 'components/HallEditor/BookingTable/useStatusColor';

type TCenterCoords = {
  start: {
    x: number;
    y: number;
  };
  end: {
    x: number;
    y: number;
  };
};

export const TablePath: FC<Pick<HallSlotsQResponse, 'slots'>> = ({ slots }) => {
  const timeWithDate = useSliderDatetime();
  const isActualTime = useSelector(timelineSelectors.getIsActualTime);
  const slot = selectSlotByShift(slots, timeWithDate());
  const { tableColor, slot: { booking } = {} } = getSlotExtraOptions(
    slot,
    timeWithDate()
  );
  const statusColor = useStatusColor(booking, isActualTime, tableColor);
  const isShow = booking && booking.places.length > 1;

  const getPath = useMemo(() => {
    if (booking && booking.places.length > 1) {
      const sortArray = [...booking.places].sort((a, b) => {
        const sumA = sumOfSquares(a.schemaTable.x, a.schemaTable.y);
        const sumB = sumOfSquares(b.schemaTable.x, b.schemaTable.y);
        return sumA - sumB;
      });
      // Собираем массив путей
      return sortArray.reduce((acc: TCenterCoords[], val, i, arr) => {
        const start = getCenterCoords(val, SCHEME_FACTOR);
        const end = getCenterCoords(arr[i + 1], SCHEME_FACTOR);
        if (start && end) {
          acc.push({ start, end });
        }
        return acc;
      }, []);
    }
    return null;
  }, [slots]);

  if (!isShow || !getPath) return null;

  return (
    <>
      {getPath.map((path) => (
        <path
          stroke={statusColor}
          key={`${path.start.x}-${path.end.y}`}
          d={`M ${path.start.x} ${path.start.y} L ${path.end.x} ${path.end.y} Z`}
          strokeWidth={20}
          className={cn(styles.tablePath)}
        />
      ))}
    </>
  );
};
