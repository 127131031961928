import { Spinner } from 'ui-kit';
import { transformStatuses, FormattedStatuses, sortStatuses, } from './transform-data';
import { StatusCollapse } from './status-collapse';
import { useAllStatuses, useEditStatusMutation } from 'features/api/dictionaries-api';
import type { StatusId, Status } from 'types/status';

const renderCollapses = (
  statuses: Status[],
  partialModalStatus: (
    statusId: StatusId,
  ) => void,
   editStatus: ReturnType<typeof useEditStatusMutation>[0], 
   isEditing: boolean,
) => {
  const formattedStatuses = transformStatuses(statuses);
  return <section className='collapses'>{Object.keys(formattedStatuses).map((categoryName) => (
    <StatusCollapse
      key={categoryName}
      categoryName={categoryName}
      formattedStatuses={sortStatuses(formattedStatuses[categoryName as keyof FormattedStatuses])}
      partialModalStatus={partialModalStatus}
      editStatus={editStatus}
      isEditing={isEditing}
    />
  ))}</section>
};

export const StatusManagement = ({
  onCallModalStatus,
}: {
  onCallModalStatus: (
    allStatuses: Status[],
    statusId: StatusId,
    editStatus: ReturnType<typeof useEditStatusMutation>[0],
    isLoading: boolean
  ) => void;
}) => {
  const { data, isLoading } = useAllStatuses();
  const [editStatus, { isLoading: isEditing }] = useEditStatusMutation();
  const partialModalStatus = (statusId: StatusId) => onCallModalStatus(data, statusId, editStatus, isEditing)
  return isLoading ? (
    <Spinner />
  ) : (
    renderCollapses(data, partialModalStatus, editStatus, isEditing)
  );
};
