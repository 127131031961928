import { TTableSlots } from '../../hall-scheme/redux/HallSchemaV2/NewScheme/utils';
import { useMoveBookingActions } from 'features/MoveBooking';

import { isCanDrag } from './utils';
import { getBookingStartTime } from '../../../utils';
import { clientShortName } from 'common/helpers';
import type { BookingTable } from 'types/table';
import { useDrag, useDrop } from 'react-dnd';
import { EDraggableEntity } from '../../../constants';
import type { Booking } from 'types/booking';
import { useTimelineActions } from 'features/Timeline';
import type { SlotEvent } from 'features/api/hallschema-api';

export type TBookingTableTemplateProps = {
  onClick?: () => void;
  tableSlots: TTableSlots[number];
  table: BookingTable;
};

export const useDragNDropTables = (
  slot: SlotEvent | undefined,
  tableId: BookingTable['tableId'],
  tableNumber: BookingTable['tableNumber'],
  figure: BookingTable['figure']
) => {
  const { setTime } = useTimelineActions();
  const {
    selectMoveSource,
    selectSourceTableNumber,
    addTargetTables,
  } = useMoveBookingActions();
  const fullName = clientShortName(
    slot?.booking?.contact || slot?.booking?.client
  );

  const handleMoveClick = (draggedBooking: Booking) => {
    const bookingTableNumber = draggedBooking.places.map(
      (place) => place.number
    );
    setTime(getBookingStartTime(draggedBooking));
    draggedBooking.bookingId && selectMoveSource(draggedBooking.bookingId);
    selectSourceTableNumber(bookingTableNumber);
    const payload = {
      tableId: tableId,
      bookingId: draggedBooking?.bookingId,
      tableNumber: tableNumber.toString(),
    };
    addTargetTables(payload);
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [EDraggableEntity.BOOKING_TABLE, EDraggableEntity.BOOKING_CARD],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        booking: monitor.getItem<Booking>(),
        canDrop: true,
      }),
      drop: handleMoveClick,
      canDrop: (booking) => booking.bookingId !== slot?.booking.bookingId,
    }),
    [tableId, slot]
  );

  const [isDragging, drag, dragPreview] = useDrag(
    () => ({
      type: EDraggableEntity.BOOKING_TABLE,
      canDrag: () => isCanDrag(slot?.booking, tableId),
      item: slot?.booking,
      previewOptions: {captureDraggingState: true},
      collect: (monitor) => monitor.isDragging()
    }),
    [fullName, slot]
  );
  return {
    isOver,
    drag,
    dragPreview,
    drop,
    isDragging,
  };
};
