import {ReactNode} from 'react';

import styles from './TabAddon.module.scss';

export interface TabAddonProps{
  children: ReactNode;
}

export function TabAddon({children}: TabAddonProps) {
  return <div className={styles.addon}>{children}</div>;
}
