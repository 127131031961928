import React from "react";
import {generateLogin, hasMiddleName, ICONS} from "common/helpers";
import {AppointUser} from "models/user.model";
import {UsersService} from "services/users.service";
import {SettingsHostess} from "models/settings.model";
import {Response} from "models/common";
import {Card, Input} from "ui-kit";
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {ETranslations} from "../../../types/translates";

interface ChangeHostessModalProps {
  settingsHostess?: SettingsHostess;
  closeModal: (isForceUpdate?: boolean) => void;
  intlUtils: ReturnType<typeof useIntlUtils>;
}

interface ChangeHostessModalState {
  hostess?: AppointUser;
  hostessList: AppointUser[];
  error?: {
    message: string;
    numberAttempt: number;
    results: PromiseSettledResult<Response<any>>[];
    names: { [name: string]: string };
  };
}

export default class ChangeHostessModal extends React.Component <ChangeHostessModalProps, ChangeHostessModalState> {
  idForHostess = 999;
  isForceUpdate = true;
  readonly newHostess: AppointUser = {
    name: "",
    phone: "",
    login: "",
    password: "12345",
    photo: null,
    role: "HOSTESS",
    restaurant_id: 0,
  };

  constructor(props: ChangeHostessModalProps) {
    super(props);
    this.state = {
      hostessList: [this.getNewHostess()],
      ...(props.settingsHostess && props.settingsHostess.hostess && {hostess: props.settingsHostess.hostess}),
    };
  }

  getNewHostess() {
    const {settingsHostess: {restId = 0} = {}} = this.props;
    this.idForHostess += 1;
    return {...this.newHostess, id: this.idForHostess, restaurant_id: restId};
  }

  handleChangeHostessName(value: string, number?: number) {
    this.setState((prevState) => this.changeHostess(prevState, "name", value, number));
  }

  handleChangeHostessPhone(value: string, number?: number) {
    this.setState((prevState) => this.changeHostess(prevState, "phone", value, number));
  }

  changeHostessList(action: "add" | "remove", number: number) {
    this.setState((prevState) => {
      let hostessList = [...prevState.hostessList];
      if (action === "remove") {
        hostessList = hostessList.slice(0, number).concat(hostessList.slice(number + 1));
      } else {
        hostessList = hostessList.concat(this.getNewHostess());
      }
      return {...prevState, hostessList};
    });
  }

  /* eslint-disable */
  changeHostess(prevState: { hostess?: AppointUser; hostessList: AppointUser[] }, field: string, value: string, number?: number) {
    let hostessList = [...prevState.hostessList];
    const { hostess }: any = prevState;
    if (typeof number === "number") {
      const foundHostess = prevState.hostessList[number] as any;
      const startArr = hostessList.slice(0, number);
      const endArr = hostessList.slice(number + 1);
      foundHostess[field] = value;
      hostessList = [...startArr, foundHostess, ...endArr];
    } else {
      hostess[field] = value;
    }
    return { hostess, hostessList };
  }

  /* eslint-enable */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  saveHostess({password, ...user}: AppointUser) {
    UsersService.change(user).then(() => this.props.closeModal(this.isForceUpdate));
  }

  saveHostessList(userList: AppointUser[]) {
    const {error} = this.state;
    if (error) {
      this.errorHandling(userList, error.results, error.numberAttempt + 1);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const promiseHostess = userList.map(({id, ...user}) => UsersService.create({
        ...user,
        login: generateLogin(user, 1),
      }));
      Promise.allSettled([...promiseHostess]).then((results) => this.errorHandling(userList, results, 2));
    }
  }

  errorHandling(userList: AppointUser[], results: PromiseSettledResult<Response<any>>[], numberAttempt: number) {
    const filteredError = userList
      .filter((user, idx: number) => {
        const prevResult = results[idx];

        let status, message = "";
        if (prevResult && prevResult.status === "fulfilled" && prevResult.value) {
          status = prevResult.value.status;
          message = prevResult.value.error?.message || "";
        }

        const errorMessage = this.props.intlUtils.intl.formatMessage({id: ETranslations.ERROR_USER_ALREADY_EXIST},
          {login: generateLogin(user, numberAttempt - 1)});
        return prevResult && prevResult.status === "fulfilled" && status === "ERROR" && message === errorMessage;
      })
      .map(({id: _id, ...user}) => ({
        ...user,
        login: generateLogin(user, numberAttempt),
      }));

    if (!filteredError.length) {
      this.props.closeModal(this.isForceUpdate);
      this.setState({error: undefined});
    } else if (filteredError.every((errorUser) => errorUser.login === generateLogin(errorUser, numberAttempt - 1))) {
      if (filteredError.every((errorUser) => !hasMiddleName(errorUser, numberAttempt))) {
        const names: { [name: string]: string } = {};
        filteredError.forEach(({name}) => names[name] = name);
        this.setState({
          error: {
            numberAttempt,
            results,
            names,
            message: this.props.intlUtils.intl.formatMessage({id: ETranslations.ERROR_HOSTESS_MIDDLE_NAME}),
          },
        });
      } else {
        this.errorHandling(userList, results, numberAttempt);
      }
    } else {
      const promiseFilteredError = filteredError.map((errorUser) => UsersService.create(errorUser));
      Promise.allSettled(promiseFilteredError).then((newResults) => this.errorHandling(userList, newResults, numberAttempt + 1));
    }
  }

  get editMode() {
    return !!this.state.hostess;
  }

  get isValid() {
    // required fill name
    if (this.editMode) {
      return !!this.state.hostess?.name;
    }
    return this.state.hostessList.every(({name}) => !!name);
  }

  onSave() {
    const {hostess, hostessList} = this.state;
    if (this.editMode && hostess) {
      this.saveHostess(hostess);
    } else {
      this.saveHostessList(hostessList);
    }
  }

  render() {
    const {closeModal} = this.props;
    const {hostess, hostessList} = this.state;

    const intlTitles = {
      hostessEdit: this.props.intlUtils.getIntlEntityEdition(ETranslations.HOSTESS),
      hostessAdd: this.props.intlUtils.getIntlAddOf(ETranslations.HOSTESS),
      hostessFullName: this.props.intlUtils.intl.formatMessage({id: ETranslations.SEARCH_HEADER_OPTION_HOSTESS_FULL_NAME}),
      additionalPhone: this.props.intlUtils.intl.formatMessage({id: ETranslations.ADDITIONAL_PHONE})
    }

    return (
      <div className="change-hostess" onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        <Card.Header
          title={hostess ? intlTitles.hostessEdit : intlTitles.hostessAdd}
          controls={<img src={ICONS.crossGrey} onClick={() => closeModal()} alt="" />}
        />
        <div className="content custom-scroll">
          <div className={`titled-block full-name ${hostess ? "edit" : "add"}`}>
            {
              this.editMode && hostess ? (
                <div>
                  <Input
                    label={intlTitles.hostessFullName}
                    value={hostess.name}
                    onChange={(e) => this.handleChangeHostessName(e.target.value)}
                  />
                  <Input
                    label={intlTitles.additionalPhone}
                    type="number"
                    value={hostess.phone || ""}
                    onChange={(e) => this.handleChangeHostessPhone(e.target.value)}
                  />
                </div>
              ) : (
                hostessList.map((hostes, i) => (
                  <React.Fragment key={hostes.id}>
                    <div className="hostess">
                      <Input
                        label={intlTitles.hostessFullName}
                        type="text"
                        value={hostes.name}
                        onChange={(e) => this.handleChangeHostessName(e.target.value, i)}
                      />
                      {/*<Input*/}
                      {/*  label={intlTitles.additionalPhone}*/}
                      {/*  type="number"*/}
                      {/*  value={hostes.phone || ""}*/}
                      {/*  onChange={(e) => this.handleChangeHostessPhone(e.target.value, i)}*/}
                      {/*/>*/}
                      <img
                        src={i === 0 ? ICONS.plus : ICONS.trashBin}
                        onClick={() => this.changeHostessList(i === 0 ? "add" : "remove", i)}
                        alt=""
                        width={24}
                        height={24}
                      />
                    </div>
                    <div className={`error-block ${this.state.error?.names[hostes.name] && "active"}`}>
                      {this.state.error?.message}
                    </div>
                  </React.Fragment>
                ))
              )
            }
          </div>
        </div>
        <div className="form-controls">
          <button
            className="primary" type="button"
            onClick={() => this.onSave()}
            disabled={!this.isValid}
          >
            {this.props.intlUtils.intl.formatMessage({id: ETranslations.BASE_ADD})}
            {!this.isValid ?
              <div className="tooltip">
                {this.props.intlUtils.intl.formatMessage({id: ETranslations.ERROR_HOSTESS_FULL_NAME_MUST_BE_EXIST})}
              </div> : ""}
          </button>
        </div>
      </div>
    );
  }
}
