import {ComponentType, ReactNode} from 'react';

export interface TabItemProps<T> {
  title: ReactNode;
  tabName: T
  children?: ReactNode;
  disabled?: boolean;
  icon?: ComponentType;
}

export function TabItem<T>({children}: TabItemProps<T>) {
  return <>{ children }</>;
}
