import { config } from 'config';
import { useAllStatuses } from 'features/api/dictionaries-api';
import type { Booking, BookingOrder, ManagerialTable } from 'types/booking';
import type { Status } from 'types/status';
import { getStatusColor } from './utils';
import { SpecialStatusColors } from 'constants/colors';

export const useStatusColor = (
  booking: Booking | ManagerialTable | BookingOrder | undefined,
  isActualTime: boolean,
  tableColor: string
) => {
  const { data } = useAllStatuses();
  const baseStatusColors = data.reduce(
    (result, status) => (
      !status.is_extra && (result[status.system_name] = status.color), result
    ),
    SpecialStatusColors as Record<Status['system_name'], string> &
      typeof SpecialStatusColors
  );
  config.grayConfirmedStatusColor
    && (baseStatusColors.CONFIRMED = baseStatusColors.NEW);
    
  return booking?.seatType === 'MANAGEMENT'
    ? baseStatusColors.MANAGEMENT
    : booking?.status
    ? getStatusColor(
        booking as Booking,
        baseStatusColors,
        isActualTime,
        tableColor
      )
    : baseStatusColors.EMPTY;
};
