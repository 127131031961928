import { useCallback } from 'react';
import { Notification } from 'services/notification';
import { coreApi } from './core';
import type { ErrorResponse } from 'types/commons';

const printBookingApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    printBookingInfo: build.mutation<void, { bookingId: number | null }>({
      query: (body) => ({
        url: 'v2/print',
        method: 'post',
        body,
      }),
      async onQueryStarted(_params, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          const message = (e as ErrorResponse).error?.data?.errorMessage;
          message && Notification.error({ title: message });
        }
      },
    }),
  }),
});

const { usePrintBookingInfoMutation } = printBookingApi;

export function usePrintBookingInfo(bookingId: number | null) {
  const [print, ...rest] = usePrintBookingInfoMutation();
  const handlePrint = useCallback(() => print({ bookingId }), [bookingId]);

  return [handlePrint, ...rest] as const;
}
