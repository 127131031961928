import React, {MouseEvent} from "react";
import {ICONS, Labeled} from "ui-kit";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Field, useField} from "react-final-form";
import {InputField} from "./sub-renders/InputField";
import EachGroupFiled from "./sub-renders/EachGroupFiled";
import {appContextSelectors} from "features/AppContex";
import {ETranslations} from "types/translates";
import {requiredField} from "components/registration/validators/validators";
import styles from "../Form.module.scss";


const Settings = ({isCreating}: { isCreating: boolean }) => {
  const {
    input: {value: rules, onChange,},
  } = useField<any[]>("settings.rules",);

  const startDate = useSelector(appContextSelectors.date);

  const handleAddNewRule = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onChange([
      ...rules,
      {
        rule_name: "",
        start_date: new Date(startDate.format("YYYY-MM-DD")),
        end_date: null,
        infininte: false,
        repeat_days: [],
        start_active_time: {value: 0, label: "0"},
        end_active_time: {value: 0, label: "0"},
        shiftId: "",
        places: [],
      },
    ]);
  };

  const intl = useIntl();

  return (
    <div className={styles["setting-step"]}>
      <p className={styles["title"]}>{intl.formatMessage({id: ETranslations.EACH_ACCESS_DESC})}</p>
      <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.NAME_GROUP_OF_RULES})} required as="div">
        <Field
          validate={(value) => requiredField(value, intl.formatMessage({id: ETranslations.ERROR_DATE_NOT_SPECIFIED}))}
          name="settings.nameOfGroup"
          placeholder={intl.formatMessage({id: ETranslations.ENTER_NAME})}
          component={InputField}
        />
      </Labeled>

      <div className={styles.eachGroup}>
        {rules.map((eachRule, index) => {
          return <EachGroupFiled isCreating={isCreating} key={index} eachRule={{...eachRule, index}} />;
        })}

        <button className={styles.createNew} onClick={handleAddNewRule}>
          <ICONS.Plus />
          {intl.formatMessage({id: ETranslations.CREATE_RULE})}
        </button>
      </div>
    </div>
  );
};

export default Settings;
