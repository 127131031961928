export const extraStatusColors = [
  { id: 1, color: '#F86767', extendedPalette: false },
  { id: 2, color: '#FF8A65', extendedPalette: false },
  { id: 3, color: '#FDBBA1', extendedPalette: true },
  { id: 4, color: '#FFA726', extendedPalette: false },
  { id: 5, color: '#FEC535', extendedPalette: true },
  { id: 6, color: '#FFCC80', extendedPalette: false },
  { id: 7, color: '#FFE0B2', extendedPalette: true },
  { id: 8, color: '#DBA786', extendedPalette: true },
  { id: 9, color: '#BCAAA4', extendedPalette: false },
  { id: 10, color: '#D7CCC8', extendedPalette: false },
  { id: 11, color: '#FCE806', extendedPalette: false },
  { id: 12, color: '#FFFA4A', extendedPalette: true },
  { id: 13, color: '#FFF59D', extendedPalette: false },
  { id: 14, color: '#DEDAAD', extendedPalette: true },
  { id: 15, color: '#66BB6A', extendedPalette: false },
  { id: 16, color: '#60C689', extendedPalette: true },
  { id: 17, color: '#9EE189', extendedPalette: true },
  { id: 18, color: '#A5D6A7', extendedPalette: false },
  { id: 19, color: '#C8E6C9', extendedPalette: true },
  { id: 20, color: '#C4D0C6', extendedPalette: false },
  { id: 21, color: '#AFB42B', extendedPalette: false },
  { id: 22, color: '#CDDC39', extendedPalette: true },
  { id: 23, color: '#E5F07E', extendedPalette: false },
  { id: 24, color: '#4DB6AC', extendedPalette: false },
  { id: 25, color: '#72E6F7', extendedPalette: false },
  { id: 26, color: '#9AFFFF', extendedPalette: true },
  { id: 27, color: '#B2DFDB', extendedPalette: true },
  { id: 28, color: '#ABAEC7', extendedPalette: false },
  { id: 29, color: '#AFC4DF', extendedPalette: true },
  { id: 30, color: '#94CBF7', extendedPalette: false },
  { id: 31, color: '#CDE3FF', extendedPalette: true },
  { id: 32, color: '#B39DDB', extendedPalette: false },
  { id: 33, color: '#97A5F8', extendedPalette: true },
  { id: 34, color: '#B5C4FA', extendedPalette: false },
  { id: 35, color: '#D9DEF8', extendedPalette: true },
  { id: 36, color: '#CE93D8', extendedPalette: false },
  { id: 37, color: '#D1C4E9', extendedPalette: true },
  { id: 38, color: '#E1BEE7', extendedPalette: false },
  { id: 39, color: '#F8CCFF', extendedPalette: true },
  { id: 40, color: '#F3E5F5', extendedPalette: true },
  { id: 41, color: '#FA97FC', extendedPalette: false },
  { id: 42, color: '#F18C9B', extendedPalette: true },
  { id: 43, color: '#FAA5A5', extendedPalette: true },
  { id: 44, color: '#F8BBD0', extendedPalette: true },
  { id: 45, color: '#FFCDD2', extendedPalette: false },
  { id: 46, color: '#FFEBEE', extendedPalette: true },
  { id: 47, color: '#BABABA', extendedPalette: true },
  { id: 48, color: '#D3D3D3', extendedPalette: false },
  { id: 49, color: '#E8E6E7', extendedPalette: true },
];

export const SpecialStatusColors = {
  LATE: '#F7D694',
  OVERSTAYED: '#F7896F',
  MANAGEMENT: '#FA97FC',
  EMPTY: 'var(--floorplanTable_empty_background)',
} as const;