import type { ClientNoteDTO } from 'models/notes.model';
import styles from './GuestNotes.module.scss';
import { NoteContent } from './NoteContent';
import { Actions } from './Actions';
import { ICONS } from 'ui-kit';
import { formateDate } from './utils';
import type { ReactNode } from 'react';
import type { IntlShape } from 'react-intl';

const Note = ({
  photo,
  authorName,
  createdAt,
  restaurantName,
  noteText,
  id,
  children,
}: {
  photo: string;
  authorName: string;
  createdAt: string;
  restaurantName: string;
  noteText: string;
  id: number;
  children: ReactNode;
}) => (
  <article className={styles.note}>
    <img
      className={styles.avatar}
      src={photo || ICONS.defaultUser}
      alt="User Avatar"
    />
    <NoteContent
      className={styles.content}
      authorName={authorName}
      createdAt={createdAt}
      restaurantName={restaurantName}
      text={noteText}
      id={id}
    />
    {children}
  </article>
);

export const NoteList = ({
  notes,
  children,
  onEdit,
  onDelete,
  userId,
  formatMessage,
}: {
  notes: ClientNoteDTO[];
  children: ReactNode;
  onEdit: (noteId: number, note: string) => void;
  onDelete: (noteId: number) => void;
  formatMessage: IntlShape['formatMessage'];
  userId?: number;
}) => (
  <section className={styles.main}>
    <div className={styles.notes}>
      {notes.map(
        (note) =>
          note.notes !== 'test—delete' && (
            <Note
              key={`${note.id}-${note.notes.length}`}
              id={note.id}
              photo={note.created_by.photo}
              authorName={note.created_by.name}
              createdAt={formateDate(note.created_at, formatMessage)}
              restaurantName={note.restaurant_name}
              noteText={note.notes}
            >
              {userId === note.created_by.id && (
                <Actions
                  editNote={() => onEdit(note.id, note.notes)}
                  deleteNote={() => onDelete(note.id)}
                  className={styles.actions}
                />
              )}
            </Note>
          )
      )}
    </div>
    {children}
  </section>
);
