import {RequestWrapper} from "./request-wrapper";
import {apiINT} from "./api-data";
import {AuthService} from "./auth.service";
import {TelephonyAction} from "../models/telephony.model";

export class CallsService {
  static async getActiveCalls(restId: number): Promise<{ data: TelephonyAction[] }> {
    return RequestWrapper.fetch(`${apiINT}/calls?restId=${restId}`, {
      method: 'GET',
      headers: {'Content-Type': 'application/json', Authorization: AuthService.getToken()},
    }).then((r) => r.json());
  }
}

