import React, {ReactNode} from 'react';
import cn from 'classnames';

import styles from './Modal.module.scss';

interface ModalContentProps{
  children: ReactNode;
  className?: string;
  noPadding?: boolean;
}

export function ModalContent({children, className, noPadding}: ModalContentProps) {
  return (
    <div className={cn(styles.modalContent, className, {[styles.noPadding]: noPadding})}>
      {children}
    </div>
  );
}
