import dayjs, { Dayjs } from 'dayjs';
import styles from './TimeSelect.module.scss';
import { useMemo } from 'react';
import { HourData } from 'types/shift';
import { Labeled } from 'ui-kit';
import { useIntlUtils } from 'hooks/useIntlUtils';
import { ETranslations } from 'types/translates';
import { Slot } from './Slot';

interface TimeSelectProps {
  allShifts: HourData[];
  inputValue: any;
  currentTime: Dayjs;
  dateValue: any;
  onChange: (value: any) => void;
  disabled: boolean;
  isEditMode: boolean;
  startAndEnd?: any;
  isFromManagement?: boolean;
}

export function TimeSelect({
  allShifts,
  inputValue,
  onChange,
  currentTime,
  dateValue,
  disabled,
  isEditMode,
  isFromManagement,
  startAndEnd,
}: TimeSelectProps) {
  const { intl } = useIntlUtils();
  const formateDateValue = dayjs(dateValue?.value);
  const formatedInputValue = dayjs(inputValue?.value);

  const hoursSlots = useMemo(() => {
    return allShifts;
  }, [inputValue, allShifts, dateValue]);

  const minutesSlots = useMemo(() => {
    const currentHoursSlots = allShifts?.filter((eachShift) => {
      const activeHours = formatedInputValue.isSame(eachShift.value, 'hour');
      return activeHours;
    })[0];

    return currentHoursSlots;
  }, [inputValue, allShifts, dateValue, hoursSlots]);

  return (
    <div className={styles.hoursWrapper}>
      <Labeled
        className={styles.bookingLabel}
        label={intl.formatMessage({ id: ETranslations.PLURAL_HOURS_NOM })}
        required
      >
        <div className={styles.date}>
          {hoursSlots.map(({ label, value, minutes }) => {
            const availableMinutes = minutes.filter(
              ({ value: minutesValue }) => {
                return (
                  currentTime.valueOf() <= minutesValue
                  || currentTime.format('HH:mm')
                    === dayjs(minutesValue).format('HH:mm')
                );
              }
            );

            const isFromManagementCheck = isFromManagement
              ? value > startAndEnd.endTime
                || (value < startAndEnd.startTime && !isEditMode)
              : false;

            const isCurrentHours = formatedInputValue.isSame(value, 'hour');
            const isSelectedHours = formatedInputValue.isSame(value, 'hour');
            const disableButton = isEditMode
              ? false
              : !availableMinutes.length || isFromManagementCheck || disabled;

            const classname = {
              [styles.active]:
                (isCurrentHours && availableMinutes.length) || isSelectedHours,
              [styles.disabled]: disableButton,
              [styles.default]:
                (!!availableMinutes.length && !disabled)
                || (isFromManagement && value <= startAndEnd.endTime)
                || value >= startAndEnd.startTime
                || isEditMode,
            };

            const firstAvailableMinuteSlot = availableMinutes[0]?.label;

            return (
              <Slot
                key={value}
                label={label}
                firstAvailableMinuteSlot={firstAvailableMinuteSlot}
                value={value}
                disableButton={disableButton}
                classname={classname}
                onChange={onChange}
              />
            );
          })}
        </div>
      </Labeled>
      <Labeled
        className={styles.bookingLabel}
        label={intl.formatMessage({ id: ETranslations.PLURAL_MINUTES_NOM })}
        required
      >
        <div className={styles.date}>
          {minutesSlots?.minutes?.map(({ label, value }) => {
            const formatedCurrentElement = dayjs(value);
            const hourOfCurrentMinuteSlot = formatedCurrentElement
              .get('hour')
              .toString()
              .padStart(2, '0');

            const minutesNotPassed
              = !formatedCurrentElement.isBefore(inputValue?.value, 'minute')
              || formatedCurrentElement.isSame(currentTime, 'minute')
              || formateDateValue.isBefore(value);

            const isCurrentMintue = formatedInputValue.isSame(value, 'minute');
            const disabledButton = isEditMode
              ? false
              : !minutesNotPassed || disabled;
            const classname = {
              [styles.active]: isCurrentMintue,
              [styles.default]:
                !disabledButton
                || (isFromManagement && value <= startAndEnd.endTime),
              [styles.disabled]:
                disabledButton
                || (isFromManagement && value >= startAndEnd.endTime),
            };

            return (
              <Slot
                key={value}
                label={label}
                hourOfCurrentMinuteSlot={hourOfCurrentMinuteSlot}
                value={value}
                isMin
                disableButton={disabledButton}
                classname={classname}
                onChange={onChange}
              />
            );
          })}
        </div>
      </Labeled>
    </div>
  );
}

export default TimeSelect;
