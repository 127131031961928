import {HallSlotsQResponse} from 'features/api/hallschema-api';
import {useMemo} from 'react';

export type TTableSlots = Record<string, HallSlotsQResponse['slots']>

export function useTablesSlots(hallSlots: HallSlotsQResponse[]){
    return useMemo(() => {
      return hallSlots.reduce((acc , {slots, table}) => {
          acc[table.table_id] = slots
          return acc
      }, {} as TTableSlots)
    }, [hallSlots])
}
