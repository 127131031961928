import React, {FC, useCallback, useMemo, useState} from 'react';
import styles from '../Form.module.scss';
import {Modal} from '../../../modal';
import {Button, Tabs, Card} from 'ui-kit';
import {ETranslations} from 'types/translates';
import {Form} from 'react-final-form';
import {getDurationTime} from '../../utils';
import {useIntl} from 'react-intl';
import {Shift, ShiftModalType} from 'types/shift';
import dayjs from 'dayjs';
import {TNullable} from 'types/commons';
import CapacityFields from "./CapacityFields";
import PropertiesFields from "./PropertiesFields";
import {INDEFINITE_DATE} from '../EditFormContainer/EditFormContainer';

const buildTimeOption = (time: string) => {
  const formatTime = dayjs.locale() === 'ru' ? 'HH:mm' : 'h:mm A';

  const date = dayjs(time, 'HH.mm.ss');
  const hour = date.get('hours');
  const minutes = date.get('minutes');
  date.set('minutes', hour * 60 + minutes);
  return {
    label: date.format(formatTime),
    value: hour * 60 + minutes
  };
};

type TTabName = string


//TODO: ODO: 2 iteration
// const timeIntervalOptions = [{label: '15 minutes', value: 15}, {label: '30 minutes', value: 30}]

export type TProps = {
  shift: TNullable<Partial<Shift>>
  onCancel: (view: ShiftModalType) => void
  onSubmitCallBack: (formData: any) => void
  isLoadingSubmit: boolean
}

const EditShiftForm: FC<TProps> = ({shift, onCancel, onSubmitCallBack, isLoadingSubmit}) => {

  if (!shift) return null;

  const durations = shift.capacity?.durations && getDurationTime(shift.capacity.durations);
  const intl = useIntl();
  const properties = intl.formatMessage({id: ETranslations.PROPERTIES})
  const capacity = intl.formatMessage({id: ETranslations.CAPACITY})
  const TABS: TTabName[] = [properties, capacity];
  const [activeTab, setActiveTab] = useState<TTabName>(TABS[0]);

  const init = useMemo(() => {
    return {
      ...shift,
      shift_name: shift.shift_name,
      start_date: shift.start_date && new Date(shift.start_date),
      end_date: shift.end_date && shift.end_date !== INDEFINITE_DATE && new Date(shift.end_date),
      continue_indefinite: shift.end_date === INDEFINITE_DATE,
      repeatDays: shift.repeat_days,
      open_time: buildTimeOption(shift.start_active_time || ''),
      close_time: buildTimeOption(shift.end_active_time || ''),
      places: shift.places_id,
      durations: durations,
      use_max_covered: shift.capacity?.use_max_covered,
      max_covered_size: shift.capacity?.max_covered_size,
      booking_interval: {label: shift.booking_interval, value: shift.booking_interval},
    };
  }, []);

  const onSubmit = useCallback(async (formData) => {
    onSubmitCallBack(formData);
  }, []);



  return (
    <Form initialValues={init} onSubmit={onSubmit}>
      {({
          handleSubmit,
          submitting,
          invalid,
          form
        }) => (
        <Card onClose={close}>
          <Tabs activeTab={activeTab} onChange={(e) => setActiveTab(e)} className={styles.tabs}>
            {TABS.map((tab) => <Tabs.Item key={tab} title={tab} tabName={tab}/>)}
          </Tabs>
          <Card.Content noPadding>
            <form onSubmit={handleSubmit} className={styles.content}>
              {activeTab === properties && <PropertiesFields form={form} shift={shift}/>}
              {activeTab === capacity && <CapacityFields form={form}/>}
              <Modal.Footer className={styles.modalFooter}>
                <Button
                  variant="secondary"
                  onClick={() => onCancel('INFO')}
                >
                  {intl.formatMessage({id: ETranslations.CANCEL})}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={isLoadingSubmit || submitting || invalid}
                >
                  {intl.formatMessage({id: ETranslations.SAVE})}
                </Button>
              </Modal.Footer>
            </form>
          </Card.Content>
        </Card>
      )}
    </Form>
  );
};

export default EditShiftForm;


