import React, {useCallback, useMemo, useState} from "react";
import styles from "./EachAccessRule.module.scss";
import {Collapse} from "ui-kit";
import {useIntl} from "react-intl";
import {ETranslations} from "types/translates";
import {AccessRulesModal} from "components/shifts/Modal/AccessRulesModal/AccessRulesModal";
import {AccessRuleData} from "types/accessRules";
import EachRule from "./EachRule";

const EachAccessRule = ({name, access_rules, id}: AccessRuleData) => {
  //@ts-ignore
  const isActive = useMemo(() => access_rules.filter((each) => !!each.active).length > 0, [access_rules]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const intl = useIntl();

  const modalOnClose = useCallback(() => {
    setModalOpen(false);
    setIsEditMode(false);
  }, []);
  const handleOpen = (e:React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setModalOpen(() => true)
  };

  return (
    <Collapse
      expendInHeader={true}
      headerClassName={styles.accessRuleHeader}
      iconClassName={styles.icon}
      header={
        <div className={styles.header}>
          <span>{name} </span>
          <div className={styles.restInfo}>
            <button className={styles[isActive ? "active" : "inactive"]}>
              {intl.formatMessage({id: isActive ? ETranslations.SHIFT_STATUS_ACTIVE : ETranslations.SHIFT_STATUS_INACTIVE})}
            </button>
            <span className={styles.open} onClick={handleOpen}>
              {intl.formatMessage({id: ETranslations.BASE_INFO})}
            </span>
          </div>
        </div>
      }
    >
      <table className={styles.rules}>
        <tbody>
          {/* @ts-ignore */}
          {access_rules.map(
            (
              {rule_name, start_date, start_active_time, end_active_time, end_date, repeat_days, shift_id, places},
              index
            ) => {
              return (
                <EachRule
                  key={index}
                  access_rule={{name, access_rules, id}}
                  currentRule={{
                    rule_name,
                    index,
                    groupName: name,
                    start_active_time,
                    end_active_time,
                    repeat_days,
                    start_date,
                    shift_id,
                    places,
                    end_date
                  }}
                />
              );
            }
          )}
        </tbody>
      </table>
      <AccessRulesModal
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        access_rule={{name, access_rules, id}}
        onClose={modalOnClose}
        title={name}
        isOpen={modalOpen}
      />
    </Collapse>
  );
};

export default EachAccessRule;
