import _ from 'lodash';

const monthRU = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

const monthEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const MONTHS_OPTIONS_DISPATCH = {
  en_EN: monthEN,
  ru_RU: monthRU
}
export const monthsOptions = (locale: keyof typeof MONTHS_OPTIONS_DISPATCH) => MONTHS_OPTIONS_DISPATCH[locale]
  .map((month, idx) => ({value: idx, label: month}));

export const yearsOptions = (start : number) => _.range(start, new Date().getFullYear() + 6, 1)
  .map((year, idx) => ({value: idx, label: year.toString()}));
