import cn from 'classnames';
import {Modal, ModalProps} from "components/modal";

import styles from "./HallLegendModal.module.scss";
import {OverbookedLabel} from "components/Table/OverbookedLabel";
import {ETranslations} from "../../../types/translates";
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {ICONS} from 'ui-kit';
import {config} from "config";


type HallLegendModal = Omit<ModalProps, 'title' | 'children'>


export default function HallLegendModal(props: HallLegendModal) {
  const {intl, getIntlNumberOf, isRussianLocale} = useIntlUtils();

  return (
    <Modal {...props} title={intl.formatMessage({id: ETranslations.HALL_SCHEME})} className={styles.legendContainer}>
      <Modal.Content className={styles.legend}>

        <div className={cn(styles.table, styles.free)} />
        <span>{intl.formatMessage({id: ETranslations.FREE})}</span>

        <div className={cn(styles.table, styles.confirmed)}/>
        <span>{intl.formatMessage({id: ETranslations.CONFIRMED})}</span>

        <div className={cn(styles.table, styles.notConfirmed)}/>
        <span>{intl.formatMessage({id: ETranslations.NOT_CONFIRMED})}</span>

        <div className={cn(styles.table, styles.inHall)}/>
        <span>{intl.formatMessage({id: ETranslations.IN_THE_HALL})}</span>

        <div className={cn(styles.table, styles.icon, styles.clock)}>
          <ICONS.ClockCircleScheme width={26} height={26}/>
        </div>
        <span>{intl.formatMessage({id: ETranslations.MIN_TO_LEFT})}</span>

        <div className={cn(styles.table, styles.late)}/>
        <span>{intl.formatMessage({id: ETranslations.LATE})}</span>

        <div className={cn(styles.table, styles.outstayed)}/>
        <span>{intl.formatMessage({id: ETranslations.OUTSTAY})}</span>

        {config.vipSign && (
          <>
            <div className={cn(styles.table, styles.icon)}>
              <ICONS.VipSign width={28} height={28}/>
            </div>
            <span>VIP</span>
          </>
        )}

        <div className={cn(styles.table, styles.number)}>12</div>
        <span>{intl.formatMessage({id : 'HSE:TABLE_NUMBER'})}</span>

        <OverbookedLabel style={{width: '26px', height: '26px', fontSize: '100%'}} />
        <span>{intl.formatMessage({id: ETranslations.BOOKING_CROSSING})}</span>

        {/* <div className={cn(styles.table, styles.deposit)}> */}
        {/*   <ICONS.LockIcon width={20} height={20}/> */}
        {/* </div> */}
        {/* <span>{intl.formatMessage({id: ETranslations.LOCK_TABLE})}</span> */}

        <div className={cn(styles.table, styles.deposit)}>
          <ICONS.LockIcon width={20} height={20}/>
        </div>
        <span>{intl.formatMessage({id: ETranslations.DEPOSIT})}</span>

      </Modal.Content>
    </Modal>
  );
}
