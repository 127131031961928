import {Booking} from "../../../types/booking";
import {useIsTablet} from "../../../ui-kit/hooks";
import {clientFullName} from "../../../common/helpers";
import {forwardRef, HTMLAttributes} from "react";
import styles from "./dndPreview.module.scss";

type TBookingCardDndLayoutProps = {
  booking: Booking
} & Pick<HTMLAttributes<HTMLSpanElement>, "style">

export const BookingCardDndLayout = forwardRef<HTMLSpanElement, TBookingCardDndLayoutProps>((data, ref) => {
  const tablet = useIsTablet();
  const {booking, style} = data;
  if (!tablet)
    return null;
  return (
    <span style={style} ref={ref} className={styles.bookingCardPreview}>{clientFullName(booking.client)}</span>
  );
});
