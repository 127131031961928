import type { TagGroup } from "types/tag";
import {api} from "./api";
import type { IResponse } from "models/common";


export const tagsApi = api
  .enhanceEndpoints({ addTagTypes: ['NewTags'] })
  .injectEndpoints({
    endpoints: (build) => ({
      createTags: build.mutation({
        query: (payload: any) => ({
          url: 'v2/tags/group',
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: () => [{ type: 'NewTags', id: 'LIST' }],
      }),

      getNewTags: build.query<
        TagGroup[],
        { destination: string; resturant_id: string }
      >({
        query: (payload: { destination: string; resturant_id: string }) => ({
          url: `v2/tags/group?destination=${payload.destination}&owner_id=${payload.resturant_id}`,
          method: 'GET',
        }),
        providesTags: () => [{ type: 'NewTags', id: 'LIST' }],
        transformResponse: (data: IResponse<TagGroup[]>) =>
          data.data.sort((a, b) => a.id - b.id),
      }),

      editNewTag: build.mutation({
        query: (payload) => {
          return {
            url: `v2/tags/group`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: () => [{ type: 'NewTags', id: 'LIST' }],
      }),
    }),
  });

export const {
    useCreateTagsMutation,
    useGetNewTagsQuery,
    useEditNewTagMutation,
} = tagsApi;