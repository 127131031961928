import React, {FC} from 'react';
import cn from 'classnames';

import {Modal, ModalProps} from '../../modal';

import styles from './style.module.scss';

type Props = Omit<ModalProps, 'children'>

export const BaseShiftsModal: FC<Props> = ({isOpen, onClose, title, children, className}) => {
  const parentSelector = document.querySelector('#settings');
  if(!parentSelector) return null;

  return (
    <Modal
      onClose={onClose}
      title={title}
      isOpen={isOpen}
      className={cn(styles.modal, className)}
      overlayClassName={styles.overlay}
      parentSelector={() => parentSelector as HTMLElement}
      portalClassName={styles.modalPortal}
    >
      {children}
    </Modal>
  );
};
