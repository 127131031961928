import React, {createContext, CSSProperties, ReactNode, useContext, useState} from 'react';
import _ from 'lodash';
import {useUnmount} from 'react-use';

interface Root {
  columns: Column[];
  styles?: CSSProperties | undefined;
}

export interface HWLContext {
  context: Root,

  getColumn(name: string): Column | undefined,

  addColumn(name: string, styles?: CSSProperties): Column,

  getWindow(columnName: string, name: string): Window | undefined,

  addWindow(columnName: string, name: string, active?: boolean, pinned?: boolean): Window,

  isActiveWindow(window: Window): boolean,

  popupWindow(window: Window): void,

  closeWindow(window: Window): void,

  findByWindowName(name: string): Window,

  closeAllWindows(): void;

  removeWindow(window: Window): void;

}

interface HorizontalWindowLayoutProviderProps {
  children: ReactNode;
}

class Column {
  name: string;
  windows: Window[];
  styles?: CSSProperties;

  constructor(name: string, styles?: CSSProperties) {
    this.name = name;
    this.windows = [];
    this.styles = styles;
  }
}

export class Window {
  name: string;
  columnRef: Column;
  active: boolean;
  pinned: boolean;

  constructor(name: string, column: Column, active?: boolean, pinned?: boolean) {
    this.name = name;
    this.active = active || false;
    this.pinned = pinned || false;
    this.columnRef = column;
  }

  isActive(): boolean {
    return this.active;
  }

  popup(): void {
    this.active = true;
  }

  close(): void {
    this.active = false;
  }
}

export const HWLContextObj = createContext<HWLContext>(null!);

export function HorizontalWindowLayoutProvider({children}: HorizontalWindowLayoutProviderProps) {
  const contextDefault: Root = {
    columns: [],
  };

  const [state, setState] = useState(contextDefault);
  const getColumn = (name: string): Column | undefined => state.columns.find((it) => it.name === name);

  const addColumn = (name: string, styles?: CSSProperties) => {
    const column = new Column(name, styles);
    state.columns.push(column);
    return column;
  };

  const getWindow = (columnName: string, name: string): Window | undefined => {
    const col = getColumn(columnName);
    if (col) {
      return col.windows.find((it) => it.name === name);
    }
    return undefined;
  };

  const findByWindowName = (name: string): Window => {
    for (let i = 0; i < state.columns.length; i++) {
      const win = getWindow(state.columns[i].name, name);
      if (win) return win;
    }
    throw new Error(`Fail find, window ${name}`);
  };

  const attachWindow = (column: Column, window: Window) => {
    column.windows.push(window);
  };

  const addWindow = (columnName: string, name: string, active?: boolean, pinned?: boolean): Window => {
    const col = getColumn(columnName);
    const win = getWindow(columnName, name);
    if (col && !win) {
      const window = new Window(name, col, active, pinned);
      attachWindow(col, window);
      return window;
    }
    throw new Error('Fail initialize component, please check layout configuration');
  };

   const removeWindow = (window: Window): void => {
    window.columnRef.windows = window.columnRef.windows.filter((w) => w !== window);
  };

  const popupWindow = (window: Window) => {
    window.popup();
    setState(_.cloneDeep(state));
  };

  const closeWindow = (window: Window) => {
    window.close();
    setState(_.cloneDeep(state));
  };

  const isActiveWindow = (window: Window): boolean => {
    const column = window.columnRef;
    // only first get matter
    const {windows} = column;
    const pinned = _.first(windows.filter((it) => it.pinned));
    // to apply pinned we need should know, every window active === false
    const noActiveWindows = windows.filter((it) => !it.active).length === windows.length;

    const isThisIsPinned = !!(pinned && pinned.name === window.name);
    return window.active || (noActiveWindows && isThisIsPinned);
  };

  useUnmount(() => setState(contextDefault));

  const closeAllWindows = () => {
    setState((s) => ({
        ...s,
        columns: s.columns.map((col) => ({
          ...col,
          windows: col.windows.map((win) => {
            win.close();
            return win;
          }),
        })),
      }));
  };

  return (
    <HWLContextObj.Provider value={{
      context: state,
      isActiveWindow,
      getColumn,
      addColumn,
      getWindow,
      addWindow,
      closeWindow,
      popupWindow,
      findByWindowName,
      closeAllWindows,
      removeWindow,
    }}
    >
      {children}
    </HWLContextObj.Provider>
  );
}

export const useHWLContext = () => useContext(HWLContextObj);
