import React from 'react';
import cn from 'classnames';
import './phone-menu.scss';

export const PhoneMenu = (props: any) => {
  const {clickHandler} = props;
  return (
    <div className={cn('phone-menu')} onClick={clickHandler}>
      <div className={cn('wrapper')}>
        <div className={cn('bar')} />
        <div className={cn('bar')} />
        <div className={cn('bar')} />
      </div>
    </div>
  );
};
