import {useEffect, useMemo} from "react";
import {useField} from "react-final-form";
import {useIntl} from "react-intl";
import {isArray} from "lodash";
import {usePrevious, useUpdateEffect} from "react-use";
import {useApplicationContextActions} from "features/AppContex";
import {useTablesOptions} from "hooks/useTablesOptions";
import {useSelector} from "react-redux";
import {Option} from "../../../../common/components/MultiSelect/multi-select";
import {useHallSchemaActions} from "../../../../features/HallSchema";
import {activeTablesSelector} from "../../../../features/HallSchema/selectors";
import type { Booking } from "types/booking";

const useChoiseTableRedux = (
  onChange: any,
  isFromManagement: boolean,
  value: any,
  bookingTables?: Booking['places']
) => {
  const intl = useIntl();
  const {
    input: { value: bookingId },
  } = useField('bookingId', { subscription: { value: true } });
  const {
    input: { value: placeForm },
  } = useField('placeId', { subscription: { value: true } });
  const { tables: options } = useTablesOptions(bookingTables);
  const { setPlace } = useApplicationContextActions();

  const { setActiveTables } = useHallSchemaActions();

  const tables = useSelector(activeTablesSelector);

  const inputOptions = useMemo(() => {
    if (isFromManagement) {
      return options.filter((o) => {
        return value.includes(o.value);
      });
    } else {
      return options;
    }
  }, [options]);

  useEffect(() => {
    if (placeForm) {
      setPlace(placeForm);
    }
  }, [placeForm]);

  const handleOnChange = (e: Option<number>[] = []) => {
    onChange(e.map((t) => t.value));
    setActiveTables({ activeTables: e.map((t) => t.value) });
  };
  const prevBookingId = usePrevious(bookingId);

  useEffect(() => {
    if (!tables.length) {
      onChange([]);
    }
  }, [tables]);

  useEffect(() => {
    if (bookingId === prevBookingId) return;
    const activeTables = isArray(value) ? value : [];
    setActiveTables({ activeTables: activeTables.length ? activeTables : [] });
    onChange(activeTables.length ? activeTables : []);
  }, [value, bookingId, prevBookingId]);

  useUpdateEffect(() => {
    if (!options.length) return;
    const selected = options.filter((o) => tables.includes(o.value));
    !!selected.length && onChange(selected.map((t) => t.value));
  }, [tables, options]);

  const selected = useMemo(() => {
    return options.filter((o) => value.includes(o.value));
  }, [value, options]);

  return {
    selected,
    intl,
    inputOptions,
    handleOnChange,
  };
};

export default useChoiseTableRedux