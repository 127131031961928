import {ChangeEventHandler, useCallback} from 'react';
import cn from 'classnames';

import styles from './checkbox.module.scss';

interface CheckboxProps {
  indent?: string;
  text: string;
  onChange: (e: boolean) => void;
  value?: boolean;
  disabled?: boolean;
  className?: string;
  useStopPropagation? : boolean;
}

export default function Checkbox(props: CheckboxProps) {
  const {text, onChange, value, disabled, className, useStopPropagation, indent} = props;

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    onChange(e.target.checked);
  }, [onChange]);


  function stopPropagation(e: any) {
    if (useStopPropagation) e.stopPropagation();
  }

  return (
    // eslint-disable-next-line
    <label className={cn(styles.checkbox, indent, className, {[styles.disabled]: disabled})}>
      <input
        type="checkbox"
        checked={value || false}
        disabled={disabled}
        onChange={handleChange}
      />
      <span
        className={cn(styles.mark, {[styles.disabled]: disabled})}
        onMouseDown={stopPropagation}
      />
      <span
        onMouseDown={stopPropagation}
      >
        {text}
      </span>
    </label>
  );
}
