import React, {FC} from "react";
import {FieldRenderProps} from "react-final-form";
import WeekDays from "../../../shifts/Form/WeekDays/WeekDays";
import {EDaysOfWeek} from "types/commons";
import {useDayjsLocale} from "../../../shifts/useDayjsLocale";

export const WeekDaysField: FC<FieldRenderProps<EDaysOfWeek[]>> = ({input, meta, ...rest}) => {
  useDayjsLocale();
  const {value, onChange, ...restInputProps} = input;

  return <WeekDays weekDays={value} onChange={onChange} {...restInputProps} {...meta} {...rest} />;
};
