import React from 'react';
import { Button, Card } from 'ui-kit';
import BookingFormReduxProxy from 'components/registration/forms/BookingFormReduxProxy';
import { ConfirmOverbookingModal } from 'components/modals/ConfirmOverbookingModal';
import ManageralModalError from 'components/ManagerialTables/form/ManageralModalError';
import { ETranslations } from '../../../../types/translates';
import useCreateBooking from './hooks/useCreateBooking';

export const CreateBooking = ({
  reset,
  isFromManagement = false,
}: {
  reset: any;
  isFromManagement?: boolean;
}): React.ReactElement => {
  const {
    isRussianLocale,
    getIntlEntityCreation,
    getIntlAddOf,
    handleChangeMode,
    intl,
    createBooking,
    isConfirmationRequired,
    clearData,
    createWithOver,
    isCreating,
    hasManageralError,
    close,
    closeModal,
  } = useCreateBooking(reset, isFromManagement);

  return (
    <Card onClose={close}>
      <Card.Header
        title={
          isRussianLocale
            ? getIntlEntityCreation(ETranslations.BASE_BOOKINGS)
            : getIntlAddOf(ETranslations.PLURAL_BOOKING)
        }
        needToReset
        ManageralButton={
          !isFromManagement && (
            <Button variant="thin" style={{ padding: 5, marginRight: 12 }}>
              <span
                style={{ font: 'var(--font-13-r)', fontSize: '400' }}
                onClick={handleChangeMode}
              >
                {intl.formatMessage({ id: ETranslations.MANAGEMENT_BOOKING })}
              </span>
            </Button>
          )
        }
      />

      <Card.Content noPadding>
        <BookingFormReduxProxy
          isFromManagement={isFromManagement}
          submitFn={createBooking}
          afterSubmit={reset}
        />
        <ConfirmOverbookingModal
          isOpen={isConfirmationRequired}
          onDecline={clearData}
          onConfirm={createWithOver}
          disabled={isCreating}
        />
      </Card.Content>

      {hasManageralError && (
        <ManageralModalError isOpen={hasManageralError} onClose={closeModal} />
      )}
    </Card>
  );
};
