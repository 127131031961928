import {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import useClosestInterval from "hooks/useClosestInterval";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {appSelectedAccessRule} from "features/AccessRules/selectors";
import {useEditAccessRuleMutation} from "features/api/access_rules";
import {AccessRuleData} from "types/accessRules";
import {appContextSelectors} from "features/AppContex";
import {usAccessRulesActions} from "features/AccessRules/slice";

const useAccessRuleForm = (currentRule: any, onClose: any) => {
  const intl = useIntl();
  const closestInterval = useClosestInterval();
  const selectedActiveRule = useSelector(appSelectedAccessRule) as AccessRuleData;
  const [error, setError] = useState("");
  const restaurant = useSelector(appContextSelectors.restaurant);
  const {reset} = usAccessRulesActions();
  const [edit] = useEditAccessRuleMutation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const toggleOpensSave = () => setOpenEditModal(state => !state);
  const toggleOpenDeleteModal = () => setOpenDeleteModal(state => !state);
  const onToggleCloseError = () => {
    toggleOpenDeleteModal();
    setError("");
  }

  const initalValue = useMemo(() => {
    const isInfinite = dayjs(currentRule.end_date as string).valueOf() >= 2208974400000
    return {
      ...currentRule,
      infininte: isInfinite ? true : false,
      index: currentRule.index,
      start_date: new Date(currentRule.start_date),
      end_date: isInfinite ? "" : new Date(currentRule.end_date),
      start_active_time: {
        value: dayjs(`${currentRule.start_date} ${currentRule.start_active_time}`).valueOf(),
        label: currentRule.start_active_time?.slice(0, -3),
      },
      end_active_time: {
        value: dayjs(`${currentRule.start_date} ${currentRule.end_active_time}`).valueOf(),
        label: currentRule.end_active_time.slice(0, -3),
      },
    };
  }, [closestInterval]);

  const {restaurant_id} = restaurant;


  const handleDeleteCurrentRule = (e: any) => {
    const idOfDeletedItem = +e.target.value
    const allAccessRulesBesideSelected = selectedActiveRule?.access_rules.filter((_, currIndex) => {
      return currIndex !== idOfDeletedItem
    })


    edit({
      id: selectedActiveRule.id,
      body: {
        name: selectedActiveRule.name,
        restaurant_id,
        access_rules: [
          ...allAccessRulesBesideSelected.map(each => {
            return {
              shift_id: each.shift_id,
              start_date: dayjs(each.start_date).format("YYYY-MM-DD"),
              end_date: dayjs(each.end_date).format("YYYY-MM-DD"),
              start_active_time: `${each.start_active_time}`,
              end_active_time: `${each.end_active_time}`,
              repeat_days: each.repeat_days,
              places_id: each.places,
              rule_name: each.rule_name,
            }
          })
        ],
        use_max_covered: true,
        max_covered_size: 1,
        party_min_person: 1,
        party_max_person: 1,
      }
    }).then((res: any) => {
      if (res.data) {
        onClose();
        toggleOpenDeleteModal();
        reset();
        return;
      }

      if (res.error.data.errorMessage) {
        setError(() => (res.error.data.errorMessage));
      }

    })

  }

  const onSubmit = useCallback(async (formData) => {
    const {index, groupName, ...rest} = formData;

    const updatedRules = selectedActiveRule?.access_rules.filter((_, currIndex) => {
      return currIndex !== index
    })

    edit({
      id: selectedActiveRule.id,
      body: {
        name: selectedActiveRule.name,
        restaurant_id,
        access_rules: [
          ...updatedRules.map(each => {
            return {
              shift_id: each.shift_id,
              start_date: dayjs(each.start_date).format("YYYY-MM-DD"),
              end_date: dayjs(each.end_date).format("YYYY-MM-DD"),
              start_active_time: `${each.start_active_time}`,
              end_active_time: `${each.end_active_time}`,
              repeat_days: each.repeat_days,
              places_id: each.places,
              rule_name: each.rule_name,
            }
          }).concat({
            shift_id: rest.shift_id.value,
            start_date: dayjs(rest.start_date).format("YYYY-MM-DD"),
            end_date: rest.infininte ? ("2040-01-01") : dayjs(rest.end_date).format("YYYY-MM-DD"),
            start_active_time: `${rest.start_active_time.label}:00`,
            end_active_time: `${rest.end_active_time.label}:00`,
            repeat_days: rest.repeat_days,
            places_id: rest.places,
            rule_name: rest.rule_name,
          }),


        ],
        use_max_covered: true,
        max_covered_size: 1,
        party_min_person: 1,
        party_max_person: 1,
      }
    }).then((res: any) => {
      if (res.data) {
        onClose();
        reset();
        return;
      }
    })

  }, []);

  return {
    initalValue,
    onSubmit,
    intl,
    openEditModal,
    toggleOpensSave,
    openDeleteModal,
    toggleOpenDeleteModal,
    handleDeleteCurrentRule,
    onToggleCloseError,
    error
  };
};

export default useAccessRuleForm;
