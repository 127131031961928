import {FunctionComponent, useMemo} from "react";
import {ParamsItemsType} from "types/booking";
import {ICONS} from "ui-kit";
import moment from "moment";
import {getTimeHoursAndMinutes} from "common/helpers";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {declOfNum} from "../../utils";
import {HOURS_TITLES, MINUTES_TITLES} from "../../constants";

const {
  Calendar,
  Clock,
  CommentFill,
  FreePhoneIcon,
  GreyRubleIcon,
  GuestsIcon,
  SeatIcon,
  Source,
  TagIcon,
} = ICONS;

interface Props {
  param : ParamsItemsType,
}


const HistoryPropertySwitcher: FunctionComponent<Props> = ({param}) => {
  const {intl, getIntlNumbersOf, getIntlTimeOf} = useIntlUtils();
  const {yesTitle, noTitle} = useMemo(() => ({
   yesTitle: intl.formatMessage({id: ETranslations.BASE_YES}),
   noTitle: intl.formatMessage({id: ETranslations.BASE_NO}),
  }), [])

  switch (param.name) {
    case 'date':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <Calendar width={24} height={24} />
            {intl.formatMessage({id: ETranslations.BASE_DATE})}
          </div>
          <div>
            <span className="new">{moment(param.new).format('DD.MM.YYYY HH:mm')}</span>
            <span className="old">{moment(param.old).format('DD.MM.YYYY HH:mm')}</span>
          </div>
        </>
      );
    case 'tables':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <SeatIcon />
            {getIntlNumbersOf(ETranslations.PLURAL_TABLES)}
          </div>
          <div>
            <span className="new">{param.new.join(' ')}</span>
            <span className="old">{param.old.join(' ')}</span>
          </div>
        </>
      );
    case 'visitTime': {
      const {hours: newHours, minutes: newMinutes} = getTimeHoursAndMinutes(param.new);
      const {hours: oldHours, minutes: oldMinutes} = getTimeHoursAndMinutes(param.old);

      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <Clock />
            {getIntlTimeOf(ETranslations.PLURAL_VISITS_ALT)}
          </div>
          <div>
            <span className="new">{`${newHours} ${intl.formatMessage({id: declOfNum(newHours, HOURS_TITLES)})}
         ${newMinutes} ${intl.formatMessage({id: declOfNum(newMinutes, MINUTES_TITLES)})}`.toLowerCase()}</span>
            <span className="old">{`${oldHours} ${intl.formatMessage({id: declOfNum(oldHours, HOURS_TITLES)})}
         ${oldMinutes} ${intl.formatMessage({id: declOfNum(oldMinutes, MINUTES_TITLES)})}`.toLowerCase()}</span>
          </div>
        </>
      );
    }
    case 'comment':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <CommentFill />
            {intl.formatMessage({id: ETranslations.COMMENT})}
          </div>
          <div>
            <span className="new">{param.new}</span>
            <span className="old">{param.old}</span>
          </div>
        </>
      );
    case 'persons':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <GuestsIcon width={24} height={24} />
            {intl.formatMessage({id: ETranslations.PLURAL_PERSONS})}
          </div>
          <div>
            <span className="new">{param.new}</span>
            <span className="old">{param.old}</span>
          </div>
        </>
      );
    case 'tags':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <TagIcon />
            {intl.formatMessage({id: ETranslations.PLURAL_TAGS_NOM})}
          </div>
          <div>
            <span className="new">{param.new.map((tag) => tag.name).join(',')}</span>
            <span className="old">{param.old.map((tag) => tag.name).join(',')}</span>
          </div>
        </>
      );
    case 'depositStatus':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <GreyRubleIcon />
            {intl.formatMessage({id: ETranslations.DEPOSIT_MADE})}
          </div>
          <div>
            <span className="new">{param.new === 'NOT_MADE' ? noTitle : yesTitle}</span>
            <span className="old">{param.old === 'NOT_MADE' ? noTitle : yesTitle}</span>
          </div>
        </>
      );
    case 'depositAmount':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <GreyRubleIcon />
            {intl.formatMessage({id: ETranslations.DEPOSIT_AMOUNT})}
          </div>
          <div>
            <span className="new">{param.new}</span>
            <span className="old">{param.old}</span>
          </div>
        </>
      );
    case 'overbooking':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            {intl.formatMessage({id: ETranslations.OVER_BOOKING})}
          </div>
          <div>
            <span className="new">{param.new ? yesTitle : noTitle}</span>
            <span className="old">{param.old ? yesTitle : noTitle}</span>
          </div>
        </>
      );
    case 'useDeposit':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <GreyRubleIcon />
            {intl.formatMessage({id: ETranslations.WITH_DEPOSIT})}
          </div>
          <div>
            <span className="new">{param.new ? yesTitle : noTitle}</span>
            <span className="old">{param.old ? yesTitle : noTitle}</span>
          </div>
        </>
      );
    case 'source':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <Source />
            {intl.formatMessage({id: ETranslations.SOURCE})}
          </div>
          <div>
            <span className="new">{param.new?.source_name}</span>
            <span className="old">{param.old?.source_name}</span>
          </div>
        </>
      );
    case 'clientName':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <GuestsIcon width={24} height={24} />
            {intl.formatMessage({id: ETranslations.NAME})}
          </div>
          <div>
            <span className="new">{param.new}</span>
            <span className="old">{param.old}</span>
          </div>
        </>
      );
    case 'phone':
      return (
        <>
          <div className="bookingHistoryChangesSection__title">
            <FreePhoneIcon />
            {intl.formatMessage({id: ETranslations.PHONE})}
          </div>
          <div>
            <span className="new">{param.new}</span>
            <span className="old">{param.old}</span>
          </div>
        </>
      );
    default: return null;
  }
};

export default HistoryPropertySwitcher;
