// https://gist.github.com/nfantone/9ab600760db8774ab4873cb1a3a22f26
import {setIn, ValidationErrors} from 'final-form';
import {ValidationError} from 'yup';
import type {AnySchema} from 'yup';

type ObjectType = { [key: string]: string };
/**
 * Sets the `innerError.message` in an `errors` object at the key
 * defined by `innerError.path`.
 * @param {Object} errors The object to set the error in.
 * @param {{ path: string, message: string }} innerError A `yup` field error.
 * @returns {Object} The result of setting the new error message onto `errors`.
 */
const setInError = (errors: ObjectType, innerError: ValidationError) => (innerError.path
  ? (setIn(errors, innerError.path, innerError.message) as ObjectType) : errors);

/**
 * Takes a `yup` validation schema and returns a function that expects
 * a map of values to validate. If the validation passes, the function resolves to `undefined`
 * (signalling that the values are valid). If the validation doesn't pass, it resolves
 * to a map of invalid field names to errors.
 * @param {import('yup').AnySchema} schema `yup` schema definition.
 * @returns {(values: Object) => Promise<?Object>} An async function that expects some `values`
 *  and resolves to either `undefined` or a map of field names to error messages.
 */

export const makeValidate = (schema: AnySchema) => async (values: unknown): Promise<ValidationErrors | undefined> => {
    try {
      await schema.validate(values, {abortEarly: false});
      return undefined;
    } catch (err) {
      if (!(err instanceof ValidationError)) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      return (err as ValidationError).inner.reduceRight(setInError, {});
    }
  };
