import React from 'react';

import {ICONS} from 'common/helpers';
import {Button} from 'ui-kit';

import styles from './HallControls.module.scss';

export const HallControls: React.FC<{zoomIn: () => void, zoomOut: () => void, resetZoom: () => void}> = ({zoomIn, zoomOut, resetZoom}) => (
     <div className={styles.root}>
      <Button variant="phantom" onClick={zoomIn} className={styles.button}>
        <ICONS.ZoomPlus />
      </Button>
      <Button variant="phantom" onClick={zoomOut} className={styles.button}>
        <ICONS.ZoomMinus />
      </Button>
      <Button
        variant="phantom" onClick={resetZoom}
        className={styles.button}
      >
        <ICONS.ZoomPlus className={styles.reset} />
      </Button>
     </div>
  );
