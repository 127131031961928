import { useMemo } from 'react';
import { Button, Spinner } from 'ui-kit';
import dayjs from 'dayjs';
import { config } from 'config';
import { isBookingCanBeCanceled, isManagerialTable } from 'utils';
import { ICONS } from 'common/helpers';
import { Menu } from 'components/Menu/Menu';
import { Popup, PopupProps } from 'components/Popup';
import { ConfirmBusyTable } from 'components/ConfirmBusyTable';
import { Booking, ManagerialTable } from 'types/booking';
import styles from './BookingActions.module.scss';
import { CancelActions } from './CancelActions';
import { ETranslations } from '../../types/translates';
import useBookingActions from './hooks/useBookingActions';
import { StatusActions } from './StatusActions';

const RESET_PADDING = { top: true, bottom: true };

type BookingActionsProps = {
  className?: string;
  booking: Booking | ManagerialTable;
  onEdit?: (booking: Booking | ManagerialTable) => void;
  allowMove?: boolean;
  isShortVersion?: boolean;
  placement?: PopupProps['placement'];
};

export function BookingActions({
  className,
  booking,
  onEdit,
  allowMove,
  placement = 'auto-start',
  isShortVersion,
}: BookingActionsProps) {
  const {
    isLoading,
    isCancel,
    isStatus,
    handleEdit,
    intl,
    isManagerialTableAndTimePassed,
    createBooking,
    cancel,
    handleMoveClick,
    setTimestamp,
    nextStatus,
    handleStatusChange,
    recovery,
    handlePrint,
    showCancel,
    showStatus,
    hide,
    popup,
    setWasOpen,
    force,
    goNextStatus,
    resetForce,
    extraStatuses,
    isBookingRestorable,
  } = useBookingActions(booking, onEdit);

  const popupContent = useMemo(() => {
    if (isLoading) return <Spinner />;
    if (isCancel && !isManagerialTable(booking))
      return (
        <CancelActions
          back={hide}
          booking={booking}
          close={popup.current?.close}
        />
      );
    if (isStatus && extraStatuses.length && !isManagerialTable(booking))
      return (
        <StatusActions
          back={hide}
          booking={booking}
          statuses={extraStatuses}
          close={popup.current?.close}
        />
      );
    return (
      <Menu className={styles.menu}>
        {onEdit && !isShortVersion && (
          <Menu.Item icon={ICONS.Edit} onClick={handleEdit}>
            {intl.formatMessage({ id: ETranslations.BASE_EDIT })}
          </Menu.Item>
        )}

        {isManagerialTable(booking)
          && !isShortVersion
          && !isManagerialTableAndTimePassed && (
            <Menu.Item icon={ICONS.Plus} onClick={createBooking}>
              {intl.formatMessage({ id: ETranslations.CREATE_BOOKING })}
            </Menu.Item>
          )}

        {isManagerialTable(booking) && !isShortVersion && (
          <Menu.Item
            onClick={async (e) => {
              e.stopPropagation();
              cancel({ id: booking.notes.management_table_id });
            }}
            className={styles.cancelContentM}
            icon={ICONS.Cancel}
          >
            <span>{intl.formatMessage({ id: ETranslations.BASE_CANCEL })}</span>
          </Menu.Item>
        )}
        {allowMove
          && window.location.hash === '#/hall-scheme'
          && !isManagerialTable(booking) && (
            <Menu.Item
              icon={ICONS.Move}
              onClick={(e) => {
                e.stopPropagation();
                handleMoveClick();
                const dateOfEdit = dayjs(
                  `${booking.bookingDate} ${booking.bookingTime}`
                ).valueOf();
                setTimestamp(dateOfEdit);
              }}
            >
              {intl.formatMessage({ id: ETranslations.BASE_RESEAT })}
            </Menu.Item>
          )}
        {nextStatus && !isShortVersion && !isManagerialTable(booking) && (
          <Menu.Item
            icon={ICONS.Check}
            onClick={(e) => {
              e.stopPropagation();
              handleStatusChange();
            }}
          >
            {nextStatus.name}
          </Menu.Item>
        )}
        {!isManagerialTable(booking) && !!extraStatuses?.length && (
          <Menu.Item
            onClick={(e) => {
              e.stopPropagation();
              showStatus();
            }}
            icon={ICONS.PlusCircle}
            className={styles.status}
          >
            <span className={styles.cancelContent}>
              {intl.formatMessage({ id: ETranslations.ASSIGN_STATUS })}
              <ICONS.NextIcon />
            </span>
          </Menu.Item>
        )}
        {!isManagerialTable(booking) && isBookingRestorable && (
          <Menu.Item
            onClick={(e) => {
              e.stopPropagation();
              recovery();
            }}
            icon={ICONS.Recovery}
          >
            {intl.formatMessage({ id: ETranslations.BASE_RESTORE })}
          </Menu.Item>
        )}
        {config.isPrintAvailable
          && !isManagerialTable(booking)
          && !isShortVersion && (
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation();
                handlePrint();
              }}
              icon={ICONS.Printer}
            >
              {intl.formatMessage({ id: ETranslations.BASE_PRINT })}
            </Menu.Item>
          )}
        {!isManagerialTable(booking)
          && !isShortVersion
          && isBookingCanBeCanceled(booking) && (
            <Menu.Item
              onClick={(e) => {
                e.stopPropagation();
                showCancel();
              }}
              icon={ICONS.Cancel}
              className={styles.cancel}
            >
              <span className={styles.cancelContent}>
                {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
                <ICONS.NextIcon />
              </span>
            </Menu.Item>
          )}
      </Menu>
    );
  }, [
    isLoading,
    isCancel,
    isStatus,
    hide,
    booking,
    onEdit,
    handleEdit,
    allowMove,
    handleMoveClick,
    nextStatus,
    handleStatusChange,
    recovery,
    showCancel,
    showStatus,
  ]);

  return (
    <>
      <Popup
        placement={isShortVersion ? 'right-end' : placement}
        ref={popup}
        onClose={hide}
        onOpen={setWasOpen}
      >
        <Popup.Trigger>
          <Button variant="phantom" className={className}>
            <ICONS.ActionsMenu />
          </Button>
        </Popup.Trigger>
        <Popup.Content noPadding={isStatus ? true : RESET_PADDING}>
          {popupContent}
        </Popup.Content>
      </Popup>
      <ConfirmBusyTable
        isOpen={force}
        confirm={goNextStatus}
        decline={resetForce}
      />
    </>
  );
}
