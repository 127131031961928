import {useCallback, useMemo} from "react";
import cn from "classnames";

import {ICONS} from 'common/helpers';
import {Modal} from 'components/modal';

import styles from "./BookingsReport.module.scss";
import {fillTimeIntervals} from "hooks/useWorkingTimeOptions";
import {Field, Form} from "react-final-form";
import {BookingsReportParams, useBookingsReportMutation} from "features/api/reports";
import moment from "moment";
import {DatePicker, SelectBasic, SelectCheckbox} from "ui-kit";
import {useIntl} from "react-intl";
import {ETranslations} from "../../../types/translates";
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {useAllStatuses} from '../../../features/api/dictionaries-api';
import type { BaseStatus } from "types/status";

const normalize = ({dateFrom, dateTo, timeTo, timeFrom, statuses, ...rest}: any): BookingsReportParams => ({
  ...rest,
  start_date: moment(dateFrom).format('YYYY-MM-DD'),
  end_date: moment(dateTo).format('YYYY-MM-DD'),
  start_time: timeFrom?.label,
  end_time: timeTo?.label,
  status: statuses?.map((it: { label: BaseStatus['name']; value: BaseStatus['system_name'] }) => it.value),
});

const useLocalizedValidate = () => {
  const intl = useIntl();

  const {endDate, endTime, startTime, startDate} = useMemo(() => ({
    startDate: intl.formatMessage({id: ETranslations.ERROR_START_DATE}),
    endDate: intl.formatMessage({id: ETranslations.ERROR_END_DATE}),
    startTime: intl.formatMessage({id: ETranslations.ERROR_START_TIME}),
    endTime: intl.formatMessage({id: ETranslations.ERROR_END_TIME}),
  }), [])
  const validate = (data: any) => {
    const {start_date: dateFrom, end_date: dateTo, start_time: timeFrom, end_time: timeTo} = normalize(data);
    const hasTime = timeFrom && timeTo;
    return {
      dateFrom: moment(dateFrom).isAfter(dateTo) ? startDate : undefined,
      dateTo: moment(dateTo).isBefore(dateFrom) ? endDate : undefined,
      timeFrom: hasTime && moment(timeFrom, 'HH:mm').isAfter(moment(timeTo, 'HH:mm'))
        ? startTime : undefined,
      timeTo: hasTime && moment(timeTo, 'HH:mm').isBefore(moment(timeFrom, 'HH:mm'))
        ? endTime : undefined,
    };
  };

  return {validate}
}

const initial = {
  dateFrom: new Date(),
  dateTo: new Date(),
};
export interface ReportModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

export function BookingsReport({isOpen, closeModal}: ReportModalProps) {
  const {
    intl,
    getIntlSelectEntity,
    getIntlDateOf,
    getIntlStatusOf,
    getIntlBaseSelect,
    isRussianLocale,
    getIntlJoinedParts
  } = useIntlUtils();
  const {validate} = useLocalizedValidate()
  const {data: statusList} = useAllStatuses();
  const statuses = statusList.filter((status) => !status.is_extra);
  const timeOptions = fillTimeIntervals('00:00:00', '23:59:59', 15)
  const [download] = useBookingsReportMutation();

  const checkboxTitles = useMemo(() => {
    return [
      getIntlJoinedParts([ETranslations.PLURAL_STATUS, ETranslations.PLURAL_BOOKINGS_NOM]),
      getIntlJoinedParts([ETranslations.PLURAL_STATUSES_ALT, ETranslations.PLURAL_BOOKINGS_NOM]),
      getIntlJoinedParts([ETranslations.PLURAL_STATUSES, ETranslations.PLURAL_BOOKINGS_NOM]),
      getIntlJoinedParts([ETranslations.PLURAL_STATUSES_NOM, ETranslations.PLURAL_BOOKINGS_NOM])
    ]
  }, [])

  const statusOptions = statuses.map(status => ({value: status.system_name, label: status.name}));
  const onSubmit = useCallback(async (data) => {
    await download(normalize(data)).unwrap();
  }, [download]);

  return (
    <Form onSubmit={onSubmit} validate={validate} initialValues={initial}>
      {({handleSubmit, submitting, invalid}) => (
        <Modal isOpen={isOpen} onClose={closeModal} title={intl.formatMessage({id: ETranslations.BOOKING_REPORT})}>
            <form onSubmit={handleSubmit}>
            <Modal.Content className={styles.report}>
              <div className={cn(styles['titled-block'], styles['date-time'])}>
                <h3 className={cn(styles.title, styles.required)}>{getIntlSelectEntity(ETranslations.PLURAL_PERIOD)}</h3>
                <div>
                  <Field name="dateFrom">
                    {({input}) => (
                      <DatePicker
                        selected={input.value}
                        placeholderText={getIntlDateOf(ETranslations.OF_START)}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                  <div className={styles.separ}>—</div>
                  <Field name="dateTo">
                    {({input}) => (
                      <DatePicker
                        selected={input.value}
                        placeholderText={getIntlDateOf(ETranslations.OF_END)}
                        onChange={input.onChange}
                      />
                      )}
                  </Field>
                </div>
              </div>
              <div className={cn(styles['titled-block'], styles.status)}>
                <h3 className={styles.title}>{getIntlSelectEntity(ETranslations.PLURAL_STATUS)}</h3>
                <Field name="statuses" multiple>
                  {({input}) => (
                    <SelectCheckbox
                      className={styles.status}
                      placeholder={getIntlBaseSelect(getIntlStatusOf(isRussianLocale ?
                        ETranslations.PLURAL_BOOKINGS_NOM : ETranslations.PLURAL_BOOKING))}
                      titles={checkboxTitles}
                      listSize={5}
                      options={statusOptions}
                      onChange={input.onChange}
                      isClearable={false}
                      value={input.value}
                      maxMenuHeight={150}
                    />
                  ) }
                </Field>

              </div>
              <div className={cn(styles.time, styles['titled-block'])}>
                <h3 className={styles.title}>{getIntlSelectEntity(ETranslations.BASE_TIME)}</h3>
                <div>
                  <Field name="timeFrom">
                    {({input}) => (
                      <SelectBasic
                        isClearable
                        changedMaxSize
                        options={timeOptions}
                        value={input.value}
                        menuPlacement="top"
                        openTo="top"
                        onChange={input.onChange}
                        placeholder={intl.formatMessage({id: ETranslations.BASE_TIME})}
                        maxMenuHeight={200}
                      />
                    )}
                  </Field>
                  <div className={styles.separ}>—</div>
                  <Field name="timeTo">
                    {({input}) => (
                      <SelectBasic
                        isClearable
                        changedMaxSize
                        options={timeOptions}
                        value={input.value}
                        menuPlacement="top"
                        openTo="top"
                        onChange={input.onChange}
                        placeholder={intl.formatMessage({id: ETranslations.BASE_TIME})}
                        maxMenuHeight={200}
                      />
                    )}
                  </Field>
                </div>
              </div>
            </Modal.Content>
            <Modal.Footer>
              <button
                type="submit"
                className="primary"
                disabled={invalid || submitting}
              >
                {intl.formatMessage({id: ETranslations.UPLOAD_TO_FORMAT})} .xls
                {submitting && <img src={ICONS.loadingSmall} alt="" />}
              </button>
            </Modal.Footer>
            </form>
        </Modal>
      )}
    </Form>
  );
}
