import React, {useCallback, useEffect, useState} from "react";
import {Spinner} from "ui-kit";
import {HideWhen} from "components/HideWhen";

import {useIsTablet} from "hooks/useIsTablet";

import {GuestCard} from "./GuestCard";
import {useLocation} from "react-router";
import * as queryString from "querystring";
import {useSelector} from "react-redux";
import {guestListSelectors, useGuestListActions} from "features/GuestsList";
import {GuestViewContext, useGuestView} from "./GuestViewContext";
import {SelectGuestFromList} from "features/GuestsList/components/select-guest-from-list";
import {useQueryParam} from "hooks/useQueryParam";
import {useUnmount} from "react-use";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {Client} from '../../models/client.model';


function GuestsContainer() {
  const {getIntlCreatingOf, isRussianLocale} = useIntlUtils();
  const [view, setView] = useGuestView();
  const selectedClient = useSelector(guestListSelectors.selectedClient);
  const {setSelectedClient} = useGuestListActions();

  const [selectedId, setSelectedId] = useState<number | undefined>();

  const isTablet = useIsTablet();
  const location = useLocation();

  useEffect(() => {
    const {clientId} = queryString.parse(location.search.replace('?', ''));
    if (clientId) setSelectedId(+clientId);
  }, [location]);

  useEffect(() => setSelectedId(selectedClient?.client_id), [selectedClient?.client_id])

  const goToCreate = useCallback(() => setView('create'), [setView]);

  useUnmount(() => { setSelectedClient(undefined); });

  const selectClientHandler = (client: Client) => setSelectedId(client.client_id);

  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <HideWhen condition={isTablet && (!!selectedClient || view === 'create')} noUnmount>
          <SelectGuestFromList
            onSelectGuest={selectClientHandler}
            controls={isTablet ? (
              <button type="button" className="primary" onClick={goToCreate}>
                {getIntlCreatingOf(isRussianLocale ? ETranslations.PLURAL_GUESTS_ALT : ETranslations.PLURAL_GUEST)}
              </button>
            ) : undefined}
          />
        </HideWhen>
      </React.Suspense>
      <HideWhen condition={isTablet && !selectedClient && view === 'view'} noUnmount>
        <GuestCard clientId={selectedId} />
      </HideWhen>
    </>
  );
}

export function Guests() {
  const phone = useQueryParam('phone');
  return (
    <GuestViewContext initial={phone ? 'create' : 'view'}>
      <GuestsContainer />
    </GuestViewContext>
  );
}
