import React, {Dispatch, FC, SetStateAction} from 'react';
import {useIntl} from 'react-intl';

import {Card} from "ui-kit";
import {TButtons} from "../Control/Control";
import GuestForm from "./GuestForm";
import {Client} from "models/client.model";
import {TView} from "containers/Guests/GuestCard";
import {ETranslations} from 'types/translates';

type TProps = {
    guest?: Client;
    buttons: TButtons[]
    view: TView
    setView: Dispatch<SetStateAction<TView>>
}
export const GuestFormContainer: FC<TProps> = ({buttons, view, guest, setView}) => {
    const intl = useIntl();

    return (
        <>
            <Card.Header title={intl.formatMessage({id: ETranslations.GUEST_CARD})}/>
            <Card.Content noPadding>
                <GuestForm view={view} guest={guest} buttons={buttons} setView={setView}/>
            </Card.Content>
        </>
    );
};
