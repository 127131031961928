import { TimelineShift } from 'types/timeline';

export const useBlackoutZone = (
  timestamp: number,
  optionsData: TimelineShift[]
) =>
  optionsData.every(({ values, zone }) =>
    //используем BOOK так как маленькие интервалы, например 15 минут могут не прийти в BLACKOUT
    zone === 'BLACKOUT' || (values.length
        && (timestamp < values[0] || timestamp > values[values.length - 1])));
