import React from 'react';
import {getMonth, getYear} from 'date-fns';
import {ValueType} from 'react-select';

import {ICONS, Button} from '..';
import {monthsOptions, yearsOptions} from './helpers';
import {DatePickerSelect, MyOptionSelectType} from './DatePickerSelect';
import styles from './DatePicker.module.scss';

type CustomHeaderPropsType = {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  locale?: 'en_EN' | 'ru_RU'
}

export const datePickerHeader = (
  {
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
    changeMonth,
    locale
  } : CustomHeaderPropsType,
  minYear? :number
): React.ReactNode => {

  const localizedMonthOptions = monthsOptions(locale || 'en_EN')
  const changeMonthHandler = (e: ValueType<MyOptionSelectType, false>) => {
    if(!e) return;
    changeMonth(e.value);
  }

  const changeYearHandler = (e: ValueType<MyOptionSelectType, false>) => {
    if(!e) return;
    changeYear(+e.label);
  }

  const yearOptions = yearsOptions(minYear || 2020);
  const monthValue = localizedMonthOptions.find((el) => el.value === getMonth(date));
  const yearValue = yearOptions.find((el) => +el.label === getYear(date));

  return (
    <div className={styles.header}>
      <Button
        variant='phantom'
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className={styles.headerButton}
      >
        <ICONS.LeftArrow/>
      </Button>
      <DatePickerSelect
        options={localizedMonthOptions}
        value={monthValue}
        onChange={changeMonthHandler}
        menuPortalTarget={null}
      />
      <DatePickerSelect
        options={yearOptions}
        value={yearValue}
        onChange={changeYearHandler}
        menuPortalTarget={null}
      />
      <Button
        variant='phantom'
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className={styles.headerButton}
      >
        <ICONS.RightArrow/>
      </Button>
    </div>
  );
};
