import {useMemo, useRef, useState, VFC} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import FullCalendar from '@fullcalendar/react';
import cn from 'classnames';
import {useIntl} from "react-intl";

import {RadioButton} from 'ui-kit';
import {TNullable} from '../../types/commons';
import {WeekPicker} from './WeekPicker';
import {useDayjsLocale} from './useDayjsLocale';
import {ICONS} from 'ui-kit/ICONS';
import {ShiftsCalendar} from './Calendar/ShiftsCalendar';
import {ShiftsTable} from './Table/ShiftsTable';
import {HideWhen} from '../HideWhen';
import {ETranslations} from "../../types/translates";
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';

import styles from './shifts.module.scss';

enum EFastPeriods {
  CURRENT_WEEK = 'CURRENT_WEEK',
  NEXT_WEEK = 'NEXT_WEEK'
}

export type TPeriod = {
  start: Dayjs,
  end: Dayjs
}


export const Shifts: VFC = () => {
  useDayjsLocale();
  const schedulerRef = useRef<FullCalendar | null>(null);
  const date = useSelector(appContextSelectors.date);

  const [period, setPeriod] = useState<TPeriod>({
    start: dayjs(date.format())
      .startOf('isoWeek'),
    end: dayjs(date.format())
      .endOf('isoWeek'),
  });


  const [viewSwitcher, setViewSwitcher] = useState<'calendar' | 'table'>('calendar');

  const intl = useIntl();
  const fastPeriod = useMemo(() => {
    const periodWeekStart = period.start.startOf('isoWeek');
    const currentWeekStart = dayjs()
      .startOf('isoWeek');
    const isWeekStartsEqual = periodWeekStart.isSame(currentWeekStart);
    if (isWeekStartsEqual) {
      return EFastPeriods.CURRENT_WEEK;
    }
    const currentNextWeekStart = dayjs()
      .add(1, 'week')
      .startOf('isoWeek');
    const isNextWeekStartsEqual = periodWeekStart.isSame(currentNextWeekStart);
    if (isNextWeekStartsEqual) {
      return EFastPeriods.NEXT_WEEK;
    }
    return null;
  }, [period]);

  const getSchedulerApiInstance = () => {
    const schedulerApi = schedulerRef.current?.getApi();
    return schedulerApi;
  };

  const handleOnRadioButtonsChange = (newFastPeriod: TNullable<EFastPeriods>) => {
    const weekCount = Number(newFastPeriod !== EFastPeriods.CURRENT_WEEK);
    const startDate = dayjs()
      .startOf('isoWeek')
      .add(weekCount, 'week');
    setPeriod({
      start: startDate,
      end: dayjs()
        .add(weekCount + 1, 'week'),
    });
    const schedulerApi = getSchedulerApiInstance();
    if (!schedulerApi) return;
    schedulerApi.gotoDate(startDate.toDate());
  };

  const handleOnPeriodChange = (direction: 1 | -1) => {
    setPeriod(prev => ({
      start: prev.start.add(direction, 'week'),
      end: prev.end.add(direction, 'week'),
    }));
    const schedulerApi = getSchedulerApiInstance();
    if (!schedulerApi) return;
    const method = direction > 0 ? 'next' : 'prev';
    schedulerApi[method]();
  };

  return (
    <div className={styles.shifts}>
      <div className={styles.header}>
        <HideWhen condition={viewSwitcher === 'table'}>
          <div className={styles.leftBlock}>
            <RadioButton<TNullable<EFastPeriods>> value={fastPeriod} onChange={handleOnRadioButtonsChange}>
              <RadioButton.Button value={EFastPeriods.CURRENT_WEEK}>
                {intl.formatMessage({id: ETranslations.THIS_WEEK})}
              </RadioButton.Button>
              <RadioButton.Button value={EFastPeriods.NEXT_WEEK}>
                {intl.formatMessage({id: ETranslations.NEXT_WEEK})}
              </RadioButton.Button>
            </RadioButton>
            <WeekPicker
              startDate={period.start.toDate()}
              endDate={period.end.toDate()}
              onDatesChange={handleOnPeriodChange}
            />
          </div>
        </HideWhen>
        <div className={styles.viewSwitcher}>
          <ICONS.Burger
            className={cn(styles.switcherIcon, viewSwitcher === 'table' && styles.switcherIconActive)}
            onClick={() => setViewSwitcher('table')}
          />
          <ICONS.Plitka
            className={cn(styles.switcherIcon, viewSwitcher === 'calendar' && styles.switcherIconActive)}
            onClick={() => setViewSwitcher('calendar')}
          />
        </div>
      </div>
      <HideWhen condition={viewSwitcher === 'table'}>
        <ShiftsCalendar period={period} schedulerRef={schedulerRef}/>
      </HideWhen>
      <HideWhen condition={viewSwitcher === 'calendar'}>
        <ShiftsTable/>
      </HideWhen>
    </div>
  );
};
