import type { Status } from 'types/status';

export type FormattedStatus = Omit<
  Status,
  'step' | 'category' | 'tenant_id' | 'is_terminal'
>;

export type FormattedStatuses = {
  [
    // @ts-ignore
    category: `${Capitalize<string>}-${string}` | Capitalize<string>
  ]: FormattedStatus[];
};

const transformCategoryName = (str: Status['category']) =>
  str.replace(
    /(\p{L})([\p{L}]+)([^\p{L}])?([\p{L}]+)/gu,
    (_, p1, p2, p3, p4) => p1 + (p3 ? `${p2}-${p4}` : p2 + p4).toLowerCase()
  ) as `${Capitalize<string>}-${string}` | Capitalize<string>;

export const transformStatuses = (statuses: Status[]) => {
  //sort editable ?
  return statuses.reduce<FormattedStatuses>(
    (
      result,
      {
        id,
        locale,
        name,
        visible,
        color,
        is_active,
        is_extra,
        system_name,
        category,
      }
    ) => {
      const formattedStatus = {
        id,
        locale,
        name,
        system_name,
        visible,
        color,
        is_extra,
        is_active,
      };
      const categoryName = transformCategoryName(category);
      // @ts-ignore
      result[categoryName]?.push(formattedStatus)
        // @ts-ignore
        || (result[categoryName] = [formattedStatus]);
      return result;
    },
    {}
  );
};

export const sortStatuses = (statuses: FormattedStatus[]) =>
  statuses.sort(
    (a, b) => Number(a.is_extra || 0) - Number(b.is_extra || 0) || a.id - b.id
  );
