import {
  StylesConfig,
  GroupTypeBase,
  Props,
  OptionTypeBase,
} from 'react-select';
import { SelectBasic } from '../';

export type MyOptionSelectType = {
  label: string;
  value: number;
};

const styles: StylesConfig<MyOptionSelectType, false> = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'var(--dtpkr_background_1)',

    ':hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    font: 'var(--font-14-b)',
    color: 'var(--gl_text_inverse)',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  menuList: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 3,
    boxShadow: 'var(--shadow-medium)',
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--gl_text_secondary_disabled)',
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    position: 'absolute',
    top: 16,
    padding: 0,
    transform: state.selectProps.menuIsOpen ? 'rotate(0deg)' : 'rotate(180deg)',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 5px 5px 5px',
    borderColor: 'transparent transparent var(--gl_icon_primary_1) transparent',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    position: 'relative',
    width: 10,
    height: '100%',
    svg: {
      display: 'none',
    },
  }),
  option: (base, state) => ({
    ...base,
    font: 'var(--font-14-b)',
    color: 'var(--gl_text_inverse)',
    backgroundColor: state.isSelected
      ? 'var(--dtpkrListItem_active_background)'
      : 'var(--dtpkrListItem_default_background)',
    ':hover': {
      backgroundColor: 'var(--dtpkrListItem_hover_background)',
    },
  }),
  menu: (base) => ({
    ...base,
    border: 'none',
    backgroundColor: 'var(--select_default_background_1)',
    marginTop: '-5px',
  }),
};

type SelectProps<
  Option extends OptionTypeBase,
  IsMulti extends boolean = false,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
> = Props<Option, IsMulti, Group> & {
  label?: string;
  id?: string;
};

export const DatePickerSelect = <
  Option extends OptionTypeBase,
  IsMulti extends boolean = false,
  Group extends GroupTypeBase<Option> = GroupTypeBase<Option>
>(
  props: SelectProps<Option, IsMulti, Group>
) => {
  return (
    <SelectBasic
      // @ts-ignore
      styles={styles}
      isSearchable={false}
      maxMenuHeight={250}
      {...props}
    />
  );
};
