import {IResponse} from '../../models/common';
import {ScheduleLandingItem, ScheduleTime} from 'models/schedule.model';
import {api} from './api';


interface ScheduleParams {
  restaurant_id: number, date: string, is_confirmed: boolean, use_visit_time: boolean
}

export const scheduleApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllScheduleByRest: build.query<ScheduleLandingItem[], ScheduleParams>({
      keepUnusedDataFor: 0,
      query: (params) => ({
        url: '/reservation/booking/placement',
        method: 'GET',
        params,
      }),
      transformResponse: (response: IResponse<ScheduleLandingItem[]>) => response.data,
    }),
    getScheduleTimeByRest: build.query<ScheduleTime, number>({
      query: (restaurant_id) => ({
        url: '/schedule/rest/time',
        method: 'GET',
        params: {restaurant_id},
      }),
      transformResponse: (response: IResponse<ScheduleTime>) => response.data,
    }),
  }),
});

export const {useGetAllScheduleByRestQuery, useGetScheduleTimeByRestQuery} = scheduleApi;
