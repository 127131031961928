import React, {FC, useCallback, useState} from 'react';
import {useDeleteShiftMutation, useFetchShiftQuery} from 'features/api/shifts';
import {Modal, ModalProps} from '../../../modal';
import {InfoShiftsModalBody} from './InfoShiftsModalBody';
import {Button, ICONS} from 'ui-kit';
import {TNullable} from 'types/commons';
import styles from '../style.module.scss';
import {BaseShiftsModal} from '../BaseShiftsModal';
import {ETranslations} from 'types/translates';
import {useIntl} from 'react-intl';
import {ShiftModalType} from "types/shift";
import {EditFormContainer} from "../../Form/EditFormContainer/EditFormContainer";

type Props = {
    shiftId: TNullable<number>
} & Omit<ModalProps, 'children'>

export const InfoShiftsModal: FC<Props> = ({isOpen, onClose, title, shiftId}) => {
    if (!shiftId) return null
    const parentSelector = document.querySelector('#settings');
    if (!parentSelector) return null;

    const {data: shiftResponse, isLoading} = useFetchShiftQuery(shiftId)
    const [deleteShift, {isLoading: deleteIsLoading}] = useDeleteShiftMutation();
    const intl = useIntl();
    const [view, setView] = useState<ShiftModalType>('INFO')

    const deleteShiftHandler = useCallback(async () => {
        await deleteShift(shiftId);
        onClose()
    }, [deleteShift, shiftId])

    const editShiftHandler = useCallback( () => {
        !isLoading && setView("EDIT")
    }, [isLoading])

    return (
        <BaseShiftsModal
            onClose={view === 'EDIT' ? ()=>setView("INFO") : onClose}
            title={title}
            isOpen={isOpen}
        >
            {view === 'INFO'
                && <>
                    <Modal.Content className={styles.modalContent}>
                        <InfoShiftsModalBody
                            isLoading={isLoading || deleteIsLoading}
                            shift={shiftResponse}
                        />
                    </Modal.Content>
                    <Modal.Footer className={styles.modalFooter}>
                        <Button variant="secondary" disabled={isLoading} onClick={deleteShiftHandler}>
                            {intl.formatMessage({id: ETranslations.BASE_DELETE})}
                        </Button>
                        <Button variant="primary" disabled={isLoading} onClick={editShiftHandler}>
                            <ICONS.Edit/>
                        </Button>
                    </Modal.Footer>
                </>
            }
            {view === 'EDIT' && <EditFormContainer setView={setView} shift={shiftResponse}/>}
        </BaseShiftsModal>
    );
};
