import {ReactElement, useMemo, Children, ReactNode} from 'react';
import cn from 'classnames';
import {TabItemProps, TabItem} from './TabItem';
import {TabTitle} from './TabTitle';

import styles from './Tabs.module.scss';
import {TabAddon, TabAddonProps} from './TabAddon';

export interface TabsProps<T extends string> {
  activeTab: T;
  onChange(tab: T): void;
  children: ReactNode;
  className?: string;
}


function TabsContent<T extends string>({onChange, activeTab, children, className}: TabsProps<T>) {
  const tabs = useMemo(() => (
    Children.toArray(children) as ReactElement<TabItemProps<T>>[]).filter((el) => el.type === TabItem), [children]);
  const tabProps = useMemo(() => tabs.map((tab) => tab.props), [tabs]);
  const activeTabContent = useMemo(() => tabs.find((t) => t.props.tabName === activeTab), [activeTab, tabs]);

  const addons = useMemo(() => (Children.toArray(children) as ReactElement<TabAddonProps>[])
    .filter((el) => el.type === TabAddon), [children]);

  return (
    <div className={cn(styles.tabs, className)}>
      <nav className={styles.tabsList}>
        <ul className={styles.tabsContainer}>
          {tabProps.map((tab) => (
            <TabTitle
              key={tab.tabName}
              tabName={tab.tabName}
              title={tab.title}
              isActive={activeTab === tab.tabName}
              disabled={tab.disabled}
              onClick={onChange}
              icon={tab.icon}
            />
          ))}
        </ul>
        {addons}
      </nav>
      {activeTabContent && <div>{activeTabContent}</div>}
    </div>
  );
}

export const Tabs = Object.assign(TabsContent, {Item: TabItem, Addon: TabAddon});
