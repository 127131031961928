import React, {FC} from 'react';
import styles from '../Form.module.scss';
import {DatePicker, Input, Labeled, SelectBasic} from '../../../../ui-kit';
import {ETranslations} from '../../../../types/translates';
import cn from 'classnames';
import {Field} from 'react-final-form';
import {getHourOptions, getPlaceTitle, required, getBookingInterval} from '../../utils';
import {indefiniteChangeHandler, placesChangeHandler} from './utils';
import {requiredField} from '../../../registration/validators/validators';
import {ChoiceDateInput} from '../../../registration/sub-renders/date-time';
import Checkbox from '../../../../common/components/checkbox';
import WeekDays from '../WeekDays/WeekDays';
import {Modal} from '../../../modal';
import {useIntlUtils} from '../../../../hooks/useIntlUtils';
import {useIntl} from 'react-intl';
import {FormApi} from 'final-form';
import {TNullable} from '../../../../types/commons';
import {Shift} from '../../../../types/shift';
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';

type TProps = {
  form: FormApi<any, Partial<any>>,
  shift: TNullable<Partial<Shift>>
}

const PropertiesFields: FC<TProps> = (
  {
    form,
    shift
  }
) => {
  const {getIntlSelectEntity} = useIntlUtils();
  const intl = useIntl();
  const restaurant = useSelector(appContextSelectors.restaurant);

  return (
    <Modal.Content className={styles.modalContent}>
      <Labeled label={intl.formatMessage({id: ETranslations.SHIFT_NAME})} required
               className={cn(styles.personsInput, styles.row, styles.halfRow)}>
        <Field name="shift_name" required validate={required} autocomplete="off">
          {({
              input,
              meta,
              ...rest
            }) => <Input {...input} {...meta} {...rest} />}
        </Field>
      </Labeled>

      <div className={cn(styles.dateBlock, styles.row, styles.dateBlockFixed)}>
        <Labeled label={intl.formatMessage({id: ETranslations.START_DATE})} required
                 as={'div'}>
          <Field
            name="start_date"
            validate={(value) =>
              requiredField(value, getIntlSelectEntity(ETranslations.ERROR_DATE_NOT_SPECIFIED))}
            required
          >
            {(props) => (
              <DatePicker
                minDate={null}
                onChange={props.input.onChange}
                selected={props.input.value}
              />
            )}
          </Field>
        </Labeled>

        <Labeled label={intl.formatMessage({id: ETranslations.END_DATE})} as={'div'}>
          <Field
            name="end_date"
            component={ChoiceDateInput}
          >
            {(props) => (
              <DatePicker
                minDate={form.getFieldState('start_date')?.value}
                onChange={props.input.onChange}
                selected={props.input.value}
              />
            )}
          </Field>
        </Labeled>

        <Field name="continue_indefinite" type="checkbox">
          {({input}) => (
            <Checkbox
              indent="confirmed"
              text={intl.formatMessage({id: ETranslations.CONTINUE_INDEFINITE})}
              onChange={(value) => indefiniteChangeHandler(value, input.onChange, form, shift)}
              value={input.checked}
              useStopPropagation
              className={styles.propertiesCheckbox}
            />
          )}
        </Field>
      </div>

      <Labeled label={intl.formatMessage({id: ETranslations.DAYS_TO_CONTINUE_THIS_SHIFT})}
               required className={styles.row}>
        <Field name="repeatDays" required validate={required}>
          {({input}) => <WeekDays weekDays={input.value} onChange={input.onChange}/>}
        </Field>
      </Labeled>

      <div className={cn(styles.dateBlock)}>
        <Field name="open_time">
          {({input}) => (
            <Labeled label={intl.formatMessage({id: ETranslations.OPEN_TIME})}
                     className={styles.row}>
              <SelectBasic
                {...input}
                options={getHourOptions()}
                changedMaxSize
                isDisabled={input.disable}
                onChange={input.onChange}
              />
            </Labeled>
          )}
        </Field>

        <Field name="close_time">
          {({input}) => (
            <Labeled label={intl.formatMessage({id: ETranslations.CLOSE_TIME})}
                     className={styles.row}>
              <SelectBasic
                {...input}
                options={getHourOptions()}
                changedMaxSize
                isDisabled={input.disable}
                onChange={input.onChange}
              />
            </Labeled>
          )}
        </Field>
      </div>

      <div className={cn(styles.dateBlock)}>
        <Field name="booking_interval">
          {({input}) => {
            return(
            <Labeled label={intl.formatMessage({id: ETranslations.TIME_INTERVAL})} className={styles.row}>
              <SelectBasic {...input} options={getBookingInterval()} changedMaxSize isDisabled={input.disable} onChange={input.onChange} />
            </Labeled>
          )}}
        </Field>
      </div>

      <div className={cn(styles.dateBlock)}>
        <Labeled label={intl.formatMessage({id: ETranslations.SEATING_AREAS_AVAILABLE})}
                 className={styles.row}>
          <Field name={'places'} type="checkbox">
            {({input}) => {
              const values = form?.getFieldState('places')?.value;
              return restaurant.places.map(place => (
                <Checkbox
                  indent="confirmed"
                  text={getPlaceTitle(place.id, restaurant)}
                  onChange={(value) => placesChangeHandler(value, values, place.id, input.onChange)}
                  value={values?.includes(place.id)}
                  useStopPropagation
                  className={styles.seatCheckbox}
                  key={place.id}
                />
              ));
            }}
          </Field>
        </Labeled>
      </div>
    </Modal.Content>
  );
};

export default PropertiesFields;
