import moment from 'moment';
import {makeValidate} from 'utils';
import * as yup from 'yup';

export const exportFormat = [{value: 1, label: 'CSV'}, {value: 2, label: 'XLSX'}];

export const timeIntervals = [
  {
    label: '15 минут', value: 15,
  },
  {
    label: '30 минут', value: 30,
  },
  {
    label: '1 час', value: 60,
  },
];

export const defaultTimeInterval = timeIntervals[0];

export const initial = {
  startDate: new Date(),
  endDate: new Date(),
  useVisitTime: true,
  isConfirmed: false,
  isLoading: false,
  format: exportFormat.find((it) => it.label === 'XLSX'),
};


const schema = yup.object({
  format: yup.mixed().required('Обязательное поле'),
  startDate: yup.date().required('Обязательное поле'),
  endDate: yup.date().required('Обязательное поле'),
  startTime: yup.mixed().required('Обязательное поле'),
  endTime: yup.mixed().required('Обязательное поле'),
}).test(
  'startDate',
  'Дата начла должна быть раньше даты окончанич', function validatePassword({startDate, endDate}) {
    if (moment(endDate).isBefore(startDate)) {
      return this.createError({path: 'startDate'});
    }
    return true;
  },
)
  .test(
  'startDate',
  'Время начла должно быть до времени окончания', function validatePassword({startTime, endTime}) {
    if (moment(endTime?.label, 'HH:mm').isBefore(moment(startTime?.label, 'HH:mm'))) {
      return this.createError({path: 'startDate'});
    }
    return true;
  },
);


export const validate = makeValidate(schema);
