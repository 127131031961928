import React, {useCallback, useEffect, useState} from 'react';
import {userIsAdmin} from '../../common/helpers';
import {VerticalSeparator} from '../../common/components/VerticalSeparator';
import {PhoneIndicator} from './phone-indicator';
import {PhoneMenu} from './phone-menu';
import {PhoneModal} from './modal/phone-modal';
import {PhoneLines} from './lines/lines';
import {HideWhen} from '../HideWhen';
import {TelephonyAction} from '../../models/telephony.model';
import {CallsService} from '../../services/calls.service';
import {config} from 'config';
import {useInterval} from 'react-use';
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';

import styles from './telephony.module.scss';
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";

export const Telephony = () => {
  const intl = useIntl();
  const restaurant = useSelector(appContextSelectors.restaurant);
  const isWork = config.telephonyEnabled && !userIsAdmin();
  const [modalTrigger, setModalTrigger] = useState<boolean>(false);
  const [phoneLines, setPhoneLines] = useState<TelephonyAction[]>([]);

  const getActiveCalls = (restId: number) => {
    CallsService.getActiveCalls(restId)
      .then((activeCalls) => {
        if (activeCalls.data) {
          setPhoneLines(activeCalls.data);
        }
      });
  };

  useEffect(() => {
    if (isWork && restaurant) {
      getActiveCalls(restaurant.restaurant_id);
    }
  }, [restaurant]);
  useInterval(() => {
    if (isWork && restaurant) {
      getActiveCalls(restaurant.restaurant_id);
    }
  }, 5000);
  const isActive = (): boolean => phoneLines.length > 0;
  const showModal = useCallback((): any => {
    setModalTrigger(!modalTrigger);
  }, [modalTrigger]);
  return (
    <>
      <HideWhen condition={!isWork}>
        <>
          <VerticalSeparator />
          <div className={styles.container}>
            <PhoneIndicator isActive={isActive()} />
            <PhoneMenu clickHandler={showModal} />
            <PhoneModal
              visibility={modalTrigger}
              closeModal={showModal}
              title={intl.formatMessage({id: ETranslations.ACTIVE_CALLS})}
            >
              <PhoneLines data={phoneLines} onClose={() => showModal()} />
            </PhoneModal>
          </div>
        </>
      </HideWhen>
    </>
  );
};
