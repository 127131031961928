import {ReactNode} from 'react';
import cn from 'classnames';

import styles from './Modal.module.scss';

interface ModalFooterProps {
  children: ReactNode;
  className?: string;
}

export function ModalFooter({children, className}: ModalFooterProps) {
  return (
    <div className={cn(styles.modalFooter, className)}>
      {children}
    </div>
  );
}
