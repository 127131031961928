import {Modal} from "components/modal";
import {Form, Field} from "react-final-form";
import {useIntl} from "react-intl";
import {Button, Input, Labeled} from 'ui-kit';

import {UpdatePlaceDTO} from "types/place";
import {ETranslations} from "types/translates";
import {requiredField} from "components/registration/validators/validators";

import styles from './HallModal.module.scss'
import {useCallback} from "react";
import {useUpdateHallMutation} from "features/api/restaurants";
import {Place} from "models/restaurant.model";

interface HallModalProps{
  hall: Partial<UpdatePlaceDTO> | undefined;
  onClose: () => void;
  onAfterCreate?: (data: Place) => void;
}

export function HallModal({hall, onClose, onAfterCreate}: HallModalProps) {
  const intl = useIntl()
  const [updateHall] = useUpdateHallMutation();

  const onSubmit = useCallback(async (data: Partial<UpdatePlaceDTO>) => {
    const updateResult = await updateHall(data).unwrap();
    onClose();
    if (!data.id) {
      onAfterCreate?.({...updateResult, restaurant_id: updateResult.restaurant_id ?? data.restaurant_id});
    }
  }, [updateHall, onClose, onAfterCreate])
  return (
    <Modal
      title={intl.formatMessage({id: hall?.id ? ETranslations.UPDATE_HALL : ETranslations.CREATE_HALL})}
      onClose={onClose} isOpen={Boolean(hall)}>
      <Form onSubmit={onSubmit} initialValues={hall}>
        {({handleSubmit, invalid, submitting}) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Modal.Content className={styles.form}>
              <Labeled
                label={intl.formatMessage({id: ETranslations.HALL_NAME})}
                required as="div"
              >
                <Field
                  name="name"
                  validate={(value) => requiredField(value, intl.formatMessage({id: ETranslations.ERROR_DATE_NOT_SPECIFIED}))}
                >
                    {({input, meta}) => <Input {...input} {...meta} />}
                </Field>
              </Labeled>
               <Labeled
                label={intl.formatMessage({id: ETranslations.HALL_WEIGHT})}
                required as="div"
              >

                <Field
                  name="weight"
                  validate={(value) => requiredField(value, intl.formatMessage({id: ETranslations.ERROR_DATE_NOT_SPECIFIED}))}
                  parse={(v) => Number(v) || ''}
                >
                    {({input, meta}) => <Input type="number" min={1} {...input} {...meta} />}
                </Field>
              </Labeled>
            </Modal.Content>
            <Modal.Footer>
              <Button variant="primary" type="submit" disabled={submitting || invalid}>{
                hall?.id ? intl.formatMessage({id: ETranslations.BASE_SAVE}) : intl.formatMessage({id: ETranslations.BASE_CREATE})
              }</Button>
            </Modal.Footer>
          </form>
        )}
      </Form>
    </Modal>
  );
}
