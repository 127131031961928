import { Plugins } from '@technolab/hse';

import { PluginHost, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { Button, ICONS, Spinner } from 'ui-kit';

import { HallSlotsQResponse, useHallScheme } from 'features/api/hallschema-api';
import { useSelector } from 'react-redux';
import { appContextSelectors } from 'features/AppContex';
import styles from './NewScheme.module.scss';
import { memo } from 'react';
import { ShiftsTimeline } from '../../ShiftsTimeline/ShiftsTimeline';
import { HallMoveStatusControls } from '../HallMoveStatusControls';
import { PreserveScalePlugin } from './PreserveScalePlugin';
import { HallControlsPlugin } from './HallControlsPlugin/HallControlsPlugin';
import { HallLegend } from '../hall-legend';
import { BookingTable } from 'components/HallEditor/BookingTable';
import { TablePath } from 'components/HallEditor/TablePath';
import { useTableFigures } from 'features/api/tables-api';
import type { BookingTable as TBookingTable } from 'types/table';

interface NewSchemeProps {
  onBookingListClick?: () => void;
  hallInfo: HallSlotsQResponse[];
}

export const NewScheme = memo(
  ({ onBookingListClick, hallInfo }: NewSchemeProps) => {
    const placeId = useSelector(appContextSelectors.place);
    const scheme = useHallScheme({ placeId });
    const { data: figures, defaultFigure } = useTableFigures();

    if (!scheme || scheme.isFetching || !figures) {
      return <Spinner />;
    }

    const sectors = scheme.sectors.reduce<TBookingTable[][]>(
      (result, sector) => (
        sector.places
          && result.push(
            sector.places.map<TBookingTable>((place) => {
              const tableFigure
                = figures.find(
                  (figure) => Number(figure.figure_id) === place.figure_id
                ) || defaultFigure;
              return {
                tableId: place.hostesInfo.id,
                tableNumber: place.seat || place.hostesInfo.number,
                tableCapacity: place.hostesInfo.type,
                position: place.position,
                figure: {
                  figureId: place.figure_id,
                  width: tableFigure.width,
                  height: tableFigure.height,
                  shape: tableFigure.shape,
                },
              };
            })
          ),
        result
      ),
      Array()
    );

    return (
      <div className={styles.container}>
        <div className={styles.scheme}>
          <PluginHost>
            <Plugins.Core data={scheme}>
              <TemplatePlaceholder
                name={Plugins.HallContent.PLUGIN_TEMPLATES.root}
              />
            </Plugins.Core>
            <Plugins.HallContent />
            <Plugins.Figure />
            <Plugins.Place />
            <Plugins.Sector />
            <Plugins.Zoom scaleFactor={0.1} />
            <Plugins.SelectedPlace />
            <Plugins.SelectedFigures />
            <Plugins.FigureActions />
            <TablePath hallInfo={hallInfo} />
            <BookingTable hallInfo={hallInfo} sectors={sectors} />
            <PreserveScalePlugin padding={20} />
            <HallControlsPlugin />
          </PluginHost>
          {onBookingListClick && (
            <Button
              square
              variant="secondary"
              className={styles.bookingListButton}
              onClick={onBookingListClick}
            >
              <ICONS.Book />
            </Button>
          )}
          <HallLegend className={styles.legend} />
        </div>
        <HallMoveStatusControls />
        <ShiftsTimeline />
      </div>
    );
  }
);
