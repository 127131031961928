import {FunctionComponent, useCallback, useState} from "react";
import {SearchBlock} from "./SearchBlock";
import {BookingClientHistoryList} from "./BookingClientHistoryList";
import moment from "moment";
import {OrderClientHistoryList} from "./OrderClientHistoryList";
import { GuestBadge } from 'components/GuestInfo';
import {useFetchClientQuery} from '../../features/api/guest-api';
import {useAllStatuses} from '../../features/api/dictionaries-api';


interface Props {
  clientId: number;
}

export interface IPayloadClientHistory {
  from: Date | string,
  to: Date | string,
  statuses?: string[],
  count: number,
  offset: number,
}

export type TModeHistory = 'order' | 'booking'

const initialPayload = {
  from: moment().format('YYYY-MM-DD'),
  to: moment().format('YYYY-MM-DD'),
  statuses: [],
  count: 10,
  offset: 0,
};

export const BookingClientHistory: FunctionComponent<Props> = ({clientId}) => {
  const {data: statusList} = useAllStatuses();
  const statuses = statusList.filter((status) => !status.is_extra);
  const systemNameArray = statuses.map(status => status.system_name);
  const [payload, setPayload] = useState<IPayloadClientHistory>({...initialPayload, statuses: systemNameArray});
  const [mode, setMode] = useState<TModeHistory>('booking');
  const {data : client} = useFetchClientQuery(clientId)

  if (!clientId) return null;

  const paginationHandler = useCallback((offset:number) => {
    setPayload((oldState) => ({...oldState, offset: (offset * oldState.count) - oldState.count}));
  }, []);

  return (
    <section>
      <GuestBadge guest={client} isHideEditPhoto/>
      <SearchBlock statuses={statuses} mode={mode} payload={payload} setPayload={setPayload} setMode={setMode}/>
      {
        mode === 'booking'
          ? (<BookingClientHistoryList mode={mode} payload={payload} clientId={clientId} paginationHandler={paginationHandler} />)
          : (<OrderClientHistoryList mode={mode} clientId={clientId} payload={payload} paginationHandler={paginationHandler} />)
      }
    </section>
  );
};
