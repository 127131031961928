import { useCallback, useMemo, useRef, useState } from 'react';
import { useUnmount, useUpdateEffect } from 'react-use';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { config } from 'config';
import { useHallSchemaActions } from 'features/HallSchema';
import { useFromProxyActions } from 'features/BookingFormProxy';
import { selectFormBooking } from 'features/BookingFormProxy/selectors';
import { appContextSelectors } from 'features/AppContex';
import { useGuestListActions } from 'features/GuestsList';
import { invalidateHallSlots } from 'features/api/hallschema-api';
import { useAppUsers } from 'contexts/AppUsersContext';
import { useQueryParam } from 'hooks/useQueryParam';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { FormBooking, Contact } from 'features/BookingFormProxy/types';
import { getTimeOption } from '../utils';
import { activeTablesSelector } from 'features/HallSchema/selectors';
import { timelineSelectors } from 'features/Timeline';
import { FormProxyProps } from '../types';
import useClosestInterval from 'hooks/useClosestInterval';
import type { Client } from 'types/client';
import { useIntl } from 'react-intl';
import { ETranslations } from 'types/translates';

const getClientOrContactInfo = <T extends Client | Contact>(
  client: T,
  phone: T extends Client ? string : undefined,
  placeholder?: string
) => {
  const surname = client?.surname?.trim() || '';
  const middleName = client?.middle_name?.trim() || '';
  const name
    = client?.name?.trim() || (middleName || surname ? '' : placeholder);
  return phone
    ? ({
        client: {
          ...client,
          name,
          middle_name: middleName,
          surname,
        },
      } as { client: Client })
    : ({
        contact: {
          name,
          middle_name: middleName,
          surname,
          contact_type: 'CONTACT',
        },
        client: null,
      } as { contact: Contact; client: null });
};

const useBookingFormReduxProxy = <P>({
  isRegisterVisit,
  isCreating = true,
  booking,
  isAnonym,
  ...rest
}: FormProxyProps<P>) => {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const { formatMessage } = useIntl();

  const dispatchRedux = useDispatch();
  const startDate = useSelector(appContextSelectors.date);
  const currentPlace = useSelector(appContextSelectors.place);
  const visitTime = useSelector(timelineSelectors.getVisistTime);
  const place = useSelector(appContextSelectors.place);

  // Store prev state
  const formValuesRef = useRef(undefined);
  const activeTables = useSelector(activeTablesSelector);
  const { setActiveTables } = useHallSchemaActions();
  const { reset: resetForm, resetClient } = useFromProxyActions();
  const { reset: resetGuestsList } = useGuestListActions();
  const { currentUser } = useAppUsers();
  const { submitFn } = rest;
  const formData = useSelector(selectFormBooking);

  const onSubmit = useCallback(
    async (values: any) => {
      //FIXME: убрать костыль после мерджа фичи
      values.phone === config.defaultPhoneNumber && (values.phone = undefined);
      const payload = {
        ...values,
        placeId: currentPlace,
        statePlace: place,
        restaurant_id: restaurant.restaurant_id,
      };

      // FIXME: поправка на поле contact/client. отредактировать после полного перехода к полю contact
      const correctPayload = {
        ...payload,
        ...getClientOrContactInfo(
          values.client,
          values.phone,
          formatMessage({ id: ETranslations.PLURAL_GUEST })
        ),
      };

      await submitFn(correctPayload as FormBooking)?.then(() => {
        // После отправки формы обновляем начальные значения. Для корректной работы последующего редактирования
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        updateInitial(payload);
        dispatchRedux(invalidateHallSlots());
      });
    },
    [submitFn, dispatchRedux, startDate, currentPlace]
  );

  const initialPhone = useQueryParam('phone') as string | undefined;
  const closestInterval = useClosestInterval();
  const russianCodePrefix = useMemo(() => {
    if (config.defaultPhoneNumber && isCreating && !formData.phone) {
      return config.defaultPhoneNumber;
    }

    return formData.phone;
  }, [formData]);

  // eslint-disable-next-line no-underscore-dangle
  const _initial = useMemo(
    () => ({
      ...formData,
      phone: initialPhone
        ? formatPhoneNumberIntl(`+${initialPhone}`) || russianCodePrefix
        : russianCodePrefix,
      // @ts-ignore
      time: isRegisterVisit
        ? getTimeOption(closestInterval || formData?.booking_time)
        : getTimeOption(formData?.booking_time),
      hostess: currentUser,
      date:
        formData?.booking_date && formData.booking_time
          ? moment(
              `${formData?.booking_date} ${formData?.booking_time}`
            ).toDate()
          : startDate.toDate(),
      deposit: formData?.deposit || {
        use_deposit: false,
        deposit_made: false,
        deposit_amount: 0,
      },
      restaurant_id: formData.restaurant_id || restaurant.restaurant_id,
      visit_time: formData.visit_time ?? 120,
      tables: formData.tables ?? activeTables ?? [],
      initialVisitTime: visitTime,
      persons: formData.persons ?? null,
    }),
    [
      formData.bookingId,
      formData.tables,
      formData.phone,
      initialPhone,
      currentUser,
      isRegisterVisit,
      closestInterval,
      booking?.bookingId,
    ]
  );

  // ЭТО КОСТЫЛЬ ЧТОБЫ ПРЕДОТВРАТИТЬ РЕ-РЕНДЕР ФОРМЫ
  const [initial, updateInitial] = useState(_initial);

  useUpdateEffect(() => {
    updateInitial(_initial);
  }, [
    _initial.bookingId,
    _initial.tables.length,
    _initial.time?.label,
    _initial.hostess,
  ]);
  // КОНЕЦ КОСТЫЛЯ

  useUnmount(() => {
    setActiveTables({ activeTables: [] });
    resetForm();
    resetGuestsList();
    resetClient();
  });

  const dispatchEventFn = (formValues: any) => {
    formValuesRef.current = formValues;
  };

  return {
    onSubmit,
    initial,
    dispatchEventFn,
  };
};

export default useBookingFormReduxProxy;
