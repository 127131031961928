import { useSelector } from 'react-redux';
import { SingleOption } from 'models/common';
import { appContextSelectors } from 'features/AppContex';
import { useApplicationContextActions } from 'features/AppContex/slice';
import { useCallback, useMemo } from 'react';
import { SelectBasic } from 'ui-kit';
import { hallModeSelector } from 'features/HallSchema/selectors';
import {
  isBookingView,
  isRegView,
} from 'components/hall-scheme/redux/HallSchemaV2/hall-helpersFn';
import { HallMode, useHallSchemaActions } from 'features/HallSchema';
import { OpenScheme } from 'components/registration/sub-renders/OpenScheme';
import { preventDefault, stopPropagation } from 'utils';
import { useTimelineActions } from 'features/Timeline';

interface PlaceSelectProps {
  disabled?: boolean;
  isNewDesign?: boolean;
  styles?: any;
  manageralBookingTimeStamp?: number;
  placeId?: unknown;
}

export function PlaceSelect({
  disabled,
  isNewDesign,
  styles,
  manageralBookingTimeStamp = 0,
}: PlaceSelectProps) {
  const { setPlace } = useApplicationContextActions();
  const { setActiveTables } = useHallSchemaActions();
  const { setTimestamp } = useTimelineActions();

  const placesOptions = useSelector(appContextSelectors.placesOptions);
  const place = useSelector(appContextSelectors.place);

  const handleChange = useCallback(
    (opt: SingleOption | null) => {
      setActiveTables({ activeTables: [] });
      setPlace(opt ? opt.value : null);
    },
    [setPlace]
  );

  const value = useMemo(
    () =>
      placesOptions.find((el) => {
        return el.value === place;
      }),
    [place]
  );

  const hallMode = useSelector(hallModeSelector);
  const { switchMode } = useHallSchemaActions();

  const showSchema = (event: any) => {
    preventDefault(event);
    stopPropagation(event);

    if (manageralBookingTimeStamp) {
      setTimestamp(manageralBookingTimeStamp);
    }

    if (isRegView(hallMode)) {
      switchMode(HallMode.REGISTRATION_HALL);
      return;
    }
    if (hallMode === HallMode.MANAGERAL_CREATE_BOOKING) {
      switchMode(HallMode.MANAGARAL_HALL_BOOKING);
      return;
    }

    if (hallMode === HallMode.MANAGARAL_TABLET) {
      switchMode(HallMode.MANAGARAL_HALL);
      return;
    }
    if (isBookingView(hallMode)) {
      switchMode(HallMode.BOOKING_HALL);
      return;
    }
    switchMode(HallMode.EDIT_HALL);
  };

  return (
    <div className={styles?.placeSelect}>
      <SelectBasic
        className={disabled ? 'input' : ''}
        isSearchable={false}
        options={placesOptions}
        value={value}
        isDisabled={disabled}
        onChange={handleChange as any}
      />
      {isNewDesign ? (
        <button disabled={disabled} className={styles?.openScheme}>
          <OpenScheme onClick={showSchema} />
        </button>
      ) : null}
    </div>
  );
}
