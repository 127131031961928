import { FunctionComponent } from 'react';
import moment from 'moment';
import { useFetchBookingHistoryDetailsChronologicalStatusQuery } from 'features/api/bookings-api';
import { Spinner } from 'ui-kit';
import { Tag } from '../Tag';
import { useIntl } from 'react-intl';
import { ETranslations } from '../../types/translates';
import { capitalizeString } from '../../utils';
import type { Status } from 'types/status';

interface Props {
  bookingId: number;
}

const BookingHistoryModalStatus: FunctionComponent<Props> = ({ bookingId }) => {
  const intl = useIntl();
  const { data }
    = useFetchBookingHistoryDetailsChronologicalStatusQuery(bookingId);

  if (!data) return <Spinner />;

  return (
    <div className="bookingHistoryStatusBody">
      <div className="bookingHistorySectionHeader">
        <div className="bookingHistoryValue bookingHistoryTitle">
          {intl.formatMessage({ id: ETranslations.BASE_DATE })}
        </div>
        <div className="bookingHistoryValue">
          {capitalizeString(
            intl.formatMessage({ id: ETranslations.PLURAL_STATUS })
          )}
        </div>
      </div>
      {data.map(({ timeKey, status }) => (
        <div className="bookingHistoryStatusSection" key={timeKey.toString()}>
          <div className="bookingHistoryValue bookingHistoryTitle">
            {moment(timeKey).format('DD.MM.YYYY HH:mm')}
          </div>
          <div>
            <div className="bookingHistoryValue">
              <Tag
                tag={{
                  color: status.color,
                  name: status.name,
                  // todo: status отвечает за стиль который будет подставлен, переделать
                  status: status.color,
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BookingHistoryModalStatus;
