import {useCallback} from "react";
import {useHistory} from "react-router";
import {useLazyFetchClientQuery, useUpdateGuestMutation} from "features/api/guest-api";
import {HallMode, useHallSchemaActions} from "features/HallSchema";
import {useIsTabletBooking} from "hooks/useIsTablet";
import {useIntlUtils} from "../../../../../hooks/useIntlUtils";
import {useCreateWithOverbooking} from "./useCreateWithOverbooking";


const useCreateBooking = (reset: any, isFromManagement?: boolean) => {
    const {getIntlEntityCreation, isRussianLocale, getIntlAddOf, intl} = useIntlUtils();
    const {create, createWithOverbooking, isConfirmationRequired, clearData, isCreating, hasManageralError, closeModal}
        = useCreateWithOverbooking();
    const history = useHistory<{ from: string } | null>();
    const {switchMode} = useHallSchemaActions();
    const isTablet = useIsTabletBooking();
    const [update] = useUpdateGuestMutation()
    const [getClient] = useLazyFetchClientQuery()


    const close = useCallback(() => {
        reset();
        if (!isFromManagement) {
            const {from} = history.location.state || {};
            if (from !== history.location.pathname) {
                history.push(from ?? "/");
            }
        }
    }, [history, reset]);

    const createBooking = useCallback(
        async (data) => {
            const {
                client = {},
            } = data;
            if (client?.editClient && client?.name && client?.client_id) {
                const {client_id, name, surname = "", middle_name = ""} = client;
                await getClient(client_id).then(async res => {
                    const newUser = {...res.data, surname, name, middle_name, type_authorization: "AUTH"};
                    //@ts-ignore
                    await update(newUser);
                })
            }
            await create({...data, seatType: isFromManagement ? "MANAGER_BOOK" : "BOOK",});
            close();
        },
        [create, reset, history]
    );

    const createWithOver = useCallback(async () => {
        await createWithOverbooking();
        close();
    }, [createWithOverbooking, reset]);

    const handleChangeMode = () => {
        switchMode(isTablet ? HallMode.MANAGARAL_TABLET : HallMode.MANAGARAL_BOOKING);
    };

    return {
        isRussianLocale,
        getIntlEntityCreation,
        getIntlAddOf,
        handleChangeMode,
        intl,
        createBooking,
        isConfirmationRequired,
        clearData,
        createWithOver,
        isCreating,
        close,
        hasManageralError,
        closeModal
    }
}

export default useCreateBooking
