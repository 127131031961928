import {useCallback, useMemo} from "react";
import moment from 'moment';
import {isEmpty} from "lodash";
import {useSelector} from "react-redux";
import {useIntl} from 'react-intl';
import {useUnmount} from 'react-use';
import {useIsTablet} from "../../../hooks/useIsTablet";
import {useIntlUtils} from "../../../hooks/useIntlUtils";
import {Client} from '../../../types/client';
import {BookingOrder} from "../../../types/booking";
import {useTimelineActions} from '../../../features/Timeline';
import {useApplicationContextActions} from '../../../features/AppContex';
import {useFromProxyActions} from "../../../features/BookingFormProxy";
import {selectOrder} from "../../../features/BookingFormProxy/selectors";
import {hallModeSelector} from '../../../features/HallSchema/selectors';
import {HallMode, useHallSchemaActions} from '../../../features/HallSchema';
import {getBookingStartTime} from '../../../utils';

const useBookingOrders = () => {
    const {getIntlEntityViewingAction} = useIntlUtils();
    const mode = useSelector(hallModeSelector);
    const {setOnlyOrder, reset, setClient} = useFromProxyActions();
    const orderSelected = useSelector(selectOrder);
    const isTablet = useIsTablet();
    const {switchMode} = useHallSchemaActions();
    const intl = useIntl();
    const {setTime, resetTimeShift} = useTimelineActions()
    const {setPlaceFromOrder, setDate} = useApplicationContextActions();


    const hasOrder = useMemo(() => !isEmpty(orderSelected) && !!orderSelected.orderId, [orderSelected]);
    const close = useCallback(() => {
        setOnlyOrder(undefined);
        resetTimeShift()
        setDate(moment())
    }, [setOnlyOrder]);

    const onSelectOrder = (order: BookingOrder) => {
        reset();
        setPlaceFromOrder(order)
        setOnlyOrder(order);
        setTime(getBookingStartTime(order));
        setDate(moment(order.bookingDate))
    };

    const handleClose = useMemo(() => {
        if (!hasOrder) return undefined;
        return close;
    }, [hasOrder]);

    const closeHall = useCallback(() => switchMode(HallMode.TABLES), [switchMode]);

    const closeGuests = useCallback(() => switchMode(HallMode.TABLE_BOOKINGS_EDIT), [switchMode]);


    const handleSetClient = useCallback((client: Client) => {
        setClient({client});
        closeGuests();
    }, [setClient, closeGuests]);

    useUnmount(() => setDate(moment()));

    return {
        intl,
        isTablet,
        hasOrder,
        onSelectOrder,
        handleClose,
        mode,
        orderSelected,
        getIntlEntityViewingAction,
        closeGuests,
        handleSetClient,
        closeHall,
    }
}

export default useBookingOrders