import React, {useCallback} from "react";
import cn from "classnames";
import {preventDefault, stopPropagation} from "utils";

interface SlotProps {
  label: string;
  value: number;
  disableButton: boolean;
  classname: any;
  onChange: (value: any) => void;
  isMin?: boolean;
  hourOfCurrentMinuteSlot?: string;
  firstAvailableMinuteSlot?: string;
}

export function Slot({
  label,
  value,
  disableButton,
  classname,
  onChange,
  isMin = false,
  hourOfCurrentMinuteSlot,
  firstAvailableMinuteSlot,
}: SlotProps) {
  const handleChangeTime = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      preventDefault(event);
      stopPropagation(event);
      if (!isMin) {
        onChange({value, label: `${label}:${firstAvailableMinuteSlot}`});
        return;
      }
      onChange({value, label: `${hourOfCurrentMinuteSlot}:${label}`});
    },
    [value]
  );

  return (
    <button key={`${label}-${value}`} disabled={disableButton} onClick={handleChangeTime} className={cn(classname)}>
      {label}
    </button>
  );
}
