import { coreApi } from './core';
import { invalidateHallSlots } from '../hallschema-api';
import { IPayloadClientHistory } from 'components/BookingClientHistory/BookingClientHistory';
import {
  BookingHistoryClientResponse,
  BookingHistoryDetailsChronologicalChangesResponse,
  BookingHistoryDetailsChronologicalPlacesResponse,
  BookingHistoryDetailsChronologicalStatusResponse,
  BookingHistoryDetailsParamResponse,
  BookingHistoryResponse,
} from 'types/booking';
import { IResponse } from 'models/common';

const bookingHistoryApi = coreApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    fetchBookingHistory: build.query<BookingHistoryResponse, number | null>({
      query: (bookingId) => ({
        url: `v2/booking/history/${bookingId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      transformResponse: (response: IResponse<BookingHistoryResponse>) =>
        response.data,
      providesTags: (_res, _err, bookingId) => [
        { type: 'BookingHistory', id: Number(bookingId) },
      ],
    }),
    fetchBookingHistoryDetailsParam: build.query<
      BookingHistoryDetailsParamResponse,
      number
    >({
      query: (id: number) => ({
        url: `v2/booking/history/details/params/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: IResponse<BookingHistoryDetailsParamResponse>
      ) => response.data,
    }),
    fetchBookingHistoryDetailsChronologicalStatus: build.query<
      BookingHistoryDetailsChronologicalStatusResponse,
      number
    >({
      query: (id: number) => ({
        url: `v2/booking/history/details/chronological/status/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: IResponse<BookingHistoryDetailsChronologicalStatusResponse>
      ) => response.data,
    }),
    fetchBookingHistoryDetailsChronologicalPlaces: build.query<
      BookingHistoryDetailsChronologicalPlacesResponse,
      number
    >({
      query: (id: number) => ({
        url: `v2/booking/history/details/chronological/places/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: IResponse<BookingHistoryDetailsChronologicalPlacesResponse>
      ) => response.data,
    }),
    fetchBookingHistoryDetailsChronologicalChanges: build.query<
      BookingHistoryDetailsChronologicalChangesResponse,
      number
    >({
      query: (id: number) => ({
        url: `v2/booking/history/details/chronological/changes/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      transformResponse: (
        response: IResponse<BookingHistoryDetailsChronologicalChangesResponse>
      ) => response.data,
    }),
    recoverBookingStatus: build.mutation<String, { bookingId: number }>({
      query: ({ bookingId }) => ({
        url: `v2/booking/recover/status/${bookingId}`,
        method: 'post',
      }),
      invalidatesTags: (res, error, { bookingId }) => [
        'Bookings',
        { type: 'Booking', id: bookingId },
        { type: 'BookingStatus', id: bookingId },
        'GlobalSearchBookings',
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(invalidateHallSlots());
      },
    }),
    bookingHistoryClient: build.query<
      BookingHistoryClientResponse,
      { clientId: number; payload: IPayloadClientHistory }
    >({
      query: ({ clientId, payload }) => ({
        url: `v2/booking/history/client/${clientId}`,
        method: 'POST',
        body: payload,
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useFetchBookingHistoryQuery,
  useFetchBookingHistoryDetailsParamQuery,
  useFetchBookingHistoryDetailsChronologicalStatusQuery,
  useFetchBookingHistoryDetailsChronologicalPlacesQuery,
  useFetchBookingHistoryDetailsChronologicalChangesQuery,
  useBookingHistoryClientQuery,
  useRecoverBookingStatusMutation,
} = bookingHistoryApi;
