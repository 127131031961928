import React, { FC } from 'react';
import { ICONS } from 'ui-kit';
import { SvgForeignObjectBody } from '../../SvgForeignObject';

import styles from './style.module.scss';

interface Props {
  isBookingSoon: boolean;
  tableNumber: number;
  statusColor?: string;
}

export const TableNumber: FC<Props> = ({
  tableNumber,
  isBookingSoon,
  statusColor,
}) => (
  <SvgForeignObjectBody as="div" className={styles.tableNumberBody}>
    <div
      style={{
        border: `7px solid ${
          statusColor || 'var(--floorplanNumber_empty_stroke)'
        }`,
      }}
      className={styles.tableNumber}
    >
      {isBookingSoon && (
        <ICONS.ClockAlarm width={35} height={35} className={styles.alarmIcon} />
      )}
      {tableNumber}
    </div>
  </SvgForeignObjectBody>
);
