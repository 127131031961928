import { skipToken } from '@reduxjs/toolkit/query/react';
import { BookingResponse } from '../../../models/booking.model';
import { BookingsFilter } from '../../../services/bookings.service';
import {
  Booking,
  BookingFilterParams,
  BookingRequestFilter,
  SearchResponse,
  TUseBookingList,
} from 'types/booking';
import { IResponse } from '../../../models/common';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { appContextSelectors } from 'features/AppContex';
import moment from 'moment';
import { Response, type ErrorResponse } from 'types/commons';
import { FormBooking } from 'features/BookingFormProxy/types';
import {
  castFormToCreateDTO,
  castFormToUpdateDTO,
} from 'features/BookingFormProxy/utils';
import { Notification } from 'services/notification';
import { bookingFormSliceActions } from 'features/BookingFormProxy';
import { coreApi } from './core';
import { GlobalSearchParams } from 'types/globalSearch';
import { PageableResponse } from 'types/response';
import { useAllStatuses } from '../dictionaries-api';
import { ETranslations } from '../../../types/translates';
import { TBookingUpdateParams } from '../../../types/IBookingDTO';
import { useIsTabVisible } from 'hooks/useIsTabVisible';
import type { ExtraStatus, Status } from 'types/status';
import { guestApi } from '../guest-api';
import { config } from 'config';

export const bookingApi = coreApi.injectEndpoints({
  endpoints: (build) => ({
    fetchBookingSearch: build.query<BookingResponse[], BookingsFilter>({
      query: (filter: BookingsFilter) => ({
        url: 'reservation/booking/search',
        method: 'POST',
        body: filter,
      }),
      transformResponse: (response: IResponse<BookingResponse[]>) =>
        response.data,
    }),
    fetchActiveBookings: build.query<SearchResponse, BookingRequestFilter>({
      query: (filter: BookingRequestFilter) => ({
        url: 'v2/booking/search',
        method: 'POST',
        body: filter,
      }),
      transformResponse: (response: Response<SearchResponse>) => response.data,
      providesTags: ['Bookings'],
    }),
    fetchTerminateBookings: build.query<SearchResponse, BookingRequestFilter>({
      query: (filter: BookingRequestFilter) => ({
        url: 'v2/booking/search/completed',
        method: 'POST',
        body: filter,
      }),
      transformResponse: (response: Response<SearchResponse>) => response.data,
      providesTags: ['Bookings'],
    }),
    getBookings: build.query<SearchResponse, BookingFilterParams>({
      query: (filter: BookingFilterParams) => ({
        url: 'v2/booking/filter',
        method: 'POST',
        body: filter,
      }),
      transformResponse: (response: Response<SearchResponse>) => response.data,
      providesTags: ['Bookings'],
      keepUnusedDataFor: 0,
      async onQueryStarted(filter, { queryFulfilled, dispatch }) {
        try {
          const { data: bookingsResponse } = await queryFulfilled;
          if (config.BRAND === 'DUBAI') {
            const userIds = new Set<number>();
            bookingsResponse.bookings.forEach(
              (book) =>
                book.client?.client_id && userIds.add(book.client.client_id)
            );

            if (userIds.size) {
              const { data: clientsResponse } = await dispatch(
                guestApi.endpoints.updateGuestsForBookings.initiate(
                  Array.from(userIds),
                  { forceRefetch: 30 }
                )
              );

              const bookings = bookingsResponse.bookings.map((book) => ({
                ...book,
                client:
                  clientsResponse?.data.find(
                    (client) => client.client_id === book.client?.client_id
                  ) || book.client,
              }));

              dispatch(
                bookingApi.util.updateQueryData(
                  'getBookings',
                  filter,
                  (draft) => {
                    draft.bookings = bookings as Booking[];
                  }
                )
              );
            }
          }
        } catch (err) {
          const errorData = (err as ErrorResponse)?.error?.data;
          if (errorData?.errorCode) {
            Notification.error({
              title: errorData?.errorMessage,
            });
          }
          throw err;
        }
      },
    }),
    registerBooking: build.mutation<FormBooking, any>({
      query: ({
        data,
        force = false,
      }: {
        data: FormBooking;
        force?: boolean;
      }) => ({
        url: 'v2/booking/register',
        params: { force },
        method: 'POST',
        body: castFormToCreateDTO(data),
      }),
      invalidatesTags: ['Bookings', 'TableOptions'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          const errorData = (err as ErrorResponse)?.error?.data;
          if (errorData?.errorCode) {
            Notification.error({
              title: errorData?.errorMessage,
            });
          }
          throw err;
        }
      },
    }),
    getBooking: build.query<Booking, number>({
      query: (id: number) => ({
        url: `v2/booking/${id}`,
        method: 'get',
      }),
      transformResponse: (response: IResponse<Booking>) => response.data,
      providesTags: (booking) => [{ type: 'Booking', id: booking?.bookingId }],
      keepUnusedDataFor: 0,
      async onQueryStarted(id, state) {
        if (config.BRAND === 'DUBAI') {
          try {
            const response = await state.queryFulfilled;
            const clientId = response.data.client?.client_id;
            if (clientId) {
              const client = await state.dispatch(
                guestApi.endpoints.fetchClient.initiate(clientId, {
                  forceRefetch: 30,
                })
              );

              const data = client.data || response.data.client;
              state.dispatch(
                bookingApi.util.updateQueryData('getBooking', id, (draft) => {
                  draft.client = data;
                })
              );
            }
          } catch (e) {
            console.error('error', e);
          }
        }
      },
    }),
    getBookingExtraStatuses: build.query<ExtraStatus[], number>({
      query: (bookingId) => ({
        url: `v2/status/extra/${bookingId}`,
        method: 'get',
      }),
      transformResponse: (response: IResponse<ExtraStatus[]>) =>
        response.data.filter(({ is_active }) => is_active),
      providesTags: (_res, _err, bookingId) => [
        { type: 'BookingExtraStatus', id: bookingId },
        'Statuses',
        'Bookings',
      ],
    }),
    createBooking: build.mutation({
      query: ({
        data,
        isOverbooking = false,
      }: {
        data: FormBooking;
        isOverbooking?: boolean;
      }) => ({
        url: 'v2/booking/create',
        method: 'POST',
        params: { force: isOverbooking || false },
        body: castFormToCreateDTO(data),
      }),
      invalidatesTags: ['Bookings', 'TableOptions', 'GlobalSearchBookings'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          const errorData = (err as ErrorResponse)?.error?.data;
          errorData?.errorCode !== 10100
            && Notification.error(
              errorData?.errorCode === 10600
                ? {
                    title: ETranslations.UNABLE_TO_CREATE_BOOKING_COVERAGE,
                  }
                : {
                    title: ETranslations.UNABLE_TO_CREATE_BOOKING,
                    message: errorData?.errorMessage,
                  }
            );
          throw err;
        }
      },
    }),
    updateBooking: build.mutation({
      query: ({ force, bookingId, ...body }: TBookingUpdateParams) => ({
        url: `v2/booking/${bookingId}`,
        method: 'PUT',
        body,
        params: {
          force,
        },
      }),
      invalidatesTags: (result, err, args) => [
        'Bookings',
        { type: 'Booking', id: args.bookingId },
        { type: 'BookingHistory', id: args.bookingId },
        'TableOptions',
        'GlobalSearchBookings',
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data },
          } = await queryFulfilled;
          dispatch(
            bookingFormSliceActions.setBooking({
              booking: data,
              client: data.client,
            })
          );
          Notification.success({
            title: ETranslations.BOOKING_UPDATE_SUCCESSFULLY,
          });
        } catch (err) {
          const errorData = (err as ErrorResponse)?.error?.data;
          if (errorData?.errorCode !== 10100) {
            Notification.error({
              title: ETranslations.UNABLE_TO_UPDATE_BOOKING,
              message: errorData?.errorMessage,
            });
          }
          throw err;
        }
      },
    }),
    applyExtraStatus: build.mutation({
      query: ({
        bookingId,
        statusId,
      }: {
        bookingId: number;
        statusId: number;
      }) => ({
        url: `v2/status/applyExtra/${bookingId}`,
        method: 'POST',
        params: { extra_status_id: statusId },
      }),
      invalidatesTags: (result, err, args) => [
        'Bookings',
        'GlobalSearchBookings',
        { type: 'Booking', id: args.bookingId },
        { type: 'BookingHistory', id: args.bookingId },
      ],
      async onQueryStarted(args, { queryFulfilled }) {
        queryFulfilled
          .then(({ data }) => {
            /* if (data === 'ERROR') throw data; */
          })
          .catch((e) => {
            if (e.error.data.errorCode === 10000) {
              return Notification.error({
                title: e.error.data.errorMessage,
              });
            }

            if (e.status === 'ERROR') {
              return Notification.error({
                title: ETranslations.ERROR_UNABLE_TO_EDIT_STATUS,
                message: e.error?.message,
              });
            }
            return Notification.error({
              title: ETranslations.ERROR_UNABLE_TO_EDIT_STATUS,
              message: e.error?.message,
            });
          });
      },
    }),
    globalSearch: build.query<PageableResponse<Booking>, GlobalSearchParams>({
      query: (params: GlobalSearchParams) => ({
        url: '/v2/booking/search/full',
        params,
      }),
      providesTags: ['GlobalSearchBookings'],
      onQueryStarted: async (params, api) => {
        try {
          const { data: bookingsResponse } = await api.queryFulfilled;
          if (config.BRAND === 'DUBAI') {
            const userIds = new Set<number>();
            bookingsResponse.content.forEach(
              (book) =>
                book.client?.client_id && userIds.add(book.client.client_id)
            );

            if (userIds.size) {
              const { data: clientsResponse } = await api.dispatch(
                guestApi.endpoints.updateGuestsForBookings.initiate(
                  Array.from(userIds),
                  { forceRefetch: 30 }
                )
              );

              const content = bookingsResponse.content.map((book) => ({
                ...book,
                client:
                  clientsResponse?.data.find(
                    (client) => client.client_id === book.client?.client_id
                  ) || book.client,
              }));

              api.dispatch(
                bookingApi.util.updateQueryData(
                  'globalSearch',
                  params,
                  (draft) => {
                    draft.content = content as Booking[];
                  }
                )
              );
            }
          }
        } catch (e) {
          console.error(e);
          if ((e as ErrorResponse)?.error?.name === 'AbortError') return;
          Notification.error({
            title: ETranslations.SEARCH_REQUEST_FAILED,
            message: (e as ErrorResponse).error?.data?.errorMessage,
          });
          throw e;
        }
      },
    }),
  }),
});

export const {
  useFetchBookingSearchQuery,
  useFetchActiveBookingsQuery,
  useFetchTerminateBookingsQuery,
  useUpdateBookingMutation,
  useCreateBookingMutation,
  useRegisterBookingMutation,
  useGetBookingQuery,
  useLazyGlobalSearchQuery,
  useGetBookingsQuery,
  useGetBookingExtraStatusesQuery,
  useApplyExtraStatusMutation,
} = bookingApi;

export function useBookingsList({
  fromDate,
  forTables,
  search,
  toDate,
  includeStatuses,
  isPooling = true,
  userid,
  isSkip = false,
  withOutPlaces,
  isManagerableTableSelected = false,
}: TUseBookingList) {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const selectedPlaces = useSelector(appContextSelectors.selectedPlaces);
  const places = useSelector(appContextSelectors.allPlaces);
  const startDate = useSelector(appContextSelectors.date);
  const isBookingForToday = useSelector(
    appContextSelectors.isDateToday
  );
    const isBookingForFuture = useSelector(
    appContextSelectors.isDateFuture
  );

  const includePlaces = useCallback(
    () =>
      selectedPlaces.filter((id) => places.some((place) => place.id === id)),
    [selectedPlaces, places]
  );

  const [currentDate, setCurrentDate] = useState(
    startDate.format('YYYY-MM-DD')
  );

  // update current date
  useEffect(() => {
    if (fromDate && toDate) return undefined;
    if (!isBookingForToday) {
      setCurrentDate(startDate.format('YYYY-MM-DD'));
      return undefined;
    }
    const interval = setInterval(() => {
       setCurrentDate(moment().format('YYYY-MM-DD'));
    }, 1e3);
    return () => {
      clearInterval(interval);
    };
  }, [isBookingForToday, isBookingForFuture, startDate]);

  const requestParams: BookingFilterParams = {
    restaurant_id: restaurant.restaurant_id,
    from: fromDate ? moment(fromDate).format('YYYY-MM-DD') : currentDate,
    to: toDate ? moment(toDate).format('YYYY-MM-DD') : currentDate,
    only_tables: forTables,
    search_keyword: search,
    sort: [
      {
        param: 'date',
        direction: 'ASC',
      },
      {
        param: 'time',
        direction: 'ASC',
      },
    ],
    user_id: userid,
    statuses: includeStatuses,
    management_tables: isManagerableTableSelected,
    places: withOutPlaces ? [] : includePlaces(),
  };
  const isTabVisible = useIsTabVisible();

  return useGetBookingsQuery(isSkip ? skipToken : requestParams, {
    pollingInterval: (isBookingForToday || isBookingForFuture) && isPooling ? 10e3 : undefined,
    skip: !isTabVisible,
    refetchOnFocus: true,
  });
}

const getStatuses = (statuses?: Status[], tableId?: number): string[] => {
  const { data: allStatuses } = useAllStatuses();

  if (statuses?.length) return statuses.map((status) => status.system_name);

  const filterStatuses = allStatuses.reduce(
    (result, status) => (
      status.category !== 'TERMINAL'
        && !status.is_extra
        && result.push(status.system_name),
      result
    ),
    Array<Status['system_name']>()
  );

  if (!tableId && config.BRAND === 'DUBAI')
    filterStatuses.push('CANCELED', 'NOT_COME');

  return filterStatuses;
};

function useBaseBookingsParams(
  search: string | undefined,
  statuses?: any[],
  tableId?: number
): BookingFilterParams {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const date = useSelector(appContextSelectors.date);

  return {
    restaurant_id: restaurant.restaurant_id,
    from: date.format('YYYY-MM-DD'),
    to: date.format('YYYY-MM-DD'),
    statuses: getStatuses(statuses, tableId),
    search_keyword: search,
    sort: [
      {
        param: 'date',
        direction: 'ASC',
      },
      {
        param: 'time',
        direction: 'ASC',
      },
    ],
  };
}

export const useUpdateBookingHandler = () => {
  const [updateRequest] = useUpdateBookingMutation();

  const updateBookingHandler = useCallback((body: TBookingUpdateParams) => {
    return updateRequest(body);
  }, []);

  return { updateBookingHandler };
};

export const useUpdateBookingFromFormHandler = () => {
  const [updateRequest] = useUpdateBookingMutation();

  const updateBookingHandler = useCallback(
    (formData: FormBooking & Pick<TBookingUpdateParams, 'force'>) => {
      const data = castFormToUpdateDTO(formData);
      return updateRequest({ ...data, bookingId: formData.bookingId });
    },
    []
  );

  return { updateBookingHandler };
};

export function useCurrentRestBookings(
  search: string | undefined,
  isTable: boolean,
  statuses?: Status[],
  loadOnlyCurrentPlace?: true,
  tableId?: number
) {
  const place = useSelector(appContextSelectors.place);
  const places = useSelector(appContextSelectors.selectedPlaces);
  const baseParams = useBaseBookingsParams(search, statuses, tableId);

  return useGetBookingsQuery(
    isTable
      ? skipToken
      : {
          ...baseParams,
          management_tables: true,
          places:
            loadOnlyCurrentPlace
            && (config.BRAND === 'DUBAI' ? places : [place]),
        }
  );
}
