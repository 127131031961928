import { FunctionComponent, useCallback } from 'react';
import { BookingCard } from '../BookingCard';
import { Booking, ManagerialTable } from 'types/booking';
import BookingHistoryLog from './BookingHistoryLog';
import './bookingHistory.scss';
import { useFetchBookingHistoryQuery } from 'features/api/bookings-api';

interface BookingHistoryProps {
  booking: Booking | ManagerialTable;
  isExpanded?: boolean;
  onClick?: (booking: Booking | ManagerialTable) => void;
}

export const BookingHistory: FunctionComponent<BookingHistoryProps> = ({
  booking,
  isExpanded,
  onClick,
}) => {
  const { data } = useFetchBookingHistoryQuery(booking.bookingId);
  const bookingClickHandler = useCallback(
    () => onClick?.(booking),
    [booking, onClick]
  );
  return (
    <div className="bookingHistory">
      <div className="bookingWrapper">
        <BookingCard booking={data?.booking || booking} onClick={bookingClickHandler} compact />
      </div>
      {Boolean(data && isExpanded) && <BookingHistoryLog data={data!} />}
    </div>
  );
};
