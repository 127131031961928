import React from "react";
import {Shift} from "types/shift";
import styles from "../../Form.module.scss";
import {Collapse, Labeled} from "ui-kit";
import {Field, useField,} from "react-final-form";
import {InputField} from "./InputField";
import InfinityField from "./InfinityField";
import {WeekDaysField} from "components/ManagerialTables/form/fields";
import DataField from "./DataField";
import TimeField from "./TimeField";
import SchemaField from "./SchemaField";
import ShiftsField from "./ShiftsField";
import {useIntl} from "react-intl";
import {ETranslations} from "types/translates";
import {requiredField} from "components/registration/validators/validators";
import {required} from "components/shifts/utils";

export interface EachGroupFiledProps {
  eachRule: {
    nameOfRule: string;
    startDate: Date;
    endDate: Date;
    infininte: boolean;
    repeat_days: string[];
    startTime: { label: string; value: number };
    endTime: { label: string; value: number };
    shift: Shift[];
    schemas: unknown[];
    index: number;
  };

  isCreating: boolean;
}

const EachGroupFiled = ({eachRule, isCreating}: EachGroupFiledProps) => {
  const currentFieldName = `settings.rules[${eachRule.index}].`;
  const {
    input: {value, onChange},
  } = useField<Array<unknown>>("settings.rules", {allowNull: true});


  const handleRemoveRule = () => {
    const newRules = value.filter((_, index) => index !== eachRule.index);
    onChange(newRules);
  };

  // @ts-ignore
  const isNotNew = !!eachRule?.access_group_id

  const intl = useIntl();

  return (
    <div className={styles.eachRule}>
      <Collapse
        initialOpen={isCreating || !isNotNew ? true : false}
        expendInHeader={false}
        autoHeight
        headerClassName={styles.collapse}
        header={
          <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.NAME_RULE})} required as="div">
            <Field
              name={`${currentFieldName}rule_name`}
              placeholder={intl.formatMessage({id: ETranslations.ENTER_NAME})}
              component={InputField}
              validate={(fieldValue) => requiredField(fieldValue, intl.formatMessage({id: ETranslations.ERROR_DATE_NOT_SPECIFIED}))}
            />
          </Labeled>
        }
      >
        <div className={styles.dateinfo}>
          <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.START_DATE})} required as="div">
            <Field
              name={`${currentFieldName}start_date`}
              component={DataField}
              placeholderText={intl.formatMessage({id: ETranslations.START_DATE})}
              validate={(fieldValue) => requiredField(fieldValue, intl.formatMessage({id: ETranslations.ERROR_DATE_NOT_SPECIFIED}))}
            />
          </Labeled>
          <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.END_DATE})} required as="div">
            <Field
              name={`${currentFieldName}end_date`}
              placeholderText={intl.formatMessage({id: ETranslations.END_DATE})}
              component={DataField}
              currentFieldName={currentFieldName}
              validate={(fieldValue, formValues) => {
                //@ts-ignore
                if (formValues?.settings?.rules[eachRule?.index]?.infininte) {
                  return undefined
                } else {
                  return required(fieldValue)
                }
              }}
            />
          </Labeled>
          <Field name={`${currentFieldName}infininte`} currentFieldName={currentFieldName} component={InfinityField} />
        </div>
        <div className={styles.schemaInfo}>
          <Labeled
            className={styles.bookingLabel}
            label={intl.formatMessage({id: ETranslations.DAYS_TO_CONTINUE_THIS_SHIFT})}
            required
            as="div"
          >
            <Field name={`${currentFieldName}repeat_days`} validate={required} component={WeekDaysField} />
          </Labeled>
        </div>
        <div className={styles.timeInfo}>
          <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.START_TIME})} required as="div">
            <Field
              name={`${currentFieldName}start_active_time`}
              placeholder={intl.formatMessage({id: ETranslations.START_TIME})}
              component={TimeField}
              currentFieldName={currentFieldName}
              validate={(fieldValue) => requiredField(fieldValue, intl.formatMessage({id: ETranslations.ERROR_DATE_NOT_SPECIFIED}))}
            />
          </Labeled>

          <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.END_TIME})} required as="div">
            <Field
              name={`${currentFieldName}end_active_time`}
              placeholder={intl.formatMessage({id: ETranslations.END_TIME})}
              validate={(fieldValue) => requiredField(fieldValue, intl.formatMessage({id: ETranslations.ERROR_DATE_NOT_SPECIFIED}))}
              component={TimeField}
            />
          </Labeled>
        </div>
        <div className={styles.schemaInfo}>
          <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.SHIFT})} required as="div">
            <Field name={`${currentFieldName}shift_id`} currentFieldName={currentFieldName} component={ShiftsField} validate={required} />
          </Labeled>
        </div>
        <div className={styles.schemaInfo}>
          <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.AVAILABLE_FOR_HALL})} required as="div">
            <Field name={`${currentFieldName}places`} component={SchemaField} validate={required} />
          </Labeled>
        </div>
        <div className={styles.delete}>
          <p onClick={handleRemoveRule}>{intl.formatMessage({id: ETranslations.BASE_DELETE})}</p>
        </div>
      </Collapse>
    </div>
  );
};

export default EachGroupFiled;
