import {usePreview} from 'react-dnd-preview';
import {BookingCardDndLayout} from "./layouts/BookingCardDndLayout";
import {Booking} from "../../types/booking";
import {EDraggableEntity} from 'constants/dragAndDrop';

export const DndPreviewContainer = () => {
  const preview = usePreview<Booking, HTMLDivElement>();
  if (!preview.display) {
    return null
  }
  const {item, style, ref, itemType} = preview;
  if (itemType === EDraggableEntity.TABLE_TEMPLATE) return null;

  return (
    <BookingCardDndLayout booking={item} ref={ref} style={style} />
  )
}
