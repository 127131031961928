import _ from 'lodash';
export class RequestWrapper {
  static async fetch(...args: any): Promise<any> {
    const resp = await global.fetch(...args);
    if (resp.status === 500) throw resp
    if (resp.status === 401) {
      if (_.isFunction(global.channels.auth.cb)) {
        global.channels.auth.cb(false);
      } else {
        console.warn('Error in auth pipeline');
      }
    }
    return resp;
  }
}
