import {Children, FC, HTMLAttributes, ReactElement} from "react";
import cn from "classnames";
import styles from "./ColumnBar.module.scss";

export type TColumnBarProps = HTMLAttributes<HTMLDivElement>
export type TColumnBarPart = {
  placement: 'left' | 'center' | 'right'
} & HTMLAttributes<HTMLDivElement>

export const Part: FC<TColumnBarPart> =({children, placement, className, ...props}) => {
  return <div className={cn(styles[placement], className)} {...props}>{children}</div>
}

export const ColumnBar: FC<TColumnBarProps> = ({children,className, ...props}) => {
  const isPartChildren = (Children.toArray(children) as ReactElement<TColumnBarPart>[]).some(el => {
    return el.type === Part
      || (el.props.children && (Children.toArray(el.props.children) as ReactElement<TColumnBarPart>[]).some(child => child.type === Part))
  })
  return <div className={cn(styles.columnBar, {[styles.parts]: isPartChildren}, className)} {...props}>{children}</div>;
};
