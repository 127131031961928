import {ICONS, Tabs, TabsProps} from ".."
import {useCloseCard} from "./useCloseCard";

import styles from './Card.module.scss';

function HeaderTabsComponent<T extends string>({children, ...props}: TabsProps<T>) {
  const close = useCloseCard();
  return (
    <Tabs {...props} className={styles.tabs}>
      {children}
      {close && (<Tabs.Addon>
        <ICONS.Cross onClick={close} className={styles.cross}/>
      </Tabs.Addon>)}
    </Tabs>
  );
}


export const HeaderTabs = Object.assign(HeaderTabsComponent, Tabs);
