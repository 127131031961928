import {FunctionComponent, ReactNode} from "react";
import "./Modal.scss";
import ReactModal, {Props as ReactModalProps} from "react-modal";
import {ICONS} from "ui-kit";

export interface ModalProps extends Omit<ReactModalProps, "onAfterClose" | "onRequestClose"> {
  onClose(): void;
  buttons: ReactNode;
  title: string;
  text: string;
  editBooking?: boolean;
}

type Props = {} & Omit<ModalProps, "children">;

const parentSelector = () => document.body;

const ManageralModal: FunctionComponent<Props> = ({onClose, buttons, title, text, editBooking, ...props}) => {
  return (
    <ReactModal
      onRequestClose={onClose}
      closeTimeoutMS={0}
      portalClassName="modalPortal"
      overlayClassName="modalOverlay"
      className="modal"
      ariaHideApp
      shouldFocusAfterRender={false}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      role="dialog"
      preventScroll
      parentSelector={parentSelector}
      {...props}
    >
      {editBooking ? <ICONS.ErrorWarning/> : <ICONS.WARNING/>}
      <div className="textContet">
        <p className="title">{title}</p>
        <p className="text">{text}</p>

        {buttons && <div className="buttonBox">{buttons}</div>}
      </div>
    </ReactModal>
  );
};

export default ManageralModal;
