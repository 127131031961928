import React, { FC, useMemo, useState } from 'react';
import cn from 'classnames';
import styles from './GuestBadge.module.scss';
import { ICONS, Tag } from 'ui-kit';
import { TagsById } from 'components/Tags';
import { Client } from 'models/client.model';
import dayjs from 'dayjs';
import { getMaskPhone, renderNameGuest } from 'common/helpers';
import { TNullable } from 'types/commons';
import { ETranslations } from 'types/translates';
import { FileInput } from '../FileInput/FileInput';
import { Notification } from 'services/notification';
import { useIntlUtils } from 'hooks/useIntlUtils';
import { config } from 'config';
import { HideWhen } from 'components/HideWhen';

const MAX_KB = 650;
const MAX_FILE_SIZE = 1024 * MAX_KB;

interface TProps {
  guest?: Client;
  setSelectedFile?: (file: TNullable<File>) => void;
  isHideEditPhoto?: boolean;
  isShort?: boolean;
}

export const GuestBadge: FC<TProps> = ({
  guest,
  setSelectedFile,
  children,
  isHideEditPhoto = false,
  isShort = false,
}) => {
  const { intl, isRussianLocale } = useIntlUtils();
  const [previewUrl, setPreviewUrl] = useState<TNullable<string>>(null);
  let visibleTags: Array<number> = [];
  let invisibleTagsCount: TNullable<number> = undefined;

  if (guest?.tags) {
    visibleTags = [...guest.tags];
  }

  if (isShort && visibleTags.length > 1) {
    invisibleTagsCount = visibleTags.splice(1).length;
  }

  const description = useMemo(() => {
    if (!guest) return '';
    const { last_visit, last_location, time } = guest;
    const result = [];
    if (last_visit) {
      result.push(
        `${intl.formatMessage({ id: ETranslations.LAST_VISIT })}: ${dayjs(
          last_visit
        ).format('DD.MM.YYYY')}`
      );
    }

    if (last_location) result.push(last_location);

    if (time) {
      result.push(
        `${intl.formatMessage({ id: ETranslations.CREATED_DATE })}: ${dayjs(
          time
        ).format('DD.MM.YYYY')}`
      );
    }

    return result.join(' • ');
  }, [guest?.last_visit, guest?.last_location, guest?.time]);

  const onChangeFile = (file: File | null) => {
    if (!file) {
      setSelectedFile && setSelectedFile(file);
      setPreviewUrl(null);
      return;
    }

    if (file.size && file.size > MAX_FILE_SIZE) {
      Notification.error({
        title: intl.formatMessage(
          { id: ETranslations.FILE_SIZE_ERROR },
          { MAX_KB }
        ),
      });
      setSelectedFile && setSelectedFile(null);
    } else {
      setSelectedFile && setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  return (
    <div
      className={cn(styles.badge, isShort ? styles.shortBlock : styles.block)}
    >
      <div className={styles.photoWrapper}>
        <img
          className={styles.photo}
          src={previewUrl || guest?.client_photo || ICONS.defaultUser}
          alt=""
        />
        {!isHideEditPhoto && (
          <FileInput
            onChangeFile={onChangeFile}
            guest={guest}
            previewUrl={previewUrl}
          />
        )}
      </div>
      <div className={styles.info}>
        {guest && (
          <>
            <div className={styles.name}>
              {config.vipSign && guest.vip && (
                  <img width={20} src={ICONS.StarA} alt="vip" style={{paddingRight: 5}}/>
              )}
              <HideWhen condition={!guest.salutation || config.BRAND === 'WRF'}>
                <span className={styles.salutation}>
                  {isRussianLocale
                    ? guest.salutation?.name
                    : guest.salutation?.nameEng}
                  .{' '}
                </span>
              </HideWhen>
              {renderNameGuest({
                name: guest.name,
                middle_name: guest.middle_name,
                surname: guest.surname,
              } as Client)}
            </div>
            {isShort && guest.phone && (
              <div className={styles.phone}>{getMaskPhone(guest.phone)}</div>
            )}
            <div className={styles.vipAndTags}>
              {visibleTags.length !== 0 && (
                <div className={styles.tags}>
                  <TagsById tagsIDs={visibleTags} />
                  {invisibleTagsCount && (
                    <Tag
                      label={`+${invisibleTagsCount}`}
                      className={styles.tagCount}
                    />
                  )}
                </div>
              )}
            </div>
            {!isShort && (
              <span className={styles.description}>{description}</span>
            )}
          </>
        )}
      </div>
      {children}
    </div>
  );
};
