import React, {useCallback, useMemo, VFC} from "react";
import cn from "classnames";
import dayjs from "dayjs";
import {ICONS} from "../../common/helpers";
import {Button} from "../../ui-kit";
import styles from "./shifts.module.scss";

export interface IWeekPickerOnClickHandler {
  (direction: 1 | -1): void;
}

export type TWeekPickerProps = {
  startDate: Date
  endDate: Date
  onDatesChange: IWeekPickerOnClickHandler
}

export const WeekPicker: VFC<TWeekPickerProps> = ({startDate, endDate, onDatesChange}) => {
  const transformDate = useMemo(() => {
    return `${dayjs(startDate).format("DD.MM.YYYY")} - ${dayjs(endDate).format("DD.MM.YYYY")}`;
  }, [startDate, endDate]);

  const handleOnNextWeekClick = useCallback(() => onDatesChange(1), []);
  const handleOnPrevWeekClick = useCallback(() => onDatesChange(-1), []);

  return <div className={styles.weekInputContainer}>
    <Button variant="phantom" className={cn(styles.iconContainer, styles.prev)} onClick={handleOnPrevWeekClick}>
      <ICONS.PrevIcon className={styles.inputIcon} />
    </Button>
    <div className={styles.input}>{transformDate}</div>
    <Button variant="phantom" className={cn(styles.iconContainer, styles.next)} onClick={handleOnNextWeekClick}>
      <ICONS.NextIcon className={styles.inputIcon} />
    </Button>
  </div>;
};
