import cn from 'classnames';
import {ButtonHTMLAttributes, forwardRef} from 'react';


import styles from './Button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant: 'primary' | 'secondary' | 'dangerous' | 'status' | 'light' | 'thin' | 'phantom' ;
    square?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({type = 'button', variant, className, square, ...props}, ref) => (
    <button
        {...props}
        type={type}
        className={cn(styles.button, styles[variant], className, {
            [styles.square]: square,
        })} ref={ref}
    />
));