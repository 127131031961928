import React, {FC} from 'react';
import {useIntl} from 'react-intl';

import {Shift} from 'types/shift';
import {getFormatTime, getShortedDaysTitle} from '../utils';
import {ETranslations} from 'types/translates';

import styles from './ShiftsTable.module.scss';

interface Props {
  shifts: Shift[];
  setShiftId: (shift: Shift) => void
}

export const TableBody: FC<Props> = ({shifts, setShiftId}) => {
  const intl = useIntl();

  return (
    <tbody>
    {shifts.map((shift) => (
      <tr className={styles.row} key={shift.id} onClick={() => setShiftId(shift)}>
        <td>
          <span className={styles.cell}>{shift.shift_name}</span>
        </td>
        <td>
          <span className={styles.cell}>
            {getFormatTime(shift.start_active_time)} - {getFormatTime(shift.end_active_time)}
          </span>
        </td>
        <td>
          <span className={styles.cell}>
            {shift.start_date} - {shift.end_date}
          </span>
        </td>
        <td>
          <span className={styles.cell}>{getShortedDaysTitle(shift.repeat_days)}</span>
        </td>
        <td>
          <span className={styles.cell}>
            {intl.formatMessage({id: shift.active ? ETranslations.SHIFT_STATUS_ACTIVE : ETranslations.SHIFT_STATUS_INACTIVE})}
          </span>
        </td>
      </tr>
    ))}
    </tbody>
  );
};
