import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {bookingFormSliceActions} from 'features/BookingFormProxy';
import {constant} from 'lodash';
import {useDispatchActions} from '../../hooks/useDispatchActions';

export interface State {
  sourceBookingId: number | undefined,
  targetBookingId: number | undefined;
  targetTableIds: undefined | number[];
  sourceTableNumbers: string[];
  targetTableNumbers: string[];

}

export const initialState: State = {
  sourceBookingId: undefined,
  targetBookingId: undefined,
  targetTableIds: undefined,
  targetTableNumbers: [],
  sourceTableNumbers: [],
};

const slice = createSlice({
  name: 'moveBooking',
  initialState,
  reducers: {
    selectMoveSource(state, {payload}: PayloadAction<number>) {
      state.sourceBookingId = payload;
    },
    selectSourceTableNumber(state, {payload}: PayloadAction<string[]>) {
      state.sourceTableNumbers = payload;
    },
    clearSelection: {
      prepare: constant({payload: undefined}),
      reducer() {
        return initialState;
      },
    },
    selectTargetBooking(state, {payload}: PayloadAction<{tableId: number, bookingId?: number, tableNumber: string}>) {
      state.targetBookingId = payload.bookingId ?? state.targetBookingId;
      state.targetTableIds = [payload.tableId].concat(state.targetTableIds ?? []);
      state.targetTableNumbers = [payload.tableNumber].concat(state.targetTableNumbers);

    },
    addTargetTables(state, {payload}: PayloadAction<{tableId: number, bookingId?: number, tableNumber: string}>) {
      state.targetTableIds = (state.targetTableIds ?? []).concat(payload.tableId);
      state.targetTableNumbers = state.targetTableNumbers.concat(payload.tableNumber);
    },
    removeTargetTable(state, {payload}: PayloadAction<{tableId: number, bookingId?: number, tableNumber: string}>) {
      const {tableId, bookingId, tableNumber} = payload;
      if (state.targetBookingId === bookingId) {
        state.targetBookingId = undefined;
      }
      state.targetTableIds = (state.targetTableIds ?? []).filter((id) => tableId !== id);
      state.targetTableNumbers = state.targetTableNumbers.filter((id) => tableNumber !== id);
    },
  },
  extraReducers: (builder) => builder
  .addCase(bookingFormSliceActions.resetBooking, () => initialState),
});

export const {name: moveBookingSliceName, reducer: moveBookingReducers} = slice;
export const useMoveBookingActions = () => useDispatchActions(slice.actions);
