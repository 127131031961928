import {HallContainer} from "components/hall-scheme/redux/HallContainer";
import {HallScheme} from "containers/HallScheme";
import {BookingOrders} from "../../containers/BookingOrders";
import {Search} from "containers/Search";
import {RequestsGrid} from "containers/RequestsGrid";
import {config} from "../../config";
import {Settings} from "containers/Settings";
import {Guests} from "containers/Guests";
import {ScheduleLanding} from "containers/ScheduleLanding";
import {Dashboard} from "containers/Dashboard";


export const routes = [
  {isShow: true, exact: true, path: "/dashboard", component: Dashboard},
  {isShow: true, exact: true, path: "/hall-scheme", component: HallScheme},
  {isShow: true, exact: true, path: "/create-booking", render: () => <HallContainer isCreateBookingOpened />},
  {isShow: true, exact: true, path: "/hall", render: () => <HallContainer />},
  {isShow: true, exact: true, path: "/requests", component: BookingOrders},
  {isShow: true, exact: true, path: "/settings", component: Settings},
  {isShow: true, exact: true, path: "/guests", component: Guests},
  {isShow: config.requestGrid, exact: true, path: "/grid", component: RequestsGrid},
  {isShow: true, exact: true, path: "/schedule-landing", component: ScheduleLanding},
  {isShow: true, exact: true, path: "/search", component: Search},
];
