import {TagOption} from "types/tag";
import {useTagsOptions} from "features/api/tags";
import {useCallback, useEffect, useMemo} from "react";
import {SelectTag} from "ui-kit";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {useIsTablet} from "ui-kit/hooks";
import {useSelector} from "react-redux";
import {restaurant} from "features/AppContex/selectors";


interface UserTagsProps{
  value: number[],
  onChange(value: number[]): void;
  placeholder?: string
}

export function UserTags({value, onChange, placeholder}: UserTagsProps) {
  const {getIntlSelectEntity} = useIntlUtils();
  const {restaurant_id} = useSelector(restaurant);
  const tagsOptions = useTagsOptions({type: "CLIENT", owner_type: restaurant_id + "", include_deleted: false});

  const selected = useMemo(() => tagsOptions.filter((t) => value.includes(t.value)), [value, tagsOptions]);
  
  const handleChange = useCallback((tags: TagOption[]) => {
    onChange(tags.map((t) => t.value));
  }, [onChange]);

  useEffect(() => {
    if (Array.isArray(value)) {
      const filteredValues = value?.filter(val => tagsOptions?.some(option => option?.value === val));
      onChange(filteredValues);
    }
  }, [tagsOptions])

  const isTablet = useIsTablet();

  return (
    <SelectTag
      menuPlacement={isTablet ? "top" : "bottom"}
      isTagsOrSource
      isSearchable
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      options={tagsOptions}
      value={selected}
      // @ts-ignore
      onChange={handleChange}
      placeholder={placeholder || getIntlSelectEntity(ETranslations.AVAILABLE_TAGS)}
    />
  );
}
