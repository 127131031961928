import {IResponse} from 'models/common';
import {useEffect, useMemo, useState} from 'react';
import {Tag} from 'types/tag';
import {api} from './api';
import {useSelector} from 'react-redux';
import {appContextSelectors} from 'features/AppContex';

const tagsApi = api.injectEndpoints({
  endpoints: ($) => ({
    getTags: $.query<Tag[], { owner_type: string; type: string; include_deleted: boolean; }>({
      query: ({owner_type, type, include_deleted}) => ({
        url: `/v2/tags/available?destination=${type}&owner_id=${owner_type}&include_deleted=${include_deleted}`,
      }),
      transformResponse: (response: IResponse<Array<{ color: string, description: string, tag_id: number }>>) => response
        .data.map((t) => ({color: t.color, name: t.description, id: t.tag_id})),
    }),
  }),
});


const {useGetTagsQuery, useLazyGetTagsQuery} = tagsApi;

export function useTags(ids: number[]) {
  const restaurant = useSelector(appContextSelectors.restaurant).restaurant_id + "";
  const {data} = useGetTagsQuery({owner_type: restaurant, type: "CLIENT", include_deleted: true});
  return useMemo(() => data?.filter((t) => ids.includes(t.id)) ?? [], [ids, data?.length, restaurant]);
}

export function useTagsOptions({type, owner_type, include_deleted}: { type: string; owner_type: string; include_deleted: boolean }) {
  const restaurant = useSelector(appContextSelectors.restaurant).restaurant_id + "";
  const [data, setData] = useState<Tag[]>([]);
  const [getTags] = useLazyGetTagsQuery()
  useEffect(() => {
    getTags({owner_type, type, include_deleted}).then(res => {
      setData(() => {
        // @ts-ignore
        return [...res.data]
      })
    })
  }, [restaurant])
  return useMemo(() => data?.map((t) => ({...t, label: t.name, value: t.id})) ?? [], [data, restaurant]);
}
