import {useFormState} from "react-final-form";

import {useSelector} from "react-redux";
import * as select from "features/BookingFormProxy/selectors";
import {FormBooking} from "features/BookingFormProxy/types";

import {Button} from "ui-kit";
import {useIntl} from "react-intl";
import {ETranslations} from "types/translates";

export function CancelBooking({openModal, type = "dangerous"}: { openModal: () => void, type?: any }) {
  const intl = useIntl();
  const {submitting} = useFormState<FormBooking>();
  const isEdit = useSelector(select.isEdit);

  if (isEdit) return null;

  const cancelBooking = () => {
    openModal();
  };

  return (
    <Button variant={type} disabled={submitting} onClick={cancelBooking}>
      {intl.formatMessage({id: ETranslations.BASE_CANCEL})}
    </Button>
  );
}
