import React, {useCallback, useState} from "react";
import {Button, Spinner} from "ui-kit";
import "../settings.scss";
import {AccessRulesModal} from "components/shifts/Modal/AccessRulesModal/AccessRulesModal";
import {useIntl} from "react-intl";
import {ETranslations} from "types/translates";
import EachAccessRule from "components/EachAccessRule/EachAccessRule";
import {useGetAccessRulesQuery} from "features/api/access_rules";
import {appContextSelectors} from "features/AppContex";
import {useSelector} from "react-redux";

type AccessRulesProps = {
  isLoading: boolean;
};

export default function AccessRules({isLoading}: AccessRulesProps) {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const {data} = useGetAccessRulesQuery(restaurant.restaurant_id)
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const modalOnClose = useCallback(() => {
    setModalOpen((a) => !a);
  }, []);
  const handleOpen = () => setModalOpen(() => true);
  const intl = useIntl();

  const ARs = data?.data ?? []
  
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="access_rules sections include-table">
          <Button className="create_rule_button" variant="primary" onClick={handleOpen}>
            {intl.formatMessage({id: ETranslations.CREATE_ACCESS_RULES_GROUP})}
          </Button>
          <div className="access_rules_box">
            {ARs?.map((each: any, index: number) => {
              return <EachAccessRule key={index} {...each} />;
            })}
          </div>
          {modalOpen ? <AccessRulesModal
            isCreating={true}
            onClose={modalOnClose}
            title={intl.formatMessage({id: ETranslations.CREATING_ACCESS_RULES_GROUP})}
            isOpen={modalOpen}
          /> : null}
        </div>
      )}
    </>
  );
}
