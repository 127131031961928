import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useIntlUtils } from 'hooks/useIntlUtils';
import { ETranslations } from 'types/translates';
import {
  useCreateGuestMutation,
  useLazyFetchClientQuery,
  useLazyFetchGuestDetailQuery,
  useUpdateGuestMutation,
} from 'features/api/guest-api';
import { useRegisterWithOverbooking } from './useRegisterWithOverbooking';

const useRegisterVisit = ({
  reset,
  isFromManagement,
}: {
  reset: any;
  isFromManagement?: boolean;
}) => {
  const { getIntlRegistrationOf, isRussianLocale } = useIntlUtils();
  const registrationTitle = useMemo(() => {
    return getIntlRegistrationOf(
      isRussianLocale
        ? ETranslations.PLURAL_VISITS_ALT
        : ETranslations.PLURAL_VISIT
    );
  }, []);
  const {
    create,
    createWithOverbooking,
    clearData,
    isConfirmationRequired,
    isCreating,
    closeModal,
    hasManageralError,
  } = useRegisterWithOverbooking();
  const history = useHistory<{ from: string }>();
  const [createGuest] = useCreateGuestMutation();
  const [getUser] = useLazyFetchGuestDetailQuery();
  const [updateGuest] = useUpdateGuestMutation();
  const [getClient] = useLazyFetchClientQuery();

  const close = useCallback(() => {
    reset();
    const { from } = history.location.state || {};
    if (from !== history.location.pathname) {
      history.push(from ?? '/');
    }
  }, [history, reset]);

  const registerVisit = useCallback(
    async (data) => {
      let { client } = data;
      const { client_id, phone, email } = data;
      if (client && data?.contact?.contact_type !== 'CONTACT' && phone) {
        const {
          editClient = false,
          name,
          surname = '',
          middle_name = '',
        } = client;
        if (!client.client_id) {
          const {
            data: { client_id: newClientId },
          } = await createGuest({
            middle_name,
            name,
            phone,
            surname,
            email,
          }).unwrap();
          client = await getUser(newClientId).unwrap();
        }
        if (editClient && client_id) {
          getClient(client_id)
            .then(async (response) => {
              const newUser = {
                ...response.data,
                surname,
                name,
                middle_name,
                type_authorization: 'AUTH',
              };
              return newUser;
            })
            .then(async (newUser) => {
              //@ts-ignore
              await updateGuest(newUser);
            });
        }
      }
      await create({
        ...data,
        seatType: isFromManagement ? 'MANAGER_BOOK' : 'IN_WALK',
        client,
      });
      close();
    },
    [create, close]
  );

  const registerOverbooking = useCallback(async () => {
    await createWithOverbooking();
    close();
  }, [createWithOverbooking, close]);

  return {
    registerVisit,
    registrationTitle,
    isConfirmationRequired,
    clearData,
    registerOverbooking,
    isCreating,
    close,
    hasManageralError,
    closeModal,
  };
};

export default useRegisterVisit;
