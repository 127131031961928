import { ReactNode, useMemo } from 'react';
import { appContextSelectors } from 'features/AppContex';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  usePlacesTables,
  useTablesOptionsQuery,
} from 'features/api/tables-api';
import type { Booking } from 'types/booking';
import type { PlaceTable } from 'types/table';
import moment from 'moment';

export interface TableOption {
  value: number;
  number: string | number;
  type: number;
  label: ReactNode;
}

export function useTablesOptions(bookingTables?: Booking['places']): {
  isLoading: boolean;
  tables: TableOption[];
} {
  const rest = useSelector(appContextSelectors.restaurant);
  const placeId = useSelector(appContextSelectors.place);
  const { values } = useFormState({ subscription: { values: true } });
  const chosenDate = moment(values.time?.value);
  const isDateValid = chosenDate.isValid()

  const bookingTime = values.time?.label;
  const { visit_time: visitTime, duration } = values;

  const {
    data: tablesData,
    isFetching,
    isError,
  } = useTablesOptionsQuery(
    placeId && bookingTime && (visitTime || duration) && isDateValid
      ? {
          placeId,
          bookingDate: chosenDate.format('YYYY-MM-DD'),
          bookingTime,
          visitTime: visitTime || duration,
          restaurantId: rest.restaurant_id,
        }
      : skipToken
  );

  // Ошибка происходит когда нет шифта на дату. Если она случилась, при монтировании дальнейшие запросы не отправляются.
  // В противном случае ртк зацикливается и шлет запросы.
  // Проблема https://github.com/reduxjs/redux-toolkit/issues/1526. Решается обновлением ртк. Либо таким фиксом.
/*   if (isError) {
    errorRef.current = isError;
  } */

  const { tables } = usePlacesTables();

  const bookingTableIds = bookingTables?.reduce<
    Array<PlaceTable['table_id'] | undefined>
  >(
    (result, bookingTable) => (
      result.push(
        tables.find((table) => table.fetcher_id === bookingTable.fetcherId)
          ?.table_id
      ),
      result
    ),
    []
  );
  const getTableColor = (tableId: number, busy: boolean) =>
    //busy && bookingTableIds && !bookingTableIds.includes(tableId)
    busy ? '#CC5962' : undefined;

  const tablesTypes = useMemo(
    () =>
      tables.reduce<Record<number, number>>((acc, t) => {
        acc[t.table_id] = t.type;
        return acc;
      }, {}),
    [tables]
  );

  const options = useMemo(() => {
    let data;

    if (!tablesData || isError) {
      data = tables
        .filter((el) => el.placeId === placeId)
        .map(({ table_id, number, type }) => ({
          value: table_id,
          number: number,
          type: type,
          label: <span>{number}</span>,
        }));
    } else {
      data = tablesData.map(({ busy, tableId, tableNumber }) => ({
        value: tableId,
        number: tableNumber,
        type: tablesTypes[tableId],
        label: (
          <span style={{ color: getTableColor(tableId, busy) }}>
            {tableNumber}
          </span>
        ),
      }));
    }

    return data.sort((a, b) => Number(a.number) - Number(b.number));
  }, [tablesData, tables, isError, placeId]);

  return { tables: options, isLoading: isFetching };
}
