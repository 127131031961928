import React from "react";
import {Card} from "ui-kit";
import BookingFormReduxProxy from "components/registration/forms/BookingFormReduxProxy";
import {ConfirmOverbookingModal} from "components/modals/ConfirmOverbookingModal";
import ManageralModalError from "components/ManagerialTables/form/ManageralModalError";
import useRegisterVisit from "./hooks/useRegisterVisit";

export const RegisterVisit = ({reset, isFromManagement = false}: { reset: any; isFromManagement?: boolean }): React.ReactElement => {
  const {
    registerVisit,
    registrationTitle,
    isConfirmationRequired,
    clearData,
    registerOverbooking,
    isCreating,
    hasManageralError,
    closeModal,
    close
  } = useRegisterVisit({reset, isFromManagement});

  return (
    <Card onClose={close}>
      <Card.Header title={registrationTitle} needToReset />
      <Card.Content noPadding>
        <BookingFormReduxProxy isFromManagement={isFromManagement} isRegisterVisit submitFn={registerVisit} />
        <ConfirmOverbookingModal
          isOpen={isConfirmationRequired}
          onDecline={clearData}
          onConfirm={registerOverbooking}
          disabled={isCreating}
        />
      </Card.Content>
      {hasManageralError && <ManageralModalError isOpen={hasManageralError} onClose={closeModal} />}
    </Card>
  );
};
