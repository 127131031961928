import {memo} from 'react';
import {Plugin, TemplateConnector} from '@devexpress/dx-react-core';

import {ICONS} from 'common/helpers';
import {Button} from 'ui-kit';

import styles from './HallControlsPlugin.module.scss';
import {Plugins} from '@technolab/hse';


export const HallControlsPlugin = memo(() => (
  <Plugin name="HallControlsPlugin">
    <TemplateConnector>
      {(_getters, actions) => (
        <div className={styles.root}>
          <Button variant="phantom" onClick={actions[Plugins.Zoom.ACTIONS.zoomIn]} className={styles.button}>
            <ICONS.ZoomPlus />
          </Button>
          <Button variant="phantom" onClick={actions[Plugins.Zoom.ACTIONS.zoomOut]} className={styles.button}>
            <ICONS.ZoomMinus />
          </Button>
          <Button
            variant="phantom" onClick={actions[Plugins.Zoom.ACTIONS.reset]}
            className={styles.button}
          >
            <ICONS.ZoomPlus className={styles.reset} />
            </Button>
              </div>
        )}
        </TemplateConnector>
  </Plugin>
));
