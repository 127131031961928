import {useCallback, useMemo} from 'react';
import cn from 'classnames';
import {SelectHeader} from 'ui-kit';
import {useRestsOptions} from 'features/api/restaurants';

import styles from './RestaurantSelect.module.scss';
import {Restaurant} from 'models/restaurant.model';
import {appContextSelectors} from 'features/AppContex';
import {useSelector} from 'react-redux';
import {useLocalStorage, useMount} from 'react-use';

interface BaseRestaurantSelectProps {
  value: number;
  className?: string;
  onChange?(value: number): void;
  onRestaurantChange?(rest: Restaurant): void
}

type RestaurantSelectProps = BaseRestaurantSelectProps & (
  { onChange(value: number): void; } | { onRestaurantChange(rest: Restaurant): void }
);

export function RestaurantSelect({onChange, onRestaurantChange, value, className}: RestaurantSelectProps) {
  const options = useRestsOptions();
  const selected = useMemo(() => options?.find((o) => o.value === value), [options, value]);
  const restaurants = useSelector(appContextSelectors.restaurants);

  const [_, setDefaultRestaurantId] = useLocalStorage('restaurantId');

  const handleChange = useCallback((option) => {
    if (!option) return;
    onChange?.(option.value);
    const rest = restaurants.find((r) => r.restaurant_id === option.value);
    rest && onRestaurantChange?.(rest);
    setDefaultRestaurantId(option.value);
  }, [onChange, onRestaurantChange, restaurants, setDefaultRestaurantId]);

  return (
    <div className={cn(className, styles.select)}>
      <SelectHeader
        options={options}
        value={selected}
        onChange={handleChange}
      />
    </div>
  );
}
