import React, {
  memo,
  MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from '../../MoveStatus.module.scss';
import { useSelector } from 'react-redux';
import {
  moveBookingSelectors,
  useMoveBookingActions,
} from 'features/MoveBooking';
import {
  SwapRequest,
  useMoveMutation,
  useSwapMutation,
} from 'features/api/hallschema-api';
import { ConfirmOverbookingModal } from 'components/modals/ConfirmOverbookingModal';
import { useBooleanState } from 'hooks/useBooleanState';
import cn from 'classnames';
import { useTablesBookings } from '../TableBookingList/useTableBookings';
import { Booking } from '../../../../types/booking';
import { BookingCardDetailList } from '../TableBookingList/BookingCardDetailList';
import { Modal } from '../../../modal';
import { Button } from 'ui-kit';
import { useBoolean } from 'react-use';
import { HallMode, useHallSchemaActions } from 'features/HallSchema';
import { useIntlUtils } from '../../../../hooks/useIntlUtils';
import { ETranslations } from '../../../../types/translates';
import { useTimelineActions } from '../../../../features/Timeline';
import ManageralModalError from 'components/ManagerialTables/form/ManageralModalError';
import { useTableBookingListActions } from 'features/TableBooking/slice';
import type { ErrorResponse, EventFor } from 'types/commons';

const Container = (props: any) => (
  <div className={styles.moveStatus} {...props} />
);

const MoveControls: React.FC<any> = () => {
  const { intl, getIntlSelectEntity } = useIntlUtils();
  const bookingSelected = useSelector(moveBookingSelectors.isSourceSelected);
  const [hasManageralError, setHasManageralError] = useState<boolean>(false);
  const { clearSelection } = useMoveBookingActions();
  const { switchMode } = useHallSchemaActions();
  const moveOrSwapData = useSelector(moveBookingSelectors.moveData);
  const sourceTableNumbers = useSelector(
    moveBookingSelectors.sourceTableNumber
  );
  const targetTablesNumbers = useSelector(
    moveBookingSelectors.targetTablesNumbers
  );
  const [move] = useMoveMutation();
  const [swap] = useSwapMutation();
  const { reset } = useTableBookingListActions();
  const [isForce, setForce] = useState<true | undefined>();
  const [isProcessing, start, stop] = useBooleanState();
  const tableId = moveOrSwapData?.destination.tables_id[0];
  const { resetTimeShift } = useTimelineActions();

  const { data, isLoading } = useTablesBookings(tableId, !tableId, '', true);
  const isHasMultipleBookings = useMemo(
    () => (data?.bookings?.length ?? 0) > 1,
    [data]
  );
  const [pickBooking, setPickTrue, setPickFalse] = useBooleanState();
  const [isSwapping, setIsSwapping] = useBoolean(false);
  const canSwap = Boolean(
    (moveOrSwapData?.destination.booking_id || data?.bookings?.length)
      && targetTablesNumbers.length
  );

  const targetBookingId = data?.bookings?.[0]?.bookingId;

  const swapPayload = useRef<SwapRequest | null>(null);

  const resetForce = useCallback(() => {
    setForce(undefined);
    setIsSwapping(false);
  }, [setForce]);

  const closeErrorModal = () => {
    setHasManageralError(() => false);
  };

  const cancel = useCallback(() => {
    resetTimeShift();
    clearSelection();
    reset();
    switchMode(HallMode.TABLE_BOOKINGS_LIST);
  }, []);

  const processSwap = useCallback(
    async (payload: SwapRequest) => {
      swapPayload.current = payload;
      start();
      setIsSwapping(true);
      try {
        await swap(payload).unwrap();
        reset();
        cancel();
        resetForce();
      } catch (e) {
        const errorData = (e as ErrorResponse['error'])?.data;
        return isForce
          ? errorData?.errorCode === 10_000 && (resetForce(), clearSelection())
          : errorData?.errorCode === 10100 && setForce(true);
      } finally {
        reset();
        setPickFalse();
        stop();
      }
    },
    [isForce, resetForce, swap]
  );

  const retrySwap = useCallback(() => {
    const { current: payload } = swapPayload;
    if (!payload) return undefined;
    return processSwap({ ...payload, force: true });
  }, []);

  const process = useCallback(async () => {
    start();
    setIsSwapping(false);
    try {
      if (!moveOrSwapData) return;
      await move({ ...moveOrSwapData, force: isForce }).unwrap();
      cancel();
      resetForce();
    } catch (e) {
      const errorData = (e as ErrorResponse['error'])?.data;
      errorData?.errorCode === 10400 && setHasManageralError(true);
      return isForce
        ? errorData?.errorCode === 10_000 && (resetForce(), clearSelection())
        : errorData?.errorCode === 10100 && setForce(true);
    } finally {
      stop();
      reset();
    }
  }, [move, moveOrSwapData, isForce, resetForce]);

  const pickExactBooking = useCallback(
    async (booking: Booking) => {
      if (moveOrSwapData) {
        const payload = {
          origin: {
            booking_id: moveOrSwapData.origin.booking_id,
          },
          destination: {
            booking_id: booking.bookingId,
          },
          force: isForce,
        };
        await processSwap(payload);
      }
    },
    [moveOrSwapData, isForce]
  );

  const handlePick = useCallback(
    (
      e: EventFor<'button', 'onClick'>,
      bookingId: number | undefined | null
    ) => {
      if (!moveOrSwapData || !bookingId) return;
      if (isHasMultipleBookings) {
        e.stopPropagation();
        setPickTrue();
        return;
      }
      const payload = {
        origin: {
          booking_id: moveOrSwapData.origin.booking_id,
        },
        destination: {
          booking_id: bookingId,
        },
        force: isForce,
      };

      processSwap(payload);
    },
    [isHasMultipleBookings, isForce, moveOrSwapData]
  );

  const tablesNumbers = useMemo(() => {
    if (!sourceTableNumbers.length || !targetTablesNumbers.length) return '';
    const source = sourceTableNumbers.join(', ');
    const target = targetTablesNumbers.join(', ');

    return ` ${source} → ${target}`;
  }, [sourceTableNumbers, targetTablesNumbers]);

  return (
    <>
      <Container style={{ display: bookingSelected ? 'flex' : 'none' }}>
        {canSwap && (
          <Button
            variant="primary"
            type="button"
            disabled={isProcessing}
            onClick={(e) =>
              handlePick(
                e,
                moveOrSwapData?.destination.booking_id
                  || data?.bookings?.[0]?.bookingId
              )
            }
          >
            {intl.formatMessage({ id: ETranslations.SEATS_SWAP })}
            {tablesNumbers}
          </Button>
        )}
        <Button
          variant="primary"
          type="button"
          disabled={!moveOrSwapData || isProcessing}
          onClick={process}
        >
          {intl.formatMessage({ id: ETranslations.DOUBLE_BOOKING })}
          {tablesNumbers}
        </Button>
        {bookingSelected && (
          <button
            className={cn('secondary', styles.cancelButton)}
            type="button"
            onClick={cancel}
          >
            {intl.formatMessage({ id: ETranslations.BASE_CANCEL })}
          </button>
        )}
      </Container>
      <ConfirmOverbookingModal
        onDecline={resetForce}
        isOpen={isForce}
        onConfirm={isSwapping ? retrySwap : process}
        disabled={isProcessing}
      />

      {hasManageralError && (
        <ManageralModalError
          isOpen={hasManageralError}
          onClose={closeErrorModal}
        />
      )}

      <Modal
        onClose={setPickFalse}
        title={getIntlSelectEntity(ETranslations.PLURAL_BOOKING)}
        isOpen={pickBooking}
      >
        <Modal.Content>
          <BookingCardDetailList
            data={data?.bookings || []}
            onClick={pickExactBooking}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

export const HallMoveStatusControls = memo(MoveControls);
