import moment from 'moment';
import {appContextSelectors} from 'features/AppContex';
import {timelineSelectors} from 'features/Timeline';
import {useCallback} from 'react';
import {useSelector} from 'react-redux';

export function useSliderDatetime() {
  const sliderTime = useSelector(timelineSelectors.getSliderTime);
  const date = useSelector(appContextSelectors.date);
  return useCallback(() => {
    const time = sliderTime();
    const {hours, minutes} = time.toObject();
    const result = date.clone().set({hours, minutes}).add({days: time.clone().startOf('day').diff(moment().startOf('day'), 'days')});
    return result;
   }, [date, sliderTime]);
}
