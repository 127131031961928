import React, {FC} from 'react';
import dayjs from 'dayjs';
import {useIntl} from 'react-intl';

import {Spinner} from 'ui-kit';
import {Shift} from 'types/shift';
import {TNullable} from 'types/commons';
import {getDurationTime, getDurationTimeFormat, getFormatTime, getPlacesTitles, getShortedDaysTitle, addPlusToDuration} from '../../utils';
import {declOfNum} from 'utils';
import {GUESTS_TITLE} from '../../../../constants';
import {ETranslations} from 'types/translates';

import styles from './style.module.scss';

interface Props {
  isLoading: boolean,
  shift: TNullable<Shift>
}

export const InfoShiftsModalBody: FC<Props> = ({isLoading, shift}) => {
  if(isLoading) return <Spinner/>
  if(!shift) return null
  const intl = useIntl();

  return (
    <>
      <section>
        <h3 className={styles.title}>{intl.formatMessage({id : ETranslations.PROPERTIES}).toUpperCase()}</h3>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <span className={styles.listItemTitle}>{intl.formatMessage({id : ETranslations.DAYS})}</span>
            <span className={styles.listItemSeparate}/>
            <span className={styles.listItemValue}>{getShortedDaysTitle(shift.repeat_days)}</span>
          </li>
          <li className={styles.listItem}>
            <span className={styles.listItemTitle}>{intl.formatMessage({id: ETranslations.START_DATE})}</span>
            <span className={styles.listItemSeparate}/>
            <span className={styles.listItemValue}>{dayjs(shift.start_date).format('DD/MM/YYYY')}</span>
          </li>
          <li className={styles.listItem}>
            <span className={styles.listItemTitle}>{intl.formatMessage({id: ETranslations.END_DATE})}</span>
            <span className={styles.listItemSeparate}/>
            <span className={styles.listItemValue}>{dayjs(shift.end_date).format('DD/MM/YYYY')}</span>
          </li>
          <li className={styles.listItem}>
            <span className={styles.listItemTitle}>{intl.formatMessage({id: ETranslations.SHIFT_TIME})}</span>
            <span className={styles.listItemSeparate}/>
            <span className={styles.listItemValue}>{getFormatTime(shift.start_active_time)} - {getFormatTime(shift.end_active_time)}</span>
          </li>
          <li className={styles.listItem}>
            <span className={styles.listItemTitle}>{intl.formatMessage({id: ETranslations.SEATING_AREAS})}</span>
            <span className={styles.listItemSeparate}/>
            <span className={styles.listItemValue}>{getPlacesTitles(shift.places_id)}</span>
          </li>
          <li className={styles.listItem}>
            <span className={styles.listItemTitle}>{intl.formatMessage({id: ETranslations.INTERVAL})}</span>
            <span className={styles.listItemSeparate} />
            <span className={styles.listItemValue}>
              {shift?.booking_interval} {intl.formatMessage({id: ETranslations.PLURAL_MINUTES})}
            </span>
         </li>
        </ul>
      </section>
      <section>
        <h3 className={styles.title}>{intl.formatMessage({id: ETranslations.DURATION}).toUpperCase()}</h3>
        <ul className={styles.list}>
          {getDurationTime(shift.capacity.durations).map((el) => (
            <li className={styles.listItem} key={el.count}>
              <span className={styles.listItemTitle}>
                {addPlusToDuration(el.count, getDurationTime(shift.capacity.durations).length)
                    + ` ${intl.formatMessage({id: declOfNum(el.count, GUESTS_TITLE)})}`}
              </span>
              <span className={styles.listItemSeparate}/>
              <span className={styles.listItemValue}>{getDurationTimeFormat(el.duration)}</span>
            </li>
          ))}
        </ul>
      </section>
      <section>
        <h3 className={styles.title}>{intl.formatMessage({id: ETranslations.CAPACITY}).toUpperCase()}</h3>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <span className={styles.listItemTitle}>{intl.formatMessage({id: ETranslations.MAX_COVERED})}</span>
            <span className={styles.listItemSeparate}/>
            <span className={styles.listItemValue}>
              {shift.capacity.use_max_covered ? shift.capacity.max_covered_size : intl.formatMessage({id: ETranslations.NO_LIMIT})}
            </span>
          </li>
        </ul>
      </section>
    </>
  );
};
