import {useCallback, useEffect} from 'react';
import cn from 'classnames';
import {useLocalStorage} from 'react-use';
import {Button, ICONS} from '..';


import styles from './ModeSwitch.module.scss';


export function ModeSwitch({className}: { className?: string }) {
  const [mode, setMode] = useLocalStorage<string | undefined>('ui:mode');

  const setLight = useCallback(() => {
    setMode('light')
  }, [setMode]);

  const setDark = useCallback(() => {
    setMode('dark')
  }, [setMode]);

  useEffect(() => {
    if (!mode) return;
    document.documentElement.dataset.mode = mode;
  }, [mode]);

  return (
    <div className={cn(className, styles.root)}>
      <Button variant="phantom" className={styles.dark} onClick={setLight}>
        <ICONS.DarkTheme />
      </Button>
      <Button variant="phantom" className={styles.light} onClick={setDark}>
        <ICONS.LightTheme />
      </Button>
    </div>
  )
  }
