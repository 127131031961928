import Checkbox from 'common/components/checkbox'
import React, {useEffect} from 'react'
import {FieldRenderProps, useField} from 'react-final-form'
import {useIntl} from 'react-intl'
import {ETranslations} from 'types/translates'
import styles from '../../Form.module.scss'
import dayjs from 'dayjs'

const InfinityField: React.FC<FieldRenderProps<string, any>> = ({input, meta, currentFieldName}) => {
  const intl = useIntl();
  const {value, onChange} = input;

  const {
    input: {value: endDateValue}
  } = useField<Array<string | Date>>(currentFieldName ? `${currentFieldName}end_date` : "end_date");

  useEffect(()=>{
    // @ts-ignore
    if(dayjs(endDateValue).valueOf()<=2208974400000){
      onChange(false);
    }
  }, [endDateValue])

  return (
    <div style={{marginTop: "18px", display: "flex", alignItems: "center"}}>
      <Checkbox
        className={styles.checkbox}
        value={!!value}
        onChange={onChange}
        indent="deposit"
        text={intl.formatMessage({id: ETranslations.INFINITY})}
        {...meta}
      />
    </div>
  )
}

export default InfinityField