import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import {
  MutationLifecycleApi,
  QueryLifecycleApi,
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { AuthService } from 'services/auth.service';
import { Notification } from 'services/notification';
import type { ErrorResponse } from 'types/commons';

export const apiORD = '/api';
export const apiORD2 = '/api/v2';
export const apiINT = `${apiORD}/internal`;
export const apiEXT = `${apiORD}/external`;
export const apiINT2 = `${apiORD2}/internal`;
export const apiEXT2 = `${apiORD2}/external`;

export const shortPollingInterval = 1e3; // - 1sec
export const middlePollingInterval = 15e3; // - 15sec
export const longPollingInterval = 60e3; // - 1min

export const getDefaultHeaders = (headers: Headers): Headers => {
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', AuthService.getToken());
  return headers;
};

type Api<
  QueryArg,
  BaseQuery extends BaseQueryFn,
  ResultType,
  ReducerPath extends string
> =
  | QueryLifecycleApi<QueryArg, BaseQuery, ResultType, ReducerPath>
  | MutationLifecycleApi<QueryArg, BaseQuery, ResultType, ReducerPath>;

export async function handleResponseError<
  QueryArg,
  ResultType = object,
  BaseQuery extends BaseQueryFn = BaseQueryFn,
  ReducerPath extends string = string
>(
  { queryFulfilled }: Api<QueryArg, BaseQuery, ResultType, ReducerPath>,
  message: string
) {
  try {
    await queryFulfilled;
  } catch (e) {
    console.error(e);
    if ((e as ErrorResponse)?.error?.name === 'AbortError') return;
    Notification.error({
      title: message,
      message: (e as ErrorResponse).error?.data?.errorMessage,
    });
    throw e;
  }
}
