import React, {useMemo, useState} from "react";
import {Modal} from "components/modal";
import {BaseShiftsModal} from "components/shifts/Modal/BaseShiftsModal";
import styles from "./CreateGroupTagsModal.module.scss"
import {Field, Form} from "react-final-form";
import {useIntl} from "react-intl";
import {ETranslations} from "types/translates";
import {BottomBar, Button, Card, Input, Labeled, SelectBasic} from "ui-kit";
import {ChooseColorRedux} from "./sub-renders/ChooseColorComponent";
import {AddTagToGroupRedux} from "./sub-renders/AddTagToGroup";
import TagsContainer from "components/TagsContainer/TagsContainer";
import {useCreateTagsMutation} from "features/api/tags-api";
import {useSelector} from "react-redux";
import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";
import {appContextSelectors} from "features/AppContex";
import {required} from "components/shifts/utils";
import {requiredField} from "components/registration/validators/validators";

interface CreateGroupTagsModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    selectedTag: string;
    isAdminOfRest: boolean;
}

export const CreateGroupTagsModal = ({
    isOpen,
    onClose,
    title,
    selectedTag,
    isAdminOfRest
}: CreateGroupTagsModalProps) => {
    const intl = useIntl();
    const [createTag] = useCreateTagsMutation();
    const restaurant = useSelector(appContextSelectors.restaurant);
    const [hasError, setHasError] = useState<string>("");

    const toggleServerError = () => {
        setHasError("");
    };

    const types = [
        {value: "GLOBAL", label: intl.formatMessage({id: ETranslations.GLOBAL})},
        {value: "LOCAL", label: intl.formatMessage({id: ETranslations.LOCAL})},
    ]

    const initalValue = useMemo(() => {
        return {
            name_of_group: "",
            color: "#F86767",
            tags: [],
            newTag: "",
            group_type: types[isAdminOfRest ? 1 : 0],
        }
    }, [isAdminOfRest])

    const onSubmit = (vals: any) => {
        const isGlobal = vals.group_type.value === "GLOBAL"
        createTag({
            name: vals.name,
            destination: selectedTag,
            color: vals.color,
            group_type: vals.group_type.value.toUpperCase(),
            owner_type: isGlobal ? undefined : "RESTAURANT",
            owner_id: isGlobal ? undefined : `${restaurant.restaurant_id}`,
            tags: vals.tags
        }).then((res) => {
            // @ts-ignore
            if (res?.error?.data?.errorMessage) {
                // @ts-ignore
                setHasError(res?.error?.data?.errorMessage as string)
                return;
            }
            onClose()
        });
    }

    return (
        <BaseShiftsModal onClose={onClose} title={title} isOpen={isOpen}>
            <Modal.Content className={styles.modalContentAccess}>
                <Card onClose={onClose}>
                    <Form initialValues={initalValue} onSubmit={onSubmit}>
                        {({handleSubmit, invalid}) => {
                            return <form onSubmit={handleSubmit} className={styles.form} >
                                <div className={styles.content}>
                                    <p className={styles.formTitle}>{intl.formatMessage({id: ETranslations.GROUP_PlURAL})}</p>
                                    <div>
                                        <Labeled label={intl.formatMessage({id: ETranslations.GROUP_TYPE})}>
                                            <Field name="group_type" validate={required}>
                                                {(props) => (
                                                    <SelectBasic
                                                        options={types}
                                                        disabled={isAdminOfRest ? true : false}
                                                        placeholder="asd"
                                                        onChange={props.input.onChange}
                                                        value={props.input.value}
                                                    />
                                                )}
                                            </Field>
                                        </Labeled>

                                        <Labeled label={intl.formatMessage({id: ETranslations.NAME_GROUP})} required>
                                            <Field name="name" validate={(value) =>
                                                requiredField(
                                                    value,
                                                )
                                            }>
                                                {(props) => (
                                                    <Input
                                                        onChange={props.input.onChange}
                                                        value={props.input.value}
                                                    />
                                                )}
                                            </Field>
                                        </Labeled>

                                    </div>
                                    <Labeled label={intl.formatMessage({id: ETranslations.GROUP_COLOR})} required>
                                        <Field name="color" component={ChooseColorRedux} />
                                    </Labeled>
                                    <p className={styles.formTitle}>{intl.formatMessage({id: ETranslations.TAGS})}</p>
                                    <Labeled label={intl.formatMessage({id: ETranslations.ADD_TAG})} required>
                                        <Field name="newTag" component={AddTagToGroupRedux} styles={styles} />
                                    </Labeled>
                                    <Field name="tags" validate={required}>
                                        {() => (
                                            <div className={styles.tagsContainer}>
                                                <TagsContainer />
                                            </div>
                                        )}
                                    </Field>

                                </div>
                                <BottomBar className={styles.controls}>
                                    <BottomBar.Part placement="left">
                                        <Button variant="primary" disabled={invalid} onClick={handleSubmit}>
                                            {intl.formatMessage({id: ETranslations.BASE_CREATE})}
                                        </Button>
                                        <Button variant="secondary" onClick={onClose} >
                                            {intl.formatMessage({id: ETranslations.BASE_CANCEL})}
                                        </Button>
                                    </BottomBar.Part>
                                </BottomBar>


                                {hasError
                                    && <ModalPopup
                                        needIcon={true}
                                        editBooking
                                        isOpen={!!hasError}
                                        onClose={toggleServerError}
                                        buttons={
                                            <Button onClick={toggleServerError} variant="secondary">
                                                {intl.formatMessage({id: ETranslations.BASE_CLOSE})}
                                            </Button>
                                        }
                                        title={intl.formatMessage({id: ETranslations.BASE_ERROR})}
                                        text={hasError}
                                    />}
                            </form>
                        }}
                    </Form>
                </Card>

            </Modal.Content>
        </BaseShiftsModal>
    );
};
