
import cn from 'classnames';

import {ICONS} from '..';

import styles from './Spinner.module.scss';

export function Spinner({className}: { className?
  : string}) {
  return (
    <div className={cn(styles['spin-loading'], className)}>
      <ICONS.Loading />
    </div>
  );
}
