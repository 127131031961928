import {coreApi2} from "./bookings-api/core";


export const accessRulesApi = coreApi2
    .enhanceEndpoints({addTagTypes: ["Rules"]})
    .injectEndpoints({
        endpoints: (build) => ({
            createAccessRule: build.mutation({
                query: (payload: any) => ({
                    url: "access",
                    method: "POST",
                    body: payload,
                }),
                invalidatesTags: [{type: "AccessRules", id: "LIST"}],
            }),

            getAccessRules: build.query({
                query: (payload: any) => ({
                    url: `access/${payload}/list`,
                    method: "GET",
                }),
                providesTags: (result, error, args) => [{type: "AccessRules", id: "LIST"}],
            }),

            removeAccessRule: build.mutation({
                query: (id: number) => ({
                    url: `access/${id}`,
                    method: "DELETE",
                }),
                invalidatesTags: [{type: "AccessRules", id: "LIST"}],
            }),

            editAccessRule: build.mutation({
                query: (payload) => ({
                    url: `access/${payload.id}`,
                    method: "PUT",
                    body: payload.body,
                }),
                invalidatesTags: (result, err, args) => [
                    {type: "AccessRules", id: "LIST"},
                ],
            }),
        }),
    });

export const {
    useCreateAccessRuleMutation,
    useRemoveAccessRuleMutation,
    useGetAccessRulesQuery,
    useEditAccessRuleMutation,
} = accessRulesApi;