import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {timelineReducer, timelineSliceName} from 'features/Timeline';
import {guestListReducer, guestListSliceName} from './features/GuestsList';
// import {applicationContextReducer, applicationContextSliceName} from "./features/AppContex";
import {hallSchemaSliceName, hallSchemaSliceReducer} from './features/HallSchema';
import {formProxySliceName, formProxySliceReducer} from './features/BookingFormProxy';
import {moveBookingReducers, moveBookingSliceName} from './features/MoveBooking';
import {applicationContextReducer, applicationContextSliceName} from './features/AppContex';
import {api} from './features/api';
import {tableBookingListReducer, tableBookingListSliceName} from './features/TableBooking/slice';
import {globalSearchSliceName, globalSearchReducer} from './features/GlobalSearch';
import {api2, relativePathApi} from "./features/api/api";
import {accessRuleReducer, accessRulesSliceName} from 'features/AccessRules/slice';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [api2.reducerPath]: api2.reducer,
  [relativePathApi.reducerPath]: relativePathApi.reducer,
  [guestListSliceName]: guestListReducer,
  [timelineSliceName]: timelineReducer,
  [hallSchemaSliceName]: hallSchemaSliceReducer,
  [accessRulesSliceName]: accessRuleReducer,
  [formProxySliceName]: formProxySliceReducer,
  [tableBookingListSliceName]: tableBookingListReducer,
  [moveBookingSliceName]: moveBookingReducers,
  [applicationContextSliceName]: applicationContextReducer,
  [globalSearchSliceName]: globalSearchReducer,
});

const setupStore = () => configureStore({
  reducer: rootReducer,
  devTools: {
    actionsBlacklist: ["coreApi", "coreApi2"],
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(
      api.middleware,
      api2.middleware,
      relativePathApi.middleware
    ),
});

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']


export const store = setupStore();
