import {useMemo} from "react";
import {useSelector} from "react-redux";
import {disableFormFieldSelector, orderTrustSelector} from "../../../../features/BookingFormProxy/selectors";
import {useAppUsers} from "../../../../contexts/AppUsersContext";
import {sourcesSelector} from "../../../../features/AppContex/selectors";
import {useIntlUtils} from "../../../../hooks/useIntlUtils";
import {ETranslations} from "../../../../types/translates";
import {config} from "config";

const useCreateFromOrder = () => {
    const {
        intl,
        getIntlJoinedParts,
        getIntlNumberOf,
        isRussianLocale,
        getIntlBaseErrorNotSelected,
    } = useIntlUtils();

    const intlTitles = useMemo(() => ({
        tableNumber: getIntlNumberOf(isRussianLocale ? ETranslations.PLURAL_TABLES_ALT : ETranslations.PLURAL_TABLE),
    }), []);

    const order = useSelector(orderTrustSelector);
    const disable = useSelector(disableFormFieldSelector);


    const isNewDesign = config.newBookingDesign;


    const {userList} = useAppUsers();
    const sources = useSelector(sourcesSelector);
    // use memo for values, to return same references of values, this fix infinity update loop
    return {
      isNewDesign,
      getIntlJoinedParts,
      disable,
      intl,
      getIntlNumberOf,
      isRussianLocale,
      intlTitles,
      getIntlBaseErrorNotSelected,
      sources,
      userList,
      order,
    };
}

export default useCreateFromOrder