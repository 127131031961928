import {VFC} from 'react';
import RCPagination from 'rc-pagination';
import cn from 'classnames';
import 'rc-pagination/assets/index.css';
import styles from './Pagination.module.scss';
import {ICONS} from 'common/helpers';
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";


interface Props {
  currentPage: number,
  pageSize: number,
  total: number,
  onChange: (page: number) => void
  className?: string;
}

export const Pagination: VFC<Props> = ({currentPage, pageSize, total, className, onChange}) => {
  const intl = useIntl();

  return (
    <RCPagination
      current={currentPage + 1}
      pageSize={pageSize}
      total={total}
      className={cn(className, styles.pagination)}
      onChange={onChange}
      prefixCls="pg"
      hideOnSinglePage
      prevIcon={<ICONS.PrevIcon />}
      nextIcon={<ICONS.NextIcon />}
      jumpNextIcon="...."
      jumpPrevIcon="...."
      locale={
        {page: intl.formatMessage({id: ETranslations.PAGE})}
      }
    />
  );
};
