import { memo } from 'react';
import cn from 'classnames';
import { Spinner } from 'ui-kit';
import { Booking, BookingOrder, ManagerialTable } from 'types/booking';
import {
  getBookingId,
  getManagerialTableId,
  isBooking,
  isManagerialTable,
} from 'utils';
import { BookingCard, BookingCardViewProps } from 'components/BookingCard';
import { BookingActions } from 'components/BookingActions';
import { OrderActions } from 'components/BookingActions/OrderActions';
import { ETranslations } from '../../types/translates';
import styles from './BookingList.module.scss';
import { useBookingList } from './useBookingList';

export interface BookingsListProps<
  T extends Booking | BookingOrder | ManagerialTable
> extends BookingCardViewProps {
  bookings: T[];
  className?: string;
  emptyMessage?: string;
  loading?: boolean;
  withActions?: boolean;
  allowMove?: boolean;
  canDrag?: boolean;
  onClick?: (book: Booking) => void;
  onEdit?: ((order: BookingOrder) => void) | ((book: Booking) => void);
}

const List = <T extends Booking | BookingOrder | ManagerialTable>({
  bookings,
  className,
  emptyMessage,
  loading,
  withActions,
  allowMove,
  canDrag = true,
  onClick,
  onEdit,
  ...props
}: BookingsListProps<T>) => {
  const { intl, onSelectBook, onEditBook, selectedBooking, isDashboard }
    = useBookingList();

  if (!bookings.length) {
    return (
      <div className={cn(styles.list, className)}>
        <div className={styles.blank}>
          {emptyMessage
            || intl.formatMessage({ id: ETranslations.BOOKING_LIST_EMPTY })}
          {loading && <Spinner className={styles.spinner} />}
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.list, className)}>
      {bookings.map((b) => (
        <BookingCard
          canDrag={canDrag}
          key={
            isManagerialTable(b)
              ? getManagerialTableId(b as ManagerialTable)
              : getBookingId(b)
          }
          booking={b as Booking}
          onClick={onClick || onSelectBook}
          {...props}
          className={styles.card}
          active={
            selectedBooking && getBookingId(selectedBooking) === getBookingId(b)
          }
          isDashboard={isDashboard}
          actions={
            withActions ? (
              isBooking(b) || isManagerialTable(b) ? (
                <BookingActions
                  booking={b}
                  onEdit={onEdit || (onEditBook as any)}
                  allowMove={allowMove}
                />
              ) : (
                <OrderActions order={b} onEdit={onEdit as any} />
              )
            ) : undefined
          }
        />
      ))}
      {loading && <Spinner className={styles.spinner} />}
    </div>
  );
};
export const BookingsList = memo(List) as typeof List;
