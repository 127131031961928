import {HTMLAttributes, VFC} from "react";
import styles from './tag.module.scss'
import cn from "classnames";

export type TTagProps = {
  label: string
} & HTMLAttributes<HTMLDivElement>
export const Tag: VFC<TTagProps> =({label, className, ...props}) => {
  return <div className={cn(styles.tag, className)} {...props}>{label}</div>
}
