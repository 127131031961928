import React from 'react';
import styles from './Chips.module.scss';
import {ICONS} from 'ui-kit/ICONS';

interface IChips {
  value: string;
  background: string;
  type: 'SELECTABLE' | 'INITIAL' | 'DELETABLE';
  textColor?: string;
  index: number;
  deleteCallBack?: (index: number, e: React.MouseEvent<SVGSVGElement>) => void;

}

const Chips = ({value, background, type, textColor, index, deleteCallBack}: IChips) => {
 
  const handleDeleteClick= (e:React.MouseEvent<SVGSVGElement>) => {
    if (typeof deleteCallBack === 'function') {
      deleteCallBack(index, e)
    }
  };

  return (
    <button style={{background, color: textColor ?? '#000'}} className={styles[type]}>
      {value}
      {type === 'DELETABLE' && <ICONS.Cross onClick={handleDeleteClick} />}
    </button>
  );
};

export default Chips;
