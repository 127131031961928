import React from "react";
import {Button, Card} from "ui-kit";
import {Form} from "react-final-form";
import {ETranslations} from "types/translates";
import {ManagerialTablesForm} from "./ManagerialTablesForm";
import CreateManageralModalError from "./CreateManageralModalError";
import useCreateManageralBooking from "./hooks/useCreateManageralBooking";

export const CreateManageralForm = ({reset}: { reset: any }) => {

  const {initialValues,
    intl,
    onSubmit,
    close,
    onClose,
    manageralError} = useCreateManageralBooking(reset)

  return (
    <Card onClose={close}>
      <Card.Header
        title={intl.formatMessage({ id: ETranslations.CREATE_BOOKING })}
        ManageralButton={
          <Button variant="primary" style={{ padding: 5, marginRight: 12, font: 'var(--font-13-r)', fontSize: '400' }}>
              {intl.formatMessage({ id: ETranslations.MANAGEMENT_BOOKING })}
          </Button>
        }
      />
      <Card.Content noPadding>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={() => {
            return <ManagerialTablesForm />;
          }}
        />
      </Card.Content>
      {!!manageralError.length && (
        <CreateManageralModalError
          onClose={onClose}
          manageralError={manageralError}
          isOpen={!!manageralError.length}
        />
      )}
    </Card>
  );
};
