import {useState} from 'react';
import styles from './ToggleSwitch.module.scss';
import cn from 'classnames';

export const ToggleSwitch = ({
  isEnabled,
  onToggle,
  disabled,
}: {
  isEnabled: boolean;
  onToggle: () => Promise<boolean | undefined>;
  disabled: boolean;
}) => {
  const [isActive, setIsActive] = useState(() => isEnabled);

  const handleChangeToggle = () => {
    setIsActive((prev) => !prev); //optimistic update
    onToggle().then((value) => setIsActive((prev) => value ?? !prev)); //real update
  };
  return (
    <label
      className={cn(styles['switch-toggle-container'], {
        [styles.disabled]: disabled,
      })}
    >
      <input
        onChange={handleChangeToggle}
        className={styles['switch-toggle-input']}
        type="checkbox"
        checked={isActive}
      />
      <span className={styles['switch-toggle']} />
    </label>
  );
};
