import { Tag } from 'ui-kit';
import type { ExtraStatus } from 'types/status';

export const ExtraStatusTag = ({
  extraStatus,
  className,
}: {
  extraStatus: ExtraStatus;
  className: string;
}) => (
  <Tag
    className={className}
    label={extraStatus.name}
    style={{ backgroundColor: extraStatus.color || 'gray' }}
  />
);
