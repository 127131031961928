import {FunctionComponent, useState} from "react";
import {Modal, ModalProps} from "components/modal";
import {Tabs} from "ui-kit";
import BookingHistoryModalBook from "./BookingHistoryModalBook";
import BookingHistoryModalChanges from "./BookingHistoryModalChanges";
import BookingHistoryModalMoving from "./BookingHistoryModalMoving";
import BookingHistoryModalStatus from "./BookingHistoryModalStatus";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";

type Props = {
  bookingId : number
} & Omit<ModalProps, 'children'>;

enum Tab {
  booking = 'booking',
  historyChanges = 'historyChanges',
  historyStatus = 'historyStatus',
  movingPlace = 'movingPlace',
}


const BookingHistoryModal: FunctionComponent<Props> = ({isOpen, onClose, title, bookingId}) => {
  const {intl, getIntlHistoryOf} = useIntlUtils()
  const [tab, setTab] = useState<string>(Tab.booking);

  return (
    <Modal onClose={onClose} title={title} isOpen={isOpen}>
      <Modal.Content className="historyModal">
        <Tabs activeTab={tab} onChange={setTab}>
          <Tabs.Item title={intl.formatMessage({id: ETranslations.PLURAL_BOOKING})} tabName={Tab.booking}>
              <BookingHistoryModalBook bookingId={bookingId} />
          </Tabs.Item>
          <Tabs.Item title={getIntlHistoryOf(ETranslations.PLURAL_CHANGES)} tabName={Tab.historyChanges}>
                  <BookingHistoryModalChanges bookingId={bookingId} />
          </Tabs.Item>
          <Tabs.Item title={getIntlHistoryOf(ETranslations.PLURAL_STATUSES_ALT)} tabName={Tab.historyStatus}>
            <BookingHistoryModalStatus bookingId={bookingId} />
          </Tabs.Item>
          <Tabs.Item
            title={getIntlHistoryOf(ETranslations.PLURAL_MOVES_ALT)}
            tabName={Tab.movingPlace}
          >
          <BookingHistoryModalMoving bookingId={bookingId} />
          </Tabs.Item>
        </Tabs>
      </Modal.Content>
    </Modal>
  );
};

export default BookingHistoryModal;
