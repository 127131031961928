import { User } from 'types/user';
import { IResponse } from 'models/common';
import { Notification } from 'services/notification';
import { api } from './api';
import { ETranslations } from '../../types/translates';
import type { AppointUser } from 'models/user.model';

interface UpdateUserPassword {
  old_password: string;
  new_password: string;
  userId: number;
}

export const userApi = api
  .enhanceEndpoints({ addTagTypes: ['Users'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAllUsers: build.query<User[], void>({
        query: () => ({
          url: 'users',
        }),
        transformResponse: (response: IResponse<User[]>) => response.data,
        providesTags: ['Users'],
      }),
      getUserById: build.query<User, number>({
        query: (userId) => ({
          url: `users/${userId}`,
          method: 'GET',
        }),
        transformResponse: (response: IResponse<User>) => response.data,
      }),
      updatePassword: build.mutation<unknown, UpdateUserPassword>({
        query: ({ userId, ...body }) => ({
          url: `/user/change_password/${userId}`,
          method: 'POST',
          body,
        }),
      }),
      createUser: build.mutation({
        query: (user: AppointUser) => ({
          url: `/user`,
          method: 'POST',
          body: user,
        }),
        invalidatesTags: ['Users'],
        async onQueryStarted(_args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (e) {
            console.error(e);
          }
        },
      }),
      deleteUser: build.mutation<unknown, number>({
        query: (userID) => ({
          url: `/user/${userID}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Users'],
        async onQueryStarted(_args, { queryFulfilled }) {
          await queryFulfilled;
          Notification.success({
            title: ETranslations.USER_SUCCESSFULLY_DELETED,
          });
        },
      }),
    }),
  });

export const {
  useGetUserByIdQuery,
  useUpdatePasswordMutation,
  useGetAllUsersQuery,
  useDeleteUserMutation,
  useCreateUserMutation
} = userApi;
