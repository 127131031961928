import {InputHTMLAttributes, forwardRef, Ref, ReactNode} from 'react';
import cn from 'classnames';

import styles from './Input.module.scss';
import {InputAddon} from './InputAddon';
import {Labeled} from '../Labeled';

type BaseInputProps = InputHTMLAttributes<HTMLInputElement>;

export interface InputProps extends Omit<BaseInputProps, 'prefix'> {
  label?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  inputClassName?: string;
  invalid?: boolean;
}

export const Input = forwardRef(
  ({
    label,
    className,
    prefix,
    suffix,
    required,
    inputClassName,
    invalid,
    ...props
   }: InputProps, ref: Ref<HTMLInputElement>) => (
    <Labeled label={label} required={required} className={className}>
      <div className={styles.inputContainer}>
        <InputAddon className={styles.prefix}>
          {prefix}
        </InputAddon>
        <input
          {...props} ref={ref}
          required={required}
          className={cn(styles.input, inputClassName, {
            [styles.withPrefix]: !!prefix,
            [styles.withSuffix]: !!suffix,
            [styles.invalid]: !!invalid,
          })}
        />
        <InputAddon className={styles.suffix}>
          {suffix}
        </InputAddon>
      </div>
    </Labeled>
  )
);
