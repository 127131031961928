import {HallMode, HallSchemaSlice, hallSchemaSliceName, initialState} from './slice';
import {createSelector} from '@reduxjs/toolkit';
import {moveBookingSelectors} from 'features/MoveBooking';

const selectDomain = (state: any) => state[hallSchemaSliceName] || initialState;
export const hallModeSelector = createSelector([
  selectDomain,
  moveBookingSelectors.isSourceSelected,
], ({mode}: HallSchemaSlice, isSelected) => (isSelected ? HallMode.MOVE_BOOKINGS_CAPTURED : mode));
export const modalActionTypeSelector = createSelector(
  selectDomain,
  ({modalActionType}: HallSchemaSlice) => modalActionType,
);

export const activeTablesSelector = createSelector(
  selectDomain,
  ({activeTables}: HallSchemaSlice) => activeTables || [],
);


export const bookingCaptureIdSelector = createSelector(
  selectDomain,
  ({bookingCaptureId}: HallSchemaSlice) => bookingCaptureId,
  );
