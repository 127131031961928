// Хук для определения, находится ли экран в ландшафтной ориентации
import {useCallback, useEffect, useState} from 'react';

const checkLandscape = (orientation: OrientationType) => [
  'landscape-primary',
  'landscape-secondary'
].includes(orientation);

export const useIsLandscape = (): boolean => {
  const [isLandscape, setIsLandscape] = useState<boolean>(
    checkLandscape(window.screen.orientation.type)
  );

  const handleOrientationChange = useCallback(() =>
      setIsLandscape(
        checkLandscape(window.screen.orientation.type)
      ),
    []);

  useEffect(() => {
    window.screen.orientation.addEventListener('change', handleOrientationChange);

    return () => {
      window.screen.orientation.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return isLandscape;
};
