import {ReactNode, useCallback, useContext, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {AuthService} from 'services/auth.service';
import {ModalContext} from 'contexts/ModalContext';
import {Header} from 'components/root/header';
import {LayoutContext} from 'contexts/LayoutContext';

import './Layout.scss';
import {useToggle} from 'react-use';
import {useApplicationContextActions} from 'features/AppContex';
import moment from 'moment';
import {useOrderCount} from '../../features/api/booking-order-api';
import {config} from 'config';

import styles from './Layout.module.scss';
import {useFromProxyActions} from 'features/BookingFormProxy';
import {Sidebar} from '../../ui-kit';
import {api} from 'features/api';
import {useDispatch} from 'react-redux';

interface LayoutProps {
  children: ReactNode;
}


export function Layout({children}: LayoutProps): JSX.Element {
  const {
    closeModals,
    state,
  } = useContext(ModalContext);
  const {setDate} = useApplicationContextActions();
  const {pageId} = useContext(LayoutContext);
  const [isMenuOpen, toggleMenu] = useToggle(false);
  const {data: requestsCount = 0} = useOrderCount();
  const [userApp] = useState(AuthService.getUser());
  const {reset} = useFromProxyActions();
  const dispatch = useDispatch();


  const [now, setNow] = useState(moment());
  const prevDate = useRef(now);

  const logout = useCallback(() => {
    AuthService.logout();
    dispatch(api.util.resetApiState())
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextNow = moment()
        .clone();
      setNow(nextNow);
      if (!nextNow.isSame(prevDate.current, 'day')) {
        setDate(nextNow);
      }
      prevDate.current = nextNow;
    }, 10e3);
    return () => clearInterval(interval);
  }, []);


  return (
    <div className={cn('container', {
      modal: state.activeModal?.position === 'root',
    })}
    >
      <Header onToggleMenu={toggleMenu} />
      <div id="content">
        <Sidebar requestsCount={requestsCount} userApp={userApp} now={now} logout={logout} isOpen={isMenuOpen} reset={reset} />
        <main
          id={pageId}
          onClick={() => closeModals()}
          className={styles.main}
        >
          {children}
        </main>
        {config.CI_COMMIT_SHORT_SHA && <span className={styles.version}>{config.CI_COMMIT_SHORT_SHA}</span>}
      </div>
    </div>
  );
}
