import type { Tag } from 'types/tag';

export type TagsShortView = {
  visibleTags: Tag[];
  invisibleTagsCount: number;
};

export const tagsToShortView = (tags: Tag[], count: number): TagsShortView => ({
  visibleTags: tags.slice(0, count),
  invisibleTagsCount: tags.length - count,
});
