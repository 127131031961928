import {api} from './api';
import {saveAs} from 'file-saver';


export interface BookingsReportParams {
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  status: string;
}

export interface PlacementReportParams {
  restaurant_id: number;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
  use_visit_time: boolean;
  is_confirmed: boolean;
  format: string;
  interval: number;
}

const reportsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    bookingsReport: builder.mutation<void, BookingsReportParams>({
      query: (params) => ({
        url: 'v2/report/xls',
        headers: {'Content-Type': 'application/json'},
        params,
        responseHandler: async (response) => {
          if (response.status === 200) {
            const data = await response.blob();
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const filename = `Report ${params.start_date} to ${params.end_date}.xlsx`;
            saveAs(blob, filename);
            return {data: filename};
          } else {
            console.error(response.body);
          }
        },
      }),
    }),
    placementReport: builder.mutation<void, PlacementReportParams>({
      query: (body) => ({
        url: 'v2/report/placement',
        method: 'POST',
        body,
        responseHandler: async (response) => {
          const data = await response.blob();
          const ext = body.format === 'XLSX' ? 'xls' : 'csv';
          const contentType = ext === 'xls' ? 'application/vnd.ms-excel' : 'text/csv';
          const blob = new Blob([data], {type: contentType});
          const filename = `placement-${body.start_date}-${body.end_date}.${ext}`;
          saveAs(blob, filename);
          return {data: filename};
        },
      }),
    }),
  }),
});

export const {useBookingsReportMutation, usePlacementReportMutation} = reportsApi;
