import { Button } from 'ui-kit';

export const ModalControls = ({
  className,
  formName,
  disabled,
  submitButtonText,
  cancelButtonText,
  onCancel,
}: {
  className: string;
  formName: string;
  disabled: boolean;
  submitButtonText: string;
  cancelButtonText: string;
  onCancel: () => void;
}) => {
  return (
    <div className={className}>
      <Button
        form={formName}
        variant="primary"
        disabled={disabled}
        type="submit"
      >
        {submitButtonText}
      </Button>
      <Button variant="secondary" onClick={onCancel}>
        {cancelButtonText}
      </Button>
    </div>
  );
};
