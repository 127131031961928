import { Role } from "services/auth.service";

export class User {
  constructor(
    public id: number,
    public name: string,
    public photo: string,
    public phone: string,
    public type?: string,
  ) { }
}

export class UserExtended implements User {
  constructor(
    public value: string | number,
    public label: string,
    public id: number,
    public name: string,
    public photo: string,
    public phone: string,
  ) { }
}

export class AppointUser {
  constructor(
    public name: string,
    public login: string,
    public restaurant_id: number,
    public role: Role,
    public photo: string | null,
    public phone: string | null,
    public id?: number,
    public max_guests?: number,
    public password?: string,
  ) { }
}
