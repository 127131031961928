import {FC, HTMLAttributes} from "react";
import cn from "classnames";

import {ColumnBar} from "..";

import styles from "./BottomBar.module.scss";

export type BottomBarProps = HTMLAttributes<HTMLDivElement>

export const BottomBar: FC<BottomBarProps> = (props) => {
  return (
    <ColumnBar {...props} className={cn(props.className, styles.bottomBar)} />
  );
};
