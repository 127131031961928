import {apiINT} from "./api-data";
import {AuthService} from "./auth.service";
import {RequestWrapper} from "./request-wrapper";
import {AppointRestaurant, Place, Restaurant, Schedule} from "models/restaurant.model";
import {Response} from "models/common";

export class RestaurantsService {
  static async getAll(): Promise<{ data: Restaurant[] }> {
    return RequestWrapper.fetch(`${apiINT}/v2/restaurants`, {headers: {Authorization: AuthService.getToken()}})
      .then(function (response) {
        return response.json();
      })
  }

  static async change(restaurant: AppointRestaurant): Promise<Response<any>> {
    return RequestWrapper.fetch(
      `${apiINT}/restaurant`,
      {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', Authorization: AuthService.getToken()},
        body: JSON.stringify(restaurant)
      },
    ).then((response) => response.json());
  }

  static async create(restaurant: AppointRestaurant): Promise<Response<any>> {
    return RequestWrapper.fetch(
      `${apiINT}/restaurant`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json', Authorization: AuthService.getToken()},
        body: JSON.stringify(restaurant)
      },
    ).then((response) => response.json());
  }
}
