import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {config} from 'config';

Sentry.init({
  dsn: 'https://74ab3125530d450e9d86a0e59972a4f7@o986743.ingest.sentry.io/5943521',
  integrations: [new Integrations.BrowserTracing()],
  enabled: process.env.NODE_ENV === 'production',
  environment: config.BRAND,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

});


export {Sentry};
