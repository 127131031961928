import React, {forwardRef, HTMLAttributes, Ref} from 'react';
import { ShortUserDetail } from 'components/GuestInfo';


interface BookingClientDetailsProps extends HTMLAttributes<HTMLDivElement> {
  clientId: number;
}


export const ClientDetailsPopup = forwardRef(({clientId}: BookingClientDetailsProps, ref: Ref<HTMLDivElement>) => {

  return (
    <ShortUserDetail userId={clientId}/>
  );
});


