import { Modal } from 'components/modal';
import styles from './GuestNotes.module.scss';
import { Button } from 'ui-kit';
import { ETranslations } from 'types/translates';
import type { IntlFormatters } from 'react-intl';
import { useState } from 'react';
import { MAX_NOTE_CHAR_COUNT, NoteModalSettings } from './utils';
import { capitalizeString } from 'utils';

export const NoteModal = ({
  title,
  isOpen,
  close,
  disabled,
  formatMessage,
  submit,
  defaultNoteText,
  submitButtonText
}: {
  title: string;
  isOpen: boolean;
  close: () => void;
  disabled: boolean;
  formatMessage: IntlFormatters['formatMessage'];
  submit: (note: string) => Promise<unknown>;
  submitButtonText: string;
  defaultNoteText?: string;
}) => {
  const [textNote, setTextNote] = useState(defaultNoteText || '');

  const handleNoteFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    submit(textNote).then(close);
  };

  return (
    <Modal
      className={styles.modal}
      title={title}
      onClose={close}
      isOpen={isOpen}
    >
      <Modal.Content className={styles.modalBody}>
        <form
          className={styles.noteForm}
          id="create-note-form"
          onSubmit={handleNoteFormSubmit}
        >
          <label htmlFor="note">
            <h2>
              {capitalizeString(formatMessage({ id: ETranslations.NOTE }))}
            </h2>
            <span>{`${textNote.length}/${MAX_NOTE_CHAR_COUNT}`}</span>
          </label>
          <textarea
            name="note"
            required
            rows={6}
            value={textNote}
            maxLength={MAX_NOTE_CHAR_COUNT}
            onChange={(e) => setTextNote(e.currentTarget.value)}
          />
        </form>
      </Modal.Content>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          variant="primary"
          disabled={disabled}
          type="submit"
          form="create-note-form"
        >
          {submitButtonText}
        </Button>
        <Button variant="secondary" onClick={close} type="button">
          {formatMessage({ id: ETranslations.BASE_CANCEL })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
