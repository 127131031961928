import React, { useState, FC } from 'react';
import { useIntl } from 'react-intl';

import { Card, Tabs } from '../../ui-kit';
import { Client } from 'models/client.model';
import { ETranslations } from '../../types/translates';
import { GuestContent } from './GuestContent/GuestContent';
import { GuestHistory } from './GuestHistory/GuestHistory';
import type { TButtons } from './GuestContent/Control/Control';
import { GuestNotes } from './GuestNotes/GuestNotes';

type TabName = 'Guest card' | 'History' | 'Notes';

interface TProps {
  guest?: Client;
  buttons: TButtons[];
}

const renderContent = (tab: TabName, props: TProps) => {
  switch (tab) {
    case 'Guest card':
      return <GuestContent guest={props.guest} buttons={props.buttons} />;
    case 'History':
      return (
        props.guest?.client_id && (
          <GuestHistory client_id={props.guest?.client_id} />
        )
      );
    case 'Notes':
      return (
        props.guest?.client_id && (
          <GuestNotes client_id={props.guest?.client_id} />
        )
      );
    default:
      return null;
  }
};

export const GuestInfo = (props: TProps) => {
  const [tab, setTab] = useState<TabName>('Guest card');
  const intl = useIntl();

  return (
    <>
      <Card.HeaderTabs<TabName> activeTab={tab} onChange={setTab}>
        <Tabs.Item<TabName>
          title={intl.formatMessage({ id: ETranslations.GUEST_CARD })}
          tabName={'Guest card'}
        />
        <Tabs.Item<TabName>
          title={intl.formatMessage({ id: ETranslations.BASE_HISTORY })}
          tabName={'History'}
        />
        <Tabs.Item<TabName>
          title={intl.formatMessage({ id: ETranslations.NOTES })}
          tabName={'Notes'}
        />
      </Card.HeaderTabs>
      <Card.Content noPadding>{renderContent(tab, props)}</Card.Content>
    </>
  );
};
