import React from 'react';
import cn from 'classnames';
import {FieldRenderProps} from 'react-final-form';
import {Button, Input} from 'ui-kit';
import {ICONS} from '../../../common/helpers';
import useChoicePhoneRedux from './hooks/useChoicePhoneRedux';
import styles from './Phone.module.scss';
import commonStyles from './style.module.scss';
import {ClientInfoPopup} from 'components/ClientInfoPopup';

const ChoicePhoneRedux = ({
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  autoFocus,
  className,
  isNewDesign,
  touched,
  isCreateBooking,
  ...props
}: any) => {
  const {clearPhoneNumber, showClients, client, isEmpty, showPopUp}
    = useChoicePhoneRedux({value, onChange, disabled, isCreateBooking});
  return (
    <div className={cn(styles.container, className)}>
      <Input.Phone
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
        // @ts-ignore
        disabled={disabled || client?.client_id}
        onBlur={onBlur}
        onFocus={onFocus}
        className={styles.phone}
        autoComplete="off"
        inputClassName={commonStyles.boldText}
        suffix={
          <>
            {(!disabled && !!client?.client_id) || !isEmpty ? (
              <ICONS.Cross
                className={styles.clear}
                onClick={clearPhoneNumber}
                width={20}
                height={20}
              />
            ) : null}
            {!client?.client_id && isEmpty && (
              <ICONS.GuestsIconNew
                onClick={showClients}
                width="100%"
                height="100%"
                className={styles.guestIconNew}
              />
            )}
          </>
        }
        {...props}
      />
      {!disabled && !client && !isNewDesign && (
        <button
          type="button"
          onClick={showClients}
          className={styles.guestButton}
        >
          <ICONS.GuestsIcon
            width="100%"
            height="100%"
            className={styles.guestIcon}
          />
        </button>
      )}
      {showPopUp && (
        <ClientInfoPopup clientId={client!.client_id} placement="right-start">
          <Button
            variant="phantom"
            type="button"
            className={commonStyles.clientIcon}
          >
            <ICONS.Question />
          </Button>
        </ClientInfoPopup>
      )}
    </div>
  );
};

export const ChoiceClientPhoneInputRedux: React.FC<FieldRenderProps<any>> = ({
  input,
  meta,
  ...rest
}) => <ChoicePhoneRedux {...input} {...meta} {...rest} />;
