import React, { FC } from 'react';
import styles from './style.module.scss';
import { ICONS } from 'ui-kit';
import { SvgForeignObjectBody } from 'components/Table/SvgForeignObject';

interface Props {
  isBookingSoon: boolean;
  tableNumber: string;
  statusColor?: string;
}

export const TableNumber: FC<Props> = ({
  isBookingSoon,
  tableNumber,
  statusColor,
}) => {
  return (
    <div className={styles.tableNumberBody}>
      <p
        data-no-d3-zoom
        className={styles.tableNumber}
        style={{ border: `2px solid ${statusColor}` }}
      >
        {isBookingSoon && <ICONS.ClockAlarm width={12} height={12} />}
        {tableNumber}
      </p>
    </div>
  );
};
