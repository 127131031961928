import dayjs from 'dayjs';
import {useReturnAllSlots} from 'features/api/shifts';
import React, {useEffect} from 'react'
import {Option} from "../../../../../../models/common";
import {FieldRenderProps, useField} from 'react-final-form'
import {SelectBasic} from 'ui-kit'
import {timelineSelectors} from 'features/Timeline';
import {useSelector} from 'react-redux';

const TimeField: React.FC<FieldRenderProps<string, any>> = ({meta, input, currentFieldName = '', ...rest}) => {
    const {options} = useReturnAllSlots()
    const timestamp = useSelector(timelineSelectors.getTimestamp);
    const {
        input: {value},
    } = useField<Option<string> | null>(input.name, {allowNull: true});

    useEffect(() => {
        if (!value?.value) {
            const nextValue = options.find(each => dayjs(each.value).isSameOrAfter(timestamp));
            input.onChange(nextValue || null);
        } else {
            return;
        }
    }, [options]);

    return (
        <SelectBasic
            value={value}
            changedMaxSize
            options={options}
            onChange={input.onChange}
            {...rest}
            {...meta}
        />
    )
}

export default TimeField