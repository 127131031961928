import { useMemo, useState, ReactNode } from 'react';
import { ETranslations } from '../../../../types/translates';
import { useIntl } from 'react-intl';
import { ToggleSwitch } from 'ui-kit/ToggleSwitch';
import type { FormattedStatus } from './transform-data';
import type { StatusId } from 'types/status';
import { useEditStatusMutation } from 'features/api/dictionaries-api';
import { ICONS } from 'ui-kit';
import cn from 'classnames';

const CollapseItem = ({
  name,
  color,
  children,
}: {
  name: string;
  color: string;
  children?: ReactNode;
}) => {
  return (
    <article className="table-row blocks">
      <div className="details">
        <span style={{ backgroundColor: color }} className="status-name">
          {name}
        </span>
      </div>
      {children}
    </article>
  );
};

export const StatusCollapse = ({
  categoryName,
  formattedStatuses,
  partialModalStatus,
  editStatus,
  isEditing,
}: {
  categoryName: string;
  formattedStatuses: FormattedStatus[] | undefined;
  partialModalStatus: (statusId: StatusId) => void;
  editStatus: ReturnType<typeof useEditStatusMutation>[0];
  isEditing: boolean;
}) => {
  const intl = useIntl();
  const intlTitles = useMemo(
    () => ({
      notFound: intl.formatMessage({ id: ETranslations.NOT_FOUND }),
    }),
    []
  );
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`statuses sections include-table`}>
      <div
        className={`rest count-${formattedStatuses?.length} ${
          isOpen ? 'active' : ''
        }`}
      >
        <div
          className="rest-info blocks"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <span>{categoryName}</span>
          <figure className={cn("arrow", {active: isOpen})}>
           <ICONS.ArrowDown />
          </figure>
        </div>
        <div
          className="table rows custom-scroll"
          // onAnimationStart={(e: any) => e.target.classList.remove('opened')}
          // onAnimationEnd={(e: any) => e.target.classList.add('opened')}
        >
          {formattedStatuses?.length ? (
            formattedStatuses.map(
              ({ id, color, name, is_active, is_extra }) => (
                <CollapseItem key={id} name={name} color={color}>
                  {is_extra && (
                    <div className="controls">
                      <button
                        className="edit"
                        onClick={(e) => {
                          e.stopPropagation();
                          partialModalStatus(id);
                        }}
                      />
                      <ToggleSwitch
                        isEnabled={is_active}
                        onToggle={() =>
                          editStatus({
                            status_id: id,
                            is_active: !is_active,
                          }).then((data) =>
                            'data' in data
                              ? data.data.data.is_active
                              : undefined)
                        }
                        disabled={isEditing}
                      />
                    </div>
                  )}
                </CollapseItem>
              )
            )
          ) : (
            <div className="message">{intlTitles.notFound}</div>
          )}
        </div>
      </div>
    </div>
  );
};
