import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiINT, apiINT2, getDefaultHeaders } from './utils';

// TODO это надо переписать и отказаться от глобальных каналов
const fetchFn: typeof fetch = async (req, init) => {
  const res = await fetch(req, init);
  const { status } = res.clone();
  if (status === 401) {
    global.channels.auth.cb?.(false);
  }
  return res;
};

export const api = createApi({
  reducerPath: 'coreApi',
  baseQuery: fetchBaseQuery({
    baseUrl: apiINT,
    prepareHeaders: getDefaultHeaders,
    fetchFn,
  }),
  endpoints: () => ({}),
  tagTypes: [
    'Bookings',
    'Booking',
    'Statuses',
    'BookingStatus',
    'Timeline',
    'BookingExtraStatus',
    'PlacesTables',
    'Shifts',
  ],
});

export const api2 = createApi({
  reducerPath: 'coreApi2',
  baseQuery: fetchBaseQuery({
    baseUrl: apiINT2,
    prepareHeaders: getDefaultHeaders,
    fetchFn,
  }),
  endpoints: () => ({}),
  tagTypes: ['Bookings', 'Booking', 'BookingStatus', 'Timeline'],
});

export const relativePathApi = createApi({
  reducerPath: 'relativePathApi',
  tagTypes: ['translates'],
  baseQuery: fetchBaseQuery({ baseUrl: '' }),
  endpoints: () => ({}),
});
