import {HTMLAttributes, useCallback} from "react";

import {ICONS} from "common/helpers";
import {Menu} from "components/Menu/Menu";
import {useCancelBookingMutation, useNotComeBookingMutation} from "features/api/bookings-api";
import {Booking} from "types/booking";

import styles from "./CancelActions.module.scss";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";

interface CancelActionsProps{
  back: HTMLAttributes<HTMLDivElement>['onClick']
  booking: Booking;
  close?: () => void;
}

export function CancelActions({back, booking, close}: CancelActionsProps) {
  const {getIntlReasonOf, intl, isRussianLocale} = useIntlUtils();
  const [cancel] = useCancelBookingMutation();
  const [notComeStatus] = useNotComeBookingMutation();
  const cancelBooking = useCallback(() => {
    const {bookingId} = booking;
    cancel(bookingId);
    close?.();
  }, [booking, close]);

  const notCome = useCallback(async () => {
    const {bookingId} = booking;
    await notComeStatus(bookingId).unwrap();
    close?.();
  }, [booking, close]);


  return (
    <div className={styles.container} onClick={e => e.stopPropagation()}>
      <div onClick={back} className={styles.header}>
        <ICONS.PrevIcon />
        {getIntlReasonOf(isRussianLocale ? ETranslations.PLURAL_CANCELLATION_NOM : ETranslations.PLURAL_CANCELLATION)}
      </div>
      <Menu>
        <Menu.Item onClick={cancelBooking}>
          <div className={styles.cancel}>
            {intl.formatMessage({id: ETranslations.BASE_CANCEL})}
            <span className={styles.hint}>
              {intl.formatMessage({id: ETranslations.CANCELLATION_NOTIFICATION})}
            </span>
          </div>
        </Menu.Item>
        <Menu.Item disabled={booking.status?.category === 'IN_SERVICE'} onClick={notCome}>
          {intl.formatMessage({id: ETranslations.DID_NOT_COME})}
        </Menu.Item>
      </Menu>
    </div>
  );
}
