import React from 'react';
import { Field } from 'react-final-form';
import { Labeled, BottomBar, Button } from 'ui-kit';
import {
  ChoiceVisitTimeButtonsInput,
  ChoiceVisitTimeControls,
} from '../../registration/sub-renders/visit-time';
import { WeekDaysField } from './fields';
import styles from './styles.module.scss';
import { ETranslations } from 'types/translates';
import ManageralModal from './ManageralModal';
import { required } from 'components/shifts/utils';
import { DataChips } from 'components/registration/sub-renders/data-chips';
import { PlaceSelect } from 'components/PlaceSelect';
import {
  ChoiceAutoTableInputRedux,
  ChoiceTablesInputRedux,
} from 'components/registration/sub-renders/location';
import { Edit } from 'components/registration/forms/FormControls/Edit';
import { CancelBooking } from './CancelBooking';
import cn from 'classnames';
import { ChoiceDateInput } from './fields/DateField';
import { EndDate } from './fields/EndDateInput';
import { ChoiceTimeInput } from 'components/registration/sub-renders/date-time';
import { ChoiceTimeInputNew } from 'components/registration/sub-renders/date-time-new';
import useManageralTablesForm from './hooks/useManageralTablesForm';

export const ManagerialTablesForm = ({
  editBooking = false,
}: {
  editBooking?: boolean;
}) => {
  const {
    handleSubmit,
    isNewDesign,
    intl,
    disabeld,
    intlTitles,
    valid,
    openModal,
    dirty,
    handleReset,
    isEditMode,
    timePassed,
    handleOpenBooking,
    modalIsOpen,
    closeModal,
    cancelBooking,
    manageralBookingTimeStamp,
  } = useManageralTablesForm(editBooking );

  return (
    <>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={isNewDesign ? styles.timePartFull : styles.timePart}>
          <Labeled
            label={intl.formatMessage({ id: ETranslations.START_DATE })}
            required
          >
            <Field
              name="start_date"
              component={ChoiceDateInput}
              required
              disabled={editBooking}
            />
          </Labeled>
          <div className={styles.showInSmall}>
            <Field
              name="start_date"
              isCreateBooking={true}
              component={DataChips}
              disabled={editBooking}
            />
          </div>
          <Labeled
            label={intl.formatMessage({ id: ETranslations.END_DATE })}
            required
          >
            <Field
              name="end_date"
              component={EndDate}
              required
              disabled={editBooking}
            />
          </Labeled>
          {!isNewDesign && (
            <Labeled
              label={intl.formatMessage({ id: ETranslations.BASE_TIME })}
              required
            >
              <ChoiceTimeInput
                isFromManagement
                disabled={disabeld}
                allowValueFromOutsideRange={editBooking}
              />
            </Labeled>
          )}

          {isNewDesign && (
            <div
              style={{
                gridTemplateColumns: 'auto',
                display: 'grid',
                gridColumn: 'span 2',
                marginTop: '8px',
              }}
            >
              <ChoiceTimeInputNew
                allowValueFromOutsideRange={editBooking}
                disabled={disabeld}
                isEditMode={isEditMode}
              />
            </div>
          )}
        </div>

        <div className={styles.mainInfo}>
          <Labeled label={intl.formatMessage({ id: ETranslations.DURATION })}>
            <div className={styles.duration}>
              <Field
                name="duration"
                validate={(value) => !value}
                isManagerialTable
                newDesign
                component={ChoiceVisitTimeButtonsInput}
                disabled={disabeld}
              />
              <Field
                name="duration"
                validate={(value) => !value}
                isFromManagement
                component={ChoiceVisitTimeControls}
                disabled={disabeld}
              />
            </div>
          </Labeled>
          <div
            className={cn(
              editBooking
                ? `${styles.placeBox} ${styles.edit}`
                : styles.placeBox
            )}
          >
            <Labeled
              className={styles.bookingLabel}
              label={intl.formatMessage({ id: ETranslations.SEATING_AREA })}
              required
            >
              <PlaceSelect
                manageralBookingTimeStamp={manageralBookingTimeStamp}
                styles={styles}
                isNewDesign
                disabled={disabeld}
              />
            </Labeled>
              <div
                className={cn(
                  editBooking ? styles.tableBoxEdit : styles.tableBox
                )}
              >
                <Labeled
                  className={styles.bookingLabel}
                  required
                  label={intlTitles.tableNumber}
                >
                  <div className={styles.openSchemeContainerNew}>
                    <Field
                      name="tables"
                      validate={(value) => {
                        return !value.length;
                      }}
                      component={ChoiceTablesInputRedux}
                      disabled={disabeld}
                    />
                  </div>
                </Labeled>
                <ChoiceAutoTableInputRedux
                  isCreateBooking={true}
                  disabled={disabeld}
                  isShowTableScheme={false}
                />
              </div>
          </div>

            <div className={styles.tableBox}>
              <Labeled
                className={styles.bookingLabel}
                required
                label={intlTitles.tableNumber}
              >
                <div className={styles.openSchemeContainerNew}>
                  <Field
                    name="tables"
                    validate={(value) => {
                      return !value.length;
                    }}
                    component={ChoiceTablesInputRedux}
                    disabled={disabeld}
                  />
                </div>
              </Labeled>
              <ChoiceAutoTableInputRedux
                isCreateBooking={editBooking}
                disabled={disabeld}
                isShowTableScheme={false}
              />
            </div>
        </div>
        {!editBooking && (
          <Labeled
            className={styles.daysOfRePeat}
            label={intl.formatMessage({
              id: ETranslations.DAYS_TO_REPEAT_A_BOOKING,
            })}
            required
          >
            <Field
              name="week_days"
              component={WeekDaysField}
              required
              validate={required}
            />
          </Labeled>
        )}
      </form>
      <BottomBar className={styles.controls}>
        {!editBooking && (
          <BottomBar.Part placement="left">
            <Button
              variant="primary"
              disabled={editBooking || !valid}
              type="button"
              onClick={openModal}
            >
              {intl.formatMessage({ id: ETranslations.BASE_CREATE })}
            </Button>
            <Button variant="secondary" disabled={!dirty} onClick={handleReset}>
              {intl.formatMessage({ id: ETranslations.CANCEL })}
            </Button>
          </BottomBar.Part>
        )}
        {editBooking && (
          <>
            {!isEditMode && (
              <BottomBar.Part placement="left">
                {!timePassed && (
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={handleOpenBooking}
                  >
                    {intl.formatMessage({ id: ETranslations.CREATE_BOOKING })}
                  </Button>
                )}
              </BottomBar.Part>
            )}
            <BottomBar.Part placement="right">
              <CancelBooking openModal={openModal} />
              <Edit isFromManagement showSave />
            </BottomBar.Part>
          </>
        )}
      </BottomBar>
      <ManageralModal
        buttons={
          <>
            {!editBooking ? (
              <>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  {intl.formatMessage({ id: ETranslations.BASE_CREATE })}
                </Button>
                <Button variant="secondary" onClick={closeModal}>
                  {intl.formatMessage({ id: ETranslations.CANCEL })}
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="dangerous"
                  type="submit"
                  onClick={cancelBooking}
                >
                  {intl.formatMessage({ id: ETranslations.BASE_DELETE })}
                </Button>
                <Button variant="secondary" onClick={closeModal}>
                  {intl.formatMessage({ id: ETranslations.CANCEL })}
                </Button>
              </>
            )}
          </>
        }
        title={
          !editBooking
            ? intl.formatMessage({ id: ETranslations.CREATING_MANAGERAL_TABLE })
            : intl.formatMessage({
                id: ETranslations.DELETE_MANAGERAL_TABLE_TILE,
              })
        }
        text={intl.formatMessage({
          id: ETranslations.CANCEL_MANAGAREL_RESERVATION,
        })}
        isOpen={modalIsOpen}
        editBooking={editBooking}
        onClose={closeModal}
      />
    </>
  );
};
