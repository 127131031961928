import React from "react";
import {FieldRenderProps} from "react-final-form";
import {DatePicker, SelectBasic} from "ui-kit";
import {ETranslations} from "../../../types/translates";
import useChoiseData from "./hooks/useChoiseData";
import useChoiceTimeInput from "./hooks/useChoiceTimeInput";
import styles from "./style.module.scss";


type DateProps = FieldRenderProps<Date, any>;

export const ChoiceTimeInput = ({
  disabled = false,
  allowValueFromOutsideRange = false,
  isFromManagement = false,
}: {
  disabled?: boolean;
  allowValueFromOutsideRange?: boolean;
  isFromManagement?: boolean;
}) => {

  const {
    intl,
    handleChange,
    inputOptions,
    value
  } = useChoiceTimeInput(allowValueFromOutsideRange, isFromManagement, disabled)

  return (
    <SelectBasic
      value={value}
      changedMaxSize
      className={disabled ? "input" : ""}
      options={inputOptions}
      onChange={handleChange}
      disabled={disabled}
      isValid={!!value?.value}
      placeholder={intl.formatMessage({id: ETranslations.BASE_TIME})}
    />
  );
};

const ChoiceDate = ({value, onChange, disabled, minDate = new Date()}: any) => {

  const {datePickerHandler} = useChoiseData(disabled, value, onChange)

  return (
    <DatePicker
      selected={value}
      className={styles.datePicker}
      minDate={minDate}
      disabled={disabled}
      onChange={datePickerHandler}
      customInputClassName={styles.boldText}
    />
  );
};

export const ChoiceDateInput: React.FC<DateProps> = ({input, meta, ...rest}) => <ChoiceDate {...input} {...meta} {...rest} />;
