import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ETranslations } from 'types/translates';
import { Collapse } from 'ui-kit';
import Chips from 'ui-kit/Chips/Chips';
import { EditGroupTagsModal } from './Modals/EditGroupTagsModal';
import type { TagGroup } from 'types/tag';
import styles from './EachTagGroup.module.scss';

interface IEachTagGroup {
  eachGroup: TagGroup;
  selectedTag: string;
  isHostess: boolean;
  isAdminOfRest: boolean;
}

const EachTagGroup = ({
  eachGroup,
  selectedTag,
  isHostess,
  isAdminOfRest,
}: IEachTagGroup) => {
  const { name, color, tags, group_type } = eachGroup;
  const intl = useIntl();
  const [handleOpenEditModal, setHandleOpenEditModal]
    = useState<boolean>(false);
  const onToggleOpenModal = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    setHandleOpenEditModal((prevState) => !prevState);
  };

  const CollapseHeader = useMemo(() => {
    return (
      <div className="collapseHeader">
        <p className={styles.tagGroupName}>
          {name}{' '}
          <span>
            •{' '}
            {intl.formatMessage({
              id:
                group_type === 'GLOBAL'
                  ? ETranslations.GLOBAL
                  : ETranslations.LOCAL,
            })}
          </span>
        </p>
        <p
          style={{
            display:
              isHostess || (isAdminOfRest && group_type === 'GLOBAL')
                ? 'none'
                : 'block',
          }}
          onClick={onToggleOpenModal}
        >
          {intl.formatMessage({ id: ETranslations.BASE_CHANGE })}
        </p>
      </div>
    );
  }, [color, name, tags]);

  return (
    <Collapse
      expendInHeader={true}
      headerClassName={styles.header}
      iconClassName={styles.icon}
      header={CollapseHeader}
    >
      <div className="groupsValue">
        {tags?.map((stringContect, index) => {
          return (
            <Chips
              key={`${index}-${stringContect}`}
              index={index}
              type="INITIAL"
              textColor="#313131"
              background={color}
              value={stringContect}
            />
          );
        })}
      </div>

      {handleOpenEditModal && (
        <EditGroupTagsModal
          isOpen={handleOpenEditModal}
          selectedTag={selectedTag}
          // @ts-ignore
          groupInfo={eachGroup}
          onClose={setHandleOpenEditModal}
          title={intl.formatMessage({ id: ETranslations.EDIT_GROUP })}
        />
      )}
    </Collapse>
  );
};

export default EachTagGroup;
