import { forwardRef } from 'react';
import { clientFullName, ICONS } from 'common/helpers';
import { Client } from 'types/client';
import { config } from 'config';

import styles from './BookingClientInfo.module.scss';
import {
  ManagerialClient,
} from '../../types/booking';
import { Tag } from 'components/Tag';

interface BookingClientInfoProps {
  client: Client | ManagerialClient;
  isManagerialBooking: boolean;
}

export const BookingClientInfo = forwardRef<
  HTMLSpanElement,
  BookingClientInfoProps
>(({ client, isManagerialBooking }, ref) => (
  <div className={styles.bookingClientInfo}>
    {config.vipSign && client.vip && (
      <img width={20} className="star" src={ICONS.StarA} alt="vip" />
    )}
    <span className={styles.clientName} ref={ref}>
      {clientFullName(client)}
    </span>
    {config.clientsHasLoyalty && client.grade_name && (
      <>
        <ICONS.DotIcon />
        <span className={styles.gradeName}>{client.grade_name}</span>
      </>
    )}
    {isManagerialBooking && (
      <Tag
        tag={{
          color: '#FA97FC',
          status: 'management',
          name: 'Manager',
        }}
      />
    )}
  </div>
));
