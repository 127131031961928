import cn from 'classnames';

import {CloseCardContext} from './useCloseCard';

import styles from './Card.module.scss';

export type  CardProps = JSX.IntrinsicElements['div'] & {
  onClose?: () => void;
  layer?: 1 | 2 | 3;
  shadowed?: boolean;
}

export const Card = ({className, onClose, layer = 1, shadowed, ...props}: CardProps) => (
  <CloseCardContext.Provider value={onClose}>
    <div {...props} className={cn(
      className,
      styles.card,
      styles[`layer${layer}` as keyof typeof styles],
      {[styles.shadowed]: shadowed},
)} />
  </CloseCardContext.Provider>
);
