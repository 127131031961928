import React, {FC} from 'react';
import {useAppUsers} from 'contexts/AppUsersContext';
import {useGetUserByIdQuery} from 'features/api/user-api';

interface Props {
  userId: number
}

const FindUser: FC<Props> = ({userId}) => {
  const {data} = useGetUserByIdQuery(userId);

  return (<span>{data?.name}</span>);
};

export const UserLabel: FC<Props> = ({userId}) => {
  const {userList} = useAppUsers();
  const user = userList.find((elem) => elem.id === userId);

  if (user) return (<span>{user.name}</span>);

  return <FindUser userId={userId} />;
};


