import React, {FC, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';

import {useFetchShiftsListQuery} from 'features/api/shifts';
import {appContextSelectors} from 'features/AppContex';
import {TableHead} from './TableHead';
import {TableBody} from './TableBody';
import {TNullable} from 'types/commons';
import {InfoShiftsModal} from '../Modal/InfoModal/InfoShiftsModal';
import {Shift} from 'types/shift';

import styles from './ShiftsTable.module.scss';

export const ShiftsTable: FC = () => {
  const restaurant = useSelector(appContextSelectors.restaurant);
  const {data: shifts} = useFetchShiftsListQuery(restaurant.restaurant_id);
  const [activeShift, setActiveShift] = useState<TNullable<Shift>>(null)
  const [modalOpen, setModalOpen] = useState(false);

  const onClickHandler = useCallback((shift: Shift) => {
    setActiveShift(shift);
    setModalOpen(true);
  }, [])


  if (!shifts) return null;

  return (
    <div>
      <table className={styles.table}>
        <TableHead/>
        <TableBody shifts={shifts.data} setShiftId={onClickHandler}/>
      </table>
      <InfoShiftsModal
        onClose={() => setModalOpen(false)}
        title={activeShift?.shift_name || ''}
        isOpen={modalOpen && Boolean(activeShift)}
        shiftId={activeShift?.id}
      />
    </div>
  );
};
