import {useState} from 'react';
import {
  MAX_STATUS_NAME_LENGTH,
  FormErrors,
  EditStatusFormData,
} from '../utils';
import {ETranslations} from 'types/translates';
import type {EditStatusRequest, Locale, Status, StatusId} from 'types/status';
import {extraStatusColors} from 'constants/colors';
import type {IntlFormatters} from 'react-intl';

export const useEditStatusForm = (
  statuses: Status[],
  currentStatus: Status,
  onValidSubmit: (payload: EditStatusRequest) => void,
  formatMessage: IntlFormatters['formatMessage'],
  locale: Locale
) => {
  const [errors, setErrors] = useState<FormErrors>(null);
  const setStatusNameError = (message: string) =>
    setErrors((prev) => ({
      ...prev,
      'status-name': message,
    }));
  const setStatusColorError = (message: string) =>
    setErrors((prev) => ({
      ...prev,
      color: message,
    }));

  const handleStatusNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value.length >= MAX_STATUS_NAME_LENGTH
      && setStatusNameError(
        `${formatMessage({
          id: ETranslations.ERROR_CHARACTER_LIMIT_REACHED,
        })} ${MAX_STATUS_NAME_LENGTH}`
      );

    errors?.['status-name']
      && e.currentTarget.value.length < MAX_STATUS_NAME_LENGTH
      && setErrors((prev) => ({...prev, 'status-name': undefined}));
  };

  const validation = (
    statusName: string,
    statusColor: string,
    id: StatusId
  ) => {
    let isValid = true;
    if (
      statuses.find((status) => status.name === statusName && status.id !== id)
    ) {
      setStatusNameError(
        formatMessage({
          id: ETranslations.ERROR_STATUS_NAME_IN_USE,
        })
      );
      isValid = false;
    }
    if (statusName.length > MAX_STATUS_NAME_LENGTH) {
      setStatusNameError(
        `${formatMessage({
          id: ETranslations.ERROR_CHARACTER_LIMIT_REACHED,
        })} ${MAX_STATUS_NAME_LENGTH}`
      );
      isValid = false;
    }
    if (!extraStatusColors.find(({color}) => color === statusColor)) {
      setStatusColorError(
        formatMessage({
          id: ETranslations.ERROR_INCORRECT_COLOR,
        })
      );
      isValid = false;
    }

    return isValid;
  };

  const handleEditStatusSubmit: React.FormEventHandler<HTMLFormElement> = (
    e
  ) => {
    e.preventDefault();
    const formData: EditStatusFormData = new FormData(e.currentTarget);
    const statusName = String(formData.get('status-name'));
    const statusColor = String(formData.get('color'));
    const isValid = validation(statusName, statusColor, currentStatus.id);
    if (!isValid) return;

    const payload: EditStatusRequest = {
      status_id: currentStatus.id,
      is_active: currentStatus.is_active,
      localization: {
        locale,
        name: statusName,
        color: statusColor,
      },
    };

    onValidSubmit(payload);
  };

  return {errors, handleStatusNameChange, handleEditStatusSubmit};
};
