import {useEffect, useMemo, useRef} from 'react';
import {useLocation} from 'react-router';
import queryString from 'querystring';

/**
 * Location query hook
 * @param param name, what we looking in query string
 * @param callback action, what we do if param found
 * @param release action, what happens when component close or did mount
 */
export const useQueryParam = (param: string, callback?: (value: any) => void, release?: () => any) => {
  const location = useLocation();
  const ref = useRef<string | undefined>(undefined);
  const queryParam = useMemo(() => {
    // parse function
    const params = queryString.parse(location.search.replace('?', ''));
    return params[param];
  }, [location, param]);

  useEffect(() => {
    if (queryParam) {
      const value = queryParam.toString();
      if (ref.current !== value) {
        callback?.(value);
        ref.current = value;
      }
    }
    return release;
  }, [queryParam]);
  return queryParam;
};
