import {useEffect, useMemo, useRef} from "react";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import {useMount} from "react-use";
import {useField} from "react-final-form";
import {Option} from "../../../../models/common";
import {useHWLContext} from "common/components/HorizontalWindowLayout/hwl-context";
import {timelineSelectors, useTimelineActions} from "features/Timeline";
import {useShiftHoursAndMinutes, useShiftTimesOptions} from "features/api/shifts";


const useChoiseTimeInputNew = (allowValueFromOutsideRange: boolean,
    isFromManagement: boolean, disabled: boolean) => {

    const {input} = useField<Option<string> | null>("time", {allowNull: true});
    const {
        input: {value: dateValue},
    } = useField<string | null>("date", {allowNull: true});
    const {value, onChange} = input;
    const {options, isFetching} = useShiftTimesOptions(allowValueFromOutsideRange);
    const startHour = useSelector(timelineSelectors.startHour);
    const timeStampValue = useSelector(timelineSelectors.getTimestamp);
    const {setIsActualTime, setTimestamp} = useTimelineActions();
    const allShifts = useShiftHoursAndMinutes();
    const initTimeRef = useRef(null);
    const {findByWindowName} = useHWLContext() || {};

    const {
        input: {value: initialVisitTime},
    } = useField<number>("initialVisitTime", {allowNull: true});

    const handleChange = (e: any) => {
        onChange(e);
        setTimestamp(e.value);
    };

    // Хук для обратной совместимости
    // Изначально с бека приходит время в формате HH:mm строкой
    // В доступных опциях по шифту приходит абсолютное время.
    // При первом рендере меняем строку на таймстамп.
    useEffect(() => {
        if (typeof value?.value === "string" && dateValue) {
            const date = dayjs(dateValue);
            const time = dayjs(value.value, "HH:mm");
            const hour = time.hour();
            const minute = time.minute();
            const timestamp = date.set("hour", hour).set("minute", minute).valueOf();
            onChange({...value, value: timestamp});
        }
    }, [value]);

    useMount(() => {
        try {
            const window = findByWindowName("reg");
            if (!window.active) return;
        } catch {
            //
        }
    });


    useEffect(() => {
        if (value?.value) {
            setIsActualTime(false);
        }
    }, [value?.value, startHour]);

    useEffect(() => {
        if (!value && isFromManagement) {
            const label = dayjs(timeStampValue).format("HH:mm");
            //@ts-ignore
            initTimeRef.current = {label, value: timeStampValue};
            onChange({label, value: timeStampValue});
            return;
        }
        if (disabled || allowValueFromOutsideRange || isFetching) return;
        const hasValue = options.some((o) => Number(o.value) === Number(value?.value));
        if (!value?.value || !hasValue) {
            const nextValue = options[0];
            onChange(nextValue || null);
        }
    }, [options, disabled, allowValueFromOutsideRange, isFetching]);

    const startAndEnd = useMemo(() => {
        //@ts-ignore
        const startTime = dayjs(initTimeRef?.current?.value);
        const endTime = startTime.clone().add(initialVisitTime, 'minutes');

        return {
            endTime: endTime.valueOf(), startTime: startTime.valueOf()
        }
    }, [initTimeRef?.current, initialVisitTime])

    return {
        allShifts,
        handleChange,
        startAndEnd,
        dateValue,
        value
    }
}

export default useChoiseTimeInputNew