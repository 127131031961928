import React from 'react';
import cn from 'classnames';
import './phone-modal.scss';
import {ICONS} from '../../../common/helpers';

export const PhoneModal = (props: any) => {
  const {visibility, title, closeModal} = props;
  return (
    <div id="phone-modal" style={{display: visibility ? 'block' : 'none'}}>
      <div className={cn('modal-header')}>
        <h2>{title}</h2>
        <ICONS.Cross onClick={closeModal} />
      </div>
      <div className={cn('content')}>
        {props.children}
      </div>
    </div>
  );
};
