import { ICONS } from 'common/helpers';
import { Menu } from 'components/Menu/Menu';
import {
  useApplyExtraStatusMutation,
  useCleanExtraStatusMutation,
} from 'features/api/bookings-api';
import { Booking } from 'types/booking';

import styles from './StatusActions.module.scss';
import { useIntlUtils } from '../../hooks/useIntlUtils';
import { ETranslations } from '../../types/translates';
import type { ExtraStatus } from 'types/status';
import cn from 'classnames';
import { Suspense } from 'react';
import { Spinner } from 'ui-kit';

export const StatusActions = ({
  back,
  close,
  booking,
  statuses,
}: {
  back: () => void;
  close?: () => void;
  booking: Booking;
  statuses: ExtraStatus[];
}) => {
  const { intl } = useIntlUtils();
  const [applyExtraStatus, { isLoading: isApplying }]
    = useApplyExtraStatusMutation();
  const [cleanExtraStatus, { isLoading: isCleaning }]
    = useCleanExtraStatusMutation();
  const isLoading = isApplying || isCleaning;

  return (
    <Suspense fallback={<Spinner />}>
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <button onClick={back} className={styles.header}>
          <ICONS.PrevIcon /> {intl.formatMessage({ id: ETranslations.BACK })}
        </button>
        <Menu className={styles.menu}>
          {statuses?.map((status) => (
            <Menu.Item
              className={cn(styles.item, {
                [styles.active]: booking.extraStatus?.id === status.id,
                [styles.disabled]: isLoading,
              })}
              key={status.id}
              onClick={() =>
                (booking.extraStatus?.id === status.id
                  ? cleanExtraStatus(booking.bookingId)
                  : applyExtraStatus({
                      bookingId: booking.bookingId,
                      statusId: status.id,
                    })
                ).then(close)
              }
            >
              <div
                className={styles.circle}
                style={{ backgroundColor: status.color }}
              />
              <span className={styles.statusName}>{status.name}</span>
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </Suspense>
  );
};
