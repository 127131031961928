import React, { useState } from 'react';
import styles from './GuestNotes.module.scss';
import {
  useFetchClientNotesQuery,
  useUpdateNoteMutation,
} from 'features/api/notes-api';
import { useSelector } from 'react-redux';
import { appContextSelectors } from 'features/AppContex';
import { BottomBar, Button, Spinner } from 'ui-kit';
import { useIntlUtils } from 'hooks/useIntlUtils';
import { NoteModalSettings, defaultNoteModalSettings } from './utils';
import { useAppUsers } from 'contexts/AppUsersContext';
import { ETranslations } from 'types/translates';
import { NoteModal } from './NoteModal';
import { NoteList } from './NoteList';

export const GuestNotes = ({ client_id }: { client_id: number }) => {
  const { restaurant_id } = useSelector(appContextSelectors.restaurant);
  const { currentUser } = useAppUsers();
  const { data, isLoading } = useFetchClientNotesQuery({
    client_id,
    restaurant_id,
  });
  const { createNote, editNote } = useUpdateNoteMutation();
  const {
    intl,
    getIntlCreatingOf,
    getIntlEntityCreation,
    getIntlEntityEdition,
  } = useIntlUtils();
  const [modalSettings, setModalSettings] = useState<NoteModalSettings>(
    () => defaultNoteModalSettings
  );

  if (!data) return <Spinner />;

  const renderModal = () => {
    switch (modalSettings.mode) {
      case 'create':
        return (
          <NoteModal
            key={modalSettings.mode}
            title={getIntlEntityCreation(ETranslations.A_NOTE)}
            close={() => setModalSettings(defaultNoteModalSettings)}
            isOpen={Boolean(modalSettings.mode)}
            submit={(note: string) =>
              createNote({
                client_id,
                restaurant_id,
                note,
              })
            }
            submitButtonText={intl.formatMessage({
              id: ETranslations.BASE_CREATE,
            })}
            formatMessage={intl.formatMessage}
            disabled={isLoading}
          />
        );
      case 'edit':
        return (
          <NoteModal
            key={modalSettings.mode}
            title={getIntlEntityEdition(ETranslations.A_NOTE)}
            close={() => setModalSettings(defaultNoteModalSettings)}
            isOpen={Boolean(modalSettings.mode)}
            submit={(note: string) =>
              editNote({
                client_id,
                restaurant_id,
                id: modalSettings.noteId,
                note,
              })
            }
            submitButtonText={intl.formatMessage({
              id: ETranslations.BASE_SAVE,
            })}
            defaultNoteText={modalSettings.note}
            formatMessage={intl.formatMessage}
            disabled={isLoading}
          />
        );
      case 'delete':
        return (
          <NoteModal
            key={modalSettings.mode}
            title={getIntlEntityEdition(ETranslations.A_NOTE)}
            close={() => setModalSettings(defaultNoteModalSettings)}
            isOpen={Boolean(modalSettings.mode)}
            submit={() =>
              editNote({
                client_id,
                restaurant_id,
                id: modalSettings.noteId,
                note: 'test—delete',
              })
            }
            submitButtonText={intl.formatMessage({
              id: ETranslations.BASE_SAVE,
            })}
            formatMessage={intl.formatMessage}
            disabled={isLoading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {data.length ? (
        <NoteList
          notes={data}
          userId={currentUser?.id}
          onEdit={(noteId: number, note: string) =>
            setModalSettings({ mode: 'edit', noteId, note })
          }
          onDelete={(noteId: number) =>
            setModalSettings({ mode: 'delete', noteId, note: 'test—delete' })
          }
          formatMessage={intl.formatMessage}
        >
          <BottomBar>
            <BottomBar.Part placement="left">
              <Button
                variant="primary"
                onClick={() => setModalSettings({ mode: 'create' })}
              >
                {getIntlCreatingOf(ETranslations.NOTE_ALT)}
              </Button>
            </BottomBar.Part>
          </BottomBar>
        </NoteList>
      ) : (
        <div className={styles.emptyNotes}>
          {intl.formatMessage({ id: ETranslations.EMPTY_NOTES })}
          <Button
            onClick={() => setModalSettings({ mode: 'create' })}
            variant="primary"
          >
            {intl.formatMessage({ id: ETranslations.BASE_CREATE })}
          </Button>
        </div>
      )}
      {renderModal()}
    </>
  );
};
