import React, {FC} from "react";
import DayPicker, {ReactDatePickerProps, registerLocale} from "react-datepicker";
import en_EN from "date-fns/locale/en-GB";
import ru_RU from "date-fns/locale/ru";
import cn from "classnames";

import {DatePickerInput} from "./DatePickerInput";
import {datePickerHeader} from "./DatePickerHeader";
import styles from "./DatePicker.module.scss";
import "./customStyles.scss";
import {useLocalStorage} from "react-use";
import {ELocales} from "../../types/commons";
import {config} from "../../config";

registerLocale("ru_RU", ru_RU);
registerLocale("en_EN", en_EN);

const DATE_PICKER_LOCALES = {
  en_EN,
  ru_RU,
};

interface Props extends ReactDatePickerProps {
  minYear?: number;
  customInputFormatDate?: string;
  customInputClassName?: string;
  invalid?: boolean;
}

export const DatePicker: FC<Props> = ({
  onChange,
  selected,
  minYear = 2020,
  customInputFormatDate = "DD.MM.YYYY",
  className,
  disabled,
  customInputClassName,
  invalid = false,
  ...rest
}) => {
  const [userLocale] = useLocalStorage<ELocales>("locale", config.defaultLocale);
  return (
    <div className={className}>
      <DayPicker
        dateFormat="dd.MM.yyyy"
        selected={selected}
        onChange={onChange}
        customInput={
          <DatePickerInput
            format={customInputFormatDate}
            customInputClassName={cn(customInputClassName, cn({[styles.invalid]: invalid}))}
            disabled={disabled}
            placeholderText={rest.placeholderText}
          />
        }
        locale={DATE_PICKER_LOCALES[userLocale || "en_EN"]}
        renderCustomHeader={(headerProps) => datePickerHeader({...headerProps, locale: userLocale}, minYear)}
        className={cn(styles.datePicker)}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
};
