import React from "react";
import {FieldRenderProps} from "react-final-form";
import {Input} from "ui-kit";

export const InputField: React.FC<FieldRenderProps<string, any>> = ({input, meta, ...rest}) => {
    const {onChange, value} = input
    return (
        <Input
            value={value}
            maxLength={50}
            onChange={onChange}
            {...rest}
            {...meta}
        />
    );
};
