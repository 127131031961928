import {UserTags as Tags} from 'components/UserTags';
import {useCallback} from 'react';

interface UserTagsProps{
  value: string;
  onChange(value: string): void;
}

export function UserTags({value, onChange}: UserTagsProps) {
  const handleChange = useCallback((nextTags: number[]) => {
    onChange(nextTags.join(','));
   }, [onChange]);

  return <Tags value={value.split(',').map((id) => Number(id)).filter((id) => !Number.isNaN(id))} onChange={handleChange} />;
}
