import React from 'react';
import cn from 'classnames';
import { Spinner, Card, Input } from 'ui-kit';
import BookingFormReduxProxy from 'components/registration/forms/BookingFormReduxProxy';
import styles from './TableBookingList.module.scss';
import { BookingCardDetailList } from './BookingCardDetailList';
import { Booking } from 'types/booking';
import { BookingCard } from 'components/BookingCard';
import { ConfirmOverbookingModal } from 'components/modals/ConfirmOverbookingModal';
import { CloseBookingContext } from 'contexts/CloseBookingContext';
import { TableActions } from './TableActions';
import ManageralModalError from 'components/ManagerialTables/form/ManageralModalError';
import useEditBooking from './hooks/useEditBooking';
import useTableBookingList from './hooks/useTableBookingList';

interface EditBookingProps {
  bookingId: number;
  hideCard?: boolean;
}

export const EditBooking = ({ bookingId, hideCard }: EditBookingProps) => {
  const {
    booking,
    update,
    overbookingData,
    clearData,
    updateWithOverbooking,
    hasManageralError,
    closeModal,
    isUpdating,
    isDashboard
  } = useEditBooking(bookingId);

  return (
    <div className={styles.edit}>
      {!hideCard && (
        <div className={cn(styles.sectionContent, styles.editCard)}>
          {booking ? (
            <BookingCard
              booking={booking}
              compact
              className={styles.bookingCard}
              isDashboard={isDashboard}
            />
          ) : (
            <Spinner />
          )}
        </div>
      )}
      {booking && (
        <BookingFormReduxProxy
          booking={booking}
          isCreating={false}
          isUpdate={true}
          submitFn={update}
          isAnonym={booking?.contact?.contact_type === 'CONTACT'}
        />
      )}
      <ConfirmOverbookingModal
        isOpen={!!overbookingData}
        onDecline={clearData}
        onConfirm={updateWithOverbooking}
        disabled={isUpdating}
      />
      {hasManageralError && (
        <ManageralModalError isOpen={hasManageralError} onClose={closeModal} />
      )}
    </div>
  );
};

export const TableBookingList: React.FC = () => {
  const {
    closeWindowHandler,
    handleClose,
    tableData,
    searchValue,
    setSearchValue,
    isEdit,
    intlTitles,
    isLoading,
    booking,
    bookingList,
    isRegisterDisabled,
  } = useTableBookingList();

  return (
    <CloseBookingContext.Provider value={closeWindowHandler}>
      <Card id="table-bookings" onClose={handleClose}>
        <Card.Header title={`Брони стола №${tableData?.number || ''}`}>
          <div className="right-block">
            <Input.Search
              value={searchValue}
              onSearch={(search) => setSearchValue({ search })}
            />
          </div>
        </Card.Header>
        {isEdit ? (
          <Card.Content>
            <Card.Header title={intlTitles.bookingEdit} />
            {isLoading && <Spinner />}
            <EditBooking bookingId={(booking as Booking).bookingId} />
          </Card.Content>
        ) : (
          <Card.Content className={styles.listContainer} noPadding>
            <div className={styles.list}>
              {isLoading && <Spinner />}
              <BookingCardDetailList data={bookingList} />
            </div>
            {tableData && (
              <TableActions
                isRegisterDisabled={isRegisterDisabled}
                table={tableData}
              />
            )}
          </Card.Content>
        )}
      </Card>
    </CloseBookingContext.Provider>
  );
};
