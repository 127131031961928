import {initialState, State, tableBookingListSliceName} from './slice';
import {createSelector} from '@reduxjs/toolkit';

const selectorDomain = (state: any) => state[tableBookingListSliceName] || initialState;
export const tableBookingSearchSelector = createSelector(
  selectorDomain,
  ({search}: State) => search,
);
export const tableBookingsTableSelector = createSelector(
  selectorDomain,
  ({table}: State) => table,
);
