import React, {FunctionComponent} from "react";
import {useFetchBookingHistoryDetailsChronologicalChangesQuery} from "features/api/bookings-api";
import {Spinner} from "ui-kit";
import moment from "moment";
import HistoryPropertySwitcher from "./HistoryPropertySwitcher";
import {UserLabel} from "./UserLabel";
import {useIntlUtils} from "../../hooks/useIntlUtils";
import {ETranslations} from "../../types/translates";
import {capitalizeString} from '../../utils';

interface Props {
  bookingId: number
}

const BookingHistoryModalChanges: FunctionComponent<Props> = ({bookingId}) => {
  const {getIntlHistoryOf,intl} = useIntlUtils()
  const {data} = useFetchBookingHistoryDetailsChronologicalChangesQuery(bookingId);
  if (!data) return <Spinner />;

  return (
    <div className="bookingHistoryChangesBody">
      <div className="bookingHistorySectionHeader">
          <div className="bookingHistoryValue bookingHistoryTitle">{intl.formatMessage({id: ETranslations.BASE_DATE})}</div>
          <div className="bookingHistoryValue">{capitalizeString(intl.formatMessage({id: ETranslations.PLURAL_PARAMETERS_NOM}))}</div>
          <div className="bookingHistoryValue">{getIntlHistoryOf(ETranslations.PLURAL_CHANGES)}</div>
      </div>
      {data.map(({params, timeKey}) => params.map((param) => (
        <div className="bookingHistoryChangesSection" key={timeKey.toString() + param.name}>
          <div className="bookingHistoryValue bookingHistoryTitle">
            {moment(timeKey).format('DD.MM.YYYY HH:mm')}
            <UserLabel userId={param.userId} />
          </div>
          <HistoryPropertySwitcher param={param} />
        </div>
      )))}
    </div>
  );
};

export default BookingHistoryModalChanges;
