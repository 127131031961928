export enum ETranslations {
  AUTH_SYSTEM_NAME = 'AUTH_SYSTEM_NAME',
  AUTH_SYSTEM_ENTRY = 'AUTH_SYSTEM_ENTRY',
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_PASSWORD = 'AUTH_PASSWORD',
  AUTH_SING_IN = 'AUTH_SING_IN',
  AUTH_INCORRECT_CREDENTIALS = 'AUTH_INCORRECT_CREDENTIALS',
  AUTH_SUPPORT_SERVICE = 'AUTH_SUPPORT_SERVICE',
  BACK = 'BACK',
  BASE_YES = 'BASE_YES',
  BASE_NO = 'BASE_NO',
  BASE_COLLAPSE = 'BASE_COLLAPSE',
  BASE_NO_VALUES = 'BASE_NO_VALUES',
  BASE_BOOK = 'BASE_BOOK',
  ACCESS_RULES = 'ACCESS_RULES',
  BASE_BOOKINGS = 'BASE_BOOKINGS',
  BASE_ACTIVE = 'BASE_ACTIVE',
  BASE_DATE = 'BASE_DATE',
  BASE_TIME = 'BASE_TIME',
  BASE_ERROR = 'BASE_ERROR',
  DAYS_TO_REPEAT_A_BOOKING = 'DAYS_TO_REPEAT_A_BOOKING',
  EDIT_GROUP = 'EDIT_GROUP',
  CREATING_MANAGERAL_TABLE = 'CREATING_MANAGERAL_TABLE',
  DELETE_MANAGERAL_TABLE_TILE = 'DELETE_MANAGERAL_TABLE_TILE',
  DELETE_MANAGERAL_TABLE_TEXT = 'DELETE_MANAGERAL_TABLE_TEXT',
  DELETE_AR_QUESTION = 'DELETE_AR_QUESTION',
  DELETE_WARNING_TEXT = 'DELETE_WARNING_TEXT',
  DELETE_WARNING_TEXTS = 'DELETE_WARNING_TEXTS',
  WARNING = 'WARNING',
  WARNING_TAG = 'WARNING_TAG',
  ASK_SAVE_CHANGES = 'ASK_SAVE_CHANGES',
  IMMEDIATELY_CHANGES = 'IMMEDIATELY_CHANGES',
  CANCEL_MANAGAREL_RESERVATION = 'CANCEL_MANAGAREL_RESERVATION',
  BASE_HISTORY = 'BASE_HISTORY',
  BASE_CHOOSE = 'BASE_CHOOSE',
  BASE_CONFIRM = 'BASE_CONFIRM',
  BASE_EDIT = 'BASE_EDIT',
  BASE_CHANGE = 'BASE_CHANGE',
  BASE_RESEAT = 'BASE_RESEAT',
  BASE_REFRESH = 'BASE_REFRESH',
  BASE_MOVE = 'BASE_MOVE',
  BASE_RESTORE = 'BASE_RESTORE',
  BASE_CANCEL = 'BASE_CANCEL',
  START_TIME = 'START_TIME',
  END_TIME = 'END_TIME',
  BASE_DELETE = 'BASE_DELETE',
  BASE_REPEAT = 'BASE_REPEAT',
  BASE_CLOSE = 'BASE_CLOSE',
  BASE_RESET = 'BASE_RESET',
  BASE_PRINT = 'BASE_PRINT',
  BASE_NOW = 'BASE_NOW',
  BASE_SUM = 'BASE_SUM',
  BASE_NUMBER = 'BASE_NUMBER',
  CREATE_GROUP_RULE = 'CREATE_GROUP_RULE',
  COLOR = 'COLOR',
  SETTINGS = 'SETTINGS',
  CREATE_RULE = 'CREATE_RULE',
  CREATING_ACCESS_RULES_GROUP = 'CREATING_ACCESS_RULES_GROUP',
  BASE_DEACTIVATE = 'BASE_DEACTIVATE',
  CREATE_ACCESS_RULES_GROUP = 'CREATE_ACCESS_RULES_GROUP',
  NAME_GROUP_OF_RULES = 'NAME_GROUP_OF_RULES',
  BASE_TOTAL = 'BASE_TOTAL',
  BASE_NOT_SPECIFIED = 'BASE_NOT_SPECIFIED',
  BASE_NOT_INDICATED = 'BASE_NOT_INDICATED',
  BASE_CHOSEN_DATE = 'BASE_CHOSEN_DATE',
  BASE_CHOOSE_ENTITY = 'BASE_CHOOSE_ENTITY',
  BASE_SHOW_ENTITY = 'BASE_SHOW_ENTITY',
  BASE_SELECT_ENTITY = 'BASE_SELECT_ENTITY',
  BASE_ADD_ENTITY = 'BASE_ADD_ENTITY',
  BASE_ENTER_ENTITY = 'BASE_ENTER_ENTITY',
  BASE_ADD = 'BASE_ADD',
  BASE_ALL_ENTITIES = 'BASE_ALL_ENTITIES',
  BASE_ENTER_VALUE = 'BASE_ENTER_VALUE',
  BASE_CREATE = 'BASE_CREATE',
  BASE_ENTITY_CREATION = 'BASE_ENTITY_CREATION',
  BASE_ENTITY_UPDATING = 'BASE_ENTITY_UPDATING',
  BASE_ENTITY_CHANGING = 'BASE_ENTITY_CHANGING',
  MANAGEMENT_RESERVATION = 'MANAGEMENT_RESERVATION',
  MANAGEMENT_ERROR_TITLE = 'MANAGEMENT_ERROR_TITLE',
  MANAGEMENT_ERROR_TEXT = 'MANAGEMENT_ERROR_TEXT',
  BASE_SAVE = 'BASE_SAVE',
  BASE_SEARCH = 'BASE_SEARCH',
  BASE_ALL = 'BASE_ALL',

  PLURAL_FILTER = 'PLURAL_FILTER',
  PLURAL_FILTERS = 'PLURAL_FILTERS',
  PLURAL_FILTERS_ALT = 'PLURAL_FILTERS_ALT',
  PLURAL_FILTERS_NOM = 'PLURAL_FILTERS_NOM',

  PLURAL_CHANGE = 'PLURAL_CHANGE',
  PLURAL_CHANGES = 'PLURAL_CHANGES',
  PLURAL_CHANGES_NOM = 'PLURAL_CHANGES_NOM',

  PLURAL_HALL = 'PLURAL_HALL',
  PLURAL_HALLS = 'PLURAL_HALLS',
  PLURAL_HALLS_ALT = 'PLURAL_HALLS_ALT',
  PLURAL_HALLS_NOM = 'PLURAL_HALLS_NOM',

  PLURAL_PERIOD = 'PLURAL_PERIOD',
  PLURAL_PERIODS = 'PLURAL_PERIODS',
  PLURAL_PERIODS_ALT = 'PLURAL_PERIODS_ALT',
  PLURAL_PERIODS_NOM = 'PLURAL_PERIODS_NOM',

  PLURAL_ELEMENT = 'PLURAL_ELEMENT',
  PLURAL_ELEMENTS = 'PLURAL_ELEMENTS',
  PLURAL_ELEMENTS_ALT = 'PLURAL_ELEMENTS_ALT',
  PLURAL_ELEMENTS_NOM = 'PLURAL_ELEMENTS_NOM',

  PLURAL_STATUS = 'PLURAL_STATUS',
  PLURAL_STATUSES = 'PLURAL_STATUSES',
  PLURAL_STATUSES_ALT = 'PLURAL_STATUSES_ALT',
  PLURAL_STATUSES_NOM = 'PLURAL_STATUSES_NOM',
  ASSIGN_STATUS = 'ASSIGN_STATUS',

  PLURAL_PARAMETER = 'PLURAL_PARAMETER',
  PLURAL_PARAMETERS = 'PLURAL_PARAMETERS',
  PLURAL_PARAMETERS_ALT = 'PLURAL_PARAMETERS_ALT',
  PLURAL_PARAMETERS_NOM = 'PLURAL_PARAMETERS_NOM',

  PLURAL_TABLE = 'PLURAL_TABLE',
  PLURAL_TABLES = 'PLURAL_TABLES',
  PLURAL_TABLES_ALT = 'PLURAL_TABLES_ALT',
  PLURAL_TABLES_NOM = 'PLURAL_TABLES_NOM',

  PLURAL_VISIT = 'PLURAL_VISIT',
  PLURAL_VISITS = 'PLURAL_VISITS',
  PLURAL_VISITS_ALT = 'PLURAL_VISITS_ALT',
  PLURAL_VISITS_NOM = 'PLURAL_VISITS_NOM',

  PLURAL_TAG = 'PLURAL_TAG',
  PLURAL_TAGS = 'PLURAL_TAGS',
  PLURAL_TAGS_ALT = 'PLURAL_TAGS_ALT',
  PLURAL_TAGS_NOM = 'PLURAL_TAGS_NOM',

  PLURAL_GUEST = 'PLURAL_GUEST',
  PLURAL_GUESTS = 'PLURAL_GUESTS',
  PLURAL_GUESTS_ALT = 'PLURAL_GUESTS_ALT',
  PLURAL_GUESTS_NOM = 'PLURAL_GUESTS_NOM',
  PLURAL_GUESTS_THEY = 'PLURAL_GUESTS_THEY',

  PLURAL_CANCELLATION = 'PLURAL_CANCELLATION',
  PLURAL_CANCELLATIONS = 'PLURAL_CANCELLATIONS',
  PLURAL_CANCELLATION_NOM = 'PLURAL_CANCELLATION_NOM',

  PLURAL_ACTION = 'PLURAL_ACTION',
  PLURAL_ACTIONS = 'PLURAL_ACTIONS',
  PLURAL_ACTIONS_NOM = 'PLURAL_ACTIONS_NOM',

  PLURAL_MINUTE = 'PLURAL_MINUTE',
  PLURAL_MINUTES = 'PLURAL_MINUTES',
  PLURAL_MINUTES_NOM = 'PLURAL_MINUTES_NOM',

  PLURAL_HOUR = 'PLURAL_HOUR',
  PLURAL_HOURS = 'PLURAL_HOURS',
  PLURAL_HOURS_ALT = 'PLURAL_HOURS_ALT',
  PLURAL_HOURS_NOM = 'PLURAL_HOURS_NOM',

  PLURAL_RESTAURANT = 'PLURAL_RESTAURANT',
  PLURAL_RESTAURANTS = 'PLURAL_RESTAURANTS',
  PLURAL_RESTAURANTS_ALT = 'PLURAL_RESTAURANTS_ALT',
  PLURAL_RESTAURANTS_NOM = 'PLURAL_RESTAURANTS_NOM',

  PLURAL_VALUE = 'PLURAL_VALUE',
  PLURAL_VALUES = 'PLURAL_VALUES',
  PLURAL_VALUES_NOM = 'PLURAL_VALUES_NOM',

  PLURAL_BOOKING = 'PLURAL_BOOKING',
  PLURAL_BOOKINGS = 'PLURAL_BOOKINGS',
  PLURAL_BOOKINGS_NOM = 'PLURAL_BOOKINGS_NOM',

  PLURAL_ALT_BOOKING = 'PLURAL_ALT_BOOKING',
  PLURAL_ALT_BOOKINGS = 'PLURAL_ALT_BOOKINGS',
  PLURAL_ALT_BOOKINGS_NOM = 'PLURAL_ALT_BOOKINGS_NOM',

  PLURAL_REQUEST = 'PLURAL_REQUEST',
  PLURAL_REQUESTS = 'PLURAL_REQUESTS',
  PLURAL_REQUESTS_NOM = 'PLURAL_REQUESTS_NOM',
  PLURAL_REQUESTS_THEY = 'PLURAL_REQUESTS_THEY',

  PLURAL_PERSON = 'PLURAL_PERSON',
  PLURAL_PERSONS = 'PLURAL_PERSONS',
  PLURAL_PERSONS_NOM = 'PLURAL_PERSONS_NOM',

  PLURAL_MOVING = 'PLURAL_MOVING',
  PLURAL_MOVES = 'PLURAL_MOVES',
  PLURAL_MOVES_ALT = 'PLURAL_MOVES_ALT',
  PLURAL_MOVES_NOM = 'PLURAL_MOVES_NOM',

  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  GLOBAL_SEARCH_PLACEHOLDER = 'GLOBAL_SEARCH_PLACEHOLDER',
  BOOKING_GLOBAL_SEARCH = 'BOOKING_GLOBAL_SEARCH',
  WAITING_LIST = 'WAITING_LIST',
  CANCELLATION_NOTIFICATION = 'CANCELLATION_NOTIFICATION',
  DID_NOT_COME = 'DID_NOT_COME',
  OUTSTAY = 'OUTSTAY',
  LATE = 'LATE',
  BOOKING_OVERLAP = 'BOOKING_OVERLAP',
  BOOKING_CROSSING = 'BOOKING_CROSSING',
  BOOKING_SUPERIMPOSING = 'BOOKING_SUPERIMPOSING',
  OF_BIRTH = 'OF_BIRTH',
  OF_REGISTRATION = 'OF_REGISTRATION',
  OF_START = 'OF_START',
  OF_END = 'OF_END',
  BOOKING_STATUS_CLOSED = 'BOOKING_STATUS_CLOSED',
  BOOKING_STATUS_CANCELED = 'BOOKING_STATUS_CANCELED',
  BOOKING_STATUS_DID_NOT_COME = 'BOOKING_STATUS_DID_NOT_COME',
  BOOKING_STATUS_IN_HALL = 'BOOKING_STATUS_IN_HALL',
  BOOKING_STATUS_NEW = 'BOOKING_STATUS_NEW',
  BOOKING_STATUS_WAIT_LIST = 'BOOKING_STATUS_WAIT_LIST',
  VISIT_IN_YEAR = 'VISIT_IN_YEAR',
  COMMENT = 'COMMENT',
  GUEST_NOTE = 'GUEST_NOTE',
  DURATION = 'DURATION',
  SITTING_AREA = 'SITTING_AREA',
  RESERVATION_NOTE = 'RESERVATION_NOTE',
  RESERVATION_TAGS = 'RESERVATION_TAGS',
  BOOKED_BY = 'BOOKED_BY',
  DETAILS = 'DETAILS',
  HISTORY_LOG_CREATE = 'HISTORY_LOG_CREATE',
  HISTORY_LOG_MODIFY = 'HISTORY_LOG_MODIFY',
  HISTORY_LOG_CHANGE_STATUS = 'HISTORY_LOG_CHANGE_STATUS',
  HISTORY_LOG_MOVING_PLACE = 'HISTORY_LOG_MOVING_PLACE',
  HISTORY_LOG_RESTORE = 'HISTORY_LOG_RESTORE',
  HISTORY_LOG_OVERBOOKING = 'HISTORY_LOG_OVERBOOKING',
  TABLE_BOOKINGS = 'TABLE_BOOKINGS',
  GUEST_LIST = 'GUEST_LIST',
  PHONE = 'PHONE',
  WORK_PHONE = 'WORK_PHONE',
  OF_PHONE = 'OF_PHONE',
  ADDITIONAL_PHONE = 'ADDITIONAL_PHONE',
  EMAIL = 'EMAIL',
  ALT_EMAIL = 'ALT_EMAIL',
  OF_CREATION = 'OF_CREATION',
  OF_LOYALTY = 'OF_LOYALTY',
  OF_USER = 'OF_USER',
  OF_REQUEST = 'OF_REQUEST',
  OF_BOOKING = 'OF_BOOKING',
  WITHOUT_SEAT = 'WITHOUT_SEAT',
  ENTITY_ON_PAGE = 'ENTITY_ON_PAGE',
  HOSTESS = 'HOSTESS',
  CURRENT = 'CURRENT',
  CURRENT_UNIT = 'CURRENT_UNIT',
  NEW_UNIT = 'NEW_UNIT',
  PREVIOUS = 'PREVIOUS',
  CREATED = 'CREATED',
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_AMOUNT = 'DEPOSIT_AMOUNT',
  WITH_DEPOSIT = 'WITH_DEPOSIT',
  NO_DEPOSIT = 'NO_DEPOSIT',
  NO_LIMIT = 'NO_LIMIT',
  NO_ACTIVE_CALLS = 'NO_ACTIVE_CALLS',
  DEPOSIT_MADE = 'DEPOSIT_MADE',
  NAME = 'NAME',
  LAST_NAME = 'LAST_NAME',
  FIRST_NAME = 'FIRST_NAME',
  MIDDLE_NAME = 'MIDDLE_NAME',
  SOURCE = 'SOURCE',
  SOURCES = 'SOURCES',
  OVER_BOOKING = 'OVER_BOOKING',
  BOOKING_LIST_EMPTY = 'BOOKING_LIST_EMPTY',
  DETAILED_INFO = 'DETAILED_INFO',
  FOR_GUEST = 'FOR_GUEST',
  FOR_BOOKING = 'FOR_BOOKING',
  BASE_OPEN = 'BASE_OPEN',
  FAILED = 'FAILED',
  SEATS_SWAP = 'SEATS_SWAP',
  ENTER_NAME = 'ENTER_NAME',
  CURRENT_BOOKING = 'CURRENT_BOOKING',
  EACH_ACCESS_DESC = 'EACH_ACCESS_DESC',
  NAME_RULE = 'NAME_RULE',
  INFINITY = 'INFINITY',
  SHIFT = 'SHIFT',
  AVAILABLE_FOR_HALL = 'AVAILABLE_FOR_HALL',
  CHOOSE_SHIFT = 'CHOOSE_SHIFT',
  UPCOMING_BOOKINGS = 'UPCOMING_BOOKINGS',
  PLACING_BY_FACT = 'PLACING_BY_FACT',
  HALL_SCHEME = 'HALL_SCHEME',
  BOOKING_REPORT = 'BOOKING_REPORT',
  SEATING_REPORT = 'SEATING_REPORT',
  UPLOAD_TO_FORMAT = 'UPLOAD_TO_FORMAT',
  ENTITY_DELETING = 'ENTITY_DELETING',
  ENTITY_CREATING = 'ENTITY_CREATING',
  ENTITY_EDITING = 'ENTITY_EDITING',
  ENTITY_ADDING = 'ENTITY_ADDING',
  ENTITY_CONFIRMING = 'ENTITY_CONFIRMING',
  CONFIRMED = 'CONFIRMED',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  NOT_FOUND = 'NOT_FOUND',
  BOOKED = 'BOOKED',
  BOOKED_PLURAL = 'BOOKED_PLURAL',
  IN_THE_HALL = 'IN_THE_HALL',
  FREE = 'FREE',
  TIMELINE = 'TIMELINE',
  TIME_SLOTS = 'TIME_SLOTS',
  TIME_SLOT = 'TIME_SLOT',
  OPTIONS = 'OPTIONS',
  ONLY_CONFIRMED = 'ONLY_CONFIRMED',
  STAY_TIME_EXCLUDE = 'STAY_TIME_EXCLUDE',
  IN_FACT_EXCLUDE = 'IN_FACT_EXCLUDE',
  FORMAT = 'FORMAT',
  UPLOAD = 'UPLOAD',
  PRINT = 'PRINT',
  PAGE = 'PAGE',
  PROLONG = 'PROLONG',
  CAME = 'CAME',
  QUANTITY = 'QUANTITY',
  AUTO_CHOICE = 'AUTO_CHOICE',
  TEXT = 'TEXT',
  YESTERDAY = 'YESTERDAY',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  IN_FACT = 'IN_FACT',
  HOSTESS_ADD = 'HOSTESS_ADD',
  CREATE_HALL = 'CREATE_HALL',
  UPDATE_HALL = 'UPDATE_HALL',
  HOSTESS_INFO_MISSING = 'HOSTESS_INFO_MISSING',
  INFO_MISSING = 'INFO_MISSING',
  UNKNOWN_GUEST = 'UNKNOWN_GUEST',
  GO_TO_USER_PROFILE = 'GO_TO_USER_PROFILE',
  ACTIVE_CALLS = 'ACTIVE_CALLS',
  AVAILABLE_TAGS = 'AVAILABLE_TAGS',
  INFO_VIEWING_ACTION = 'INFO_VIEWING_ACTION',
  INFO_VIEWING_ACTION_GUEST = 'INFO_VIEWING_ACTION_GUEST',
  UNPROCESSED = 'UNPROCESSED',
  BOOKING_MANAGEMENT = 'BOOKING_MANAGEMENT',
  MANAGEMENT_BOOKING = 'MANAGEMENT_BOOKING',
  MANAGERAL_TABLES = 'MANAGERAL_TABLES',
  GUEST_UPDATED = 'GUEST_UPDATED',
  STARTING_NEW_RESTAURANT = 'STARTING_NEW_RESTAURANT',
  RESTAURANT_NAME = 'RESTAURANT_NAME',
  FULL_NAME = 'FULL_NAME',
  ROLE = 'ROLE',
  REMAINING = 'REMAINING',
  BOOKING_SOON = 'BOOKING_SOON',
  DOUBLE_BOOKING = 'DOUBLE_BOOKING',
  BOOKING_CREATION_DATE = 'BOOKING_CREATION_DATE',

  DATE_OF = 'DATE_OF',
  DATE_OF_STRICT = 'DATE_OF_STRICT',
  TIME_OF = 'TIME_OF',
  COUNT_OF = 'COUNT_OF',
  REASON_OF = 'REASON_OF',
  HISTORY_OF = 'HISTORY_OF',
  HISTORY_OF_EN = 'HISTORY_OF_EN',
  STATUS_OF = 'STATUS_OF',
  CARD_OF = 'CARD_OF',
  PARAMETERS_OF = 'PARAMETERS_OF',
  FULL_NAME_OF = 'FULL_NAME_OF',
  MOVING_OF = 'MOVING_OF',
  NUMBER_OF = 'NUMBER_OF',
  NUMBERS_OF = 'NUMBERS_OF',
  LEVEL_OF = 'LEVEL_OF',
  INFO_MISSING_OF = 'INFO_MISSING_OF',
  IN_THE_NAME = 'IN_THE_NAME',
  REGISTRATION_OF = 'REGISTRATION_OF',
  LIST_OF = 'LIST_OF',
  OR = 'OR',
  AND = 'AND',
  WITHOUT = 'WITHOUT',
  FROM = 'FROM',
  TO = 'TO',
  IN = 'IN',
  BY = 'BY',

  SEARCH_HEADER_OPTION_NAME_PHONE = 'SEARCH_HEADER_OPTION_NAME_PHONE',
  SEARCH_HEADER_OPTION_GUEST_TAG = 'SEARCH_HEADER_OPTION_GUEST_TAG',
  SEARCH_HEADER_OPTION_GUEST_EMAIL = 'SEARCH_HEADER_OPTION_GUEST_EMAIL',
  SEARCH_HEADER_OPTION_BOOKING_COMMENT = 'SEARCH_HEADER_OPTION_BOOKING_COMMENT',
  SEARCH_HEADER_OPTION_BOOKING_TAG = 'SEARCH_HEADER_OPTION_BOOKING_TAG',
  SEARCH_HEADER_OPTION_BOOKING_SOURCE = 'SEARCH_HEADER_OPTION_BOOKING_SOURCE',
  SEARCH_HEADER_OPTION_HOSTESS_FULL_NAME = 'SEARCH_HEADER_OPTION_HOSTESS_FULL_NAME',
  SETTINGS_TITLE_ROLES = 'SETTINGS_TITLE_ROLES',
  SETTINGS_TITLE_HOSTESS = 'SETTINGS_TITLE_HOSTESS',
  SETTINGS_TITLE_BOOKING_HISTORY = 'SETTINGS_TITLE_BOOKING_HISTORY',
  SETTINGS_TITLE_SHIFTS = 'SETTINGS_TITLE_SHIFTS',
  SETTINGS_TITLE_RESTAURANT_MANAGEMENT = 'SETTINGS_TITLE_RESTAURANT_MANAGEMENT',
  SETTINGS_ROLE_SYSTEM_ADMIN = 'SETTINGS_ROLE_SYSTEM_ADMIN',
  SETTINGS_ROLE_RESTAURANT_ADMIN = 'SETTINGS_ROLE_RESTAURANT_ADMIN',
  SETTINGS_ROLE_HOSTESS = 'SETTINGS_ROLE_HOSTESS',
  SETTINGS_ROLE_CALL_CENTER = 'SETTINGS_ROLE_CALL_CENTER',
  SETTINGS_ROLE_RESTAURANTS = 'SETTINGS_ROLE_RESTAURANTS',
  SETTINGS_ROLE_SYSTEM_ADMIN_DECL = 'SETTINGS_ROLE_SYSTEM_ADMIN_DECL',
  SETTINGS_ROLE_RESTAURANT_ADMIN_DECL = 'SETTINGS_ROLE_RESTAURANT_ADMIN_DECL',
  SETTINGS_ROLE_HOSTESS_DECL = 'SETTINGS_ROLE_HOSTESS_DECL',
  SHOW_ALL = 'SHOW_ALL',
  SETTINGS_ROLE_CALL_CENTER_DECL = 'SETTINGS_ROLE_CALL_CENTER_DECL',
  SETTINGS_ROLE_RESTAURANTS_DECL = 'SETTINGS_ROLE_RESTAURANTS_DECL',
  STATUSES = 'STATUSES',

  SIDEBAR_DASHBOARD = 'SIDEBAR_DASHBOARD',
  SIDEBAR_GRID = 'SIDEBAR_GRID',
  SIDEBAR_BOARDING_SCHEDULE = 'SIDEBAR_BOARDING_SCHEDULE',
  SIDEBAR_HANDLING = 'SIDEBAR_HANDLING',

  ERROR_START_DATE = 'ERROR_START_DATE',
  ERROR_END_DATE = 'ERROR_END_DATE',
  ERROR_START_TIME = 'ERROR_START_TIME',
  ERROR_END_TIME = 'ERROR_END_TIME',
  ERROR_REQUIRED_FIELD = 'ERROR_REQUIRED_FIELD',
  ERROR_PASSWORDS_SHOULD_BE_EQUAL = 'ERROR_PASSWORDS_SHOULD_BE_EQUAL',
  ERROR_NOT_SELECTED = 'ERROR_NOT_SELECTED',
  ERROR_DATE_NOT_SPECIFIED = 'ERROR_DATE_NOT_SPECIFIED',
  ERROR_CLIENT_MUST_BE_VALID = 'ERROR_CLIENT_MUST_BE_VALID',
  ERROR_BOOKING_CREATION_NOT_POSSIBLE = 'ERROR_BOOKING_CREATION_NOT_POSSIBLE',
  ERROR_SOMETHING_WENT_WRONG = 'ERROR_SOMETHING_WENT_WRONG',
  ERROR_GUEST_UPDATING = 'ERROR_GUEST_UPDATING',
  ERROR_USER_ALREADY_EXIST = 'ERROR_USER_ALREADY_EXIST',
  ERROR_HOSTESS_FULL_NAME_MUST_BE_EXIST = 'ERROR_HOSTESS_FULL_NAME_MUST_BE_EXIST',
  ERROR_HOSTESS_MIDDLE_NAME = 'ERROR_HOSTESS_MIDDLE_NAME',
  ERROR_GUEST_CREATION = 'ERROR_GUEST_CREATION',
  ERROR_UNABLE_TO_CHANGE_BOOKING_STATUS = 'ERROR_UNABLE_TO_CHANGE_BOOKING_STATUS',
  ERROR_UNABLE_TO_CANCEL_BOOKING = 'ERROR_UNABLE_TO_CANCEL_BOOKING',
  ERROR_UNABLE_TO_SAVE_HALL_SCHEME = 'ERROR_UNABLE_TO_SAVE_HALL_SCHEME',
  ERROR_UNABLE_TO_EDIT_STATUS = 'ERROR_UNABLE_TO_EDIT_STATUS',
  ERROR_CHARACTER_LIMIT_REACHED = 'ERROR_CHARACTER_LIMIT_REACHED',
  ERROR_STATUS_NAME_IN_USE = 'ERROR_STATUS_NAME_IN_USE',
  ERROR_INCORRECT_COLOR = 'ERROR_INCORRECT_COLOR',

  CONFIRM_USER_DELETING = 'CONFIRM_USER_DELETING',
  TABLE_ALREADY_BUSY = 'TABLE_ALREADY_BUSY',
  TABLE_ALREADY_BUSY_HINT = 'TABLE_ALREADY_BUSY_HINT',
  ALL_RESTAURANTS = 'ALL_RESTAURANTS',
  UNKNOWN_RESTAURANT = 'UNKNOWN_RESTAURANT',
  USER_CARD_UPDATING = 'USER_CARD_UPDATING',
  GUEST_CARD_UPDATING = 'GUEST_CARD_UPDATING',
  APP_VERSION_UPDATED = 'APP_VERSION_UPDATED',
  PREV_BOOKING_STATUS_RESTORED = 'PREV_BOOKING_STATUS_RESTORED',
  BOOKING_UPDATE_SUCCESSFULLY = 'BOOKING_UPDATE_SUCCESSFULLY',
  UNABLE_TO_CREATE_BOOKING = 'UNABLE_TO_CREATE_BOOKING',
  UNABLE_TO_CREATE_BOOKING_COVERAGE = 'UNABLE_TO_CREATE_BOOKING_COVERAGE',
  UNABLE_TO_RESTORE_BOOKING_STATUS = 'UNABLE_TO_RESTORE_BOOKING_STATUS',
  UNABLE_TO_UPDATE_BOOKING = 'UNABLE_TO_UPDATE_BOOKING',
  SEARCH_REQUEST_FAILED = 'SEARCH_REQUEST_FAILED',
  BOOKING_STATUS_NOT_COME = 'BOOKING_STATUS_NOT_COME',
  BOOKING_STATUS_CONFIRMED = 'BOOKING_STATUS_CONFIRMED',
  TIME_INTERVAL = 'TIME_INTERVAL',
  INTERVAL = 'INTERVAL',
  BOOKING_STATUS_EXTERNAL = 'BOOKING_STATUS_EXTERNAL',
  BOOKING_STATUS_UNDEFINED = 'BOOKING_STATUS_UNDEFINED',
  USER_SUCCESSFULLY_DELETED = 'USER_SUCCESSFULLY_DELETED',
  LOCATE = 'LOCATE',
  PROPERTIES = 'PROPERTIES',
  DAYS = 'DAYS',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  SEATING_AREAS = 'SEATING_AREAS',
  SEATING_AREA = 'SEATING_AREA',
  SHIFT_STATUS_ACTIVE = 'SHIFT_STATUS_ACTIVE',
  SHIFT_STATUS_INACTIVE = 'SHIFT_STATUS_INACTIVE',
  SHIFT_NAME = 'SHIFT_NAME',
  SHIFTS_NAME = 'SHIFTS_NAME',
  CONTINUE_INDEFINITE = 'CONTINUE_INDEFINITE',
  DAYS_TO_CONTINUE_THIS_SHIFT = 'DAYS_TO_CONTINUE_THIS_SHIFT',
  OPEN_TIME = 'OPEN_TIME',
  CLOSE_TIME = 'CLOSE_TIME',
  SEATING_AREAS_AVAILABLE = 'SEATING_AREAS_AVAILABLE',
  SAVE = 'SAVE',
  CANCEL = 'CANCEL',
  SET_MAX_COVERED = 'SET_MAX_COVERED',
  MAX_COVERED = 'MAX_COVERED',
  DURATION_DESCRIPTION = 'DURATION_DESCRIPTION',
  DAY = 'DAY',
  SHIFT_TIME = 'SHIFT_TIME',
  CAPACITY = 'CAPACITY',
  NEXT_WEEK = 'NEXT_WEEK',
  THIS_WEEK = 'THIS_WEEK',
  SALUTATION = 'SALUTATION',
  VIP = 'VIP',
  GENDER = 'GENDER',
  CANCELS = 'CANCELS',
  PERSONAL_INFO = 'PERSONAL_INFO',
  CONTACT = 'CONTACT',
  SPECIAL = 'SPECIAL',
  ABOUT = 'ABOUT',
  CREATE_BOOKING = 'CREATE_BOOKING',
  LAST_VISIT = 'LAST_VISIT',
  CREATED_DATE = 'CREATED_DATE',
  SHIFT_DATES = 'SHIFT_DATES',
  CREATE_SHIFT = 'CREATE_SHIFT',
  TABLE_PLACE = 'TABLE_PLACE',
  GUEST_CARD = 'GUEST_CARD',
  FILE_SIZE_ERROR = 'FILE_SIZE_ERROR',
  GRID_RESOURCES_HEADER_TITLE = 'GRID_RESOURCES_HEADER_TITLE',
  GUEST_AVG_PER_VISIT = 'GUEST_AVG_PER_VISIT',
  GUEST_TOTAL_SPEND = 'GUEST_TOTAL_SPEND',
  HALL_NAME = 'HALL_NAME',
  HALL_WEIGHT = 'HALL_WEIGHT',
  PROFILE = 'PROFILE',
  EDIT_HALL_NAME_AND_NUMBER = 'EDIT_HALL_NAME_AND_NUMBER',
  EDIT_HALL_SCHEME = 'EDIT_HALL_SCHEME',
  REQUEST_GRID_NO_TABLES = 'REQUEST_GRID_NO_TABLES',
  HALL_EDITOR_SAVE_SCHEME = 'HALL_EDITOR_SAVE_SCHEME',
  HALL_EDITOR_HAS_UNSAVED = 'HALL_EDITOR_HAS_UNSAVED',
  LOCK_TABLE = 'LOCK_TABLE',
  MIN_TO_LEFT = 'MIN_TO_LEFT',
  WITH_PHONE = 'WITH_PHONE',
  WITHOUT_PHONE = 'WITHOUT_PHONE',
  GROUP_TYPE = 'GROUP_TYPE',
  LOCAL = 'LOCAL',
  GLOBAL = 'GLOBAL',

  NAME_GROUP = 'NAME_GROUP',
  GROUP_COLOR = 'GROUP_COLOR',
  BASE_INFO = 'BASE_INFO',
  ADD_TAG = 'ADD_TAG',
  DELETE_AR = 'DELETE_AR',
  GROUP_PlURAL = 'GROUP_PlURAL',
  TAGS = 'TAGS',
  PLURAL_CLIENT = 'PLURAL_CLIENT',
  CREATE_GROUP = 'CREATE_GROUP',
  GROUP_CREATING = 'GROUP_CREATING',
  HALLS = 'HALLS',
  PERIOD_TIME = 'PERIOD_TIME',
  DELETE_AR_GROUP_QUESTION = 'DELETE_AR_GROUP_QUESTION',
  END_DATE_ONE = 'END_DATE_ONE',
  APPLY = 'APPLY',
  STATES = 'STATES',
  TITLE = 'TITLE',
  NOTES = 'NOTES',
  NOTE = 'NOTE',
  NOTE_ALT = 'NOTE_ALT',
  A_NOTE = 'A_NOTE',
  EMPTY_NOTES = 'EMPTY_NOTES',
  MORE = 'MORE',
  LESS = 'LESS',
  DELETE_TABLE_DESCRIPTION = 'DELETE_TABLE_DESCRIPTION',
  PLURAL_STATE = 'PLURAL_STATE',
  PLURAL_STATES = 'PLURAL_STATES',
  PLURAL_STATES_ALT = 'PLURAL_STATES_ALT',
  PLURAL_STATES_NOM = 'PLURAL_STATES_NOM',
  VIEW_BOOKINGS = 'VIEW_BOOKINGS',
  ERROR_FETCHING_BOOKINGS = 'ERROR_FETCHING_BOOKINGS',
  ERROR_DUPLICATE_TABLE_NUMBERS = 'ERROR_DUPLICATE_TABLE_NUMBERS',
}
