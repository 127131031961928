import React from "react";
import {BaseShiftsModal} from "../BaseShiftsModal";
import styles from '../style.module.scss'
import AccessRuleForm from "components/shifts/Form/AccessRuleForm/AccessRuleForm";
import {Modal} from "components/modal";

interface AccessRulesModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  access_rule?: any;
  isCreating?: boolean;
  isEditMode?: boolean;
  setIsEditMode?: any;
}

export const AccessRulesModal = ({
  isOpen,
  onClose,
  title,
  access_rule,
  isCreating = false,
  isEditMode,
  setIsEditMode,
}: AccessRulesModalProps) => {
  return (
    <BaseShiftsModal onClose={onClose} title={title} isOpen={isOpen}>
      <Modal.Content className={styles.modalContentAccess}>
        <AccessRuleForm
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          onClose={onClose}
          isCreating={isCreating}
          access_rule={access_rule}
        />
      </Modal.Content>
    </BaseShiftsModal>
  );
};
