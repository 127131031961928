import {useMemo, forwardRef, Ref} from 'react';
import moment from 'moment';
import {ReactDatePickerProps} from 'react-datepicker';
import cn from 'classnames';

import {Calendar} from '../ICONS/icons';
import {Input} from '../Input/Input';
import styles from './DatePicker.module.scss';
import {useIntl} from "react-intl";
import {ETranslations} from "../../types/translates";

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  onClick?(): void;
  onChange?(): void;
  format?: string,
  customInputClassName?: string
}

export const DatePickerInput = forwardRef(
  ({value, onClick, placeholderText, format, disabled, customInputClassName}: Props, ref: Ref<HTMLInputElement>) => {
  const intl = useIntl();
  const transformDate = useMemo(() => {
    return value ? moment(value, 'DD.MM.YYYY').format(format) : '';
  },[value])

  return(
    <>
      <Input
        ref={ref}
        inputClassName={cn(styles.input, customInputClassName)}
        readOnly
        value={transformDate}
        suffix={<Calendar className={styles.inputIcon}/>}
        onClick={onClick}
        placeholder={placeholderText || intl.formatMessage({id: ETranslations.BASE_DATE})}
        disabled={disabled}
      />
    </>
  )
}
);
