import { ICONS } from 'common/helpers';
import { Card } from 'ui-kit';
import { useIntlUtils } from '../../../hooks/useIntlUtils';
import { ETranslations } from '../../../types/translates';

import { useEditStatusMutation } from 'features/api/dictionaries-api';
import type { Status, StatusId } from 'types/status';
import { getColor } from './utils';
import { Palette } from './edit-status-form/palette';
import { extraStatusColors } from 'constants/colors';
import { ModalControls } from './ModalControls';
import { EditStatusForm } from './edit-status-form/edit-status-form';
import { transformLocale } from 'utils';

export const EditStatus = ({
  closeModal,
  intlUtils,
  statuses,
  statusId,
  editStatus,
  isLoading,
}: {
  closeModal: (isForceUpdate?: boolean) => void;
  intlUtils: ReturnType<typeof useIntlUtils>;
  statuses: Status[];
  statusId: StatusId;
  editStatus: ReturnType<typeof useEditStatusMutation>[0];
  isLoading: boolean;
}) => {
  const currentStatus = statuses.find(
    (status) => status.id === statusId && status.is_extra
  );

  if (!currentStatus) return null;

  const currentColor = getColor(currentStatus) || extraStatusColors[0];

  const {
    intl: { formatMessage, locale },
  } = intlUtils;

  const intlTitles = {
    statusLabel: intlUtils.getIntlStatusAlt(ETranslations.TITLE),
    statusEdit: intlUtils.getIntlStatusAlt(
      ETranslations[locale === 'en' ? 'BASE_EDIT' : 'PLURAL_CHANGE']
    ),
    statusColor: intlUtils.getIntlStatusAlt(ETranslations.COLOR),
  };

  const formName = 'status-edit';
  return (
    <div
      className="changeStatus"
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Card.Header
        title={<span className="modalTitle">{intlTitles.statusEdit}</span>}
        controls={
          <button onClick={() => closeModal()}>
            <img src={ICONS.crossGrey} alt="" />
          </button>
        }
      />
      <EditStatusForm
        formName={formName}
        statuses={statuses}
        currentStatus={currentStatus}
        onValidSubmit={(payload) =>
          editStatus(payload).then((data) => 'data' in data && closeModal())
        }
        labelClassName={undefined}
        label={intlTitles.statusLabel}
        formatMessage={formatMessage}
        locale={transformLocale(locale)}
      >
        <Palette
          title={intlTitles.statusColor}
          currentColor={currentColor}
          formatMessage={formatMessage}
        />
      </EditStatusForm>
      <ModalControls
        className="modalControls"
        formName={formName}
        disabled={isLoading}
        submitButtonText={formatMessage({
          id: ETranslations.SAVE,
        })}
        cancelButtonText={formatMessage({
          id: ETranslations.CANCEL,
        })}
        onCancel={() => closeModal()}
      />
    </div>
  );
};
