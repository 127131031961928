import {has, uniqueId} from 'lodash';
import React, {useState} from 'react'
import {useField} from 'react-final-form';
import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";
import Chips from 'ui-kit/Chips/Chips'
import {Button} from 'ui-kit';
import {useIntl} from 'react-intl';
import {ETranslations} from 'types/translates';

const TagsContainer = () => {
    const {
        input: {onChange: changeTags, value: tags},
    } = useField<string[]>("tags", {allowNull: true});

    const {
        input: {value: color},
    } = useField<string>("color", {allowNull: true});
    const intl = useIntl();
    const [hasError, setHasError] = useState<boolean>(false);
    const onToggleError = () => setHasError((state) => !state);

    return (
        <>
            {tags.map((each: string, index: number) => {
                const handleDeleteEachChips = (id: number, e: React.MouseEvent<SVGSVGElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (tags.length > 1) {
                        const filteredTags = tags.filter((_, idx) => (idx !== id))
                        changeTags([...filteredTags]);
                    } else {
                        onToggleError();
                    }
                }
                return <Chips
                    deleteCallBack={handleDeleteEachChips}
                    index={index}
                    textColor='#313131'
                    type="DELETABLE"
                    value={each}
                    key={uniqueId()}
                    background={color} />
            })}

            {
                hasError && <ModalPopup
                    needIcon={true}
                    editBooking={true}
                    isOpen={hasError}
                    onClose={onToggleError}
                    buttons={
                        <>
                            <Button variant="secondary" type="submit" onClick={onToggleError}>
                                {intl.formatMessage({id: ETranslations.BASE_CLOSE})}
                            </Button>
                        </>
                    }
                    title={intl.formatMessage({id: ETranslations.WARNING})}
                    text={intl.formatMessage({id: ETranslations.WARNING_TAG})}
                />
            }
        </>
    )
}

export default TagsContainer