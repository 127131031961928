import {Modal} from 'components/modal';
import {Button, ICONS} from 'ui-kit';
import styles from './style.module.scss';
import {useIntl} from "react-intl";
import {ETranslations} from "../../../types/translates";
import {capitalizeString} from "../../../utils";
import {useMemo} from "react";

interface ConfirmOverbookingModalProps {
  onConfirm(): void;
  onDecline(): void;
  isOpen?: boolean;
  disabled?: boolean;
}

export function ConfirmOverbookingModal({isOpen = false, onConfirm, onDecline, disabled}: ConfirmOverbookingModalProps) {
  const intl = useIntl();

  const bookingCrossingTitle = useMemo(() => intl.formatMessage({id: ETranslations.BOOKING_CROSSING}), [])

  return (
    <Modal title={bookingCrossingTitle} isOpen={isOpen} onClose={onDecline} className={styles.modal}>
      <Modal.Content className={styles.content}>
        <ICONS.Info className={styles.icon} width={21} height={21} />
        <div className={styles.infoBlock}>
          <h5>{bookingCrossingTitle}</h5>
          <span>{intl.formatMessage({id: ETranslations.BOOKING_SUPERIMPOSING})}</span>
          <div className={styles.buttonBlock}>
            <Button
              variant="dangerous"
              type="button"
              onClick={onConfirm} disabled={disabled}
            >
              {intl.formatMessage({id: ETranslations.BASE_CONFIRM})}
            </Button>
            <Button
              variant="secondary"
              type="button"
              className={styles.secondary}
              onClick={onDecline}
              disabled={disabled}
            >
              {capitalizeString(intl.formatMessage({id: ETranslations.BASE_CANCEL}))}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}
