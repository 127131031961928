import React, {useEffect, useMemo} from "react";
import {useSelector} from "react-redux";
import {FieldRenderProps, useField} from "react-final-form";
import {appContextSelectors} from "features/AppContex";
import {useFetchShiftsListQuery} from "features/api/shifts";
import {SelectBasic} from "ui-kit";
import {Option} from "common/components/MultiSelect/multi-select";
import {useIntl} from "react-intl";
import {ETranslations} from "types/translates";

const ShiftsField: React.FC<FieldRenderProps<string, any>> = ({input}) => {
    const intl = useIntl();

    const {
        input: {value, onChange},
    } = useField<Option<any> | null>(input.name, {allowNull: true});
    const restaurant = useSelector(appContextSelectors.restaurant);
    const {data: shifts} = useFetchShiftsListQuery(restaurant.restaurant_id);
    const options = useMemo(() => {
        if (!shifts?.data.length) {
            return [{label: "", value: ""}]
        }

        return shifts.data.map(each => ({label: each.shift_name, value: each.id}))
    }, [shifts?.data])

    useEffect(() => {
        if (value && typeof value === 'number') {
            //@ts-ignore
            const selected = options.filter((option: Option<any>) => {
                return option.value === value;
            })[0];
            if (selected?.value) {
                onChange(selected);
            }
        }
    }, [value, options]);


    return <SelectBasic
        value={value}
        placeholder={intl.formatMessage({id: ETranslations.CHOOSE_SHIFT})}
        changedMaxSize
        options={options}
        isValid={!!value}
        onChange={onChange}
    />
};

export default ShiftsField;
