import {Table} from "./common";
import {Client} from "./client.model";
import type { Status } from "types/status";
// todo: удалить модель целиком
export type Loyalty = 'none' | 'low' | 'medium' | 'max';

export class BookingResponse {
  constructor(
    public id: number,
    public date: string,
    public client: Client,
    public place_id: number,
    public user_id: number,
    public tags: number[],
    public visit_time: number,
    public persons: number,
    public deposit_status: boolean,
    public deposit_sum: number,
    public status: Status,
    public deposit_status_reserv: boolean,
    public tables: Table[],
    public comment: string,
    public time_key: string,
    public source_id: number,
    public restaurant_id: number,
  ) { }
}

/**
 * @deprecated старого типа объект
 */
export class Booking {
  constructor(
    public id: number,
    public date: string,
    public client: Client,
    public place_id: number,
    public user_id: number,
    public tags: number[],
    public visit_time: number,
    public persons: number,
    public deposit_status: boolean,
    public deposit_sum: number,
    public status: Status,
    public deposit_status_reserv: boolean,
    public tables: Table[],
    public comment: string,
    public time: string,
    public bookStatus: 'not-booked' | 'booked' | 'booked-paid' | 'booked-not-paid' | 'not-booked-not-paid' | 'not-booked-paid' | string,
    public time_key: string,
    public source_id?: number,
  ) { }
}

export class BookingForTable {
  constructor(
    public hour: number,
    public min: number,
    public visit_time: number,
    public status: Status,
    public id?: number
  ) { }
}

export class Tag {
  constructor(
    public label: string,
    public value: number,
    public color: 'orange' | 'blue' | 'green' | 'violet' | string,
  ) { }
}

export class TagServer {
  constructor(
    public tag_id: number,
    public description: string,
    public color: string
  ) { }
}

export class Source {
  constructor(
    public label: string,
    public value: number,
    public id: number,
    public source_name: string,
  ) { }
}

export class SourceServer {
  constructor(
    public id: number,
    public source_name: string,
  ) { }
}

export enum TimeStatus {
  'farFarAway' = 'farFarAway',
  'inHall' = 'inHall',
  'upcoming' = 'upcoming',
  'expired' = 'expired',
  'notCome' = 'notCome'
}
export interface BookingInfoFilter {
  place_id: number[];
  date: string;
  time?: string;
  visit_time: number;
  guests: number;
  table_type?: number;
}

export interface BookingTimeHostess {
  time_key: string;
  hostess_id: number;
}

export interface BookingInfoItems {
  items: BookingInfoItem;
}

export interface BookingInfoItem {
  [key: number]: BookingAvailableInfo;
}

export class BookingAvailableInfo {
  constructor(
    public date: string,
    public time: string[],
    public visit_time: number[],
    public guests: number[],
    public table_type: number[],
    public table_num: Table[],
    public visit_option_by_time: number[],
    public time_options_by_visit: string[],
  ) { }
}

export interface HistoryBooking {
  booking_id: number;
  date: string;
  user_name: string;
  action: string;
  params: {field: string; new: any; old: any}[];
}

export interface SwapTarget {
  booking_id: number;
}
