import {Role} from 'services/auth.service';
import {AppointUser} from './user.model';
import type {Status, StatusId} from 'types/status';
import type {useEditStatusMutation} from 'features/api/dictionaries-api';

export enum SettingsModes {
  appointRoles = 'appointRoles',
  appointHostess = 'appointHostess',
  sources = 'sources',
  newRest = 'newRest',
  changeBook = 'changeBook',
  shifts = 'shifts',
  restManagement = 'restManagement',
  access_rules = 'access_rules',
  tags = 'new-tags',
  statuses = 'statuses',
}

export class OptionSettingsMode {
  constructor(
    public value: number,
    public mode: SettingsModes,
    public label: string,
    public permissions: Role[]
  ) {}
}

export class OptionAppointRole {
  constructor(
    public id: number,
    public label: string,
    public role: Role,
    public declension: string,
    public data: AppointUser[]
  ) {}
}

export interface SettingsHostess {
  hostess?: AppointUser;
  restId?: number;
}

export interface SettingsUser {
  user?: AppointUser;
  role?: Role;
}

export interface SettingsStatus {
  allStatuses: Status[];
  statusId: StatusId;
  editStatus: ReturnType<typeof useEditStatusMutation>[0];
  isEditing: boolean;
}
