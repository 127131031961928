import React from "react";
import styles from '../style.module.scss'
import {BaseShiftsModal} from "../BaseShiftsModal";
import CurrentRuleForm from "components/shifts/Form/AccessRuleForm/CurrentRuleForm";
import {Modal} from "components/modal";

interface AccessRulesModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  currentRule:any;
}

export const CurrentRuleModal = ({
  isOpen,
  onClose,
  title,
  currentRule,
}: AccessRulesModalProps) => {
  return (
    <BaseShiftsModal onClose={onClose} title={title} isOpen={isOpen}>
      <Modal.Content className={styles.modalContentAccess}>
        <CurrentRuleForm
          onClose={onClose}
          currentRule={currentRule}
        />
      </Modal.Content>
    </BaseShiftsModal>
  );
};
