import { apiINT } from './api-data';
import { AuthService } from './auth.service';
import { RequestWrapper } from './request-wrapper';
import { AppointUser, User } from 'models/user.model';
import { store } from 'storage';
import { userApi } from 'features/api/user-api';
import type { Response as TResponse } from 'models/common';
import { Notification } from './notification';

export class UsersService {
  static async getHostess(idRest?: number): Promise<TResponse<User[]>> {
    return RequestWrapper.fetch(
      `${apiINT}/users?type=HOSTESS${
        idRest ? `&restaurant_ids=${idRest}&auth=false` : ''
      }`,
      { headers: { Authorization: AuthService.getToken() } }
    ).then((response) => response.json());
  }

  static async getById(id: number): Promise<TResponse<User>> {
    return RequestWrapper.fetch(`${apiINT}/users/${id}`, {
      headers: { Authorization: AuthService.getToken() },
    }).then((response) => response.json());
  }

  static async getAllRests(restIds: string): Promise<TResponse<AppointUser[]>> {
    return RequestWrapper.fetch(
      `${apiINT}/users?type=HOSTESS&restaurant_ids=${restIds}`,
      { headers: { Authorization: AuthService.getToken() } }
    ).then((response) => response.json());
  }

  static async change(user: AppointUser): Promise<TResponse<any>> {
    return RequestWrapper.fetch(`${apiINT}/user`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(user),
    }).then((response) => {
      store.dispatch(userApi.util.invalidateTags(['Users']));
      return response.json();
    });
  }

  /**
   * @deprecated нужно переписать на RTK-Query
   */
  static async create(user: AppointUser): Promise<TResponse<any>> {
    return RequestWrapper.fetch(`${apiINT}/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: AuthService.getToken(),
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        store.dispatch(userApi.util.invalidateTags(['Users']));
        return response.json();
      })
      .catch((e: unknown) => {
        if (e instanceof Response) {
          e.json().then((error) =>
            Notification.error({
              title: error.errorMessage,
            })
          );
        }
      });
  }
}
