import React from "react";
import {OrderControls} from "./FormControls/OrderControls";
import cn from "classnames";
import {BottomBar, Labeled} from "ui-kit";
import {requiredField} from '../validators/validators';
import {Field} from "react-final-form";
import {ChoiceDateInput, ChoiceTimeInput} from "../sub-renders/date-time";
import {ChoiceClientPhoneInputRedux} from "../sub-renders/phone";
import {ChoiceClientInfoRedux} from "../sub-renders/client";
import {ChoiceVisitTimeButtonsInput, ChoiceVisitTimeControls} from "../sub-renders/visit-time";
import {
  ChoiceAutoTableInputRedux,
  ChoicePersonsInput,
  ChoiceTablesInputRedux,
} from "../sub-renders/location";
import {ChoiceNotesInput} from "../sub-renders/note";
import {ChoiceTagsInput} from "../sub-renders/tags";
import {ChoiceSourceInput} from "../sub-renders/source";
import {ChoiceDepositInput} from "../sub-renders/deposit";
import {ChoiceHostessInput} from "../sub-renders/hostess";
import {Edit} from "./FormControls/Edit";
import {BookingOrder} from "../../../types/booking";
import {PlaceSelect} from "components/PlaceSelect";
import {ETranslations} from "../../../types/translates";
import styles from "./Form.module.scss";
import {capitalizeString} from '../../../utils';
import {DataChips} from "../sub-renders/data-chips";
import {ChoiceTimeInputNew} from "../sub-renders/date-time-new";
import useCreateFromOrder from "./hooks/useCreateFromOrder";

export const CreateFromOrder: React.FC<any> = ({values, handleSubmit, valid, rest}) => {

  const {
    isNewDesign,
    getIntlJoinedParts,
    disable,
    intl,
    getIntlNumberOf,
    isRussianLocale,
    intlTitles,
    getIntlBaseErrorNotSelected,
    sources,
    userList,
    order,
  } = useCreateFromOrder();



  return (
    <>
      <form className={cn(styles.form, "custom-scroll")}>
        <div className={styles.formElement}>
          <div className={styles.eachElementGridEdit}>
            <div className={cn(isNewDesign ? styles.dateTimeNewEdit : styles.dateTimeEdit)}>
              <div className={styles.datePicker}>
                <Labeled
                  label={getIntlJoinedParts([ETranslations.BASE_DATE, ETranslations.AND, ETranslations.BASE_TIME])}
                  required
                  className=""
                  as="div"
                >
                  <Field
                    name="date"
                    component={ChoiceDateInput}
                    validate={(value) => requiredField(value, "Дата не указана")}
                    disabled={disable}
                  />
                </Labeled>

              </div>
              <Field
                name="date"
                isCreateBooking={false}
                component={DataChips}
                disabled={disable}
                validate={(value) => requiredField(value, intl.formatMessage({id: ETranslations.ERROR_DATE_NOT_SPECIFIED}))}
              />
              {!isNewDesign && <ChoiceTimeInput
                disabled={disable}
                allowValueFromOutsideRange
              />}
            </div>

            {isNewDesign && <div
              className={cn(styles.fullRow, styles.twoCol, styles.gap12, {
              })}
            >
              <ChoiceTimeInputNew
                allowValueFromOutsideRange
                disabled={disable}
                isEditMode={!disable}
              />
            </div>}
          </div>
        </div>

        <div className={styles.formElement}>
          <div className={styles.eachElementGridEdit}>
            <Field
              name="phone"
              component={ChoiceClientPhoneInputRedux}
              autoFocus
              isNewDesign
              required
              label={intl.formatMessage({id: ETranslations.PHONE})}
              className={cn("phone book add required", {hasValue: true})}
              disabled={disable}
              validate={(value) => !value}
            />
            <div className={styles.twoElems}>
              <Field
                name="client"
                component={ChoiceClientInfoRedux}
                isNewDesign
                showMenu={false}
                disable
                isCreateBooking
              />
            </div>
            <div className={cn(styles.twoElems, styles.gap16)}>
              <Field
                name="persons"
                component={ChoicePersonsInput}
                label={capitalizeString(intl.formatMessage({id: ETranslations.PLURAL_GUESTS_NOM}))}
                validate={(value) => requiredField(value, intl.formatMessage({id: ETranslations.ERROR_REQUIRED_FIELD}))}
                required
                className={cn("persons", {hasValue: !requiredField(values.persons)})}
                disabled={disable}
              />

              <div>
                <Labeled
                  className={cn(styles.visit, styles.bookingLabel)}
                  label={intl.formatMessage({id: ETranslations.DURATION})}
                  as="div"
                >
                  <div className={styles.visitTimeNewDesign}>
                    <Field name="visit_time" disabled={disable} newDesign component={ChoiceVisitTimeButtonsInput} />
                    <Field
                      name="visit_time"
                      newDesign
                      component={ChoiceVisitTimeControls}
                      validate={(value) => !value}
                      disabled={disable}
                    />
                  </div>
                </Labeled>
              </div>
            </div>
            <div className={styles.twoElems}>
                <Labeled
                  required
                  label={getIntlNumberOf(isRussianLocale ? ETranslations.PLURAL_HALLS_ALT : ETranslations.PLURAL_HALL)}
                >
                  <PlaceSelect styles={styles} isNewDesign disabled={disable} />
                </Labeled>
              <div className={styles.twoElemsTabelsEdit}>
                <Labeled required label={intlTitles.tableNumber}>
                  <div className="openSchemeContainer">
                    <Field
                      name="tables"
                      component={ChoiceTablesInputRedux}
                      validate={(value) => requiredField(value, getIntlBaseErrorNotSelected(intlTitles.tableNumber))}
                      disabled={disable}
                      isShowTableScheme
                    />
                  </div>
                </Labeled>
                <ChoiceAutoTableInputRedux disabled={disable} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formElement}>
          <div className={styles.eachElementGridEdit}>
            <Labeled label={intl.formatMessage({id: ETranslations.RESERVATION_TAGS})}>
              <Field
                name="tags"
                component={ChoiceTagsInput}
                disabled={disable}
              />
            </Labeled>
            <div className={styles.fullRowEdit}>
              <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.DEPOSIT})}>
                <Field name="deposit" isNewDesign component={ChoiceDepositInput} disabled={disable} />
              </Labeled>
            </div>
            <div className={cn(styles.twoElems, styles.soruceAndDepositEdit)}>
              <Labeled label={intl.formatMessage({id: ETranslations.SOURCE})}>
                <Field
                  name="source"
                  component={ChoiceSourceInput}
                  options={sources}
                  disabled={disable}
                />
              </Labeled>

              <Labeled required label={intl.formatMessage({id: ETranslations.HOSTESS})}>
                <Field
                  name="hostess"
                  component={ChoiceHostessInput}
                  options={userList}
                  disabled={disable}
                />
              </Labeled>
            </div>

            <div className={styles.fullRowEdit}>
              <Field
                name="comment"
                component={ChoiceNotesInput}
                label={getIntlJoinedParts([ETranslations.COMMENT, ETranslations.FOR_BOOKING])}
                disabled={disable}
              />


            </div>

            <div className={styles.fullRowEdit}>
              <Field
                className={cn(styles.bookingLabel, styles.notes)}
                name="notes"
                component={ChoiceNotesInput}
                label={intl.formatMessage({id: ETranslations.TABLE_PLACE})}
                disabled
              />
            </div>

          </div>
        </div>
        <Field name="rest" initialValue={rest}>
          {({input}) => (
            <input type="hidden" {...input} />
          )}
        </Field>
      </form>
      <BottomBar>
        <BottomBar.Part placement="left">
          <OrderControls order={order as BookingOrder} submit={handleSubmit} valid={valid} />
        </BottomBar.Part>
        <BottomBar.Part placement="right">
          <Edit showSave={false} />
        </BottomBar.Part>
      </BottomBar>
    </>
  );
};
