import {Response} from "models/common";
import _ from 'lodash';
import { Sentry } from "common/sentry";
import {config} from '../config';
import { ELocales } from "../types/commons";

interface AuthData {
  login: string;
  password: string;
  locale?: ELocales;
}

export type Role = 'ADMINISTRATOR' | 'RESTAURANT' | 'CALL_CENTER' | 'ADMINISTRATOR_RESTAURANT' | 'HOSTESS';

export class UserApp {
  constructor(
    public name: string,
    public photo: string,
    public role: Role,
    public id?: number,
  ) {}
}

export class AuthResponse {
  constructor(
    public token: string,
    public user: UserApp,
  ) {}
}

export class AuthService {
  static setToken(token: string) {
    localStorage.setItem('auth-token-wrf', token);
  }

  static setUser(user: UserApp) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  static isAuthenticated(): boolean {
    return _.isString(localStorage.getItem('auth-token-wrf'));
  }

  static rmToken() {
    localStorage.removeItem('auth-token-wrf');
  }

  static getToken(): string {
    return localStorage.getItem('auth-token-wrf') ?? '';
  }

  // TODO переписать на  Redux
  /** @deprecated Пользователя нужно хранить в store */
  static getUser(): UserApp {
    let user;
    if (localStorage.getItem('user')) {
      user = JSON.parse(localStorage.getItem('user') ?? '');
    } else {
      // Not broken previous auth logic
      const name = localStorage.getItem('nameUser') ?? '';
      const photo = localStorage.getItem('photoUser') ?? '';
      const role = localStorage.getItem('role') as Role ?? 'HOSTESS';
      user = new UserApp(name, photo, role);
    }
    Sentry.setUser({ username: user.name, id: user.id, role: user.role});
    return user;
  }

  static getUserRole(): Role {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user') ?? '').role ?? 'HOSTESS';
    } else {
      // Not broken previous auth logic
      return localStorage.getItem('role') as Role ?? 'HOSTESS';
    }
  }

  static async login(data: AuthData) {
    const resp: Response<AuthResponse> = await fetch(
      `api/auth/login`,
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({...data, tenant: config.tenant})
      }
    ).then( (r) => r.json());

    const {token, user: {name, photo, role, id}} = resp.data;
    AuthService.setToken(token);
    AuthService.setUser(new UserApp(name, photo ? photo : 'defaultUser', role, id));
  }

  static logout = async () => {
    AuthService.rmToken();
    try {
      _.get(global, 'channels.auth.cb')(false);
    } catch (e) {
      console.warn('Logout error');
    }
  }
}
