import React, {useMemo, useState} from "react";
import {Modal} from "components/modal";
import {BaseShiftsModal} from "components/shifts/Modal/BaseShiftsModal";
import styles from "./CreateGroupTagsModal.module.scss"
import {Field, Form} from "react-final-form";
import {useIntl} from "react-intl";
import {ETranslations} from "types/translates";
import {BottomBar, Button, Card, Input, Labeled, SelectBasic} from "ui-kit";
import {ChooseColorRedux} from "./sub-renders/ChooseColorComponent";
import {AddTagToGroupRedux} from "./sub-renders/AddTagToGroup";
import TagsContainer from "components/TagsContainer/TagsContainer";
import ModalPopup from "components/shifts/Modal/AccessRulesModal/ModalPopup";
import {useEditNewTagMutation} from "features/api/tags-api";
import {useSelector} from "react-redux";
import {appContextSelectors} from "features/AppContex";
import type { TagGroup } from "types/tag";

interface EditGroupTagsModalProps {
  isOpen: boolean;
  onClose: any;
  groupInfo: TagGroup;
  title: string;
  selectedTag: string;
}

export const EditGroupTagsModal = ({
    isOpen,
    onClose,
    groupInfo,
    selectedTag,
    title,
}: EditGroupTagsModalProps) => {

    const intl = useIntl()

    const types = [
        {value: "GLOBAL", label: intl.formatMessage({id: ETranslations.GLOBAL})},
        {value: "LOCAL", label: intl.formatMessage({id: ETranslations.LOCAL})},
    ]
    const restaurant = useSelector(appContextSelectors.restaurant);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [hasError, setHasError] = useState<string>("");
    const toggleOpensSave = () => {
        setOpenEditModal(prev => !prev)
    };
    const toggleServerError = () => {
        setHasError("");
    }
    const [edit] = useEditNewTagMutation();

    const onSubmit = (vals: TagGroup) => {
        // @ts-ignore
        const isGlobal = vals.group_type.value === "GLOBAL"
        edit({
            id: vals.id,
            name: vals.name,
            destination: selectedTag,
            color: vals.color,
            // @ts-ignore
            group_type: vals.group_type.value.toUpperCase(),
            owner_type: isGlobal ? undefined : "RESTAURANT",
            owner_id: isGlobal ? undefined : `${restaurant.restaurant_id}`,
            tags: vals.tags
        }).then((res) => {
            // @ts-ignore
            if (res?.error?.data?.errorMessage) {
                // @ts-ignore
                setHasError(res?.error?.data?.errorMessage as string)
                setOpenEditModal(false);
                return
            }
            onClose()
        });
    }

    const initalValue = useMemo(() => {
        return {
            ...groupInfo,
            group_type: {
                value: groupInfo.group_type,
                label: intl.formatMessage({id: groupInfo.group_type === "GLOBAL" ? ETranslations.GLOBAL : ETranslations.LOCAL})
            }
        }
    }, [groupInfo])
    const handleCloseModal = () => onClose();

    return (
        <BaseShiftsModal onClose={handleCloseModal} title={title} isOpen={isOpen}>
            <Modal.Content className={styles.modalContentAccess}>
                <Card onClose={handleCloseModal}>
                    <Form
                        initialValues={initalValue}
                        onSubmit={onSubmit}>
                        {({handleSubmit}) => {
                            return <form onSubmit={handleSubmit} className={styles.form} >
                                <div className={styles.content}>
                                    <p className={styles.formTitle}>{intl.formatMessage({id: ETranslations.GROUP_PlURAL})}</p>
                                    <div>
                                        <Labeled label={intl.formatMessage({id: ETranslations.GROUP_TYPE})}>
                                            <Field name="group_type">
                                                {(props) => (
                                                    <SelectBasic
                                                        options={types}
                                                        placeholder=""
                                                        onChange={props.input.onChange}
                                                        value={props.input.value}
                                                        disabled={true}
                                                    />
                                                )}
                                            </Field>
                                        </Labeled>

                                        <Labeled label={intl.formatMessage({id: ETranslations.NAME_GROUP})} required>
                                            <Field name="name">
                                                {(props) => (
                                                    <Input
                                                        onChange={props.input.onChange}
                                                        value={props.input.value}
                                                    />
                                                )}
                                            </Field>
                                        </Labeled>

                                    </div>
                                    <Labeled label={intl.formatMessage({id: ETranslations.GROUP_COLOR})} required>
                                        <Field name="color" component={ChooseColorRedux} />
                                    </Labeled>
                                    <p className={styles.formTitle}>{intl.formatMessage({id: ETranslations.TAGS})}</p>
                                    <Labeled label={intl.formatMessage({id: ETranslations.ADD_TAG})} required>
                                        <Field name="newTag" component={AddTagToGroupRedux} styles={styles} />
                                    </Labeled>
                                    <div className={styles.tagsContainer}>
                                        <TagsContainer />
                                    </div>
                                </div>

                                <BottomBar className={styles.controls}>
                                    <BottomBar.Part placement="left">
                                        <Button variant="primary" onClick={toggleOpensSave}>
                                            {intl.formatMessage({id: ETranslations.BASE_SAVE})}
                                        </Button>
                                        <Button variant="secondary" onClick={handleCloseModal}>
                                            {intl.formatMessage({id: ETranslations.BASE_CANCEL})}
                                        </Button>
                                    </BottomBar.Part>
                                </BottomBar>

                                {
                                    openEditModal && <ModalPopup
                                        needIcon={true}
                                        isOpen={openEditModal}
                                        onClose={toggleOpensSave}
                                        buttons={
                                            <>
                                                <Button variant="primary" type="submit" onClick={handleSubmit}>
                                                    {intl.formatMessage({id: ETranslations.BASE_SAVE})}
                                                </Button>
                                                <Button onClick={toggleOpensSave} variant="secondary">
                                                    {intl.formatMessage({id: ETranslations.CANCEL})}
                                                </Button>
                                            </>
                                        }
                                        title={intl.formatMessage({id: ETranslations.ASK_SAVE_CHANGES})}
                                        text={intl.formatMessage({id: ETranslations.IMMEDIATELY_CHANGES})}
                                    />
                                }

                                {hasError
                                    && <ModalPopup
                                        needIcon={true}
                                        editBooking
                                        isOpen={!!hasError}
                                        onClose={toggleServerError}
                                        buttons={
                                            <Button onClick={toggleServerError} variant="secondary">
                                                {intl.formatMessage({id: ETranslations.BASE_CLOSE})}
                                            </Button>
                                        }
                                        title={intl.formatMessage({id: ETranslations.BASE_ERROR})}
                                        text={hasError}
                                    />}
                            </form>
                        }}
                    </Form>
                </Card>

            </Modal.Content>
        </BaseShiftsModal>
    );
};
