import React from "react";
import styles from "./Form.module.scss";
import {Labeled} from "ui-kit";
import {Field} from "react-final-form";
import {InputField} from "./Steps/sub-renders/InputField";
import {required} from "components/shifts/utils";
import DataField from "./Steps/sub-renders/DataField";
import InfinityField from "./Steps/sub-renders/InfinityField";
import {WeekDaysField} from "components/ManagerialTables/form/fields";
import TimeField from "./Steps/sub-renders/TimeField";
import ShiftsField from "./Steps/sub-renders/ShiftsField";
import SchemaField from "./Steps/sub-renders/SchemaField";
import {useIntl} from "react-intl";
import {ETranslations} from "types/translates";

const RuleForm = () => {
  const intl = useIntl();
  return (
    <div className={styles.currentRuleForm}>
      <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.NAME_GROUP_OF_RULES})} required as="div">
        <Field disabled validate={required} name="groupName" placeholder="Бойцовский клуб" component={InputField} />
      </Labeled>
      <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.NAME_RULE})} required as="div">
        <Field validate={required} name="rule_name" placeholder="Бойцовский клуб" component={InputField} />
      </Labeled>
      <div className={styles.dateInfo}>
        <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.START_DATE})} required as="div">
          <Field validate={required} name="start_date" placeholder="Бойцовский клуб" component={DataField} />
        </Labeled>
        <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.END_DATE})} required as="div">
          <Field name="end_date" placeholder="Бойцовский клуб" component={DataField} validate={(fieldValue, formValues) => {
            //@ts-ignore
            if (formValues?.infininte) {
              return undefined
            } else {
              return required(fieldValue)
            }
          }} />
        </Labeled>
        <Field name={`infininte`} component={InfinityField} />
      </div>

      <Labeled className={styles.bookingLabel} 
              label={intl.formatMessage({id: ETranslations.DAYS_TO_CONTINUE_THIS_SHIFT})} required as="div">
        <Field validate={required} placeholder="Бойцовский клуб" name={`repeat_days`} component={WeekDaysField} />
      </Labeled>

      <div className={styles.hours}>
        <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.START_TIME})} as="div">
          <Field validate={required} name="start_active_time" placeholder="Бойцовский клуб" component={TimeField} />
        </Labeled>
        <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.END_TIME})} as="div">
          <Field validate={required} name="end_active_time" placeholder="Бойцовский клуб" component={TimeField} />
        </Labeled>
      </div>
      <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.SHIFT})} required as="div">
        <Field disabled validate={required} name="shift_id" placeholder="Бойцовский клуб" component={ShiftsField} />
      </Labeled>
      <div className={styles.schemaInfo}>
        <Labeled className={styles.bookingLabel} label={intl.formatMessage({id: ETranslations.AVAILABLE_FOR_HALL})} as="div">
          <Field disabled validate={required} name="places" placeholder="Бойцовский клуб" component={SchemaField} />
        </Labeled>
      </div>
    </div>
  );
};

export default RuleForm;
