import type { ReactNode } from 'react';
import cn from 'classnames';
import type { BaseStatus, ExtraStatus } from 'types/status';
import type { Booking, ManagerialStatus, Source } from 'types/booking';
import { ICONS } from 'ui-kit';
import { BaseStatusTag } from './BaseStatusTag';
import { ExtraStatusTag } from './ExtraStatusTag';
import type { TNullable } from 'types/commons';
import { useIsTablet } from 'ui-kit/hooks';
import { tagsToShortView } from './utils';
import { BookingTags } from './BookingTags';

export const BookingHeader = ({
  styles,
  useDeposit,
  compact,
  status,
  extraStatus,
  phone,
  source,
  tags,
  isDashboard,
  isEndingSoon,
  children,
}: {
  styles: {
    [key: string]: string;
  };
  useDeposit: boolean;
  children: ReactNode;
  phone: string | false;
  source: TNullable<Source>;
  tags?: Booking['tags'];
  compact?: boolean;
  status?: BaseStatus | ManagerialStatus | false;
  extraStatus?: ExtraStatus | false | null;
  isDashboard?: boolean;
  isEndingSoon: boolean;
}) => {
  const isTablet = useIsTablet();
  const visibleTagsCount = isDashboard && !isTablet ? 3 : 2
  const formattedTags = tags
    ? tagsToShortView(tags, visibleTagsCount)
    : { visibleTags: [], invisibleTagsCount: 0 };

  const renderStatusSection = () => {
    if (isDashboard)
      return (
        <div className={cn(styles.section, styles.clientInfo)}>
          {children}
          <div className={styles.statusGroup}>
            <BaseStatusTag
              className={cn(styles.statusTag, styles.baseStatusTag)}
              status={status}
            />
            {extraStatus && (
              <ExtraStatusTag
                className={styles.statusTag}
                extraStatus={extraStatus}
              />
            )}
            {useDeposit && <ICONS.DepositLock />}
            {isEndingSoon && <ICONS.ClockCircle />}
          </div>
        </div>
      );

    if (compact && (!isTablet || !extraStatus))
      return (
        <div className={cn(styles.section, styles.clientInfo)}>
          {children}
          <div className={styles.statusGroup}>
            <BaseStatusTag
              className={cn(styles.statusTag, styles.baseStatusTag)}
              status={status}
            />
            {useDeposit && <ICONS.DepositLock />}
            {isEndingSoon && <ICONS.ClockCircle />}
          </div>
        </div>
      );

    return (
      <>
        <div
          className={cn(styles.section, styles.statusGroup, {
            [styles.dashboardTag]: isDashboard,
          })}
        >
          <BaseStatusTag
            className={cn(styles.statusTag, styles.baseStatusTag)}
            status={status}
          />
          {useDeposit && <ICONS.DepositLock />}
          {isEndingSoon && <ICONS.ClockCircle />}
          {extraStatus && (
            <ExtraStatusTag
              className={styles.statusTag}
              extraStatus={extraStatus}
            />
          )}
        </div>
        <div className={styles.section}>{children}</div>
      </>
    );
  };

  return (
    <>
      {renderStatusSection()}
      {(phone || formattedTags.visibleTags.length || (extraStatus && compact && !isTablet && !isDashboard )) && (
        <div className={styles.section}>
          <div className={styles.subSection}>
            <span className={styles.phone}>{phone}</span>
            {Boolean(formattedTags.visibleTags.length) && < BookingTags tags={formattedTags}/>}
          </div>
          {extraStatus && compact && !isTablet && !isDashboard && (
            <ExtraStatusTag
              className={styles.statusTag}
              extraStatus={extraStatus}
            />
          )}
        </div>
      )}
    </>
  );
};
