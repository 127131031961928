import React, {FC} from 'react';
import {SlotEvent, TTable} from 'features/api/hallschema-api';
import {TablePathSvg} from './TablePathSvg';
import {Template, TemplatePlaceholder} from '@devexpress/dx-react-core';
import {Plugins} from '@technolab/hse';

interface Props {
  slots: SlotEvent[];
  places: Record<number, TTable>
}

export const TablePathTemplate: FC<Props> = ({slots, places}) => {

  return (
    <Template name={Plugins.Sector.TEMPLATES.root}>
      {slots.map((slot) => <TablePathSvg slots={[slot]} places={places} key={slot.booking.bookingId}/>)}
      <TemplatePlaceholder/>
    </Template>
  );
};
