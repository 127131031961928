import React from 'react';
import {Spinner} from 'ui-kit';
import {usePageId} from 'contexts/LayoutContext';

const ScheduleLandingComponent = React.lazy(() => import('../../components/schedule-landing/schedule-landing'));
export function ScheduleLanding() {

  usePageId('scheduler-landing');



  return (
    <React.Suspense fallback={<Spinner />}>
      <ScheduleLandingComponent />
    </React.Suspense>
  );
}
