import {useCallback} from 'react';
import moment, {Moment} from 'moment';
import {useSelector} from 'react-redux';

import {RadioButton, Button, ICONS, DatePicker} from "ui-kit";

import {appContextSelectors} from 'features/AppContex';
import {useApplicationContextActions} from 'features/AppContex/slice';

import styles from  './Header.module.scss'
import {useIntl} from "react-intl";
import {ETranslations} from "../../../types/translates";
import {timelineSelectors, useTimelineActions} from 'features/Timeline';

export function DateControls() {
  const intl = useIntl();
  const {setDate} = useApplicationContextActions();
  const timestamp = useSelector(timelineSelectors.getTimestamp);
  const {setTimestamp, setIsActualTime} = useTimelineActions();
  const timestampDate = moment(timestamp);


  const startDate = useSelector(appContextSelectors.date);

  const isNeedActualTime = (first : Moment, second: Moment) => {
    const firstTime = moment(first.format('HH:mm'), 'HH:mm')
    const secondTime = moment(second.format('HH:mm'), 'HH:mm')
    const diff = firstTime.diff(secondTime, 'minutes');

   return setIsActualTime(Math.abs(diff) <= 5)
  }


  const getDay = useCallback(() => {
    if (moment().isSame(startDate, 'day')) return 'today';
    return moment().add(1, 'day').isSame(startDate, 'day') ? 'tomorrow' : undefined
  }, [startDate]);

  const prevDay = useCallback(() => {
    const now = moment();
    const prev = startDate.clone().add({day: -1})
    if(now.isSame(prev, 'day')) {
      isNeedActualTime(now, prev)
    } else {
      setIsActualTime(false)
    }
    setTimestamp(timestampDate.add(-1, 'day').valueOf())
    setDate(prev);
  }, [startDate, setDate, timestamp]);

  const nextDay = useCallback(() => {
    const now = moment();
    const next = startDate.clone().add({day: 1})
    if(now.isSame(next, 'day')) {
      isNeedActualTime(now, next)
    } else {
      setIsActualTime(false)
    }
    setTimestamp(timestampDate.add(1, 'day').valueOf())
    setDate(next);
  }, [startDate, setDate, timestamp]);

  const switchDate = (day: 'today' | 'tomorrow' | undefined) => {
    const date = moment();
    if (day === 'today') {
      setTimestamp(date.clone()
        .set('hour', timestampDate.hour())
        .set('minute', timestampDate.minute())
        .valueOf())
      setDate(date)
      isNeedActualTime(date, timestampDate)
    } else {
      const next = date.clone().add({day: 1})
      setTimestamp(next.set('hour', timestampDate.hour()).set('minute', timestampDate.minute()).valueOf())
      setDate(next);
      setIsActualTime(false)
    }
  };

  const datePickerHandler = (date: Date) => {
    const now = moment()
    const selectedDate = moment(date);
    const timestampDay = timestampDate.clone().startOf('day');
    const diffDays = selectedDate.diff(timestampDay, 'day')

    const newTimestamp = timestampDate.clone().add({
      day: diffDays
    })

    setDate(selectedDate)
    setTimestamp(newTimestamp.valueOf())

    if(selectedDate.isSame(now, 'day')) {
      isNeedActualTime(now, newTimestamp)
    } else {
      setIsActualTime(false)
    }
  }

  return (
    <>
      <RadioButton value={getDay()} onChange={switchDate} className={styles.dateControl}>
        <RadioButton.Button value="today">{intl.formatMessage({id: ETranslations.TODAY})}</RadioButton.Button>
        <RadioButton.Button value="tomorrow">{intl.formatMessage({id: ETranslations.TOMORROW})}</RadioButton.Button>
      </RadioButton>
      <div className={styles.calendar}>
        <Button
          className={styles.dateButton}
          variant="phantom"
          onClick={prevDay}
        >
          <img src={ICONS.leftLogo} alt="" />
        </Button>
        <DatePicker
          selected={startDate.toDate()}
          dateFormatCalendar="MMMM"
          onChange={datePickerHandler}
          className={styles.datepicker}
          customInputFormatDate="ddd, DD.MM.YYYY"
          customInputClassName={styles.datePickerInput}
        />
        <Button variant="phantom" onClick={nextDay} className={styles.dateButton}><img src={ICONS.right} alt="" /></Button>
      </div>
    </>
  )

}
