import moment from 'moment';
import {useMemo} from 'react'
import {useActiveShift} from "features/api/shifts";

const useClosestInterval = () => {
    const now = useMemo(moment, []);
    const shift = useActiveShift(now);

    const closestInterval = useMemo(() => {
        if (!shift) return null;
        return Object?.keys(shift.absolute_times)?.find((val, idx, arr) => {
            if (val === now.format("HH:mm")) return true;
            if (idx < 1) return false;
            const current = moment(val, "HH:mm");
            const prev = moment(arr[idx - 1], "HH:mm");
            return now.isSameOrAfter(prev, "second") && now.isSameOrAfter(current, "second");
        });
    }, [shift, now]);

    return closestInterval;
}

export default useClosestInterval