import cn from 'classnames';
import { extraStatusColors } from 'constants/colors';
import { editStatusFormInputNames } from '../utils';
import { useState } from 'react';
import type { IntlFormatters } from 'react-intl';
import { ETranslations } from 'types/translates';

const ColorRadioInput = ({
  id,
  color,
  hide,
  checked,
}: {
  id: number;
  color: string;
  hide: boolean;
  checked?: boolean;
}) => (
  <>
    <input
      id={`color-${id}`}
      type="radio"
      value={color}
      name={editStatusFormInputNames.color}
      defaultChecked={checked}
    />
    <label
      className={cn('color-option', { hide })}
      htmlFor={`color-${id}`}
      style={{ backgroundColor: color }}
    />
  </>
);

export const Palette = ({
  title,
  currentColor,
  formatMessage,
}: {
  title: string;
  currentColor: typeof extraStatusColors[number];
  formatMessage: IntlFormatters['formatMessage'];
}) => {
  const [isExtended, setIsExtended] = useState(false);
  const buttonText = isExtended
    ? formatMessage({ id: ETranslations.LESS })
    : formatMessage(
        { id: ETranslations.BASE_SHOW_ENTITY },
        {
          entity: formatMessage({
            id: ETranslations.BASE_ALL,
          }).toLowerCase(),
        }
      );

  return (
    <section className='palette'>
      <div className="palette-title">
        {title}
        <button
          onClick={(e) => (e.preventDefault(), setIsExtended((prev) => !prev))}
        >
          {buttonText}
        </button>
      </div>
      {extraStatusColors.map(({ id, color, extendedPalette }) => (
        <ColorRadioInput
          key={id}
          id={id}
          color={color}
          hide={!isExtended && extendedPalette}
          checked={id === currentColor.id}
        />
      ))}
    </section>
  );
};
